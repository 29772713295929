import { connect } from "react-redux";
import { taskResult } from "state/selectors/projects";
import { setNewPoPosition } from "state/actions/projects";
import ResultContainer from "./ResultContainer";

const mapStateToProps = state => ({
  taskResult: taskResult(state)
});

const mapDispatchToProps = {
  setNewPoPosition
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultContainer);

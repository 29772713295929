import React from "react";
import handleFormat from "./handleFormat";
import titles from "./titles";

const FacilityResultValues = ({ data }) => {
  return (
    <div className="facility-result-values">
      <div className="facility-result-values-top">
        <h3 className="facility-result-value-title">
          Суммарная стоимость, руб.
        </h3>
        <h3 className="facility-result-value">{handleFormat(data.total)}</h3>
        <div className="clearfix" />
      </div>
      <div className="facility-result-values-bottom">
        {Object.keys(titles).map((key, index) => {
          const formatValue = handleFormat(data[key]);
          return (
            <div key={index} className="facility-result-field-row">
              <h3 className="facility-result-value-title">— {titles[key]}</h3>
              <h3 className="facility-result-value">{formatValue}</h3>
              <div className="clearfix" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FacilityResultValues;

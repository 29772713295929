import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import CommonButtons from "../TaskResultsTopRow/CommonButtons";
import TaskLogs from "../TaskLogs";
import FacilityResultContainer from "./FacilityResultContainer";
import HandleFacility from "./HandleFacility";

const FacilityTaskResultView = ({ taskResult }) => {
  const [handler, setHandler] = React.useState("logs");
  return (
    <div className="task-complete">
      <div className="task-complete__toggle-row">
        <CommonButtons currentHandler={handler} toggleHandler={setHandler} />
        {handler === "results" &&
          taskResult &&
          taskResult[0] &&
          taskResult[0].props?.start_cost && <HandleFacility />}
      </div>
      <div className="task-complete__main-container">
        <ScrollBar>
          {handler === "logs" ? (
            <TaskLogs />
          ) : (
            <FacilityResultContainer taskResult={taskResult} />
          )}
        </ScrollBar>
      </div>
    </div>
  );
};

export default FacilityTaskResultView;

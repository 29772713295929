import React from "react";
import { connect } from "react-redux";
import { showModal as setShowModal } from "state/actions/settings";
import { modalOption } from "state/selectors/settings";

import CancelConfirmationContainer from "./CancelConfirmationContainer";

const CancelConfirmationRedux = props => {
  return <CancelConfirmationContainer {...props} />;
};
const mapDispatchToProps = {
  setShowModal
};
const mapStateToProps = state => ({
  confirmCancelation: modalOption(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelConfirmationRedux);

import { connect } from "react-redux";
import { networks, getTasksById } from "state/selectors/projects";

import { getSortedLinears } from "state/selectors/objects";

import { setInfoLogs } from "state/actions/common";

import {
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities
} from "state/actions/settings";

import {
  hideLinears,
  setOpenedTasks,
  hideLinearsMetaclass,
  hideLinearsByMetaclassAndType,
  hideLinearsByMetaclassAndNetwork
} from "state/actions/groupsSettings";

import {
  sortClassesByMetaclasses,
  sortMetaclassesByClasses
} from "state/selectors/wellfield";

import { getTaskResult, setTaskId } from "state/actions/projects";

import LinearsListContainer from "./LinearsListContainer";

const mapDispatchToProps = {
  activeTab,
  setTaskId,
  setInfoLogs,
  setCardInfo,
  hideLinears,
  getTaskResult,
  setRemoveProps,
  setOpenedTasks,
  openRemoveAlert,
  setTaskFacilities,
  hideLinearsMetaclass,
  hideLinearsByMetaclassAndType,
  hideLinearsByMetaclassAndNetwork
};

const mapStateToProps = state => ({
  networks: networks(state),
  getTasksById: getTasksById(state),
  getSortedLinears: getSortedLinears(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state),
  sortMetaclassesByClasses: sortMetaclassesByClasses(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearsListContainer);

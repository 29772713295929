import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import classnames from "classnames";
import DropdownComponent from "./DropdownComponent";
import DataFieldsContainer from "./DataFieldsContainer";

const DataFields = ({
  dem = "",
  type,
  name = "",
  value,
  error,
  label,
  content,
  options,
  required,
  disabled,
  placeholder,
  onBlurHandler,
  onChangeHandler,
  classContainer = ""
}) => {
  let err = error;
  if (error && required && (value === 0 || value)) {
    err = false;
  }
  return (
    <>
      <div
        className={classnames("DataFieldsRow", classContainer, { error: err })}
      >
        {!!label && (
          <span className="input-label">
            {label}
            {required && " *"}
          </span>
        )}
        {type === "option" ? (
          <DropdownComponent
            name={name}
            value={value}
            placeholder={placeholder}
            type={type}
            onChangeHandler={onChangeHandler}
            options={options}
            disabled={disabled}
          />
        ) : (
          <DataFieldsContainer
            dem={dem}
            value={value}
            name={name}
            onBlurHandler={onBlurHandler}
            disabled={disabled}
            required={required}
            type={type}
            placeholder={placeholder}
            onChangeHandler={onChangeHandler}
          />
        )}
      </div>
      {content && <span className="create-task-row-content">{content}</span>}
    </>
  );
};

DataFields.propTypes = {
  dem: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  /** Options for select [{ value: '1', label: '1 }] */
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeHandler: PropTypes.func.isRequired,
  classContainer: PropTypes.string
};

DataFields.defaultProps = {
  dem: "",
  label: "",
  placeholder: "",
  type: "",
  options: [],
  value: "",
  classContainer: ""
};

export default DataFields;

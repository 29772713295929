import React, { useState, useEffect } from "react";
import "../modal.scss";
import area from "@turf/area";
import Button from "components/Common/Button";

const AboutProjectModalContainer = ({
  projectName,
  projectArea,
  layout,
  getWellfieldName,
  setShowModal
}) => {
  const [areaSquare, setAreaSquare] = useState("");
  const closeRemoveAlert = () => {};
  useEffect(() => {
    if (projectArea) {
      setAreaSquare((area(projectArea) / 1000000).toFixed(2));
    }
  }, [projectArea]);
  const closeHandler = () => {
    setShowModal("SUCCESS", { type: "", option: null });
  };
  return (
    <>
      <div className="splash-screen" />
      <div className="modal">
        <div className="modal-container">
          <div className="main-container__top-row modal-container__top-row">
            <h2 className="main-title">О проекте</h2>
            <div onClick={closeRemoveAlert} className="new-project__close">
              <div
                onClick={closeHandler}
                className="new-project__close-line close"
              />
            </div>
          </div>
          <div className="modal-content">
            <div className="modal-content__row">
              <h2 className="modal-content__title">Название проекта</h2>
              <h3 className="modal-content__subtitle">{projectName}</h3>
            </div>
            <div className="modal-content__row">
              <h2 className="modal-content__title">Месторождение</h2>
              <h3 className="modal-content__subtitle">{getWellfieldName}</h3>
            </div>
            <div className="modal-content__row">
              <h2 className="modal-content__title">Схема обустройства</h2>
              <h3 className="modal-content__subtitle">{layout}</h3>
            </div>
            <div className="modal-content__row">
              <h2 className="modal-content__title">Область строительства</h2>
              <h3 className="modal-content__subtitle">{areaSquare} км2</h3>
            </div>
          </div>
          <div className="main-container__bottom-row">
            <Button secondary onClick={closeHandler}>
              Закрыть
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutProjectModalContainer;

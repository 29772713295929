import React, { useEffect, useState, useRef } from "react";
import { withLeaflet, Pane, Polyline } from "react-leaflet";
import Markers from "./Markers";

import "./style.scss";

const polylineStyle = { weight: 3, color: "#8495ac" };

const Ruler = ({ leaflet: { map } }) => {
  const solidLine = useRef(null);
  const [points, setPoints] = useState([]);
  useEffect(() => {
    const clickHandler = ({ latlng, originalEvent: { target } }) => {
      if (!target.classList.contains("ruler-marker")) {
        setPoints(prev => [...prev, latlng]);
      }
    };
    map.on("click", clickHandler);
    return () => map.off("click", clickHandler);
  }, [map]);
  const removePoint = key => {
    const pointsArr = points.slice();
    pointsArr.splice(key, 1);
    setPoints(pointsArr);
  };
  const dragendHandler = ({ target: { _latlng } }, index) => {
    const pointsArr = points.slice();
    setPoints([]);
    pointsArr[index] = _latlng;
    setPoints(pointsArr);
  };
  const onMoveHandler = ({ latlng }, index) => {
    const pointsArr = points.slice();
    pointsArr[index] = latlng;
    solidLine.current.leafletElement.setLatLngs(pointsArr);
  };
  if (!points.length) return null;
  const iconSize = [12, 12];
  return (
    <Pane name="ruler">
      <Polyline {...polylineStyle} ref={solidLine} positions={points} />
      <Markers
        points={points}
        iconSize={iconSize}
        removePoint={removePoint}
        onClosePopup={removePoint}
        onMoveHandler={onMoveHandler}
        dragendHandler={dragendHandler}
      />
    </Pane>
  );
};
export default withLeaflet(Ruler);

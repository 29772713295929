import {
  LOGIN_SUCCESS,
  SET_AUTH_SUCCESS,
  SET_AUTH_FAILURE
} from "../constants/auth";

const initialState = {
  auth: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_SUCCESS:
      return {
        ...state,
        auth: action.payload
      };
    case SET_AUTH_FAILURE:
      return {
        ...state,
        auth: false
      };
    case LOGIN_SUCCESS:
    default:
      return state;
  }
}

import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import ProjectList from "./ProjectList";
import "../projects.scss";

const OpenProjectsComponent = ({
  openList,
  activeText,
  download,
  remove,
  projects,
  projectId,
  activeRow,
  choseProject,
  tooltipRef
}) => {
  return (
    <ScrollBar>
      <ProjectList
        tooltipRef={tooltipRef}
        remove={remove}
        openList={openList}
        projects={projects}
        download={download}
        projectId={projectId}
        activeRow={activeRow}
        activeText={activeText}
        choseProject={choseProject}
      />
    </ScrollBar>
  );
};

export default OpenProjectsComponent;

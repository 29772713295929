import React from "react";
import { connect } from "react-redux";
import { taskResult, taskId } from "state/selectors/projects";
import { getTaskResult } from "state/actions/projects";
import { setHideLinearsByNetwork } from "state/actions/groupsSettings";
import { ActiveResultComponent } from "../ResultTable";
import PipesContainer from "./PipesContainer";

const RoadsResult = props => {
  const [activeResult, setActiveResult] = React.useState();
  const { taskResult } = props;
  const removeResult = () => {
    setActiveResult("");
    if (typeof props.handleActiveResult === "function") {
      props.handleActiveResult("");
    }
    props.setHideLinearsByNetwork("SUCCESS", []);
  };
  const onClickHandler = (el, index) => {
    setActiveResult({ ...el, index });
    const hiddenNetworks = taskResult
      .filter(item => item.props.network_id !== el.props.network_id)
      .map(({ props }) => props.network_id);
    if (typeof props.handleActiveResult === "function") {
      props.handleActiveResult({ ...el, index });
    }
    props.setHideLinearsByNetwork("SUCCESS", hiddenNetworks);
  };
  return (
    <div className="task-result__container">
      {activeResult && (
        <ActiveResultComponent
          goBack={removeResult}
          activeResult={activeResult}
          linearResultType="linears"
          taskType="pipes"
        />
      )}
      {taskResult && !activeResult && (
        <PipesContainer
          onClickHandler={onClickHandler}
          taskResult={taskResult}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  taskId: taskId(state),
  taskResult: taskResult(state)
});

const mapDispatchToProps = { setHideLinearsByNetwork, getTaskResult };

export default connect(mapStateToProps, mapDispatchToProps)(RoadsResult);

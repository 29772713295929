import { connect } from "react-redux";
import { getClickType, getActiveTab } from "state/selectors/settings";
import { getClassesByMetaclass } from "state/actions/wellfield";
import {
  clickType,
  activeTab,
  setTaskSettings,
  changeTaskSettings,
  setTransformSettings,
  showModal as setShowModal
} from "state/actions/settings";
import { removeHiddenMetaclass } from "state/actions/groupsSettings";
import { additionalTaskProps } from "state/selectors/wellfield";

import {
  avaliableTaskTypes,
  metaclassesByTypeWithPalette
} from "state/selectors/projects";

import TopMenuContainer from "./TopMenuContainer";

const mapStateToProps = state => ({
  getClickType: getClickType(state),
  getActiveTab: getActiveTab(state),
  avaliableTaskTypes: avaliableTaskTypes(state),
  additionalTaskProps: additionalTaskProps(state),
  metaclassesByTypeWithPalette: metaclassesByTypeWithPalette(state)
});

const mapDispatchToProps = {
  clickType,
  activeTab,
  setShowModal,
  setTaskSettings,
  changeTaskSettings,
  setTransformSettings,
  getClassesByMetaclass,
  removeHiddenMetaclass
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuContainer);

import { connect } from "react-redux";
import { taskId } from "state/selectors/projects";
import { showTaskResults } from "state/actions/settings";
import { cardInfo } from "state/selectors/settings";
import { oppenedTasks, getLinearsToggle } from "state/selectors/groupsSettings";
import { removeOpenedTasks } from "state/actions/groupsSettings";

import SwitchResultsContainer from "./SwitchResultsContainer";

const mapStateToProps = state => ({
  taskId: taskId(state),
  cardInfo: cardInfo(state),
  oppenedTasks: oppenedTasks(state),
  getLinearsToggle: getLinearsToggle(state)
});

const mapDispatchToProps = {
  showTaskResults,
  removeOpenedTasks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchResultsContainer);

import React from "react";
import Select, { components } from "react-select";
import classnames from "classnames";
import colourStyles from "./utils/colourStyles";
import { ReactComponent as DropdownArrow } from "../../../assets/images/dropdown.svg";

const DropdownIndicator = props => {
  const { isDisabled } = props;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownArrow
          style={{
            fill: isDisabled ? "#656F79" : "#77F4E8"
          }}
        />
      </components.DropdownIndicator>
    )
  );
};
const Input = props =>
  components.Input && (
    <components.Input
      {...props}
      className="custom-input"
      styles={{ background: "black" }}
    />
  );
const filterOption = val => ({ value }) => value === val;
const DropdownComponent = ({
  options,
  disabled,
  name,
  value,
  placeholder,
  type,
  onChangeHandler
}) => {
  const setValueToSelect = val => {
    if (val !== undefined && options) {
      return options.filter(filterOption(val))[0];
    }
    return null;
  };
  return (
    <div className={classnames("select-container", { disabled })}>
      <div className="data-fields-error-line" />
      <Select
        name={name}
        value={setValueToSelect(value)}
        components={{ DropdownIndicator, Input }}
        styles={colourStyles(disabled)}
        placeholder={placeholder}
        isDisabled={!!disabled}
        menuPlacement="auto"
        options={options}
        onChange={e => onChangeHandler(e, type)}
      />
    </div>
  );
};

export default DropdownComponent;

import React from "react";
import coloringObj from "utils/coloringObj";
import GetWithErrorHandling from "utils/GetWithErrorHandling";
import ButtonContainer from "./ButtonContainer";

const GatheringCentersResultsViews = ({
  dataArr,
  facilities,
  taskResultProps,
  applied,
  type,
  facilitiesIds
}) => {
  return (
    <div className="task-result__container">
      {dataArr.map((el, index) => {
        return (
          <div key={el} className="task-result__row">
            <span
              className="task-results__color-line"
              style={{ backgroundColor: coloringObj(`tmpColor${index}`).color }}
            />
            <div className="task-results-inline" />
            <div className="d-inline align-middle task-results__title-container">
              <h3 className="task-results__title">
                {GetWithErrorHandling({
                  data: facilities,
                  path: [el, 0, "name"]
                })}
              </h3>
            </div>
            <div className="d-inline align-middle">
              <h3 className="task-results__sub-title">
                Кустовые площадки:
                <span className="task-results__props-value d-inline align-middle">
                  {facilitiesIds[index]
                    .map(el =>
                      GetWithErrorHandling({
                        data: facilities,
                        path: [el, 0, "name"]
                      })
                    )
                    .join(", ")}
                </span>
              </h3>
            </div>
            {applied && (
              <ButtonContainer
                type={type}
                data={taskResultProps}
                index={index}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GatheringCentersResultsViews;

import React, { Component } from "react";
import { connect } from "react-redux";
import { clickType, setCardInfo } from "state/actions/settings";
import { getTaskSettings } from "state/selectors/settings";

import CommonFields from "./CommonFields";

class Area extends Component {
  componentWillUnmount() {
    this.props.setCardInfo("SUCCESS", {});
  }

  componentDidMount() {
    if (!this.props.taskInfo) {
      this.props.clickType("SUCCESS", ["createArea", "drawAreaObject"]);
    }
  }

  render() {
    const { taskInfo, errors = [], getTaskSettings } = this.props;
    return (
      <div>
        <CommonFields errors={errors} data={taskInfo} info={!!taskInfo} />
        {!getTaskSettings.geom && !taskInfo && (
          <div className="alert-row">
            <h2 className="alert-row__title">Нарисуйте объект на карте</h2>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  clickType,
  setCardInfo
};

export default connect(
  state => ({
    getTaskSettings: getTaskSettings(state)
  }),
  mapDispatchToProps
)(Area);

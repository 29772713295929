import React from "react";
import { withLeaflet, Marker } from "react-leaflet";
import _get from "lodash.get";
import { divIcon } from "leaflet";

import coloringObj from "utils/coloringObj";
import _isEqual from "lodash.isequal";
import "../connectionPointsStyle.scss";

class ConnectionPoints extends React.Component {
  state = { points: null, show: false };

  componentDidMount() {
    const { leaflet } = this.props;
    this.handleProps(this.props);
    this.showHandler();
    leaflet.map.on("zoomend", this.showHandler);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      projectPalette,
      connectionTypes,
      connectionPoints,
      sortClassesByMetaclasses
    } = this.props;
    const { points, show } = this.state;
    if (
      !_isEqual(nextProps.projectPalette, projectPalette) ||
      !_isEqual(nextProps.connectionTypes, connectionTypes) ||
      !_isEqual(nextProps.connectionPoints, connectionPoints) ||
      !_isEqual(nextProps.sortClassesByMetaclasses, sortClassesByMetaclasses)
    ) {
      return true;
    }
    if (!_isEqual(points, nextState.points) || show !== nextState.show) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    this.handleProps(this.props);
  }

  componentWillUnmount() {
    const { leaflet } = this.props;
    leaflet.map.off("zoomend", this.showHandler);
  }

  handleProps = props => {
    const {
      connectionPoints,
      connectionTypes,
      sortClassesByMetaclasses,
      projectPalette
    } = props;
    if (
      connectionPoints &&
      connectionTypes &&
      sortClassesByMetaclasses &&
      projectPalette
    ) {
      const points = connectionPoints.map(el => {
        return el.connection_points.map(item => {
          const classId = _get(sortClassesByMetaclasses, [
            connectionTypes[item.connection_type_id].linear_metaclass_id,
            "valuesArray",
            0
          ]);
          return {
            ...item,
            type: el.type,
            palette: projectPalette[classId],
            connectionType: {
              ...connectionTypes[item.connection_type_id],
              classId
            }
          };
        });
      });
      const arr = points.flat().filter(({ type }) => {
        return type !== "calculated_tmp";
      });
      this.setState({ points: arr });
    }
  };

  showHandler = () => {
    const { leaflet } = this.props;
    const { show } = this.state;
    if (leaflet.map.getZoom() > 12) {
      if (!show) {
        this.setState({ show: true });
      }
    } else if (show) {
      this.setState({ show: false });
    }
  };

  render() {
    const { points, show } = this.state;
    if (!points || !show || !points.length) return null;
    return (
      <>
        {points.map(el => {
          const [lng, lat] = el.geom.coordinates;
          const customIcon = divIcon({
            className: "connection-point",
            iconSize: [6, 4],
            iconAnchor: [3, 2],
            html: `<div style="background-color: ${
              coloringObj(el.palette).color
            }" class="${el.palette}" />`
          });
          return (
            <Marker
              key={el.id}
              icon={customIcon}
              position={{ lat, lng }}
              zIndexOffset={-999}
              options={{
                type: el.connectionType.type,
                metaclassId: el.connectionType.linear_metaclass_id
              }}
            />
          );
        })}
      </>
    );
  }
}

export default withLeaflet(ConnectionPoints);

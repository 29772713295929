export default (type, distanceToQuarry) => {
  const data = {
    power_supply_centers: [
      {
        label: "Кустовые площадки",
        name: "power_consumers_ids",
        chooseObjectsParams: ["choosePowerConsumersIds"]
      }
    ],
    water_pumping_stations: [
      {
        label: "Кустовые площадки",
        name: "water_consumers_ids",
        chooseObjectsParams: ["waterConsumersIds"]
      }
    ],
    roads: [
      {
        label: "Объекты",
        name: "facilities_ids",
        chooseObjectsParams: ["linearRoads", "chooseFacility"]
      }
    ],
    gathering_centers: [
      {
        label: "Кустовые площадки",
        name: "wells_ids",
        chooseObjectsParams: ["chooseGatheringCenters"]
      }
    ],
    facility_placement: [
      {
        label: "Объект",
        name: "facility_id",
        chooseObjectsParams: ["chooseSingleFacility"]
      },
      {
        label: "Карьеры",
        name: "quarries_ids",
        chooseObjectsParams: ["chooseQuarries"]
      }
    ],
    quarries: [
      {
        label: "Карьеры",
        name: "quarries_ids",
        chooseObjectsParams: ["chooseQuarries"]
      }
    ],
    linear_cost: [
      {
        label: "Объект",
        name: "linear_id",
        chooseObjectsParams: ["chooseLinearObject"]
      }
    ],
    high_pressure_water_pipe: [
      {
        label: "Стоки",
        name: "sinks_ids",
        chooseObjectsParams: ["chooseSinks"]
      },
      {
        label: "Исток",
        name: "sources_ids",
        chooseObjectsParams: ["singleObj", "chooseSources"]
      }
    ],
    high_voltage: [
      {
        name: "sources_ids",
        label: "Источник",
        chooseObjectsParams: ["singleObj", "chooseSources"]
      },
      {
        name: "consumers_ids",
        label: "Потребители",
        chooseObjectsParams: ["chooseConsumers"]
      }
    ]
  };
  if (
    [
      "oil_pipe",
      "oil_gathering_line",
      "low_pressure_gas_pipe",
      "high_pressure_gas_pipe",
      "low_pressure_water_pipe"
    ].some(el => el === type)
  ) {
    return [
      {
        label: "Сток",
        name: "sinks_ids",
        chooseObjectsParams: ["singleObj", "chooseSinks"]
      },
      {
        label: "Истоки",
        name: "sources_ids",
        chooseObjectsParams: ["chooseSources"]
      }
    ];
  }
  if (type === "facility_placement" && distanceToQuarry) {
    return [
      {
        label: "Объект",
        name: "facility_id",
        chooseObjectsParams: ["distanceToQuarry", "chooseSingleFacility"]
      }
    ];
  }
  return data[type];
};

import React from "react";
import { useSelector } from "react-redux";
import { getActiveTab } from "state/selectors/settings";
import classnames from "classnames";

import Handlers from "./Handlers";
import MainContainer from "./MainContainer";

const ExpandedMenu = React.memo(() => {
  const type = useSelector(getActiveTab);
  return (
    <div className={classnames("left-sidebar__bottom", { full: !!type }, type)}>
      <Handlers />
      <MainContainer type={type} />
    </div>
  );
});

export default ExpandedMenu;

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import setAuthToken from "utils/setAuthToken";
import { setAuth } from "state/actions/auth";
import { removeObject, renameProject } from "state/actions/projects";
import { openRemoveAlert } from "state/actions/settings";
import { removeProps, showRemoveAlert } from "state/selectors/settings";
import { setMapType } from "state/actions/map";
import Button from "components/Common/Button";
import RenameProject from "./RenameProject";

class RemoveAlert extends Component {
  state = { projectName: "" };

  closeRemoveAlert = () => {
    this.props.openRemoveAlert("SUCCESS", false);
  };

  removeHandler = () => {
    const {
      id,
      name,
      type: condition,
      removeType: type
    } = this.props.removeProps;
    if (!["logout", "renameProject"].includes(type)) {
      let obj = {
        id,
        type,
        name
      };
      if (condition) {
        obj = {
          ...obj,
          condition
        };
      }
      this.props.removeObject("REQUEST", {
        ...obj,
        activeTab: this.props.removeProps.activeTab
      });
      if (type === "project") {
        this.props.setMapType("SUCCESS", "Google");
        this.props.history.replace("/projects");
      }
    } else if (type === "logout") {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("settings");
      setAuthToken(false);
      this.props.setAuth("SUCCESS", false);
    } else {
      const { projectName } = this.state;
      this.props.renameProject("REQUEST", projectName);
    }
    this.props.openRemoveAlert("SUCCESS", false);
  };

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { removeProps } = prevProps;
    const { name } = removeProps;
    if (
      this.props.removeProps.removeType === "renameProject" &&
      name === undefined &&
      this.props.removeProps.name
    ) {
      this.setState({
        projectName: this.props.removeProps.name
      });
    }
  }

  setPropsToState = props => {
    const { removeProps } = props;
    const { removeType, name } = removeProps;
    if (removeType === "renameProject") {
      this.setState({
        projectName: name
      });
    }
  };

  changeProjectName = ({ target }) => {
    this.setState({
      projectName: target.value
    });
  };

  render() {
    const { removeProps, containerClass, showRemoveAlert } = this.props;
    const {
      name,
      title,
      wellfield,
      condition,
      typeTitle,
      removeType,
      objectType,
      questionTitle
    } = removeProps;
    if (!showRemoveAlert) return null;
    const removeAlertButtonTitle =
      removeType === "logout"
        ? "Да"
        : removeType === "renameProject"
        ? "Сохранить"
        : "Удалить";

    return (
      <>
        <div className="splash-screen" />
        <div className={classnames("remove-alert card", containerClass)}>
          <div className="main-container__top-row">
            <h2 className="main-title">
              {!["logout", "renameProject"].includes(removeType) && (
                <>Удаление</>
              )}{" "}
              {title}
            </h2>
            <div className="new-project__close" onClick={this.closeRemoveAlert}>
              <div className="new-project__close-line close" />
            </div>
          </div>
          <div className="card-main">
            <h2 className="remove-alert__question-title">
              {!["logout", "renameProject"].includes(removeType) && (
                <>Вы действительно хотите удалить</>
              )}{" "}
              {questionTitle}
              {questionTitle ? "?" : ""}
            </h2>
            {removeType === "renameProject" && (
              <RenameProject
                changeProjectName={this.changeProjectName}
                projectName={this.state.projectName}
              />
            )}
            {!["logout", "renameProject"].includes(removeType) && (
              <table className="remove-alert__content">
                <tbody>
                  {name && (
                    <tr className="remove-alert__content-row">
                      <td>
                        <h3 className="remove-alert__content-title">
                          Название
                        </h3>
                      </td>
                      <td>
                        <h3 className="remove-alert__content-type">{name}</h3>
                      </td>
                    </tr>
                  )}
                  {wellfield && (
                    <tr className="remove-alert__content-row">
                      <td>
                        <h3 className="remove-alert__content-title">
                          Месторождение
                        </h3>
                      </td>
                      <td>
                        <h3 className="remove-alert__content-type">
                          {wellfield}
                        </h3>
                      </td>
                    </tr>
                  )}
                  {typeTitle && (
                    <tr className="remove-alert__content-row">
                      <td>
                        <h3 className="remove-alert__content-title">
                          Тип {typeTitle}
                        </h3>
                      </td>
                      <td>
                        <h3 className="remove-alert__content-type object-type">
                          {objectType}
                        </h3>
                      </td>
                    </tr>
                  )}
                  {condition && (
                    <tr className="remove-alert__content-row">
                      <td>
                        <h3 className="remove-alert__content-title">
                          Состояние
                        </h3>
                      </td>
                      <td>
                        <h3 className="remove-alert__content-type">
                          {condition}
                        </h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
          <div className="card-bottom">
            <Button
              onClick={this.removeHandler}
              className="remove-alert__button"
            >
              {removeAlertButtonTitle}
            </Button>
            <Button secondary onClick={this.closeRemoveAlert}>
              {removeType !== "logout" ? "Отмена" : "Нет"}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

RemoveAlert.propTypes = {
  setAuth: PropTypes.func.isRequired
};

const mapDipatchToProps = {
  setAuth,
  removeObject,
  renameProject,
  openRemoveAlert,
  setMapType
};

const mapStateToProps = state => ({
  removeProps: removeProps(state),
  showRemoveAlert: showRemoveAlert(state)
});

export default withRouter(
  connect(mapStateToProps, mapDipatchToProps)(RemoveAlert)
);

import React from "react";

const ActiveTextComponent = ({ text, index, arrLength, coloredActiveText }) => (
  <span>
    {text}
    {index !== arrLength - 1 && (
      <span className="row-toggle__text-active">{coloredActiveText}</span>
    )}
  </span>
);

export default ActiveTextComponent;

import React from "react";
import HandlersButton from "../HandlersButton";

export default ({ handleTransform, draggable, rotation, repaint, draw }) => {
  return (
    <div className="handlers">
      <HandlersButton
        type="contour"
        active={draw}
        clickType="draw"
        title="Рисование контура"
        onClick={handleTransform}
      />
      <HandlersButton
        type="drag"
        active={draggable}
        title="Перемещение"
        clickType="draggable"
        onClick={handleTransform}
      />
      <HandlersButton
        title="Поворот"
        type="rotate"
        active={rotation}
        onClick={handleTransform}
        clickType="transform.rotation"
      />
      <HandlersButton
        type="repaint"
        active={repaint}
        clickType="repaint"
        title="Перерисовать"
        onClick={handleTransform}
      />
    </div>
  );
};

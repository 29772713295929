import React from "react";
import { useDispatch } from "react-redux";
import { sendNetwork, getTaskResult } from "state/actions/projects";
import Button from "components/Common/Button";

const ApplyResultButton = ({ taskResult }) => {
  const dispatch = useDispatch();
  const applyHandler = () => {
    const appliedResult = taskResult.slice();
    appliedResult[0].props.applied = true;
    dispatch(getTaskResult("SUCCESS", appliedResult));
    dispatch(
      sendNetwork("REQUEST", {
        id: taskResult[0].id,
        name: taskResult[0].task_name
      })
    );
  };
  return <Button onClick={applyHandler}>Перенести в проект</Button>;
};

export default ApplyResultButton;

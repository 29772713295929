export default {
  oil_density: "Плотность нефти",
  water_density: "Плотность воды",
  pressure_max: "Максимальное давление",
  pressure_min: "Минимальное давление",
  flow_temperature: "Температура потока",
  mu_oil_50: "Вязкость нефти при 50 \u00BA",
  mu_oil_20: "Вязкость нефти при 20 \u00BA",
  gas_rel_density: "Относит. плотность газа"
};

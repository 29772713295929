import React from "react";
import AreasList from "./AreasList";
import LinearsList from "./LinearsList";
import ObjectsList from "./ObjectsList";
import Tasks from "./Tasks";

export default props => {
  const withProps = Component => {
    return () => <Component {...props} />;
  };
  switch (props.type) {
    case "areas":
      return withProps(AreasList);
    case "linear":
      return withProps(LinearsList);
    case "objects":
      return withProps(ObjectsList);
    case "tasks":
      return withProps(Tasks);
    default:
      return null;
  }
};

import { taskConstants } from "utils/constants";

export default type => {
  const { ROADS, QUARRIES, FACILITY } = taskConstants;
  let commonProps = ["name", "props.graph_edge_length_m", "props.dem_id"];
  if (["gathering_centers", "water_pumping_stations"].includes(type)) {
    return ["name", "props.dem_id"];
  }
  if (type === FACILITY) {
    commonProps = [...commonProps, "props.placement_radius"];
  }
  if (type === ROADS) {
    commonProps = [
      ...commonProps,
      "props.class_id_target",
      "props.road_width",
      "props.backfilling_height"
    ];
  }
  if (type === QUARRIES) {
    commonProps = [...commonProps, "props.network_id"];
  }
  if (type === "createFacility") {
    return ["name", "type"];
  }
  if (type === "createLinearObject") {
    return ["name", "class_id", "metaclass_id"];
  }
  if (type === "createArea") {
    return ["name", "class_id"];
  }
  return commonProps;
};

import React from "react";

export default function ObjecIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4 6.2C12.4 3.878 10.516 2 8.2 2C5.8804 2 4 3.8804 4 6.2C4 9.35 8.2 14 8.2 14C8.2 14 12.4 9.35 12.4 6.2ZM9.26066 7.26066C8.97936 7.54196 8.59782 7.7 8.2 7.7C7.37157 7.7 6.7 7.02843 6.7 6.2C6.7 5.37157 7.37157 4.7 8.2 4.7C9.02843 4.7 9.7 5.37157 9.7 6.2C9.7 6.59782 9.54196 6.97936 9.26066 7.26066Z"
        fill="#8495AC"
      />
    </svg>
  );
}

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _isEqual from "lodash.isequal";
import CreateNewProjectView from "./CreateNewProjectView";

class CreateNewProjectContainer extends Component {
  state = { options: {}, values: {} };

  componentDidMount() {
    this.setOptions(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      !_isEqual(prevProps.wellfields, this.props.wellfields) ||
      !_isEqual(prevProps.layouts, this.props.layouts)
    ) {
      this.setOptions(this.props);
    }
  }

  handleLayouts = layouts => {
    const schemeOptions = Object.keys(layouts)
      .filter(key => layouts[key][0].type !== "primary")
      .map(key => {
        const data = layouts[key][0];
        return {
          value: data.id,
          label: data.name
        };
      });
    this.setState(state => ({
      ...state,
      options: {
        ...state.options,
        schemeOptions
      }
    }));
  };

  handleWellfields = wellfields => {
    const wellfield = Object.keys(wellfields)
      .filter(key => wellfields[key])
      .map(key => ({
        value: wellfields[key].id,
        label: wellfields[key].name,
        center: wellfields[key].geom.coordinates
      }));
    this.setState(state => ({
      ...state,
      options: {
        ...state.options,
        wellfield
      }
    }));
  };

  setOptions = props => {
    const { wellfields, layouts } = props;
    if (layouts) {
      this.handleLayouts(layouts);
    }
    if (wellfields) {
      this.handleWellfields(wellfields);
    }
  };

  onChangeHandler = (e, value) => {
    if (value === "wellfield" && e !== this.state.values.wellfield) {
      this.setState(
        {
          values: {
            ...this.state.values,
            [value]: e,
            schemeOptions: null
          },
          options: {
            ...this.state.options,
            schemeOptions: []
          }
        },
        () => {
          this.props.onChangeHandler(e, value);
        }
      );
    } else {
      this.setState(
        {
          values: {
            ...this.state.values,
            [value]: e
          }
        },
        () => {
          this.props.onChangeHandler(e, value);
        }
      );
    }
  };

  render() {
    const { createNewProject, cancel } = this.props;
    const { values, options } = this.state;
    return (
      <CreateNewProjectView
        cancel={cancel}
        values={values}
        options={options}
        createNewProject={createNewProject}
        onChangeHandler={this.onChangeHandler}
      />
    );
  }
}

CreateNewProjectContainer.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  createNewProject: PropTypes.func.isRequired
};

export default CreateNewProjectContainer;

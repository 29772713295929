import { connect } from "react-redux";
import {
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert
} from "state/actions/settings";
import { getSortedAreasObjects } from "state/selectors/objects";
import {
  sortClassesByMetaclasses,
  sortMetaclassesByClasses
} from "state/selectors/wellfield";
import { hideAreas, hideAreasGroup } from "state/actions/groupsSettings";

import AreasListContainer from "./AreasListContainer";

const mapDispatchToProps = {
  hideAreas,
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert,
  hideAreasGroup
};

const mapStateToProps = state => ({
  sortedAreas: getSortedAreasObjects(state),
  sortMetaclassesByClasses: sortMetaclassesByClasses(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(AreasListContainer);

import React from "react";
import { connect } from "react-redux";
import { newWellfieldLogs } from "state/selectors/common";
import NewWellfieldDropzoneContainer from "./NewWellfieldDropzoneContainer";

const NewWellfieldDropzoneRedux = ({ ...props }) => (
  <NewWellfieldDropzoneContainer {...props} />
);

const mapStateToProps = state => ({
  newWellfieldLogs: newWellfieldLogs(state)
});

export default connect(mapStateToProps)(NewWellfieldDropzoneRedux);

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import coloringObj from "utils/coloringObj";
import { changeTaskSettings } from "state/actions/settings";
import {
  getSettingsWidth,
  getSettingsPalette,
  getSettingsLength
} from "state/selectors/settings";
import { divIcon } from "leaflet";
import { DrawMarker } from "../DrawingTools";

export default () => {
  const palette = useSelector(getSettingsPalette);
  const width = useSelector(getSettingsWidth);
  const length = useSelector(getSettingsLength);
  const dispatch = useDispatch();
  const markerPositionHandler = ({ position, markerArea }) => {
    const { lng, lat } = position;
    const center_point = {
      type: "Point",
      coordinates: [lat, lng]
    };
    const facility_area = {
      type: "Polygon",
      coordinates: [markerArea.map(el => [el[1], el[0]])]
    };
    dispatch(changeTaskSettings("SUCCESS", { center_point, facility_area }));
  };
  let icon;
  if (palette) {
    icon = divIcon({
      className: `icon ${palette}`,
      iconSize: [16, 16],
      iconAnchor: [8, 8]
    });
  }
  const markerProps = { icon };
  const polygonStyle = coloringObj(palette);
  return (
    <DrawMarker
      width={width || 300}
      length={length || 300}
      markerProps={markerProps}
      polygonStyle={polygonStyle}
      markerPositionHandler={markerPositionHandler}
    />
  );
};

import React, { Component } from "react";
import { connect } from "react-redux";
import { getMapType } from "state/selectors/map";
import { setMapType } from "state/actions/map";
import Options from "./Options";

class MapType extends Component {
  state = { showMapType: false };

  onChangeHandler = e => {
    const { setMapType } = this.props;
    const mapType = e.target.value;
    setMapType("SUCCESS", mapType);
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  showMapTypeHandler = e => {
    if (!this.state.showMapType) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
    const cond = e.target.className.includes("layers");
    if (cond) {
      this.setState({ showMapType: !this.state.showMapType });
    }
  };

  handleClickOutside = event => {
    if (
      this.menuRef &&
      this.state.showMapType &&
      !this.menuRef.contains(event.target)
    ) {
      this.setState({
        showMapType: false
      });
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  };

  setMenuRef = node => {
    this.menuRef = node;
  };

  render() {
    const { showMapType } = this.state;
    return (
      <div
        ref={this.setMenuRef}
        data-place="left"
        data-tip="Тип подложки"
        data-for="mainTooltip"
        className="layers rightMenuIcon"
        onClick={this.showMapTypeHandler}
      >
        {showMapType && (
          <div className="map-type">
            <h2 className="map-type__title">Тип подложки:</h2>
            <Options
              inputId="ax23"
              mapType={this.props.mapType}
              onChange={this.onChangeHandler}
              titles={["Google", "Без подложки"]}
              values={["Google", "withoutMap"]}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mapType: getMapType(state)
});
export default connect(mapStateToProps, { setMapType })(MapType);

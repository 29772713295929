import { taskConstants } from "utils/constants";

const { ROADS, HVL, QUARRIES, LINEAR_COST } = taskConstants;

export default [
  {
    title: "Расчет оптимального расположения ПО",
    type: "facility",
    icon: "facility"
  },
  {
    title: "Расчет сети автодорог",
    type: ROADS,
    icon: "roads"
  },
  {
    title: "Расчет оптимального освоения карьеров",
    type: QUARRIES,
    icon: "quarries"
  },
  {
    title: "Расчет сети трубопроводов",
    type: "linearPipes",
    icon: "pipes"
  },
  {
    title: "Расчет сети ВЛ",
    type: HVL,
    icon: "hvl"
  },
  {
    title: "Расчет стоимости ЛО",
    type: LINEAR_COST,
    icon: "linear_cost"
  }
];

import React, { Component } from "react";
import DataFields from "components/Common/DataFields";
import { connect } from "react-redux";

import { changeTaskSettings } from "state/actions/settings";

class CommonField extends Component {
  state = { value: "" };

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  componentDidUpdate(prevProps) {
    const { addedProps } = this.props;
    if (prevProps.addedProps.defaultValue !== addedProps.defaultValue) {
      this.setPropsToState(this.props);
    }
  }

  onBlurHandler = ({ target }) => {
    const { valueAsNumber, type } = target;
    if (type === "number") {
      this.setState({ value: valueAsNumber }, () => {
        const { addedProps } = this.props;
        if (typeof addedProps.onChangeHandler === "function") {
          addedProps.onChangeHandler(valueAsNumber, type);
        }
      });
    }
  };

  onChangeHandler = (value, type, valueAsNumber, option, name) => {
    this.setState({ value }, () => {
      const { addedProps, changeTaskSettings } = this.props;
      if (typeof addedProps.onChangeHandler === "function") {
        addedProps.onChangeHandler(value, type);
      }
      if (["props.length", "props.width"].includes(name)) {
        changeTaskSettings("SUCCESS", { [name]: valueAsNumber });
      }
    });
  };

  setPropsToState = props => {
    const { addedProps } = props;
    if (addedProps.defaultValue !== undefined) {
      this.setState({
        value: addedProps.round
          ? addedProps.defaultValue.toFixed(2)
          : addedProps.defaultValue
      });
    }
  };

  render() {
    const { value } = this.state;
    const { addedProps, error } = this.props;
    const { propName: name, ...props } = addedProps;
    return (
      <DataFields
        {...props}
        name={name}
        error={error}
        value={value}
        onBlurHandler={this.onBlurHandler}
        onChangeHandler={({ value, type, valueAsNumber }, option) =>
          this.onChangeHandler(value, type, valueAsNumber, option, name)
        }
      />
    );
  }
}

const mapDispatchToProps = {
  changeTaskSettings
};

export default connect(null, mapDispatchToProps)(CommonField);

import React from "react";
import CommonField from "../../CommonField";
import coefs from "./coefs";

const CoefsRow = ({ defaultValues, info }) => {
  return (
    <div className="coefs-row">
      <h2 className="coefs-row__title">Коэффициенты прокладки в коридоре</h2>
      <div>
        {coefs.map(({ coef, label }, key) => (
          <CommonField
            key={key}
            addedProps={{
              propName: `props.${coef}`,
              placeholder: "",
              type: "number",
              classContainer: "quarter",
              defaultValue: defaultValues[coef],
              label,
              disabled: info
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default CoefsRow;

import { divIcon } from "leaflet";

const customIcon = ({ icon, className }) => {
  const markerHtmlStyles = `background-image: url(${icon});width: 16px;height: 16px;`;
  return divIcon({
    className: `custom-pin facility-pin ${className || ""}`,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    html: `<div style="${markerHtmlStyles}" />`
  });
};

export default customIcon;

/* eslint-disable no-param-reassign */
const groupBy = (xs, key) => {
  if (!xs || typeof xs.reduce !== "function") return {};
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export default groupBy;

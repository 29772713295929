import React from "react";
import "./style.scss";
import AreaHandlers from "./AreaHandlers";
import LinearHandlers from "./LinearHandlers";
import FacilityHandlers from "./FacilityHandlers";
import ChooseObjectsHandler from "./ChooseObjectsHandler";

const HandlersContainer = () => (
  <>
    <AreaHandlers />
    <LinearHandlers />
    <FacilityHandlers />
    <ChooseObjectsHandler />
  </>
);
export default HandlersContainer;

import React from "react";
import { DomEvent } from "leaflet";
import { Marker } from "react-leaflet";

const Point = ({
  position,
  onDblclick,
  onDragEnd,
  index,
  draggable = true,
  onDragHandler,
  ...props
}) => {
  const onDrag = e => {
    if (typeof onDragHandler === "function") {
      onDragHandler(e, index);
    } else {
      console.log("Set onDragHandler");
    }
  };
  const onDragEndHandler = e => {
    if (typeof onDragEnd === "function") {
      onDragEnd(e, index);
    } else {
      console.log("Set onDragEnd");
    }
  };
  const onDblclickHandler = e => {
    if (typeof onDragEnd === "function") {
      onDblclick(e, index);
    } else {
      console.log("Set onDblclick");
    }
  };
  const onClickHandler = e => {
    DomEvent.stop(e);
  };
  return (
    <Marker
      {...props}
      onDrag={onDrag}
      zIndexOffset={9999}
      position={position}
      draggable={draggable}
      onClick={onClickHandler}
      onDragEnd={onDragEndHandler}
      onDblclick={onDblclickHandler}
    />
  );
};

export default Point;

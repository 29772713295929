import { connect } from "react-redux";
import { lastActiveTab } from "state/selectors/settings";
import {
  activeTab,
  setRemoveProps,
  openRemoveAlert,
  removeClickType,
  setTaskSettings,
  changeTaskSettings
} from "state/actions/settings";
import { getFacilitiesFromProjectByTaskType } from "state/actions/projects";

import MainContainerBottomContainer from "./MainContainerBottomContainer";

const mapStateToProps = state => ({
  lastActiveTab: lastActiveTab(state)
});

const mapDispathToProps = {
  activeTab,
  setRemoveProps,
  openRemoveAlert,
  setTaskSettings,
  removeClickType,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(MainContainerBottomContainer);

import React from "react";
import { connect } from "react-redux";
import { showModal as setShowModal } from "state/actions/settings";

import HelpModalContainer from "./HelpModalContainer";

const HelpModalRedux = props => {
  return <HelpModalContainer {...props} />;
};

const mapStateToProps = state => ({
  // showModal: showModal(state)
});

export default connect(mapStateToProps, { setShowModal })(HelpModalRedux);

import React from "react";
import { connect } from "react-redux";
import { taskId, taskLogs } from "state/selectors/projects";
import ResultProcess from "../ResultProcess";
import FacilityResultCol from "./FacilityResultCol";

const FacilityResultContainer = ({ taskResult, taskId, taskLogs }) => {
  if (!taskResult || !taskResult.length) {
    if (!taskLogs[taskId]) return null;
    return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
  }
  if (!taskResult[0].props.start_cost || !taskResult[0].props.best_cost) {
    return (
      <div className="facility-without-result-container">
        <h2 className="facility-without-result">
          Изменился формат отображения результатов. <br /> Для того чтобы их
          увидеть пересчитайте задачу.
        </h2>
      </div>
    );
  }
  return (
    <div className="facility-result-container">
      {taskResult[0].props.start_cost && (
        <FacilityResultCol
          data={taskResult[0].props.start_cost}
          title="Начальная позиция"
          left
        />
      )}
      {taskResult[0].props.best_cost && (
        <FacilityResultCol
          data={taskResult[0].props.best_cost}
          title="Оптимальная позиция"
        />
      )}
      <div className="clearfix" />
    </div>
  );
};

const mapStateToProps = state => ({
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(FacilityResultContainer);

import React from "react";
import WithCommonObjectsFunc from "components/HOC/WithCommonObjectsFunc";
import ListWrapperItem from "./ListWrapperItem";

const ListWrapper = props => {
  const { objects } = props;
  return (
    <>
      {Object.keys(objects).map(key => (
        <ListWrapperItem key={key} itemKey={key} {...props} />
      ))}
    </>
  );
};

export default WithCommonObjectsFunc(ListWrapper);

import React, { Component } from "react";
import { connect } from "react-redux";
import { taskLogs } from "state/selectors/projects";
import classnames from "classnames";
import handleTipTitle from "./handleTipTitle";

const handleIcon = finished => (finished ? "done" : "process");

class TaskStatus extends Component {
  textArea = React.createRef();

  clickHandler = () => {
    this.textArea.current.select();
    document.execCommand("copy");
  };

  removeHandler = () => {
    const { removeHandler, item } = this.props;
    removeHandler(item);
  };

  repeatTask = () => {
    const { repeatTask, item } = this.props;
    repeatTask(item);
  };

  downloadTask = () => {
    const { downloadTask, item } = this.props;
    downloadTask(item);
  };

  render() {
    const { item, taskLogs } = this.props;
    const { id } = item;
    let icon = "process";
    let textForCopy = "";
    if (taskLogs && taskLogs[id] && taskLogs[id][0]) {
      const {
        finished,
        has_errors: hasErrors,
        debug_info: debugInfo = "Нет информации",
        task_id: taskId
      } = taskLogs[id][0];
      icon = hasErrors ? "error" : handleIcon(finished);
      const port = process.env.API_PORT;
      const { project_id: projectId } = item.props;
      textForCopy = `project_id: ${projectId} \nport: ${port} \ntask_id: ${taskId} \ndebugInfo: ${debugInfo}`;
    }
    const tipTitle = handleTipTitle(icon);
    return (
      <div className="left-container-icon">
        <button
          data-for="mainTooltip"
          onClick={this.downloadTask}
          data-tip="Скачать информацию по задаче"
          className="download-task-icon icon download-icon"
        />
        <button
          data-for="mainTooltip"
          onClick={this.repeatTask}
          className="icon repeatIcon"
          data-tip="Перезапустить задачу"
        />
        <button onClick={this.removeHandler} className="field-row__remove" />
        <button
          data-tip={tipTitle}
          data-for="mainTooltip"
          onClick={this.clickHandler}
          className={classnames("icon", icon)}
        />
        <textarea
          className="hidden-textarea"
          ref={this.textArea}
          value={textForCopy}
          readOnly
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(TaskStatus);

export default disabled => {
  const config = {
    control: (provided, state) => {
      let borderColor = "transparent";
      if (state.isDisabled) {
        borderColor = "#202A38";
      } else if (state.isFocused) {
        borderColor = "#191F26";
      }
      return {
        borderRadius: "2px",
        display: "flex",
        border: `1px solid ${borderColor}`,
        backgroundColor: state.isDisabled ? "transparent" : "#202a38"
      };
    },
    indicatorSeparator: () => ({
      display: "none"
    }),
    input: styles => ({
      ...styles,
      fontSize: "14px",
      cursor: "pointer",
      color: "#fff"
    }),
    option: styles => ({
      ...styles,
      cursor: "pointer",
      color: "#fff",
      backgroundColor: "#202a38",
      "&:hover": {
        backgroundColor: "#3e4b5d"
      },
      transition: "background-color 0.25s"
    }),
    singleValue: styles => ({
      ...styles,
      fontSize: 14,
      lineHeight: "16px",
      color: "white"
    }),
    container: provided => {
      return {
        ...provided,
        height: "32px",
        fontSize: "14px"
      };
    },
    valueContainer: styles => ({
      ...styles,
      height: "32px"
    }),
    menu: provided => {
      return {
        ...provided,
        marginTop: 1,
        marginBottom: 0,
        border: "1px solid #191F26",
        backgroundColor: "#202a38"
      };
    },
    placeholder: styles => ({
      ...styles,
      color: "rgba(207,216,221, 0.5)",
      fontSize: "14px"
    }),
    dropdownIndicator: (provided, state) => {
      const rotate = state.isFocused ? "180" : "0";
      return {
        ...provided,
        padding: 0,
        transition: "transform 0.25s",
        transform: `rotate(${rotate}deg)`
      };
    },
    indicatorsContainer: styles => ({
      ...styles,
      padding: 8
    })
  };
  if (disabled) {
    config.placeholder = styles => ({
      ...styles,
      color: "#cfd8dd",
      fontSize: "14px"
    });
  }
  return config;
};

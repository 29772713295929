import { taskConstants } from "utils/constants";

export default type => {
  const {
    ROADS,
    HVL,
    QUARRIES,
    LINEAR_COST,
    FACILITY,
    PIPES,
    GATHERING_CENTERS
  } = taskConstants;
  if (type === LINEAR_COST) {
    return ["linear_id"];
  }
  if (type === FACILITY) {
    return ["facility_id"];
  }
  if (type === ROADS) {
    return ["facilities_ids"];
  }
  if (type === HVL) {
    return ["sources_ids", "consumers_ids"];
  }
  if (type === QUARRIES) {
    return ["quarries_ids"];
  }
  if (type === GATHERING_CENTERS) {
    return ["wells_ids"];
  }
  if (PIPES.includes(type)) {
    return ["sinks_ids", "sources_ids"];
  }
  return [];
};

import { connect } from "react-redux";
import { getMapType } from "state/selectors/map";

import TileLayerContainer from "./TileLayerContainer";

const mapStateToProps = state => ({
  mapType: getMapType(state)
});

export default connect(mapStateToProps)(TileLayerContainer);

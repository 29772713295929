import React, { Component } from "react";
import { connect } from "react-redux";
import { changeTaskSettings, clickType } from "state/actions/settings";
import { getFacilitiesFromProjectByTaskType } from "state/actions/projects";
import _isEqual from "lodash.isequal";
import classnames from "classnames";

import CommonFields from "../CommonFields";
import CommonField from "../../CommonField";
import BottomRow from "./BottomRow";
import RadioComponent from "./RadioComponent";

export const facilityPlacementFields = [
  "step_m",
  "step_deg",
  "placement_radius"
];

const defaultValues = {
  step_m: 10,
  step_deg: 15,
  placement_radius: 100
};

class Facility extends Component {
  state = {
    active: "quarries",
    defaultValues,
    taskInfo: { props: {} }
  };

  componentDidMount() {
    this.setPropsToState(this.props);
    const { info, getFacilitiesFromProjectByTaskType } = this.props;
    if (!info) {
      getFacilitiesFromProjectByTaskType("REQUEST", "facility_placement");
    } else {
      const { taskInfo, changeTaskSettings } = this.props;
      const { facility_id: facilityId } = taskInfo.props;
      const { quarries_ids: quarriesIds } = taskInfo.props;
      changeTaskSettings("SUCCESS", {
        props: { facility_id: facilityId, quarries_ids: quarriesIds }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { info } = this.props;
    if (prevProps.info !== info) {
      const { taskInfo } = this.props;
      if (info && !_isEqual(prevProps.taskInfo, taskInfo)) {
        this.setState({
          taskInfo,
          defaultValues: {
            placement_radius: taskInfo.props.placement_radius,
            step_m: taskInfo.props.step_m,
            step_deg: taskInfo.props.step_deg
          }
        });
      }
    }
  }

  componentWillUnmount() {
    const { changeTaskSettings } = this.props;
    changeTaskSettings("SUCCESS", { props: {} });
  }

  setPropsToState = props => {
    const { info, taskInfo } = props;
    if (info) {
      const active =
        taskInfo.props.distance_to_quarry !== undefined
          ? "distanceToQuarry"
          : "quarries";
      this.setState({
        taskInfo,
        active,
        defaultValues: {
          placement_radius: taskInfo.props.placement_radius,
          step_m: taskInfo.props.step_m,
          step_deg: taskInfo.props.step_deg
        }
      });
    }
  };

  onChangeHandler = active => {
    const { info, changeTaskSettings, clickType } = this.props;
    if (info) return;
    if (active === "distanceToQuarry") {
      changeTaskSettings("SUCCESS", { "props.quarries_ids": [] });
      clickType("SUCCESS", [active, "chooseSingleFacility"]);
    } else {
      clickType("SUCCESS", ["chooseSingleFacility"]);
    }
    this.setState({ active });
  };

  render() {
    const { defaultValues, taskInfo, active } = this.state;
    const { info, errors = [] } = this.props;
    return (
      <div className={classnames({ "task-info": info })}>
        <CommonFields info={info} errors={errors} taskInfo={taskInfo} />
        <div className="facility-placement-row">
          <CommonField
            error={errors.includes("props.placement_radius")}
            addedProps={{
              dem: "м",
              required: true,
              propName: "props.placement_radius",
              placeholder: "",
              type: "number",
              defaultValue: defaultValues.placement_radius,
              classContainer: "half nowrap-label",
              label: "Радиус смещения",
              disabled: info
            }}
          />
        </div>
        <div className="create-task-main-container">
          <RadioComponent
            info={info}
            active={active}
            onChange={this.onChangeHandler}
            defaultValue={taskInfo.props.distance_to_quarry}
          />
        </div>
        {!info && <BottomRow active={active} />}
      </div>
    );
  }
}

const mapDispatchToProps = {
  clickType,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType
};

export default connect(null, mapDispatchToProps)(Facility);

const priority = [
  { value: 0, label: "Низкий" },
  { value: 4, label: "Средний" },
  { value: 9, label: "Высокий" }
];

const data = [
  {
    propName: "name",
    placeholder: "",
    required: true,
    classContainer: "wide",
    label: "Название задачи"
  },
  {
    propName: "priority",
    type: "option",
    placeholder: "",
    required: true,
    classContainer: "half",
    label: "Приоритет",
    defaultValue: 4,
    options: priority
  },
  "dem",
  {
    propName: "props.number_of_water_pumping_stations",
    placeholder: "",
    type: "number",
    required: true,
    defaultValue: 1,
    classContainer: "DataFieldsRow half",
    content: "≤ кол-ва кустовых площадок",
    label: "Количество БКНС"
  },
  {
    parentContainerClass: "border-top",
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.water_density",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 1000,
        classContainer: "wide",
        label: "Плотность воды"
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.flow_temperature",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 10,
        classContainer: "wide",
        label: "Температура потока"
      }
    ]
  },
  {
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.pressure_min",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 6.0,
        classContainer: "wide",
        label: "Минимальное давление"
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.pressure_max",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 40.0,
        classContainer: "wide",
        label: "Максимальное давление"
      }
    ]
  }
];

export default data;

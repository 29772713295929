import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getDemsByWellfield } from "../../../../state/selectors/wellfield";
import CommonField from "../../CommonField";

const handleConfig = ({ disabled, defaultValue, options }) => ({
  disabled,
  defaultValue,
  required: true,
  propName: "props.dem_id",
  type: "option",
  placeholder: "",
  classContainer: "wide",
  label: "Цифровая модель рельефа",
  options
});

const DemsComponent = ({ disabled = false, defaultValue, error }) => {
  const dems = useSelector(getDemsByWellfield, shallowEqual);
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    const arr = dems.map(({ id, type }) => ({
      value: id,
      label: type
    }));
    setOptions(arr);
  }, [dems]);
  const config = handleConfig({
    disabled,
    defaultValue,
    options
  });
  return <CommonField error={error} addedProps={config} />;
};

export default DemsComponent;

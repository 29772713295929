import "proxy-polyfill";
import "react-app-polyfill/ie9";
import "core-js/es/array/includes";
import "core-js/es/array/flat";
import "core-js/es/string/includes";
import React from "react";
import ReactDOM from "react-dom";
import App from "./core";
import * as serviceWorker from "./serviceWorker";

const rootEl = document.getElementById("root");

ReactDOM.render(<App />, rootEl);

serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}

import React, { useEffect } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { auth } from "state/selectors/auth";
import { getCommonInfo } from "state/actions/common";

import Login from "containers/Auth/Login";
import MainPage from "containers/MainPage";
import Wellfields from "containers/Wellfields";
import NewWellfield from "containers/NewWellfield";
import CommonContainer from "containers/CommonContainer";
import PrivateRoute from "components/Common/PrivateRoute";
import OpenProjects from "containers/Projects/OpenProjects";
import CreateNewProject from "containers/Projects/CreateNewProject";
import ProjectsFirstPage from "containers/Projects/ProjectsFirstPage";

const MainComponent = ({ getCommonInfo, auth }) => {
  useEffect(() => {
    if (auth) getCommonInfo();
  }, [auth]);
  return (
    <HashRouter>
      <CommonContainer>
        <Switch>
          <PrivateRoute
            exact
            path="/app/new"
            component={CreateNewProject}
            auth={auth}
          />
          <PrivateRoute path="/app/:id" component={MainPage} auth={auth} />
          <PrivateRoute
            exact
            path="/wellfields/new"
            component={NewWellfield}
            auth={auth}
          />
          <PrivateRoute
            exact
            path="/wellfields"
            component={Wellfields}
            auth={auth}
          />
          <PrivateRoute
            exact
            path="/projects"
            component={ProjectsFirstPage}
            auth={auth}
          />
          <PrivateRoute
            exact
            path="/projects/all"
            component={OpenProjects}
            auth={auth}
          />
          <Route path="/login" component={Login} />
        </Switch>
      </CommonContainer>
    </HashRouter>
  );
};

MainComponent.propTypes = {
  getCommonInfo: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  getCommonInfo
};

const mapStateToProps = state => ({
  auth: auth(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);

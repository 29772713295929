import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import CommonButtons from "../TaskResultsTopRow/CommonButtons";
import TaskLogs from "../TaskLogs";
import QuarriesResult from "./QuarriesResult";

const Quarries = () => {
  const [handler, setHandler] = React.useState("logs");
  return (
    <div className="task-complete">
      <div className="task-complete__toggle-row">
        <CommonButtons currentHandler={handler} toggleHandler={setHandler} />
      </div>
      <div className="task-complete__main-container">
        <ScrollBar>
          {handler === "logs" ? <TaskLogs /> : <QuarriesResult />}
        </ScrollBar>
      </div>
    </div>
  );
};

export default Quarries;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !auth ? <Redirect to="/login" /> : <Component {...props} {...rest} />
    }
  />
);
PrivateRoute.propTypes = {
  auth: PropTypes.bool.isRequired
};
export default PrivateRoute;

import React from "react";
import { withLeaflet } from "react-leaflet";
import _get from "lodash.get";
import LinearView from "./LinearView";

class LinearContainer extends React.Component {
  openCardHandler = itemId => {
    const { getVisibleLinears, activeTab, leaflet, setCardInfo } = this.props;
    const item = getVisibleLinears[itemId];
    setCardInfo("SUCCESS", {
      type: "linear",
      data: item.properties
    });
    activeTab("SUCCESS", "infoCard");
    leaflet.map.closePopup();
  };

  onClickHandler = e => {
    const { chooseLinearObject, changeTaskSettings } = this.props;
    if (chooseLinearObject) {
      const linearId = _get(e, "target.options.data.properties.id");
      changeTaskSettings("SUCCESS", {
        props: {
          linear_id: linearId
        }
      });
    }
  };

  render() {
    const { getVisibleLinears } = this.props;
    return (
      <>
        {Object.keys(getVisibleLinears).map((key, index) => {
          const el = getVisibleLinears[key];
          return (
            <LinearView
              el={el}
              index={index}
              key={el.properties.id + index}
              onClickHandler={this.onClickHandler}
              openCardHandler={this.openCardHandler}
            />
          );
        })}
      </>
    );
  }
}

export default withLeaflet(LinearContainer);

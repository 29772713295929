import groupBy from "utils/groupBy";
import toObject from "utils/toObject";
import coloringObj from "utils/coloringObj";
import setSubtitle from "containers/LeftMenu/List/LinearsList/utils/setSubtitle";
import _mapValues from "lodash.mapvalues";
import sortByTitle from "utils/sortByTitle";
import _flow from "lodash.flow";
import _get from "lodash.get";
import _transform from "lodash.transform";
import _pickBy from "lodash.pickby";
import { setIcon } from "components/HOC/WithIcons";
import {
  getMetaclassesSortedById,
  sortClassesByMetaclasses,
  classesByWellfield
} from "state/selectors/wellfield";
import {
  networks,
  projectPalette,
  taskQuarries,
  linearTaskObjects
} from "state/selectors/projects";
import {
  networkId,
  linearId,
  consumersIds,
  sourcesIds,
  sinksIds,
  quarriesIds,
  facilities,
  facilityId,
  chooseSinks,
  chooseSources,
  chooseQuarries,
  chooseConsumers,
  waterConsumersIds,
  powerConsumersIds,
  choosePowerConsumersIds,
  chooseWaterConsumersIds,
  chooseGatheringCenters,
  facilitiesFromProjectByTaskType,
  gatheringCentersFacilities
} from "state/selectors/settings";
import center from "@turf/center";

import { createSelector } from "reselect";
import { getActiveTab } from "./settings";
import { taskObjects } from "./projects";

export const getLinears = createSelector(
  ({ objects: { linears } }) => linears,
  linears => linears
);

export const getAreasFromState = createSelector(
  ({ objects: { areas } }) => areas,
  areas => areas
);

export const facilitiesByLayout = createSelector(
  ({ wellfield: { facilitiesByLayout } }) => facilitiesByLayout,
  facilitiesByLayout => facilitiesByLayout.data
);

export const getFacilities = createSelector(
  ({ objects: { facilities } }) => facilities,
  facilities => facilities
);

export const getSortedLinears = createSelector(
  [getLinears, getMetaclassesSortedById, projectPalette, networks],
  (linears, getMetaclassesSortedById, projectPalette, networks) => {
    const linearObjects = {};
    if (!linears) return false;
    const objectsByMetaclass = groupBy(linears, "metaclass_id");
    Object.keys(objectsByMetaclass).forEach(key => {
      const title = _get(getMetaclassesSortedById[key], "[0].name");
      const objects = objectsByMetaclass[key];
      let beforeProp = coloringObj(projectPalette[objects[0].class_id]);
      beforeProp = beforeProp ? beforeProp.color : null;
      linearObjects[key] = {
        title,
        beforeProp
      };
      const objectsByNetwork = groupBy(objects, "first_network");
      const objectsWithoutNetwork = objectsByNetwork.without_network;
      delete objectsByNetwork.without_network;
      const objectsWithoutNetworkByType = groupBy(
        objectsWithoutNetwork,
        "type"
      );
      delete objectsWithoutNetworkByType.calculated_tmp;
      if (Object.keys(objectsWithoutNetworkByType).length) {
        Object.keys(objectsWithoutNetworkByType).forEach(subKey => {
          if (getMetaclassesSortedById[key][0].palette !== "forest_corridor") {
            linearObjects[key].subLayer = {
              [subKey]: {
                beforeProp,
                title: setSubtitle(subKey),
                values: objectsWithoutNetworkByType[subKey]
              }
            };
          } else {
            linearObjects[key] = {
              ...linearObjects[key],
              values: objectsWithoutNetworkByType[subKey]
            };
          }
        });
      }
      Object.keys(objectsByNetwork).forEach(networkKey => {
        const objectsByNetworkByType = groupBy(
          objectsByNetwork[networkKey],
          "type"
        );
        delete objectsByNetworkByType.calculated_tmp;
        if (Object.keys(objectsByNetworkByType).length) {
          Object.keys(objectsByNetworkByType).forEach(subKey => {
            if (!linearObjects[key].subLayer) {
              linearObjects[key].subLayer = {};
            }
            if (!linearObjects[key].subLayer[subKey]) {
              linearObjects[key].subLayer[subKey] = {
                beforeProp,
                title: setSubtitle(subKey)
              };
            }
            if (!linearObjects[key].subLayer[subKey].subLayer) {
              linearObjects[key].subLayer[subKey].subLayer = {};
            }
            if (networks[networkKey]) {
              linearObjects[key].subLayer = {
                ...linearObjects[key].subLayer,
                [subKey]: {
                  ...linearObjects[key].subLayer[subKey],
                  subLayer: {
                    ...linearObjects[key].subLayer[subKey].subLayer,
                    [networkKey]: {
                      beforeProp,
                      containerClass: "network",
                      networkId: networks[networkKey][0],
                      title: networks[networkKey][0].name,
                      values: objectsByNetworkByType[subKey],
                      taskId: objectsByNetworkByType[subKey][0].props.task_id
                    }
                  }
                }
              };
            }
          });
        }
      });
    });
    Object.keys(linearObjects).forEach(key => {
      if (!linearObjects[key].subLayer && !linearObjects[key].values) {
        delete linearObjects[key];
      }
    });
    return linearObjects;
  }
);

export const facilitiesBy = type =>
  createSelector(getFacilities, items => groupBy(items, type));

export const getFacilityById = facilitiesBy("id");
export const getFacilitiesByType = facilitiesBy("type");
export const getFacilityByClassId = facilitiesBy("class_id");

const filterFacilitiesByType = ({ type }) =>
  ["existing", "calculated"].includes(type);

const groupFacilitiesByClassId = facilities =>
  _pickBy(
    groupBy(facilities.filter(filterFacilitiesByType), "class_id"),
    el => !!el.length
  );

const sortFacilities = (projectPalette, classesByWellfield) => (el, key) => {
  const beforeProp = coloringObj(projectPalette[key]).fillColor;
  const title = _get(classesByWellfield, [key, "name"]) || "";
  return {
    title,
    beforeProp,
    rowClass: "row-toggle-facility",
    values: el.sort(sortByTitle("title"))
  };
};

const sortObjects = sortedObjects => (aKey, bKey) =>
  sortByTitle("title")(sortedObjects[aKey], sortedObjects[bKey]);

const composeSortedObjects = sortedObjects => {
  return Object.keys(sortedObjects)
    .sort(sortObjects(sortedObjects))
    .reduce((acc = {}, key) => {
      acc[key] = sortedObjects[key];
      return acc;
    }, {});
};

export const getSortedFacilities = createSelector(
  [getFacilities, projectPalette, classesByWellfield],
  (facilities, projectPalette, classesByWellfield) => {
    if (facilities) {
      const mapObject = facilities =>
        _mapValues(
          groupFacilitiesByClassId(facilities),
          sortFacilities(projectPalette, classesByWellfield)
        );
      const sortedObjects = _flow([mapObject, composeSortedObjects]);
      return sortedObjects(facilities);
    }
    return null;
  }
);

export const getGroupsSettings = type =>
  createSelector(
    ({ groupsSettings }) => groupsSettings[type],
    items => items
  );

export const getAreasByMetaclassId = createSelector(getAreasFromState, items =>
  groupBy(items, "metaclass_id")
);

const sortAreas = (sortClassesByMetaclasses, projectPalette) => (el, key) => ({
  beforeProp:
    coloringObj(
      projectPalette[_get(sortClassesByMetaclasses, [key, "valuesArray", 0])]
    ).color || null,
  title: _get(sortClassesByMetaclasses, [key, "title"]),
  values: el.sort(sortByTitle("title"))
});

export const getSortedAreasObjects = createSelector(
  [getAreasFromState, projectPalette, sortClassesByMetaclasses],
  (areas, projectPalette, sortClassesByMetaclasses) => {
    const sortedObjects = _mapValues(
      groupBy(areas, "metaclass_id"),
      sortAreas(sortClassesByMetaclasses, projectPalette)
    );
    return composeSortedObjects(sortedObjects);
  }
);

export const getLinearsId = createSelector(getLinears, linears =>
  toObject(linears, "id")
);

export const getLinearsByMetaclassId = createSelector(getLinears, items =>
  groupBy(items, "metaclass_id")
);

const filterHiddenObjects = (hiddenObjects, hiddenGroup) => groupType => el =>
  !hiddenObjects.includes(el.id) && !hiddenGroup.includes(el[groupType]);

const mapFacilitiesByLayout = el => ({
  ...el,
  className: "new-project-facility"
});

export const getVisibleFacilities = createSelector(
  [
    getFacilities,
    getGroupsSettings("hideFacilities"),
    getGroupsSettings("hideFacilitiesGroup"),
    facilitiesByLayout
  ],
  (facilities, hideFacilities, hideFacilitiesGroup, facilitiesByLayout) => {
    if (!facilities && !facilitiesByLayout) return [];
    if (facilitiesByLayout) {
      return facilitiesByLayout.map(mapFacilitiesByLayout);
    }
    return facilities.filter(
      filterHiddenObjects(hideFacilities, hideFacilitiesGroup)("class_id")
    );
  }
);

const transformActiveObjToClasses = id => (acc, el, key) => {
  if (el && typeof el.includes === "function" && el.includes(id)) {
    if (["quarriesIds", "consumersIds"].includes(key)) {
      acc.push("second-selected");
    } else {
      acc.push("first-selected");
    }
  }
};

const handleFacilityClasses = ({ id, ...props }) =>
  _transform(props, transformActiveObjToClasses(id), []);

const handleFacilitiesForSelect = ({
  id,
  facilitiesFromProjectByTaskType,
  ...props
}) => {
  const {
    chooseWaterConsumersIds,
    choosePowerConsumersIds,
    chooseGatheringCenters,
    chooseQuarries,
    chooseSources,
    chooseSinks,
    chooseConsumers
  } = props;
  const classNames = [];
  if (facilitiesFromProjectByTaskType) {
    if (choosePowerConsumersIds) {
      const { power_consumers_ids } = facilitiesFromProjectByTaskType;
      if (power_consumers_ids && power_consumers_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
    if (chooseWaterConsumersIds) {
      const { water_consumers_ids } = facilitiesFromProjectByTaskType;
      if (water_consumers_ids && water_consumers_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
    if (chooseGatheringCenters) {
      const { wells_ids } = facilitiesFromProjectByTaskType;
      if (wells_ids && wells_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
    if (chooseQuarries) {
      const { quarries_ids } = facilitiesFromProjectByTaskType;
      if (quarries_ids && quarries_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
    if (chooseSources) {
      const { sources_ids } = facilitiesFromProjectByTaskType;
      if (sources_ids && sources_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
    if (chooseSinks) {
      const { sinks_ids } = facilitiesFromProjectByTaskType;
      if (sinks_ids && sinks_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
    if (chooseConsumers) {
      const { consumers_ids } = facilitiesFromProjectByTaskType;
      if (consumers_ids && consumers_ids.includes(id)) {
        classNames.push("facility-for-select");
      }
    }
  }
  return classNames;
};

const handleGatheringCentersClasses = ({ args, id, facilitiesIds }) => {
  const classNames = args
    .filter(i => !!i)
    .map(el => {
      const ind = el.indexOf(id);
      return `facility-with-tmp-color tmpColor${ind}`;
    });
  if (facilitiesIds && facilitiesIds.length) {
    const ind = facilitiesIds.findIndex(arr =>
      arr instanceof Array ? arr.includes(id) : false
    );
    if (ind !== -1) {
      classNames.push(`facility-with-tmp-color tmpColor${ind}`);
    }
  }
  return classNames;
};

const handleGatheringCentersFacilities = ({
  gatheringCentersFacilities,
  id
}) => {
  if (!gatheringCentersFacilities) return [];
  const {
    electricity_centers_ids: elCentersIds,
    water_gathering_centers_ids: waterCentersIds,
    gathering_centers_ids: gatheringCentersIds,
    facilities_ids: facilitiesIds
  } = gatheringCentersFacilities;
  return handleGatheringCentersClasses({
    id,
    facilitiesIds,
    args: [elCentersIds, waterCentersIds, gatheringCentersIds]
  });
};

export const facilitiesOnMap = createSelector(
  [
    gatheringCentersFacilities,
    consumersIds,
    sourcesIds,
    sinksIds,
    getVisibleFacilities,
    projectPalette,
    taskQuarries,
    quarriesIds,
    facilities,
    facilityId,
    facilitiesFromProjectByTaskType,
    chooseGatheringCenters,
    chooseQuarries,
    chooseSources,
    chooseSinks,
    chooseConsumers,
    chooseWaterConsumersIds,
    waterConsumersIds,
    powerConsumersIds,
    choosePowerConsumersIds,
    taskObjects
  ],
  (
    gatheringCentersFacilities,
    consumersIds,
    sourcesIds,
    sinksIds,
    facilities,
    projectPalette,
    taskQuarries,
    quarriesIds,
    activeFacilities,
    activeFacilityId,
    facilitiesFromProjectByTaskType,
    chooseGatheringCenters,
    chooseQuarries,
    chooseSources,
    chooseSinks,
    chooseConsumers,
    chooseWaterConsumersIds,
    waterConsumersIds,
    powerConsumersIds,
    choosePowerConsumersIds,
    taskObjects
  ) => {
    if (facilities && projectPalette) {
      return facilities.reduce((acc, cur) => {
        const {
          facility_area: facilityArea,
          class_id: classId,
          id,
          name,
          type,
          className = "",
          props
        } = cur;
        if (!facilityArea) return acc;
        const classNames = [
          ...handleFacilityClasses({
            id,
            quarriesIds,
            activeFacilities,
            activeFacilityId,
            sinksIds,
            sourcesIds,
            consumersIds,
            waterConsumersIds,
            powerConsumersIds
          }),
          ...handleFacilitiesForSelect({
            id,
            facilitiesFromProjectByTaskType,
            chooseWaterConsumersIds,
            choosePowerConsumersIds,
            chooseGatheringCenters,
            chooseQuarries,
            chooseSources,
            chooseSinks,
            chooseConsumers
          }),
          ...handleGatheringCentersFacilities({
            id,
            gatheringCentersFacilities
          })
        ];
        const palette = projectPalette[classId];
        const [lng, lat] = center(facilityArea).geometry.coordinates;
        const icon = setIcon(projectPalette[classId]);
        let style = coloringObj(_get(projectPalette, [classId]));
        let tmpColor = "";
        const position = { lat, lng };
        const data = {
          ...facilityArea,
          properties: { id, palette }
        };
        if (taskQuarries) {
          if (taskQuarries.indexOf(id) !== -1) {
            tmpColor = `tmpColor${taskQuarries.indexOf(id)}`;
            style = coloringObj(tmpColor);
          } else if (taskObjects) {
            style.color = "#77F4E8";
            style.fillColor = "#77F4E8";
          }
        }
        if (taskQuarries && taskObjects) {
          if (props && props.network_ids) {
            if (!props.network_ids.some(el => taskObjects.data.includes(el))) {
              return acc;
            }
          } else {
            return acc;
          }
        } else if (type === "calculated_tmp") {
          return acc;
        }
        acc.push({
          id,
          data,
          name,
          icon,
          type,
          props,
          style,
          palette,
          class_id: classId,
          position,
          facility_area: facilityArea,
          className: [...classNames, className].join(" ")
        });
        return acc;
      }, []);
    }
    return [];
  }
);

export const connectionPoints = createSelector(
  getVisibleFacilities,
  facilities => {
    return facilities.reduce((acc, cur) => {
      if (cur.connection_points && cur.connection_points.length) {
        const { type, connection_points: points } = cur;
        acc.push({
          type,
          facility_id: points[0].facility_id,
          connection_points: points
        });
      }
      return acc;
    }, []);
  }
);

const filterCalculatedFacilities = ({ type }) => type === "calculated_tmp";

const filterTaskFacilities = object => el => !!object[el];

const filterTaskFacilitiesById = object => id => object[id];

const checkIncludesValues = data => v => !!data.includes(v);

const transformHVLFacilities = data => (acc, val) => {
  const networkIds = val?.props?.network_ids ?? [];
  if (!networkIds.some(checkIncludesValues(data))) {
    return false;
  }
  return acc.push(val);
};

export const taskFacilities = createSelector(
  [taskObjects, getVisibleFacilities, getActiveTab],
  (taskObjects, facilities, activeTab) => {
    const type = taskObjects?.type;
    const data = taskObjects?.data;
    if (activeTab !== "taskInfo" || type === "linear" || !data) return false;
    const object = toObject(facilities, "id");
    const calculatedObjects = _pickBy(object, filterCalculatedFacilities);
    if (type === "high_voltage") {
      const highVoltageFacilities = _transform(
        calculatedObjects,
        transformHVLFacilities(data),
        []
      );
      return {
        type,
        data: highVoltageFacilities
      };
    }
    return {
      type,
      data: data
        .filter(filterTaskFacilities(calculatedObjects))
        .map(filterTaskFacilitiesById(calculatedObjects))
    };
  }
);

const composeAreas = projectPalette => ({
  geom,
  id,
  name,
  class_id: classId
}) => {
  const key = projectPalette[classId];
  return {
    ...geom,
    properties: {
      id,
      key,
      name,
      class_id: classId,
      style: coloringObj(key)
    }
  };
};

export const getVisibleAreas = createSelector(
  [
    projectPalette,
    getAreasFromState,
    getGroupsSettings("hideAreas"),
    getGroupsSettings("hideAreasGroup")
  ],
  (projectPalette, areas, hideAreas, hideAreasGroup) => {
    if (!areas || !Object.keys(projectPalette).length) return [];
    return areas
      .filter(filterHiddenObjects(hideAreas, hideAreasGroup)("metaclass_id"))
      .map(composeAreas(projectPalette));
  }
);

export const getVisibleLinears = createSelector(
  [
    projectPalette,
    linearTaskObjects,
    taskQuarries,
    networkId,
    linearId,
    getLinears,
    getGroupsSettings("hideLinears"),
    getGroupsSettings("hideLinearsMetaclass"),
    getGroupsSettings("hideLinearsByMetaclassAndType"),
    getGroupsSettings("hideLinearsByMetaclassAndNetwork"),
    getGroupsSettings("hideLinearsByNetwork")
  ],
  (
    projectPalette,
    linearTaskObjects,
    taskQuarries,
    networkId,
    linearId,
    linears,
    hideLinears,
    hideLinearsMetaclass,
    hideLinearsByMetaclassAndType,
    hideLinearsByMetaclassAndNetwork,
    hideLinearsByNetwork
  ) => {
    if (!linears) return {};
    const data = linears
      .filter(({ id, networks_ids, metaclass_id, type }) => {
        const linearCond = !hideLinears.includes(id);
        const linearMetaclass = !hideLinearsMetaclass.includes(metaclass_id);
        let metaclassAndType = true;
        let metaclassAndNetwork = true;
        let network = true;
        if (hideLinearsByMetaclassAndType[metaclass_id]) {
          const { types } = hideLinearsByMetaclassAndType[metaclass_id];
          metaclassAndType = !types.includes(type);
        }
        if (hideLinearsByMetaclassAndNetwork[metaclass_id]) {
          const { types, networks } = hideLinearsByMetaclassAndNetwork[
            metaclass_id
          ];
          metaclassAndNetwork =
            !types.includes(type) ||
            !networks_ids.some(network => networks.includes(network));
        }
        if (hideLinearsByNetwork && hideLinearsByNetwork.length) {
          network = !networks_ids.some(network =>
            hideLinearsByNetwork.includes(network)
          );
        }
        return (
          linearCond &&
          linearMetaclass &&
          metaclassAndType &&
          metaclassAndNetwork &&
          network
        );
      })
      .map(item => {
        const { id, type, networks_ids = [], name, class_id } = item;
        let styleId;
        let thirdCond = type !== "calculated_tmp";
        let fourthCond = !taskQuarries ? true : !taskQuarries.length;
        const classNames = [
          "linear",
          id,
          type,
          ...networks_ids.map(network => `network-${network}`)
        ];
        if (linearId && id === linearId) {
          classNames.push("active");
        }
        if (networkId && networks_ids) {
          if (
            networks_ids.includes(networkId) &&
            !classNames.includes("active")
          ) {
            classNames.push("active");
          }
        }
        if (type === "calculated_tmp") {
          if (linearTaskObjects && !taskQuarries && linearTaskObjects.length) {
            if (linearTaskObjects.some(el => item.networks_ids.includes(el))) {
              styleId = `tmpColor${linearTaskObjects.indexOf(
                item.networks_ids[0]
              )}`;
              thirdCond = true;
              fourthCond = true;
            }
          }
          if (linearTaskObjects && taskQuarries) {
            item.networks_ids.forEach((_, id) => {
              if (linearTaskObjects.includes(id)) {
                thirdCond = true;
                if (item.props && taskQuarries) {
                  const quarryId = item.props.attached_quarry_id;
                  if (quarryId) {
                    if (taskQuarries.indexOf(quarryId) !== -1) {
                      styleId = `tmpColor${taskQuarries.indexOf(quarryId)}`;
                      fourthCond = true;
                    }
                  }
                }
              }
            });
          }
        }
        if (thirdCond && fourthCond) {
          const { diameter, thickness, cost, length } = item.props;
          let style = {
            ...coloringObj(projectPalette[class_id])[item.type]
          };
          if (styleId && type === "calculated_tmp") {
            style = coloringObj(styleId);
          }
          const color = style ? style.color : "hotpink";
          const geoObj = {
            properties: {
              color,
              style,
              classNames,
              id,
              type,
              name,
              class_id,
              networks_ids,
              styleId: styleId || class_id,
              objectType: projectPalette[class_id],
              props: item.props
            }
          };

          if (cost) geoObj.properties.cost = cost;

          if (length) geoObj.properties.length = length;

          if (diameter) geoObj.properties.diameter = diameter;

          if (thickness) geoObj.properties.thickness = thickness;

          if (item.linear_area && item.objectType !== "forest_corridor") {
            return Object.assign(geoObj, item.linear_area);
          }
          return Object.assign(geoObj, item.geom);
        }
        return false;
      })
      .filter(el => el)
      .reduce((acc, el) => {
        acc[el.properties.id] = el;
        return acc;
      }, {});
    return data;
  }
);

export default type => {
  switch (type) {
    case "objects":
      return "Площадной объект";
    case "areas":
      return "Область";
    case "linear":
      return "Линейный объект";
    case "tasks":
      return "Задача";
    case "ruler":
      return "Линейка";
    case "timeline":
      return "Таймлайн";
    case "heatMap":
      return "Карта желательности";
    default:
      return "";
  }
};

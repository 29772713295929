import React, { Component } from "react";
import { connect } from "react-redux";
import { getFacilitiesFromProjectByTaskType } from "state/actions/projects";
import { changeTaskSettings, setTaskSettings } from "state/actions/settings";
import classnames from "classnames";

import BottomRow from "./BottomRow";
import CommonFields from "../CommonFields";

class LinearCost extends Component {
  state = { taskInfo: { props: {} } };

  componentDidMount() {
    const {
      info,
      taskInfo,
      changeTaskSettings,
      getFacilitiesFromProjectByTaskType
    } = this.props;
    if (info) {
      const { linear_id } = taskInfo.props;
      changeTaskSettings("SUCCESS", {
        props: {
          linear_id
        }
      });
    }
    if (!info) {
      getFacilitiesFromProjectByTaskType("REQUEST", "linear_cost");
    }
    this.setState({
      taskInfo
    });
  }

  componentWillUnmount() {
    const { setTaskSettings } = this.props;
    setTaskSettings("SUCCESS", { props: {} });
  }

  render() {
    const { taskInfo } = this.state;
    const { info, errors = [] } = this.props;
    return (
      <div className={classnames("card-container", { "task-info": info })}>
        <CommonFields info={info} errors={errors} taskInfo={taskInfo} />
        {!info && <BottomRow />}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setTaskSettings,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType
};

export default connect(null, mapDispatchToProps)(LinearCost);

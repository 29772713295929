/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import Button from "components/Common/Button";
import DataFields from "components/Common/DataFields";
import Hint from "./Hint";

const dataForInputs = [
  {
    label: "Название проекта",
    value: "projectName",
    placeholder: "",
    classContainer: "wide",
    type: "text"
  },
  {
    label: "Месторождение",
    placeholder: "",
    value: "wellfield",
    classContainer: "wide",
    type: "option"
  },
  {
    label: "Схема обустройства месторождения",
    placeholder: "",
    value: "schemeOptions",
    classContainer: "wide",
    type: "option"
  }
];

export default ({
  createNewProject,
  cancel,
  values,
  options,
  onChangeHandler
}) => {
  return (
    <div className="new-project card">
      <div className="main-container__top-row">
        <h2 className="main-title">Создание проекта</h2>
        <div className="new-project__close" onClick={cancel}>
          <div className="new-project__close-line close" />
        </div>
      </div>
      <div className="new-project__main card-main">
        {dataForInputs.map((item, key) => (
          <DataFields
            key={key}
            type={item.type}
            label={item.label}
            value={values[item.value]}
            options={options[item.value]}
            placeholder={item.placeholder}
            classContainer={item.classContainer}
            onChangeHandler={({ value }) => onChangeHandler(value, item.value)}
          />
        ))}
        <Hint />
      </div>
      <div className="card-bottom">
        <Button onClick={createNewProject} className="new-project__button">
          Создать
        </Button>
        <Button secondary onClick={cancel} className="new-project__button">
          Отмена
        </Button>
      </div>
    </div>
  );
};

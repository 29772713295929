import React from "react";
import { connect } from "react-redux";
import { taskResult, taskId, taskLogs } from "state/selectors/projects";
import { setHideLinearsByNetwork } from "state/actions/groupsSettings";
import { ActiveResultComponent } from "../ResultTable";
import ResultProcess from "../ResultProcess";

const RoadsResult = props => {
  const [activeResult, setActiveResult] = React.useState();
  const { taskResult, taskLogs, taskId } = props;
  const handleResult = (el, index) => {
    setActiveResult({ ...el, index });
    const hiddenNetworks = taskResult
      .filter(item => item.props.network_id !== el.props.network_id)
      .map(({ props }) => props.network_id);
    if (typeof props.handleActiveResult === "function") {
      props.handleActiveResult({ ...el, index });
    }
    props.setHideLinearsByNetwork("SUCCESS", hiddenNetworks);
  };
  React.useEffect(() => {
    handleResult(taskResult[0], 0);
  }, [taskResult]);
  if (!taskResult || !taskResult.length) {
    if (!taskLogs[taskId]) return null;
    return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
  }
  return (
    <div className="task-result__container">
      {activeResult && (
        <ActiveResultComponent
          hideArrow
          mainTitle="Вариант сети"
          activeResult={activeResult}
          linearResultType="linears"
          resultTableRowClass="hvl-result-top-row-table"
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  taskResult: taskResult(state),
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

const mapDispatchToProps = { setHideLinearsByNetwork };

export default connect(mapStateToProps, mapDispatchToProps)(RoadsResult);

import React from "react";
import LinearIcon from "./Icons/LinearIcon";
import ObjectIcon from "./Icons/ObjectIcon";
import QuarryIcon from "./Icons/QuarryIcon";
import SinkIcon from "./Icons/SinkIcon";
import SourceIcon from "./Icons/SourceIcon";
import WellIcon from "./Icons/WellIcon";

const Component = ({ taskType, name }) => {
  if (taskType === "linear_cost") {
    return <LinearIcon />;
  }
  if (taskType === "facility_placement") {
    return name === "facility_id" ? <ObjectIcon /> : <QuarryIcon />;
  }
  if (taskType === "roads") {
    return name === "facilities_ids" ? <ObjectIcon /> : <QuarryIcon />;
  }
  if (taskType === "quarries") {
    return <QuarryIcon />;
  }
  if (taskType === "gathering_centers") {
    return <WellIcon />;
  }
  if (!name) return null;
  return name.includes("sink") ? <SinkIcon /> : <SourceIcon />;
};

export default ({ taskType, name }) => {
  return (
    <span className="choose-objects__button-icon">
      <Component taskType={taskType} name={name} />
    </span>
  );
};

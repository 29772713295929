import React from "react";
import { Marker } from "react-leaflet";
import RulerPopup from "./RulerPopup";

export default ({ icon, index, onClosePopup, ...props }) => {
  const onDblclickHandler = () => props.removePoint(index);
  const onDragendHandler = e => props.dragendHandler(e, index);
  const onMoveHandler = e => props.moveHandler(e, index);
  return (
    <>
      <Marker
        icon={icon}
        draggable
        zIndexOffset={9999}
        onMove={onMoveHandler}
        position={props.position}
        onDragend={onDragendHandler}
        onDblclick={onDblclickHandler}
        onMovestart={props.onDragstartHandler}
      />
      {props.points.length - 1 === index ? (
        <RulerPopup
          index={index}
          onClosePopup={onClosePopup}
          points={props.points}
        />
      ) : (
        <></>
      )}
    </>
  );
};

import React from "react";

const Options = ({ values, inputId, titles, mapType, onChange }) => {
  return (
    <>
      {values.map((value, id) => (
        <div className="optionRow" key={inputId + id}>
          <input
            className="radioInput"
            name={inputId}
            type="radio"
            value={value}
            id={inputId + id}
            onChange={onChange}
            checked={mapType === value}
          />
          <label className="radioInputLabel" htmlFor={inputId + id}>
            {titles[id]}
          </label>
        </div>
      ))}
    </>
  );
};

export default Options;

import React from "react";
import Handler from "./Handler";

export default props => {
  const { toggleHandler, currentHandler, type } = props;
  let firstTitle = "Журнал выполнения";
  let secondTitle = "Результаты задачи";
  let firstHandler = "logs";
  let secondHandler = "results";
  const thirdHandler = "distribution";
  if (type === "linear") {
    firstHandler = "cost";
    secondHandler = "profile";
    firstTitle = "Стоимость по участкам";
    secondTitle = "Профиль высоты";
  }
  return (
    <div>
      <Handler
        handler={firstHandler}
        toggleHandler={toggleHandler}
        currentHandler={currentHandler}
      >
        {firstTitle}
      </Handler>
      <Handler
        handler={secondHandler}
        toggleHandler={toggleHandler}
        currentHandler={currentHandler}
      >
        {secondTitle}
      </Handler>
      {type === "linear" && (
        <Handler
          handler={thirdHandler}
          toggleHandler={toggleHandler}
          currentHandler={currentHandler}
        >
          Профиль стоимости
        </Handler>
      )}
    </div>
  );
};

import { Component } from "react";

class WebSocketComponent extends Component {
  state = { ws: null };

  componentDidMount() {
    const { url, getWebSocket, onmessage, onclose } = this.props;
    const ws = new WebSocket(url);
    ws.onmessage = onmessage || (() => {});
    ws.onclose = function(e) {
      if (e.code === 1e3 || e.code === 1005) {
        (onclose || (() => {}))(e);
      }
    };
    if (typeof getWebSocket === "function") getWebSocket(ws);
    this.setState({ ws });
  }

  componentWillUnmount() {
    const { ws } = this.state;
    if (ws) ws.close();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return null;
  }
}

export default WebSocketComponent;

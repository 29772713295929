import { action } from "utils";

import {
  HIDE_AREAS,
  OPENED_LIST,
  HIDE_LINEARS,
  SAVE_SETTINGS,
  LOCK_FACILITIES,
  HIDE_FACILITIES,
  HIDE_AREAS_GROUP,
  SET_LINEARS_TOGGLE,
  SET_OPENED_TASKS,
  REMOVE_OPENED_TASKS,
  OPENED_LIST_BY_TYPE,
  HIDE_FACILITIES_GROUP,
  HIDE_LINEARS_METACLASS,
  REMOVE_HIDDEN_METACLASS,
  HIDE_LINEARS_BY_METACLASS_AND_NETWORK,
  HIDE_LINEARS_BY_METACLASS_AND_TYPE,
  HIDE_LINEARS_BY_NETWORK,
  SET_HIDE_LINEARS_BY_NETWORK
} from "../constants/groupsSettings";

export const setHideLinearsByNetwork = action(SET_HIDE_LINEARS_BY_NETWORK);
export const hideLinearsByNetwork = action(HIDE_LINEARS_BY_NETWORK);
export const hideAreas = action(HIDE_AREAS);
export const hideLinears = action(HIDE_LINEARS);
export const saveSettings = action(SAVE_SETTINGS);
export const setOpenedList = action(OPENED_LIST);
export const lockFacilities = action(LOCK_FACILITIES);
export const hideFacilities = action(HIDE_FACILITIES);
export const hideAreasGroup = action(HIDE_AREAS_GROUP);
export const setListsToggle = action(SET_LINEARS_TOGGLE);
export const setOpenedTasks = action(SET_OPENED_TASKS);
export const removeOpenedTasks = action(REMOVE_OPENED_TASKS);
export const setOpenedListByType = action(OPENED_LIST_BY_TYPE);
export const hideFacilitiesGroup = action(HIDE_FACILITIES_GROUP);
export const hideLinearsMetaclass = action(HIDE_LINEARS_METACLASS);
export const removeHiddenMetaclass = action(REMOVE_HIDDEN_METACLASS);
export const hideLinearsByMetaclassAndType = action(
  HIDE_LINEARS_BY_METACLASS_AND_TYPE
);
export const hideLinearsByMetaclassAndNetwork = action(
  HIDE_LINEARS_BY_METACLASS_AND_NETWORK
);

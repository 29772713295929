import React, { useState, useEffect } from "react";
import Button from "components/Common/Button";

const BottomRowComponent = React.memo(
  ({
    firstTitle,
    secondTitle,
    firstButtonFunc,
    secondButtonFunc,
    removeHandler
  }) => {
    return (
      <div className="taskSettingsContainerRow taskSettingsContainerBottom">
        <Button onClick={firstButtonFunc}>{firstTitle}</Button>
        <Button secondary onClick={secondButtonFunc}>
          {secondTitle}
        </Button>
        <Button secondary className="pull-right" onClick={removeHandler}>
          Удалить
        </Button>
      </div>
    );
  },
  (oldProps, nextProps) => {
    return (
      oldProps.firstTitle === nextProps.firstTitle ||
      oldProps.secondTitle === nextProps.secondTitle
    );
  }
);

const BottomRowContainer = ({
  type,
  firstButtonFunc,
  secondButtonFunc,
  removeHandler
}) => {
  const [title, setTitle] = useState({ firstTitle: "", secondTitle: "" });
  useEffect(() => {
    if (["area", "linear", "facility"].includes(type)) {
      setTitle({
        firstTitle: "Сохранить",
        secondTitle: "Отмена"
      });
    } else {
      setTitle({
        firstTitle: "Перезапустить",
        secondTitle: "Закрыть"
      });
    }
  }, [type]);
  const { firstTitle, secondTitle } = title;
  return (
    <BottomRowComponent
      firstTitle={firstTitle}
      secondTitle={secondTitle}
      firstButtonFunc={firstButtonFunc}
      secondButtonFunc={secondButtonFunc}
      removeHandler={removeHandler}
    />
  );
};

export default BottomRowContainer;

export const getHideLinears = ({ groupsSettings: { hideLinears } }) =>
  hideLinears;
export const hideLinearsMetaclass = ({
  groupsSettings: { hideLinearsMetaclass }
}) => hideLinearsMetaclass;
export const hideLinearsByMetaclassAndType = ({
  groupsSettings: { hideLinearsByMetaclassAndType }
}) => hideLinearsByMetaclassAndType;
export const hideLinearsByMetaclassAndNetwork = ({
  groupsSettings: { hideLinearsByMetaclassAndNetwork }
}) => hideLinearsByMetaclassAndNetwork;
export const getHideAreas = ({ groupsSettings: { hideAreas } }) => hideAreas;
export const hideAreasGroup = ({ groupsSettings: { hideAreasGroup } }) =>
  hideAreasGroup;
export const getOppenedList = ({ groupsSettings: { oppenedList } }) =>
  oppenedList;
export const getLockedFacilities = ({ groupsSettings: { lockFacilities } }) =>
  lockFacilities;
export const hideFacilitiesGroup = ({
  groupsSettings: { hideFacilitiesGroup }
}) => hideFacilitiesGroup;
export const getOppenedListByType = ({
  groupsSettings: { oppenedListByType }
}) => oppenedListByType;
export const getHidedFacilities = ({ groupsSettings: { hideFacilities } }) =>
  hideFacilities;
export const oppenedTasks = ({ groupsSettings: { oppenedTasks } }) =>
  oppenedTasks;
export const getLinearsToggle = ({ groupsSettings: { linearsToggle } }) =>
  linearsToggle;
export const getHideLinearsByNetwork = ({
  groupsSettings: { hideLinearsByNetwork }
}) => hideLinearsByNetwork;

import React from "react";
import { connect } from "react-redux";
import { getProjectId } from "state/selectors/projects";
import { setRemoveProps, openRemoveAlert } from "state/actions/settings";

const modalObject = (projectId, projectName) => ({
  id: projectId,
  removeType: "renameProject",
  name: projectName || "Без названия",
  title: "Изменить название"
});

const EditProjectName = ({
  projectName,
  projectId,
  setRemoveProps,
  openRemoveAlert
}) => {
  const showModal = () => {
    setRemoveProps("SUCCESS", modalObject(projectId, projectName));
    openRemoveAlert("SUCCESS", true);
  };
  return <button onClick={showModal} className="icon edit edit-project-name" />;
};

const mapStateToProps = state => ({
  projectId: getProjectId(state)
});

const mapDispatchToProps = {
  setRemoveProps,
  openRemoveAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectName);

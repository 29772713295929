import React from "react";
import Point from "./Point";

export default ({ positions, draggable, onDragHandler, ...props }) => {
  return (
    <>
      {positions &&
        positions.map((position, key) => (
          <Point
            key={key}
            {...props}
            index={key}
            position={position}
            draggable={draggable}
            onDragHandler={onDragHandler}
          />
        ))}
    </>
  );
};

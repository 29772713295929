import React from "react";

import Area from "./Area";
import Linear from "./Linear";
import Facility from "./Facility";

export default ({ type, errors }) => (
  <>
    {(() => {
      if (type === "createFacility") {
        return <Facility errors={errors} />;
      }
      if (type === "createLinearObject") {
        return <Linear errors={errors} />;
      }
      if (type === "createArea") {
        return <Area errors={errors} />;
      }
      return null;
    })()}
  </>
);

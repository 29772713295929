import React, { Component } from "react";
import { taskResult, taskId, taskLogs } from "state/selectors/projects";
import { connect } from "react-redux";
import ResultProcess from "../ResultProcess";

class LinearCostResult extends Component {
  state = { totalCost: {}, totalLength: {} };

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  componentWillUnmount() {
    const { linear_id: linearId } = this.state;
    const elems = document.getElementsByClassName(`linear ${linearId}`);
    Array.from(elems).forEach(elem => {
      if (elem.classList.contains("active")) {
        elem.classList.remove("active");
      }
    });
  }

  setPropsToState = props => {
    const { taskResult } = props;
    const data = taskResult;
    if (data) {
      let currentData = data;
      if (data[0]) {
        [currentData] = data;
      }
      let totalCost;
      let totalLength;
      if (currentData.total_length) {
        totalLength = currentData.total_length;
      }
      if (currentData.length) {
        totalLength = currentData.length;
      }
      if (currentData.props) {
        totalLength = currentData.props.length;
      }
      if (currentData.total_cost) {
        totalCost = currentData.total_cost;
      }
      if (currentData.cost) {
        totalCost = currentData.cost;
      }
      if (currentData.props) {
        totalCost = currentData.props.cost;
      }
      this.setState(
        {
          ...data.props,
          totalCost,
          totalLength
        },
        () => {
          const { linear_id: linearId } = currentData;
          const elems = document.getElementsByClassName(`linear ${linearId}`);
          Array.from(elems).forEach(elem => {
            if (!elem.classList.contains("active")) {
              elem.classList.add("active");
            }
          });
        }
      );
    }
  };

  componentWillReciveProps(nextProps) {
    this.setPropsToState(nextProps);
  }

  render() {
    const { taskId, taskResult, taskLogs } = this.props;
    if (!taskResult || !taskResult.length) {
      if (!taskLogs[taskId]) return null;
      return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
    }
    const { totalCost, totalLength } = this.state;
    if (!totalCost || !totalLength || !totalLength.total) {
      return <h2 className="plot-alert-title">Нет данных</h2>;
    }
    return (
      <table className="result-table">
        <thead>
          <tr className="result-table-top result-table-top__linear-cost">
            <th>Участок</th>
            <th>Протяженность, км</th>
            <th>Стоимость строительства, тыс. руб.</th>
            <th />
          </tr>
        </thead>
        <tbody className="result-table__body result-table__body-linear-cost">
          <tr className="result-table__body-common-fields">
            <td>Общий путь</td>
            <td>{(totalLength.total / 1000).toFixed(2) * 1}</td>
            <td>{(totalCost.total / 1000).toFixed(2) * 1}</td>
            <td />
          </tr>
          {!!totalCost.details &&
            Object.keys(totalCost.details).map((key, id) => {
              return (
                <tr key={id}>
                  <td>{key}</td>
                  <td>{(totalLength.details[key] / 1000).toFixed(2) * 1}</td>
                  <td>{(totalCost.details[key] / 1000).toFixed(2) * 1}</td>
                  <td />
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = state => ({
  taskResult: taskResult(state),
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(LinearCostResult);

import React from "react";
import classnames from "classnames";
import setDem from "./utils/setDem";

const ValueWithDem = ({ dem, value }) => {
  return (
    <div className="valueWithDem">
      <span className="valueWithDemValue">{value}</span>
      <span className="valueWithDemDem">{setDem(dem)}</span>
    </div>
  );
};

const DataFieldsContainer = ({
  dem,
  value,
  name,
  onBlurHandler,
  disabled,
  required,
  type,
  placeholder,
  onChangeHandler
}) => {
  return (
    <div className="DataFieldsContainer">
      <div className="data-fields-error-line" />
      {!!(dem && value) && <ValueWithDem dem={dem} value={value} />}
      <input
        name={name}
        onBlur={onBlurHandler}
        disabled={!!disabled}
        className={classnames("DataFields", { required })}
        type={type === "float_" ? "number" : type}
        placeholder={placeholder}
        autoComplete="off"
        value={value ?? ""}
        onChange={({ target }) => onChangeHandler(target)}
      />
    </div>
  );
};

export default DataFieldsContainer;

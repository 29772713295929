import React from "react";
import { connect } from "react-redux";
import { createLinearObject } from "state/selectors/settings";
import LinearCreationToolsContainer from "./LinearCreationToolsContainer";

const LinearCreationTools = ({ createLinearObject, layerRef }) => {
  if (!createLinearObject) return null;
  return <LinearCreationToolsContainer layerRef={layerRef} />;
};

const mapStateToProps = state => ({
  createLinearObject: createLinearObject(state)
});

export default connect(mapStateToProps)(LinearCreationTools);

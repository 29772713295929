import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeTaskSettings } from "state/actions/settings";
import { getSettingsPalette, magnet } from "state/selectors/settings";
import coloringObj from "utils/coloringObj";
import { divIcon } from "leaflet";
import { DrawingPolyline } from "../DrawingTools";

const LinearCreationTools = ({
  getSettingsPalette,
  layerRef,
  magnet,
  changeTaskSettings
}) => {
  const [state, setState] = useState({ myIcon: null, polyStyle: {} });
  useEffect(() => {
    let myIcon;
    let polyStyle = {};
    if (coloringObj(getSettingsPalette)) {
      polyStyle = coloringObj(getSettingsPalette).existing;
    }
    if (getSettingsPalette) {
      myIcon = divIcon({
        className: `draw-icon ${getSettingsPalette}`,
        iconSize: [16, 16],
        iconAnchor: [8, 8]
      });
    }
    setState({ myIcon, polyStyle });
  }, [getSettingsPalette]);
  const linePositionHandler = points => {
    const coordinates = points.map(({ lat, lng }) => [lng, lat]);
    const geom = {
      type: "LineString",
      coordinates
    };
    changeTaskSettings("SUCCESS", { geom });
  };
  return (
    <DrawingPolyline
      magnet={magnet}
      layerGroupRef={layerRef}
      pointIcon={state.myIcon}
      polyStyle={state.polyStyle}
      linePositionHandler={linePositionHandler}
    />
  );
};

const mapStateToProps = state => ({
  magnet: magnet(state),
  getSettingsPalette: getSettingsPalette(state)
});

const mapDispatchToProps = {
  changeTaskSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearCreationTools);

import React from "react";
import CommonField from "../../CommonField";
import units from "./units";
import propsNames from "./propsNames";

const Wateripes = ({ defaultValues, disabled }) => {
  return (
    <>
      <div className="create-task-row">
        <CommonField
          addedProps={{
            dem: units.water_density || "",
            propName: `props.water_density`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.water_density,
            label: propsNames.water_density,
            disabled
          }}
        />
        <CommonField
          addedProps={{
            dem: units.flow_temperature || "",
            propName: `props.flow_temperature`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.flow_temperature,
            label: propsNames.flow_temperature,
            disabled
          }}
        />
      </div>
      <div className="create-task-row">
        <CommonField
          addedProps={{
            dem: units.pressure_min || "",
            propName: `props.pressure_min`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.pressure_min,
            label: propsNames.pressure_min,
            disabled
          }}
        />
        <CommonField
          addedProps={{
            dem: units.pressure_max || "",
            propName: `props.pressure_max`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.pressure_max,
            label: propsNames.pressure_max,
            disabled
          }}
        />
      </div>
    </>
  );
};

export default Wateripes;

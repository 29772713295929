import React from "react";
import classnames from "classnames";

const ToggleListView = ({
  onMouseEnterHandler,
  onMouseLeaveHandler,
  item,
  active,
  containerClass,
  beforeComp,
  beforeProp,
  rowClass,
  chooseRow,
  toggleText,
  afterComp
}) => {
  const onMouseEnter = () => onMouseEnterHandler(item);
  const onMouseLeave = () => onMouseLeaveHandler(item);
  const chooseRowHandler = () => chooseRow(item);
  const isActive = active(item);
  return (
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classnames("row-toggle", { active: isActive }, containerClass)}
    >
      {beforeComp && beforeComp(item, beforeProp)}
      <button
        className={classnames("row-toggle__text", rowClass)}
        onClick={chooseRowHandler}
      >
        {toggleText}
      </button>
      {afterComp && afterComp(item)}
    </li>
  );
};

export default ToggleListView;

import React, { useEffect } from "react";
import classnames from "classnames";

import ReactTooltip from "react-tooltip";

export default ({
  id,
  name,
  avaliableTaskTypes,
  initalDataProps,
  taskClickHandler
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const disabled = !avaliableTaskTypes.includes(id);
  let currentDataProps = {};
  if (disabled) currentDataProps = initalDataProps;
  return (
    <li
      {...currentDataProps}
      onClick={disabled ? () => {} : () => taskClickHandler(id)}
      className={classnames("task-toggle-container__item", {
        disabled
      })}
    >
      {name}
    </li>
  );
};

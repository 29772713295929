import React, { Component } from "react";
import CardsFactory from "./CardsFactory";
import SwitchResults from "./SwitchResults";
import TopRow from "./TopRow";
import BottomRow from "./BottomRow";
import handleTitle from "./utils/handleTitle";

const handleQuestionTitle = type => {
  if (type === "linear") return "линейный объект";
  if (type === "facility") return "объект";
  return "область";
};

const titleHandler = type => {
  if (type === "linear") return "линейного объекта";
  if (type === "facility") return "объекта";
  return "области";
};

class InfoCardContainer extends Component {
  componentWillUnmount = () => {
    this.removeProps();
  };

  removeProps = () => {
    const {
      setTaskFacilities,
      setTaskId,
      getTaskResult,
      setQuarriesIds
    } = this.props;
    setTaskFacilities("SUCCESS", []);
    setTaskId("SUCCESS", null);
    getTaskResult("SUCCESS", []);
    setQuarriesIds("SUCCESS", null);
  };

  closeHandler = () => {
    this.removeProps();
    const { cardInfo, activeTab } = this.props;
    if (cardInfo && cardInfo.type) {
      const { type } = cardInfo;
      if (type) {
        if (type === "facility") {
          activeTab("SUCCESS", "objects");
        } else if (type === "area") {
          activeTab("SUCCESS", "areas");
        } else {
          activeTab("SUCCESS", type);
        }
      }
    } else {
      activeTab("SUCCESS", "tasks");
    }
  };

  repeatTask = () => {
    const { cardInfo } = this.props;
    if (!cardInfo.type) {
      const { taskInfo, repeatTask, removeTaskLogs, activeTab } = this.props;
      const { id, name } = taskInfo;
      repeatTask("REQUEST", { id, name });
      this.removeProps();
      removeTaskLogs("SUCCESS", id);
      activeTab("SUCCESS", "tasks");
    }
  };

  removeHandler = () => {
    const {
      setRemoveProps,
      openRemoveAlert,
      taskInfo,
      cardInfo,
      classesByWellfield
    } = this.props;
    let removeProps = {};
    if (taskInfo.type) {
      const { id, name, type } = taskInfo;
      removeProps = {
        id,
        activeTab: "tasks",
        name: name || "Без названия",
        objectType: handleTitle(type),
        title: "задачи",
        typeTitle: "задачи",
        removeType: "task",
        type,
        questionTitle: "задачу"
      };
    } else {
      const { type } = cardInfo;
      const { id, name, class_id: classId } = cardInfo.data;
      const { name: objectType } = classesByWellfield[classId];
      const questionTitle = handleQuestionTitle(type);
      const title = titleHandler(type);
      removeProps = {
        id,
        name: name || "Без названия",
        objectType,
        title,
        typeTitle: "объекта",
        removeType: type === "facility" ? "object" : type,
        questionTitle
      };
    }
    setRemoveProps("SUCCESS", removeProps);
    openRemoveAlert("SUCCESS", true);
  };

  render() {
    const { taskInfo, cardInfo, taskLogs } = this.props;
    let { type } = taskInfo;
    let info = taskInfo;
    if (Object.keys(cardInfo).length) {
      type = cardInfo.type;
      info = cardInfo.data;
    }
    return (
      <div className="createTaskContainer">
        <TopRow
          name={info.name}
          closeHandler={this.closeHandler}
          logs={taskLogs[info.id]}
        />
        <SwitchResults type={type} />
        {CardsFactory.build(type, info)}
        <BottomRow
          type={type}
          firstButtonFunc={this.repeatTask}
          secondButtonFunc={this.closeHandler}
          removeHandler={this.removeHandler}
        />
      </div>
    );
  }
}

export default InfoCardContainer;

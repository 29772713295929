export default (defaultValues, info) => ({
  firstToggleParams: {
    title: {
      id: "2jx",
      defaultValue: defaultValues.use_roads_params,
      propName: "props.use_roads_params",
      label: "Учитывать допустимые параметры автодорог",
      disabled: info
    },
    rows: [
      {
        dem: "%",
        placeholder: "",
        type: "number",
        defaultValue: defaultValues.height_angle,
        classContainer: "half nowrap-label",
        label: "Допустимый продольный угол",
        propName: "props.height_angle",
        disabled: info
      },
      {
        dem: "м",
        type: "number",
        propName: "props.minimal_radius",
        placeholder: "",
        defaultValue: defaultValues.minimal_radius,
        classContainer: "half nowrap-label",
        label: "Наименьший радиус кривых в плане",
        disabled: info
      },
      {
        id: "2xjx2",
        type: "checkbox",
        propName: "props.use_network_angles",
        label: "Учитывать пересечения с существующими или запланированными ЛО",
        disabled: info
      }
    ]
  },
  secondToggleParams: {
    title: {
      id: "2jxvz",
      defaultValue: defaultValues.use_quarries,
      propName: "props.use_quarries",
      label: "Учитывать карьеры",
      disabled: info
    }
  }
});

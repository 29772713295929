import React from "react";

import List from "../List";
import InfoCard from "../InfoCard";
import CreateCard from "../CreateCard";

const infoCardTypes = [
  "infoCard",
  "objectInfoCard",
  "linearInfoCard",
  "areaInfoCard",
  "taskInfo"
];

const taskCardTypes = ["tasks", "areas", "linear", "objects"];

const FieldsFactory = ({ type }) => {
  if (infoCardTypes.includes(type)) {
    return <InfoCard />;
  }
  if (taskCardTypes.includes(type)) {
    return <List type={type} />;
  }
  return <CreateCard />;
};

export default FieldsFactory;

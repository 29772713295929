import { connect } from "react-redux";
import { projectPalette } from "state/selectors/projects";
import { connectionPoints } from "state/selectors/objects";
import {
  connectionTypes,
  sortClassesByMetaclasses
} from "state/selectors/wellfield";
import ConnectionPointsContainer from "./ConnectionPointsContainer";

const mapStateToProps = state => ({
  projectPalette: projectPalette(state),
  connectionTypes: connectionTypes(state),
  connectionPoints: connectionPoints(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state)
});

export default connect(mapStateToProps)(ConnectionPointsContainer);

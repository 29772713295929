/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from "react";
import { ReactComponent as ArrowCollapse } from "assets/images/arrow-collapse.svg";
import { ReactComponent as ArrowLeft } from "assets/images/arrow-left.svg";
import classnames from "classnames";
import TaskRowTitle from "./TaskRowTitle";
import TaskRowSubTitle from "./TaskRowSubTitle";
import {
  ResultTable,
  ResultTableCell,
  ResultTableRow,
  ResultTableInnerCell
} from "./index";

const TaskResultDetailsTop = ({ value }) => {
  return (
    <div className="task-result__row-details-top">
      <h3 className="task-result__row-details-title">Общая</h3>
      <h3 className="task-result__row-details-value">{value}</h3>
    </div>
  );
};

const TaskResultDetailsBottom = ({ data }) => {
  return (
    <div className="task-result__row-details-bottom">
      {Object.keys(data.details).map((key, index) => {
        const value = data.details[key];
        return (
          <div key={index} className="task-result__details-row">
            <h3 className="task-result__row-details-title">{key}</h3>
            <h3 className="task-result__row-details-value">
              {Intl.NumberFormat("ru-RU").format(value.toFixed(2) / 1000)}
            </h3>
          </div>
        );
      })}
    </div>
  );
};

const TaskResultDetailsPipes = ({
  pipeDiameterMm,
  inputPressureAtm,
  outputPressureAtm,
  pressureDropAtmByKm
}) => {
  if (pipeDiameterMm === undefined || pipeDiameterMm === null) return null;
  return (
    <div className="task-result__row-details-bottom">
      <div className="task-result__details-row">
        <h3 className="task-result__row-details-title">Диаметр труб, мм</h3>
        <h3 className="task-result__row-details-value">
          {Intl.NumberFormat("ru-RU").format(pipeDiameterMm.toFixed(2) / 1000)}
        </h3>
      </div>
      <div className="task-result__details-row">
        <h3 className="task-result__row-details-title">P на входе, атм</h3>
        <h3 className="task-result__row-details-value">
          {Intl.NumberFormat("ru-RU").format(
            inputPressureAtm.toFixed(2) / 1000
          )}
        </h3>
      </div>
      <div className="task-result__details-row">
        <h3 className="task-result__row-details-title">P на выходе, атм</h3>
        <h3 className="task-result__row-details-value">
          {Intl.NumberFormat("ru-RU").format(
            outputPressureAtm.toFixed(2) / 1000
          )}
        </h3>
      </div>
      <div className="task-result__details-row">
        <h3 className="task-result__row-details-title">ΔP/L, атм/км</h3>
        <h3 className="task-result__row-details-value">
          {Intl.NumberFormat("ru-RU").format(
            pressureDropAtmByKm.toFixed(2) / 1000
          )}
        </h3>
      </div>
    </div>
  );
};

const ActiveResultRow = ({ el, taskType }) => {
  const [open, setOpen] = React.useState(false);
  const onClick = () => setOpen(!open);
  const totalLength = Intl.NumberFormat("ru-RU").format(
    el.length.total.toFixed(2) / 1000
  );
  const totalCost = Intl.NumberFormat("ru-RU").format(
    el.cost.total.toFixed(2) / 1000
  );
  return (
    <ResultTableRow
      className={classnames("active-result-row", { open })}
      onClick={onClick}
    >
      <ResultTableCell>
        <ResultTableInnerCell>
          <div className="task-result-collapse-arrow">
            <ArrowCollapse />
          </div>
          <TaskRowTitle
            title={el?.name.split("--")[0]}
            className="active-result-title"
          />
        </ResultTableInnerCell>
        {taskType === "pipes" && el.pipe_diameter_mm !== undefined && (
          <div
            className={classnames("task-result__row-details-component", {
              open
            })}
          >
            <TaskResultDetailsPipes
              pipeDiameterMm={el?.pipe_diameter_mm}
              inputPressureAtm={el?.input_pressure_atm}
              outputPressureAtm={el?.output_pressure_atm}
              pressureDropAtmByKm={el?.pressure_drop_atm_by_km}
            />
          </div>
        )}
      </ResultTableCell>
      <ResultTableCell>
        <ResultTableInnerCell>
          <TaskRowSubTitle subTitle="Протяженность, км" value={totalLength} />
        </ResultTableInnerCell>
        <div
          className={classnames("task-result__row-details-component", {
            open
          })}
        >
          <TaskResultDetailsTop value={totalLength} />
          <TaskResultDetailsBottom data={el.length} />
        </div>
      </ResultTableCell>
      <ResultTableCell>
        <ResultTableInnerCell>
          <TaskRowSubTitle subTitle="Стоимость, тыс. руб." value={totalCost} />
        </ResultTableInnerCell>
        <div
          className={classnames("task-result__row-details-component", {
            open
          })}
        >
          <TaskResultDetailsTop value={totalCost} />
          <TaskResultDetailsBottom data={el.cost} />
        </div>
      </ResultTableCell>
    </ResultTableRow>
  );
};

const ActiveResultComponent = ({
  mainTitle,
  activeResult,
  hideArrow = false,
  goBack = () => {},
  resultTableRowClass,
  linearResultType = "project_linears",
  taskType
}) => {
  const { props, index } = activeResult;
  if (!props) return null;
  const { total_cost, total_length } = props;
  const totalLength = Intl.NumberFormat("ru-RU").format(
    total_length.toFixed(2) / 1000
  );
  const totalCost = Intl.NumberFormat("ru-RU").format(
    total_cost.toFixed(2) / 1000
  );
  return (
    <ResultTable>
      <ResultTableRow
        onClick={goBack}
        className={classnames("active-result-top-row", [resultTableRowClass])}
      >
        <ResultTableCell>
          {!hideArrow && (
            <div className="active-result-arrow">
              <ArrowLeft />
            </div>
          )}
          <TaskRowTitle
            title={mainTitle || `Вариант №${index + 1}`}
            className="active-result-top-row-title"
          />
        </ResultTableCell>
        <ResultTableCell>
          <ResultTableInnerCell>
            <TaskRowSubTitle subTitle="Протяженность, км" value={totalLength} />
          </ResultTableInnerCell>
        </ResultTableCell>
        <ResultTableCell>
          <ResultTableInnerCell>
            <TaskRowSubTitle
              subTitle="Стоимость, тыс. руб."
              value={totalCost}
            />
          </ResultTableInnerCell>
        </ResultTableCell>
      </ResultTableRow>
      {props[linearResultType] &&
        props[linearResultType].map((el, index) => {
          return <ActiveResultRow el={el} key={index} taskType={taskType} />;
        })}
    </ResultTable>
  );
};

export default ActiveResultComponent;

import React from "react";
import { connect } from "react-redux";
import { taskResult, taskId, taskLogs } from "state/selectors/projects";

import { getFacilityById } from "state/selectors/objects";
import { taskConstants } from "utils/constants";
import ResultProcess from "../ResultProcess";
import GatheringCentersResultsViews from "./GatheringCentersResultsViews";

const {
  POWER_SUPPLY_CENTERS,
  GATHERING_CENTERS,
  WATER_PUMPING_STATIONS
} = taskConstants;

const GatheringCentersResults = ({
  facilities,
  type,
  taskResult,
  taskLogs,
  taskId
}) => {
  const [dataArr, setDataArr] = React.useState([]);
  React.useEffect(() => {
    if (taskResult && taskResult[0]) {
      const data = taskResult[0].props;
      if (type === GATHERING_CENTERS) {
        setDataArr(data.gathering_centers_ids);
      }
      if (type === POWER_SUPPLY_CENTERS) {
        setDataArr(data.electricity_centers_ids);
      }
      if (type === WATER_PUMPING_STATIONS) {
        setDataArr(data.water_gathering_centers_ids);
      }
    }
  }, [type]);
  if (!taskResult || !taskResult.length) {
    if (!taskLogs[taskId]) return null;
    return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
  }
  return (
    <GatheringCentersResultsViews
      dataArr={dataArr}
      type={type}
      taskResult={taskResult}
      facilities={facilities}
      taskResultProps={taskResult[0].props}
      facilitiesIds={taskResult[0].props.facilities_ids}
      applied={taskResult[0].props.applied}
    />
  );
};

const mapStateToProps = state => ({
  facilities: getFacilityById(state),
  taskResult: taskResult(state),
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(GatheringCentersResults);

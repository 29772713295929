import React from "react";
import { connect } from "react-redux";
import { quarriesIds, facilityId } from "state/selectors/settings";

const BottomRow = ({ active, taskFacilities, taskQuarries }) => {
  if (taskFacilities && taskQuarries) return null;
  return (
    <div className="alert-row">
      <h2 className="alert-row__title">
        Выберите на карте
        {!taskFacilities && (
          <>
            <span className="alert-row__title-bold"> «Объект»</span> для
            оптимизации
          </>
        )}
        {!taskFacilities && !taskQuarries && active === "quarries" && <> и </>}
        {!taskQuarries && active === "quarries" && (
          <>
            <span className="alert-row__title-bold"> «Карьер(ы)»</span> для
            возки стройматерилов
          </>
        )}
      </h2>
    </div>
  );
};

const mapStateToProps = state => ({
  taskFacilities: facilityId(state),
  taskQuarries: quarriesIds(state)
});

export default connect(mapStateToProps)(BottomRow);

import React from "react";
import classnames from "classnames";

const ProjectListTitle = ({ showList, onClickHandler, title }) => {
  return (
    <div className={classnames("main-row-toggle", { hide: !showList })}>
      <button
        onClick={onClickHandler}
        className="toggle-title main-toggle-title"
      >
        <span className="arrow" />
        <span className="main-row-toggle__text">{title}</span>
      </button>
    </div>
  );
};

export default ProjectListTitle;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _get from "lodash.get";
import TopMenuView from "./TopMenuView";

class TopMenu extends Component {
  componentDidMount() {
    const { handleClickOutside } = this.props;
    document.addEventListener("mousedown", handleClickOutside);
  }

  componentWillUnmount() {
    const { handleClickOutside } = this.props;
    document.removeEventListener("mousedown", handleClickOutside);
  }

  openProject = () => {
    const { history } = this.props;
    history.push("/projects/all");
  };

  createProject = () => {
    const { clearState, history } = this.props;
    clearState();
    history.replace("/app/new");
  };

  saveProject = () => {
    const { setSuccessLogs } = this.props;
    setSuccessLogs("SUCCESS", "Project is not saved successfully");
  };

  openFirstPage = () => {
    const { setMapType, history } = this.props;
    setMapType("SUCCESS", "Google");
    history.push("/projects");
  };

  showModal = () => {
    const { showModal, optionHandler } = this.props;
    showModal("SUCCESS", {
      type: "AboutProjectModal",
      option: null
    });
    optionHandler();
  };

  removeProjectHandler = () => {
    const {
      wellfieldId,
      openRemoveAlert,
      setRemoveProps,
      projectId,
      projectName,
      wellfields
    } = this.props;
    const wellfield = _get(wellfields, [wellfieldId, "name"]) || "нет названия";
    setRemoveProps("SUCCESS", {
      id: projectId,
      wellfield,
      removeType: "project",
      name: projectName || "Без названия",
      title: "проекта",
      questionTitle: "проект"
    });
    openRemoveAlert("SUCCESS", true);
  };

  downloadProjectHandler = () => {
    const { downloadProject, projectId: id, projectName: name } = this.props;
    downloadProject("REQUEST", { id, name });
  };

  render() {
    return (
      <TopMenuView
        showModal={this.showModal}
        saveProject={this.saveProject}
        openProject={this.openProject}
        openFirstPage={this.openFirstPage}
        createProject={this.createProject}
        removeProjectHandler={this.removeProjectHandler}
        downloadProjectHandler={this.downloadProjectHandler}
      />
    );
  }
}

export default withRouter(TopMenu);

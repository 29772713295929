import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import MenuIcon from "./MenuIcon";

import setTooltipTitle from "./utils/setTooltipTitle";

const MenuItem = ({ showMenuHandler, type }) => {
  const ref = React.createRef();
  const onClickHandler = () => {
    showMenuHandler(type);
    const elements = document.getElementsByClassName("topMenu__item");
    if (!ref.current.classList.contains("active")) {
      [].map.call(elements, el => el.classList.remove("active"));
      ref.current.classList.add("active");
    } else {
      [].map.call(elements, el => el.classList.remove("active"));
    }
  };
  return (
    <div
      ref={ref}
      data-for="mainTooltip"
      data-place="bottom"
      onClick={onClickHandler}
      data-tip={setTooltipTitle(type)}
      className={classnames("topMenu__item", {
        disable: ["timeline", "heatMap"].includes(type)
      })}
    >
      <MenuIcon type={type} />
    </div>
  );
};

MenuItem.propTypes = {
  onClick: PropTypes.func
};

export default MenuItem;

import React, { useRef, useEffect } from "react";

const Logout = props => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div
      tabIndex={1}
      onBlur={props.showHandler}
      ref={ref}
      className="menu__container"
      onClick={props.onClickHandler}
    >
      <div className="menu__triangle" />
      <h2 className="menu__title">Выйти из системы</h2>
    </div>
  );
};

export default Logout;

import React from "react";
import { Map, ScaleControl } from "react-leaflet";
import { connect } from "react-redux";
import { selectFacilities } from "state/selectors/settings";
import classnames from "classnames";
import Ruler from "./Ruler";
import ElementsGroup from "./ElementsGroup";
import CreationTools from "./CreationTools";
import TileLayerComponent from "./TileLayerComponent";
import SplashPoly from "./SplashPoly/SplashPolyRedux";
import ReactLeafletAdjust from "./ReactLeafletAdjust";
import "leaflet/dist/leaflet.css";
import "./style.scss";

const MapContainer = React.memo(() => {
  const layerRef = React.useRef();
  const splashRef = React.useRef();
  return (
    <Map id="map" zoom={14} center={[67.86, 75.31]}>
      <Ruler />
      <ReactLeafletAdjust />
      <TileLayerComponent />
      <ElementsGroup ref={layerRef} />
      <SplashPoly splashRef={splashRef} />
      <CreationTools layerRef={layerRef} splashRef={splashRef} />
      <ScaleControl />
    </Map>
  );
});

class ReactLeafletMap extends React.PureComponent {
  render() {
    const { selectFacilities } = this.props;
    return (
      <div
        id="mapUI"
        className={classnames({
          "select-facilities": selectFacilities
        })}
      >
        <MapContainer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  selectFacilities: selectFacilities(state)
});

export default connect(mapStateToProps)(ReactLeafletMap);

import React from "react";
import { Pane, LayerGroup } from "react-leaflet";

import Areas from "./Areas";
import Linear from "./Linears";
import Facilities from "./Facilities";
import TaskFacilities from "./Facilities/TaskFacilities";
import ConnectionPoints from "./Facilities/ConnectionPoints";

const ElementsGroup = (props, layerRef) => {
  return (
    <>
      <Pane name="areas">
        <Areas />
      </Pane>
      <Pane name="linears">
        <Linear />
      </Pane>
      <Pane name="facilities">
        <Facilities />
        <TaskFacilities />
        <LayerGroup ref={layerRef}>
          <ConnectionPoints />
        </LayerGroup>
      </Pane>
    </>
  );
};

export default React.forwardRef(ElementsGroup);

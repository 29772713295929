import {
  GET_LAYOUTS_REQUEST,
  GET_LAYOUTS_SUCCESS,
  GET_METACLASSES_BY_WELLFIELD_SUCCESS,
  GET_CLASSES_BY_WELLFIELD_SUCCESS,
  GET_CLASSES_BY_METACLASS_SUCCESS,
  SORT_CLASSES_BY_METACLASS_SUCCESS,
  SORT_METACLASSES_BY_CLASSES_SUCCESS,
  PUT_DEMS_BY_WELLFIELD_SUCCESS,
  GET_METACLASSES_PROPS_SUCCESS,
  GET_CLASSES_PROPS_SUCCESS,
  ADDITIONAL_TASK_PROPS_SUCCESS,
  PUT_CONNECTION_TYPES_SUCCESS,
  SET_PRIMARY_FACILITIES_SUCCESS,
  GET_FACILITIES_BY_LAYOUTS_SUCCESS
} from "../constants/wellfield";

import { CLEAR_STATE_SUCCESS } from "../constants/projects";

const initialState = {
  additionalTaskProps: {
    pipes: []
  },
  metaclassesByWellfield: [],
  facilitiesByLayout: {},
  primaryFacilities: {},
  connectionTypes: {},
  demsByWellfield: [],
  classesProps: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE_SUCCESS: {
      return initialState;
    }
    case GET_LAYOUTS_REQUEST: {
      return {
        ...state,
        facilitiesByLayout: [],
        primaryFacilities: []
      };
    }
    case GET_FACILITIES_BY_LAYOUTS_SUCCESS: {
      return {
        ...state,
        facilitiesByLayout: action.payload
      };
    }
    case SET_PRIMARY_FACILITIES_SUCCESS: {
      return {
        ...state,
        primaryFacilities: action.payload
      };
    }
    case GET_LAYOUTS_SUCCESS: {
      return {
        ...state,
        layouts: action.payload
      };
    }
    case PUT_CONNECTION_TYPES_SUCCESS: {
      return {
        ...state,
        connectionTypes: action.payload
      };
    }
    case ADDITIONAL_TASK_PROPS_SUCCESS: {
      return {
        ...state,
        additionalTaskProps: action.payload
      };
    }
    case GET_METACLASSES_BY_WELLFIELD_SUCCESS:
      return {
        ...state,
        metaclassesByWellfield: action.payload
      };
    case SORT_CLASSES_BY_METACLASS_SUCCESS:
      return {
        ...state,
        sortClassesByMetaclass: action.payload
      };
    case SORT_METACLASSES_BY_CLASSES_SUCCESS:
      return {
        ...state,
        sortMetaclassesByClasses: action.payload
      };
    case GET_CLASSES_PROPS_SUCCESS:
      return {
        ...state,
        classesProps: action.payload
      };
    case GET_METACLASSES_PROPS_SUCCESS:
      return {
        ...state,
        metaclassesProps: action.payload
      };
    case GET_CLASSES_BY_WELLFIELD_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case GET_CLASSES_BY_METACLASS_SUCCESS:
      return {
        ...state,
        classesByMetaclass: action.payload
      };
    case PUT_DEMS_BY_WELLFIELD_SUCCESS:
      return {
        ...state,
        demsByWellfield: action.payload
      };
    default:
      return state;
  }
}

import { connect } from "react-redux";
import { getActiveTab, getClickType } from "state/selectors/settings";
import { clickType } from "state/actions/settings";
import ChooseObjectsHandlerContainer from "./ChooseObjectsHandlerContainer";

const mapStateToProps = state => ({
  getClickType: getClickType(state),
  taskType: getActiveTab(state)
});

const mapDispatchToProps = {
  clickType
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseObjectsHandlerContainer);

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { removeObject } from "state/actions/projects";
import { showModal as setShowModal } from "state/actions/settings";
import { modalOption } from "state/selectors/settings";

import RemoveConfirmationContainer from "./RemoveConfirmationContainer";

const RemoveConfirmationRedux = props => {
  return <RemoveConfirmationContainer {...props} />;
};

const mapDipatchToProps = {
  removeObject,
  setShowModal
};

const mapStateToProps = state => ({
  removeProps: modalOption(state)
});

export default withRouter(
  connect(mapStateToProps, mapDipatchToProps)(RemoveConfirmationRedux)
);

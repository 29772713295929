import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import "./TopMenu.scss";
import ReactTooltip from "react-tooltip";

import MenuWrapper from "./MenuWrapper";
import UserContainer from "./UserContainer";
import ButtonsFactory from "./ButtonsFactory";

class TopMenuContainer extends Component {
  state = {
    buttonsType: "",
    menu: ""
  };

  componentDidMount() {
    const { location } = this.props;
    this.handleButtonsType(location.pathname);
    ReactTooltip.rebuild();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (
      prevProps.location.pathname !== location.pathname &&
      location.pathname
    ) {
      this.handleButtonsType(location.pathname);
    }
  }

  handleButtonsType = pathname => {
    if (pathname.includes("app") && !pathname.includes("new")) {
      this.setState({
        buttonsType: "project"
      });
    } else if (
      !pathname.includes("all") &&
      !pathname.includes("new") &&
      (pathname.includes("projects") || pathname.includes("wellfields"))
    ) {
      this.setState({
        buttonsType: "startPageButtons",
        menu: false
      });
    } else if (pathname.includes("all")) {
      this.setState({
        buttonsType: "user",
        menu: false
      });
    } else {
      this.setState({
        buttonsType: ""
      });
    }
  };

  handleClick = (type, elem) => {
    const {
      activeTab,
      clickType,
      getActiveTab,
      getClickType,
      setTaskSettings,
      changeTaskSettings,
      removeHiddenMetaclass,
      getClassesByMetaclass,
      setTransformSettings,
      metaclassesByTypeWithPalette
    } = this.props;
    activeTab("SUCCESS", type);
    if (type === "createFacility") {
      const initialValue = {
        class_id: elem.id,
        palette: elem.palette
      };
      clickType("SUCCESS", [type]);
      if (getActiveTab !== type) {
        setTaskSettings("SUCCESS", initialValue);
      } else {
        changeTaskSettings("SUCCESS", initialValue);
      }
      getClassesByMetaclass("SUCCESS", metaclassesByTypeWithPalette.facility);
      removeHiddenMetaclass("REQUEST", { type, id: elem.id });
    } else if (["createLinearObject", "createArea"].includes(type)) {
      clickType("SUCCESS", [type]);
      const initialValue = {
        metaclass_id: elem.id,
        palette: elem.palette
      };
      if (getActiveTab !== type) {
        setTaskSettings("SUCCESS", initialValue);
      } else {
        changeTaskSettings("SUCCESS", initialValue);
      }
      removeHiddenMetaclass("REQUEST", { type, id: elem.id });
      setTransformSettings("SUCCESS", "draw");
    } else if (getClickType !== type) {
      if (type === "quarries") {
        clickType("SUCCESS", type);
      }
      if (type === "hvl") {
        clickType("SUCCESS", type);
      }
      if (type === "linearPipes") {
        clickType("SUCCESS", `${type}.${elem}`);
        changeTaskSettings("SUCCESS", { type: elem });
      }
      if (type === "facility") {
        clickType("SUCCESS", type);
      }
    }
    this.setState({
      menu: false
    });
  };

  handleClickOutside = event => {
    if (this.topMenuRef && !this.topMenuRef.contains(event.target)) {
      this.setState({ menu: false });
      [].map.call(document.getElementsByClassName("topMenu__item"), el =>
        el.classList.remove("active")
      );
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  };

  showMenuHandler = menuHandler => {
    if (menuHandler === "ruler") {
      const { clickType } = this.props;
      clickType("SUCCESS", menuHandler);
    } else {
      const { menu } = this.state;
      const value = menu === menuHandler ? "" : menuHandler;
      if (value) {
        document.addEventListener("mousedown", this.handleClickOutside);
      } else {
        document.removeEventListener("mousedown", this.handleClickOutside);
      }
      this.setState({
        menu: value
      });
    }
  };

  render() {
    const { menu, buttonsType } = this.state;
    const {
      additionalTaskProps,
      avaliableTaskTypes,
      metaclassesByTypeWithPalette
    } = this.props;
    const pipesType = additionalTaskProps ? additionalTaskProps.pipes : [];
    return (
      <div className="topMenu" ref={node => (this.topMenuRef = node)}>
        <ButtonsFactory
          type={buttonsType}
          showMenuHandler={this.showMenuHandler}
        />
        {menu && (
          <MenuWrapper
            type={menu}
            pipesType={pipesType}
            handleClick={this.handleClick}
            metaclassesByType={metaclassesByTypeWithPalette}
            avaliableTaskTypes={avaliableTaskTypes}
          />
        )}
        <UserContainer />
      </div>
    );
  }
}

TopMenuContainer.propTypes = {
  getClassesByMetaclass: PropTypes.func.isRequired,
  changeTaskSettings: PropTypes.func.isRequired,
  setTaskSettings: PropTypes.func.isRequired,
  clickType: PropTypes.func.isRequired
};

export default withRouter(TopMenuContainer);

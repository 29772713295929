import React, { Component } from "react";
import { connect } from "react-redux";
import { setRemoveProps, openRemoveAlert } from "state/actions/settings";
import { withRouter } from "react-router-dom";

import User from "./User";

class UserContainer extends Component {
  logOut = () => {
    const { setRemoveProps, openRemoveAlert } = this.props;
    setRemoveProps("SUCCESS", {
      removeType: "logout",
      title: "Выйти из системы",
      questionTitle: "Вы действительно хотите выйти"
    });
    openRemoveAlert("SUCCESS", true);
  };

  render() {
    const { location } = this.props;
    if (!location.pathname || location.pathname.includes("login")) {
      return null;
    }
    return <User clickHandler={this.logOut} />;
  }
}

export default withRouter(
  connect(null, {
    setRemoveProps,
    openRemoveAlert
  })(UserContainer)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { errors, success, info } from 'state/selectors/common';
import { setError, setInfoLogs, setSuccessLogs } from 'state/actions/common';

import ReactNotification, { store } from 'react-notifications-component';
import ErrorItem from './ErrorItem';

class ErrorContainer extends Component {
  notificationDOMRef = React.createRef();

  componentDidUpdate() {
    const { errors, success, info } = this.props;
    let content;
    if (errors.length) {
      content = <ErrorItem errors err={errors} />;
    }
    if (success.length) {
      content = <ErrorItem success err={success} />;
    }
    if (info.length) {
      content = <ErrorItem info err={info} />;
    }
    if (success.length || errors.length || info.length) {
      const { setSuccessLogs, setInfoLogs, setError } = this.props;
      if (!this.notificationDOMRef.current) return;
      store.addNotification({
        content,
        type: 'custom',
        insert: 'bottom',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: { duration: 5000 },
        dismissable: { click: true }
      });
      if (success.length) {
        setSuccessLogs('SUCCESS', []);
      }
      if (info.length) {
        setInfoLogs('SUCCESS', []);
      }
      if (errors.length) {
        setError('SUCCESS', []);
      }
    }
  }

  onNotificationRemoval = () => {
    console.log('onNotificationRemoval');
  };

  render() {
    return (
      <ReactNotification
        ref={this.notificationDOMRef}
        onNotificationRemoval={this.onNotificationRemoval}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  info: info(state),
  errors: errors(state),
  success: success(state)
});

const mapDispatchToProps = {
  setError,
  setInfoLogs,
  setSuccessLogs
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer);

import { connect } from "react-redux";
import { taskLogs } from "state/selectors/projects";
import {
  removeClickType,
  changeTaskSettings,
  activeTab
} from "state/actions/settings";

import { getActiveTab } from "state/selectors/settings";

import MainContainerTopContainer from "./MainContainerTopContainer";

const mapStateToProps = state => ({
  taskLogs: taskLogs(state),
  type: getActiveTab(state)
});

const mapDispatchToProps = {
  activeTab,
  removeClickType,
  changeTaskSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainerTopContainer);

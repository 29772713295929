import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { taskResult } from "state/selectors/projects";
import { setNewPoPosition } from "state/actions/projects";
import Button from "components/Common/Button";

const HandleFacility = () => {
  const getTaskResult = useSelector(taskResult);
  const dispatch = useDispatch();
  const onChangeHandler = () => {
    if (getTaskResult) {
      const { id, props } = getTaskResult[0];
      const {
        optimized_facility_id: optimizedFacilityId,
        facility_id: oldFacilityId
      } = props;
      dispatch(
        setNewPoPosition("REQUEST", {
          id,
          oldFacilityId,
          facility_id: optimizedFacilityId
        })
      );
    }
  };
  return <Button onClick={onChangeHandler}>Разместить оптимально</Button>;
};

export default HandleFacility;

import { connect } from "react-redux";
import { auth } from "state/selectors/auth";
import { login, setAuth } from "state/actions/auth";

import LoginContainer from "./LoginContainer";

const mapDispatchToProps = { login, setAuth };

const mapStateToProps = state => ({
  auth: auth(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

import React from "react";

export default ({ payload, unit = "тыс. руб." }) => {
  if (!payload || !payload[0]) return null;
  if (!payload[0].payload) return null;
  return (
    <div className="graph-tooltip">
      <h2 className="graph-tooltip__text">
        {Intl.NumberFormat("ru-RU").format(payload[0].payload.y.toFixed(2))}{" "}
        {unit}
      </h2>
    </div>
  );
};

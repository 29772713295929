import React, { Component } from "react";

import SwitchComponent from "components/Common/SwitchComponent";
import _get from "lodash.get";

const setTitle = type => {
  if (type === "linear") return "Вид профиля и распределение стоимости";
  return "Журнал выполнения и результаты задачи";
};

class SwitchResultsContainer extends Component {
  state = {
    switchResult: false,
    title: ""
  };

  componentDidMount() {
    const {
      taskId,
      showTaskResults,
      oppenedTasks,
      getLinearsToggle,
      cardInfo
    } = this.props;
    if (oppenedTasks[taskId]) {
      this.setState({ switchResult: true }, () =>
        showTaskResults("SUCCESS", true)
      );
    }
    if (_get(cardInfo, ["data", "id"])) {
      if (_get(getLinearsToggle, cardInfo.data.id)) {
        this.setState({ switchResult: true }, () =>
          showTaskResults("SUCCESS", true)
        );
      }
    }
    this.handleTitle();
  }

  componentDidUpdate(prevProps) {
    const { type } = this.props;
    if (prevProps.type !== type) {
      this.handleTitle();
    }
  }

  componentWillUnmount() {
    this.showResults(false);
  }

  switchResultsHandler = () => {
    const { switchResult } = this.state;
    this.setState({ switchResult: !switchResult }, () => {
      const { switchResult } = this.state;
      const { removeOpenedTasks, taskId } = this.props;
      this.showResults(switchResult);
      if (!switchResult) {
        removeOpenedTasks("SUCCESS", { taskId });
      }
    });
  };

  showResults = val => {
    const { showTaskResults } = this.props;
    showTaskResults("SUCCESS", val);
  };

  handleTitle = () => {
    const { type } = this.props;
    const title = setTitle(type);
    this.setState({ title });
  };

  render() {
    const { title, switchResult } = this.state;
    const { type } = this.props;
    if (["facility", "area"].includes(type)) return null;
    return (
      <div className="switch-results">
        <h2 className="switch-results__title">{title}</h2>
        <div className="switch-results__line" />
        <SwitchComponent
          value={switchResult}
          onChange={this.switchResultsHandler}
          containerClass="switch-results__switch"
        />
      </div>
    );
  }
}

export default SwitchResultsContainer;

import React from "react";

export default ({
  onCancel,
  progress,
  leftLabelText,
  fileName,
  rightLabelText
}) => {
  return (
    <div className="dropzone__results dropzone ">
      <div className="dropzone__container">
        <span className="dropzone__logs-container">
          <p className="dropzone__logs-result">
            <p className="download-indicator__label">
              {leftLabelText}&laquo;{fileName}&raquo; ...{" "}
            </p>
            <progress
              className="download-indicator"
              value={progress}
              label-content={rightLabelText}
              max="100"
            />
          </p>
        </span>
        <button
          className="primary-button dropzone__logs-button"
          onClick={onCancel}
        >
          Прервать
        </button>
      </div>
    </div>
  );
};

import React from "react";
import { useDispatch, connect } from "react-redux";
import coloringObj from "utils/coloringObj";
import { taskId, taskLogs } from "state/selectors/projects";
import Button from "components/Common/Button";
import { sendNetwork } from "state/actions/projects";
import TaskRowTitle from "../ResultTable/TaskRowTitle";
import HideNetwork from "../ResultTable/HideNetwork";
import TaskRowSubTitle from "../ResultTable/TaskRowSubTitle";
import { ResultTable, ResultTableRow, ResultTableCell } from "../ResultTable";
import ResultProcess from "../ResultProcess";

const Component = ({ el, index, onClickHandler }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    if (typeof onClickHandler === "function") {
      onClickHandler(el, index);
    }
  };
  const acceptResult = () => {
    dispatch(
      sendNetwork("REQUEST", {
        index: index + 1,
        id: el.id,
        name: el.task_name
      })
    );
  };
  const totalLength = Intl.NumberFormat("ru-RU").format(
    el.props.total_length.toFixed(2) / 1000
  );
  const totalCost = Intl.NumberFormat("ru-RU").format(
    el.props.total_cost.toFixed(2) / 1000
  );
  return (
    <div className="result-table-row-container">
      <HideNetwork network_id={el.props.network_id} />
      <Button onClick={acceptResult} className="task-result-button">
        Перенести в проект
      </Button>
      <ResultTableRow onClick={onClick}>
        <ResultTableCell>
          <span
            className="task-results__color-line"
            style={{
              backgroundColor: coloringObj(`tmpColor${index}`).color
            }}
          />
          <TaskRowTitle
            title={`Вариант №${index + 1}`}
            className="with-hide-network"
          />
        </ResultTableCell>
        <ResultTableCell>
          <TaskRowSubTitle
            subTitle="Общая протяженность, км:"
            value={totalLength}
          />
        </ResultTableCell>
        <ResultTableCell>
          <TaskRowSubTitle
            subTitle="Общая стоимость, тыс. руб.:"
            value={totalCost}
          />
        </ResultTableCell>
      </ResultTableRow>
    </div>
  );
};

const Container = ({ taskResult, onClickHandler, taskLogs, taskId }) => {
  if (!taskResult || !taskResult.length) {
    if (!taskLogs[taskId]) return null;
    return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
  }
  return (
    <ResultTable>
      {taskResult &&
        taskResult.map((el, index) => {
          return (
            <Component
              el={el}
              key={el.id}
              index={index}
              onClickHandler={onClickHandler}
            />
          );
        })}
    </ResultTable>
  );
};

const mapStateToProps = state => ({
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(Container);

import React from "react";
import { connect } from "react-redux";
import { getTaskResult } from "state/actions/projects";

const ResultProcess = ({ taskLogs, taskId, getTaskResult }) => {
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    if (taskLogs[taskId][0]) {
      const { finished, has_errors: hasErrors } = taskLogs[taskId][0];
      if (hasErrors && finished) {
        setError(true);
      } else if (finished) {
        getTaskResult("REQUEST", {
          id: taskId
        });
      }
    }
  }, [taskId, taskLogs]);
  return (
    <div className="result-process-container">
      {error && (
        <h3 className="result-process-title">
          Результаты задачи отсутствуют,
          <br />
          так как расчет завершился с ошибкой.
        </h3>
      )}
      {!error && (
        <h3 className="result-process-title">
          Задача находится в процессе расчета.
          <br />
          Результаты отобразятся после окончания расчета.
        </h3>
      )}
    </div>
  );
};
const mapDispatchToProps = {
  getTaskResult
};

export default connect(null, mapDispatchToProps)(ResultProcess);

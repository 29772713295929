import { action } from "utils";
import {
  GET_LAYOUTS,
  REMOVE_WELLFIELD,
  GET_CLASSES_PROPS,
  GET_WELLFIELD_INFO,
  DOWNLOAD_WELLFIELD,
  PUT_CONNECTION_TYPES,
  ADDITIONAL_TASK_PROPS,
  PUT_DEMS_BY_WELLFIELD,
  GET_METACLASSES_PROPS,
  SET_PRIMARY_FACILITIES,
  GET_CLASSES_BY_WELLFIELD,
  GET_CLASSES_BY_METACLASS,
  SORT_CLASSES_BY_METACLASS,
  GET_FACILITIES_BY_LAYOUTS,
  SORT_METACLASSES_BY_CLASSES,
  GET_METACLASSES_BY_WELLFIELD
} from "../constants/wellfield";

export const getLayouts = action(GET_LAYOUTS);
export const removeWellfield = action(REMOVE_WELLFIELD);
export const getClassesProps = action(GET_CLASSES_PROPS);
export const getWellfieldInfo = action(GET_WELLFIELD_INFO);
export const downloadWellfield = action(DOWNLOAD_WELLFIELD);
export const putConnectionTypes = action(PUT_CONNECTION_TYPES);
export const putDemsByWellfield = action(PUT_DEMS_BY_WELLFIELD);
export const getMetaclassesProps = action(GET_METACLASSES_PROPS);
export const additionalTaskProps = action(ADDITIONAL_TASK_PROPS);
export const setPrimaryFacilities = action(SET_PRIMARY_FACILITIES);
export const getClassesByMetaclass = action(GET_CLASSES_BY_METACLASS);
export const getClassesByWellfield = action(GET_CLASSES_BY_WELLFIELD);
export const getFacilitiesByLayout = action(GET_FACILITIES_BY_LAYOUTS);
export const sortClassesByMetaclasses = action(SORT_CLASSES_BY_METACLASS);
export const sortMetaclassesByClasses = action(SORT_METACLASSES_BY_CLASSES);
export const getMetaclassesByWellfield = action(GET_METACLASSES_BY_WELLFIELD);

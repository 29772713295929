import { action } from "utils";

import {
  GET_AREAS,
  SELECT_ALL,
  GET_LINEARS,
  REMOVE_AREAS,
  REMOVE_LINEARS,
  GET_AREA_BY_ID,
  GET_FACILITIES,
  REMOVE_FACILITY,
  GET_LINEAR_BY_ID,
  SET_FACILITY_BY_ID,
  GET_FACILITIES_BY_ID
} from "../constants/objects";

export const getAreas = action(GET_AREAS);
export const selectAll = action(SELECT_ALL);
export const getlinears = action(GET_LINEARS);
export const removeAreas = action(REMOVE_AREAS);
export const getAreaById = action(GET_AREA_BY_ID);
export const removeLinears = action(REMOVE_LINEARS);
export const getFacilities = action(GET_FACILITIES);
export const removeFacility = action(REMOVE_FACILITY);
export const getLinearById = action(GET_LINEAR_BY_ID);
export const setFacilityById = action(SET_FACILITY_BY_ID);
export const getFacilitiesById = action(GET_FACILITIES_BY_ID);

import { connect } from "react-redux";
import {
  activeTab,
  createTask,
  createObject,
  removeClickType,
  changeTaskSettings
} from "state/actions/settings";
import { getProjectId } from "state/selectors/projects";
import { getTaskSettings, getActiveTab } from "state/selectors/settings";
import {
  removeTask,
  sendFacility,
  getFacilitiesFromProjectByTaskType
} from "state/actions/projects";

import CreateCardContainer from "./CreateCardContainer";

const mapStateToProps = state => ({
  type: getActiveTab(state),
  project_id: getProjectId(state),
  getTaskSettings: getTaskSettings(state),
  selectedObjects: getTaskSettings(state).props
});

const mapDispathToProps = {
  activeTab,
  removeTask,
  createTask,
  sendFacility,
  createObject,
  removeClickType,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType
};

export default connect(mapStateToProps, mapDispathToProps)(CreateCardContainer);

import React from "react";
import "../projects.scss";
import PreviewCol from "./previewCol";
import ProjectsCol from "./projectsCol";

const ProjectsFirstPageComponent = ({
  remove,
  setDate,
  projects,
  download,
  handleClick,
  setWellField,
  createNewProject,
  openProjectHandler
}) => {
  const clickHandler = item => handleClick(item);

  return (
    <div className="projects">
      <div className="projects__container">
        <PreviewCol
          createNew={createNewProject}
          openProjectHandler={openProjectHandler}
        />
        <ProjectsCol
          remove={remove}
          download={download}
          projects={projects}
          handleClick={clickHandler}
          setWellField={setWellField}
          setDate={setDate}
        />
      </div>
    </div>
  );
};

export default ProjectsFirstPageComponent;

import React, { Component } from "react";
import autosize from "autosize";

export default class RenameProject extends Component {
  componentDidMount() {
    autosize(this.node);
  }

  render() {
    const { projectName, changeProjectName } = this.props;
    return (
      <div>
        <h3 className="remove-alert__content-title">Новое название проекта</h3>
        <textarea
          rows="1"
          value={projectName}
          onChange={changeProjectName}
          ref={node => (this.node = node)}
          className="alert-textarea DataFields"
        />
      </div>
    );
  }
}

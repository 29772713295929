import axios, { CancelToken } from "axios";
import { CANCEL, END, eventChannel } from "redux-saga";

const config = () => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache"
  };
  return {
    withCredentials: true,
    timeout: 1000 * 1000,
    headers
  };
};

export const API = axios.create(config());

const createRequest = (type, ...params) => {
  const source = CancelToken.source();
  const request = API[type](...params, { cancelToken: source.token });
  request[CANCEL] = () => source.cancel();
  return request;
};

export const getRequest = url => createRequest("get", url);

export const postRequest = (url, data) => createRequest("post", url, data);

export const putRequest = (url, data) => createRequest("put", url, data);

export const deleteRequest = url => createRequest("delete", url);

export const createUploadFileChannel = (url, data) => {
  const channel = eventChannel(emitter => {
    const onProgress = ({ total, loaded }) => {
      const percentage = Math.round((loaded * 100) / total);
      emitter({ progress: percentage });
    };
    const source = CancelToken.source();
    const uploadRequestConfig = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache"
      },
      withCredentials: true,
      timeout: 1000 * 1000,
      onUploadProgress: onProgress,
      cancelToken: source.token
    };
    axios
      .post(url, data, uploadRequestConfig)
      .then(response => {
        emitter({ id: response.data.data[0].task_id });
        emitter(END);
      })
      .catch(err => {
        emitter({ err: new Error(err.message) });
        emitter(END);
      });
    const unsubscribe = () => {};
    return unsubscribe;
  });
  return channel;
};

import React, { Component } from "react";
import classnames from "classnames";
import Button from "components/Common/Button";

class RemoveConfirmationContainer extends Component {
  closeRemoveAlert = () => {
    this.props.setShowModal("SUCCESS", { type: "", option: null });
  };

  removeHandler = () => {
    const {
      id,
      name,
      type: condition,
      removeType: type
    } = this.props.removeProps;

    let obj = {
      id,
      type,
      name
    };
    if (condition) {
      obj = {
        ...obj,
        condition
      };
    }
    this.props.removeObject("REQUEST", obj);
    if (type === "project") {
      this.props.history.replace("/projects");
    }

    this.props.setShowModal("SUCCESS", { type: "", option: null });
  };

  render() {
    const { removeProps, containerClass } = this.props;
    const {
      name,
      title,
      wellfield,
      condition,
      typeTitle,
      removeType,
      objectType,
      questionTitle
    } = removeProps;

    return (
      <>
        <div className="splash-screen" />
        <div className={classnames("remove-alert card", containerClass)}>
          <div className="main-container__top-row">
            <h2 className="main-title">
              {!["logout", "renameProject"].includes(removeType) && (
                <>Удаление</>
              )}{" "}
              {title}
            </h2>
            <div className="new-project__close" onClick={this.closeRemoveAlert}>
              <div className="new-project__close-line close" />
            </div>
          </div>
          <div className="card-main">
            <h2 className="remove-alert__question-title">
              <>Вы действительно хотите удалить</> {questionTitle}
              {questionTitle ? "?" : ""}
            </h2>
            <table className="remove-alert__content">
              <tbody>
                {name && (
                  <tr className="remove-alert__content-row">
                    <td>
                      <h3 className="remove-alert__content-title">Название</h3>
                    </td>
                    <td>
                      <h3 className="remove-alert__content-type">{name}</h3>
                    </td>
                  </tr>
                )}
                {wellfield && (
                  <tr className="remove-alert__content-row">
                    <td>
                      <h3 className="remove-alert__content-title">
                        Месторождение
                      </h3>
                    </td>
                    <td>
                      <h3 className="remove-alert__content-type">
                        {wellfield}
                      </h3>
                    </td>
                  </tr>
                )}
                {typeTitle && (
                  <tr className="remove-alert__content-row">
                    <td>
                      <h3 className="remove-alert__content-title">
                        Тип {typeTitle}
                      </h3>
                    </td>
                    <td>
                      <h3 className="remove-alert__content-type object-type">
                        {objectType}
                      </h3>
                    </td>
                  </tr>
                )}
                {condition && (
                  <tr className="remove-alert__content-row">
                    <td>
                      <h3 className="remove-alert__content-title">Состояние</h3>
                    </td>
                    <td>
                      <h3 className="remove-alert__content-type">
                        {condition}
                      </h3>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="card-bottom">
            <Button
              onClick={this.removeHandler}
              className="remove-alert__button"
            >
              Удалить
            </Button>
            <Button secondary onClick={this.closeRemoveAlert}>
              Отмена
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default RemoveConfirmationContainer;

import React from "react";
import OptionButton from "./OptionButton";

const ButtonTypes = ["objects", "linear", "areas", "tasks"];

const ButtonList = ({ optionHandler }) => {
  return (
    <>
      {ButtonTypes.map((val, id) => (
        <OptionButton key={id} type={val} onClick={optionHandler} />
      ))}
    </>
  );
};

export default ButtonList;

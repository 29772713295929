export const GET_METACLASSES_BY_WELLFIELD = "GET_METACLASSES_BY_WELLFIELD";
export const GET_WELLFIELD_INFO = "GET_WELLFIELD_INFO";
export const GET_METACLASSES_PROPS = "GET_METACLASSES_PROPS";
export const GET_CLASSES_BY_WELLFIELD = "GET_CLASSES_BY_WELLFIELD";
export const GET_CLASSES_BY_METACLASS = "GET_CLASSES_BY_METACLASS";
export const SORT_CLASSES_BY_METACLASS = "SORT_CLASSES_BY_METACLASS";
export const SORT_METACLASSES_BY_CLASSES = "SORT_METACLASSES_BY_CLASSES";
export const PUT_DEMS_BY_WELLFIELD = "PUT_DEMS_BY_WELLFIELD";
export const ADDITIONAL_TASK_PROPS = "ADDITIONAL_TASK_PROPS";
export const PUT_CONNECTION_TYPES = "PUT_CONNECTION_TYPES";
export const GET_CLASSES_PROPS = "GET_CLASSES_PROPS";
export const GET_LAYOUTS = "GET_LAYOUTS";
export const GET_FACILITIES_BY_LAYOUTS = "GET_FACILITIES_BY_LAYOUTS";
export const SET_PRIMARY_FACILITIES = "SET_PRIMARY_FACILITIES";
export const REMOVE_WELLFIELD = "REMOVE_WELLFIELD";
export const DOWNLOAD_WELLFIELD = "DOWNLOAD_WELLFIELD";
// REQUEST
export const DOWNLOAD_WELLFIELD_REQUEST = "DOWNLOAD_WELLFIELD_REQUEST";
export const REMOVE_WELLFIELD_REQUEST = "REMOVE_WELLFIELD_REQUEST";
export const GET_FACILITIES_BY_LAYOUTS_REQUEST =
  "GET_FACILITIES_BY_LAYOUTS_REQUEST";
export const GET_LAYOUTS_REQUEST = "GET_LAYOUTS_REQUEST";
export const PUT_DEMS_BY_WELLFIELD_REQUEST = "PUT_DEMS_BY_WELLFIELD_REQUEST";
export const GET_CLASSES_BY_METACLASS_REQUEST =
  "GET_CLASSES_BY_METACLASS_REQUEST";
export const GET_WELLFIELD_INFO_REQUEST = "GET_WELLFIELD_INFO_REQUEST";
// SUCCESS
export const SET_PRIMARY_FACILITIES_SUCCESS = "SET_PRIMARY_FACILITIES_SUCCESS";
export const GET_FACILITIES_BY_LAYOUTS_SUCCESS =
  "GET_FACILITIES_BY_LAYOUTS_SUCCESS";
export const GET_LAYOUTS_SUCCESS = "GET_LAYOUTS_SUCCESS";
export const GET_CLASSES_PROPS_SUCCESS = "GET_CLASSES_PROPS_SUCCESS";
export const PUT_CONNECTION_TYPES_SUCCESS = "PUT_CONNECTION_TYPES_SUCCESS";
export const ADDITIONAL_TASK_PROPS_SUCCESS = "ADDITIONAL_TASK_PROPS_SUCCESS";
export const PUT_DEMS_BY_WELLFIELD_SUCCESS = "PUT_DEMS_BY_WELLFIELD_SUCCESS";
export const SORT_METACLASSES_BY_CLASSES_SUCCESS =
  "SORT_METACLASSES_BY_CLASSES_SUCCESS";
export const GET_CLASSES_BY_METACLASS_SUCCESS =
  "GET_CLASSES_BY_METACLASS_SUCCESS";
export const GET_METACLASSES_PROPS_SUCCESS = "GET_METACLASSES_PROPS_SUCCESS";
export const GET_WELLFIELD_INFO_SUCCESS = "GET_WELLFIELD_INFO_SUCCESS";
export const GET_METACLASSES_BY_WELLFIELD_SUCCESS =
  "GET_METACLASSES_BY_WELLFIELD_SUCCESS";
export const GET_CLASSES_BY_WELLFIELD_SUCCESS =
  "GET_CLASSES_BY_WELLFIELD_SUCCESS";
export const SORT_CLASSES_BY_METACLASS_SUCCESS =
  "SORT_CLASSES_BY_METACLASS_SUCCESS";

import { connect } from "react-redux";
import { getSortedFacilities } from "state/selectors/objects";

import {
  activeTab,
  setCardInfo,
  setRemoveProps,
  openRemoveAlert
} from "state/actions/settings";

import {
  hideFacilities,
  hideFacilitiesGroup
} from "state/actions/groupsSettings";

import { classesByWellfield } from "state/selectors/wellfield";

import ObjectsListContainer from "./ObjectsListContainer";

const mapStateToProps = state => ({
  sortedFacilities: getSortedFacilities(state),
  classesByWellfield: classesByWellfield(state)
});

const mapDispathToProps = {
  activeTab,
  setCardInfo,
  hideFacilities,
  setRemoveProps,
  openRemoveAlert,
  hideFacilitiesGroup
};

export default connect(
  mapStateToProps,
  mapDispathToProps
)(ObjectsListContainer);

import React from "react";
import { connect } from "react-redux";
import { createFacility } from "state/selectors/settings";
import FacilityCreationToolsContainer from "./FacilityCreationToolsContainer";

const FacilityCreationTools = ({ createFacility }) => {
  if (!createFacility) return null;
  return <FacilityCreationToolsContainer />;
};
const mapStateToProps = state => ({
  createFacility: createFacility(state)
});

export default connect(mapStateToProps)(FacilityCreationTools);

import React from "react";
import CommonField from "../../CommonField";
import units from "./units";
import propsNames from "./propsNames";

const OilPipes = ({ defaultValues, disabled }) => {
  return (
    <>
      <div className="create-task-row">
        <CommonField
          addedProps={{
            dem: units.oil_density,
            propName: `props.oil_density`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.oil_density,
            label: propsNames.oil_density,
            disabled
          }}
        />
        <CommonField
          addedProps={{
            dem: units.gas_rel_density,
            propName: `props.gas_rel_density`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.gas_rel_density,
            label: propsNames.gas_rel_density,
            disabled
          }}
        />
      </div>
      <div className="create-task-row">
        <CommonField
          addedProps={{
            dem: units.mu_oil_20,
            propName: `props.mu_oil_20`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.mu_oil_20,
            label: propsNames.mu_oil_20,
            disabled
          }}
        />
        <CommonField
          addedProps={{
            dem: units.mu_oil_50,
            propName: `props.mu_oil_50`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.mu_oil_50,
            label: propsNames.mu_oil_50,
            disabled
          }}
        />
      </div>
      <div className="create-task-row">
        <CommonField
          addedProps={{
            dem: units.flow_temperature,
            propName: `props.flow_temperature`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.flow_temperature,
            label: propsNames.flow_temperature,
            disabled
          }}
        />
      </div>
      <div className="create-task-row">
        <CommonField
          addedProps={{
            dem: units.pressure_min,
            propName: `props.pressure_min`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.pressure_min,
            label: propsNames.pressure_min,
            disabled
          }}
        />
        <CommonField
          addedProps={{
            dem: units.pressure_max,
            propName: `props.pressure_max`,
            placeholder: "",
            type: "number",
            classContainer: "half nowrap-label",
            defaultValue: defaultValues.pressure_max,
            label: propsNames.pressure_max,
            disabled
          }}
        />
      </div>
    </>
  );
};

export default OilPipes;

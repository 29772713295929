import addOrRemoveItem from "utils/addOrRemoveItem";
import {
  HIDE_AREAS_SUCCESS,
  OPENED_LIST_SUCCESS,
  HIDE_LINEARS_SUCCESS,
  SAVE_SETTINGS_REQUEST,
  SAVE_SETTINGS_SUCCESS,
  LOCK_FACILITIES_SUCCESS,
  HIDE_FACILITIES_SUCCESS,
  HIDE_AREAS_GROUP_SUCCESS,
  SET_OPENED_TASKS_SUCCESS,
  SET_LINEARS_TOGGLE_SUCCESS,
  REMOVE_OPENED_TASKS_SUCCESS,
  OPENED_LIST_BY_TYPE_SUCCESS,
  HIDE_FACILITIES_GROUP_SUCCESS,
  HIDE_LINEARS_METACLASS_SUCCESS,
  REMOVE_HIDDEN_METACLASS_REQUEST,
  HIDE_LINEARS_BY_NETWORK_SUCCESS,
  HIDE_LINEARS_BY_METACLASS_AND_NETWORK_SUCCESS,
  HIDE_LINEARS_BY_METACLASS_AND_TYPE_SUCCESS,
  SET_HIDE_LINEARS_BY_NETWORK_SUCCESS
} from "../constants/groupsSettings";

import {
  REMOVE_METACLASS_SUCCESS,
  REMOVE_TASK_SUCCESS,
  REMOVE_NETWORK_SUCCESS,
  REMOVE_CLASS_SUCCESS,
  REMOVE_METACLASS_BY_TYPE_SUCCESS
} from "../constants/projects";

import {
  REMOVE_AREAS_SUCCESS,
  REMOVE_FACILITY_SUCCESS,
  REMOVE_LINEARS_SUCCESS
} from "../constants/objects";

const initialState = {
  hideAreas: [],
  oppenedList: [],
  hideLinears: [],
  oppenedTasks: {},
  linearsToggle: {},
  hideFacilities: [],
  lockFacilities: [],
  hideAreasGroup: [],
  oppenedListByType: {},
  hideFacilitiesGroup: [],
  hideLinearsMetaclass: [],
  hideLinearsByNetwork: [],
  hideLinearsByMetaclassAndType: {},
  hideLinearsByMetaclassAndNetwork: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HIDE_LINEARS_BY_NETWORK_SUCCESS: {
      return {
        ...state,
        hideLinearsByNetwork: action.payload
      };
    }
    case HIDE_LINEARS_BY_NETWORK_SUCCESS: {
      const hideLinearsByNetwork = addOrRemoveItem(
        state.hideLinearsByNetwork,
        action.payload
      );
      return {
        ...state,
        hideLinearsByNetwork
      };
    }
    case REMOVE_LINEARS_SUCCESS: {
      const linearId = action.payload;
      const obj = {};
      if (state.hideLinears.includes(linearId)) {
        const hideLinears = state.hideLinears.slice();
        hideLinears.splice(hideLinears.indexOf(linearId), 1);
        obj.hideLinears = hideLinears;
      }
      return {
        ...state,
        ...obj
      };
    }
    case REMOVE_AREAS_SUCCESS: {
      const areaId = action.payload;
      const obj = {};
      if (state.hideAreas.includes(areaId)) {
        const hideAreas = state.hideAreas.slice();
        hideAreas.splice(hideAreas.indexOf(areaId), 1);
        obj.hideAreas = hideAreas;
      }
      return {
        ...state,
        ...obj
      };
    }
    case REMOVE_FACILITY_SUCCESS: {
      const facilityId = action.payload;
      const obj = {};
      if (state.hideFacilities.includes(facilityId)) {
        const hideFacilities = state.hideFacilities.slice();
        hideFacilities.splice(hideFacilities.indexOf(facilityId), 1);
        obj.hideFacilities = hideFacilities;
      }
      if (state.lockFacilities.includes(facilityId)) {
        const lockFacilities = state.lockFacilities.slice();
        lockFacilities.splice(lockFacilities.indexOf(facilityId), 1);
        obj.lockFacilities = lockFacilities;
      }
      return {
        ...state,
        ...obj
      };
    }
    case REMOVE_HIDDEN_METACLASS_REQUEST: {
      const { type, id } = action.payload;
      if (type === "createFacility") {
        const hideFacilitiesGroup = state.hideFacilitiesGroup.slice();
        if (hideFacilitiesGroup.includes(id)) {
          hideFacilitiesGroup.splice(hideFacilitiesGroup.indexOf(id), 1);
        }
        return {
          ...state,
          hideFacilitiesGroup
        };
      }
      if (type === "createArea") {
        const hideAreasGroup = state.hideAreasGroup.slice();
        if (hideAreasGroup.includes(id)) {
          hideAreasGroup.splice(hideAreasGroup.indexOf(id), 1);
        }
        return {
          ...state,
          hideAreasGroup
        };
      }
      if (type === "createLinearObject") {
        const hideLinearsMetaclass = state.hideLinearsMetaclass.slice();
        const hideLinearsByMetaclassAndType = {
          ...state.hideLinearsByMetaclassAndType
        };
        const hideLinearsByMetaclassAndNetwork = {
          ...state.hideLinearsByMetaclassAndNetwork
        };
        if (hideLinearsMetaclass.includes(id)) {
          hideLinearsMetaclass.splice(hideLinearsMetaclass.indexOf(id), 1);
        }
        delete hideLinearsByMetaclassAndType[id];
        delete hideLinearsByMetaclassAndNetwork[id];
        return {
          ...state,
          hideLinearsMetaclass,
          hideLinearsByMetaclassAndType,
          hideLinearsByMetaclassAndNetwork
        };
      }
      return state;
    }
    case REMOVE_OPENED_TASKS_SUCCESS: {
      const oppenedTasks = { ...state.oppenedTasks };
      const { taskId } = action.payload;
      delete oppenedTasks[taskId];
      return {
        ...state,
        oppenedTasks
      };
    }
    case SET_LINEARS_TOGGLE_SUCCESS: {
      const linearsToggle = { ...state.linearsToggle };
      const { id, currentHandler } = action.payload;
      linearsToggle[id] = {
        currentHandler
      };
      return {
        ...state,
        linearsToggle
      };
    }
    case SET_OPENED_TASKS_SUCCESS: {
      const oppenedTasks = { ...state.oppenedTasks };
      const { taskId, currentHandler } = action.payload;
      oppenedTasks[taskId] = {
        currentHandler
      };
      return {
        ...state,
        oppenedTasks
      };
    }
    case HIDE_LINEARS_BY_METACLASS_AND_TYPE_SUCCESS: {
      let hideLinearsByMetaclassAndType = {
        ...state.hideLinearsByMetaclassAndType
      };
      if (hideLinearsByMetaclassAndType[action.payload.metaclass]) {
        const key = action.payload.metaclass;
        const types = addOrRemoveItem(
          hideLinearsByMetaclassAndType[key].types,
          action.payload.types
        );
        if (!types.length) {
          delete hideLinearsByMetaclassAndType[key];
        } else {
          hideLinearsByMetaclassAndType[key] = { types };
        }
      } else {
        hideLinearsByMetaclassAndType = {
          ...hideLinearsByMetaclassAndType,
          [action.payload.metaclass]: {
            types: [action.payload.types]
          }
        };
      }
      return {
        ...state,
        hideLinearsByMetaclassAndType
      };
    }
    case HIDE_LINEARS_BY_METACLASS_AND_NETWORK_SUCCESS: {
      let hideLinearsByMetaclassAndNetwork = {
        ...state.hideLinearsByMetaclassAndNetwork
      };
      if (hideLinearsByMetaclassAndNetwork[action.payload.metaclass]) {
        const key = action.payload.metaclass;
        let types = addOrRemoveItem(
          hideLinearsByMetaclassAndNetwork[key].types,
          action.payload.types
        );
        const networks = addOrRemoveItem(
          hideLinearsByMetaclassAndNetwork[key].networks,
          action.payload.networks
        );
        if (!networks.length) {
          delete hideLinearsByMetaclassAndNetwork[key];
        } else {
          if (!types.length) {
            types = hideLinearsByMetaclassAndNetwork[key].types;
          }
          hideLinearsByMetaclassAndNetwork[key] = { types, networks };
        }
      } else {
        hideLinearsByMetaclassAndNetwork = {
          ...hideLinearsByMetaclassAndNetwork,
          [action.payload.metaclass]: {
            networks: [action.payload.networks],
            types: [action.payload.types]
          }
        };
      }
      return {
        ...state,
        hideLinearsByMetaclassAndNetwork
      };
    }
    case HIDE_FACILITIES_GROUP_SUCCESS:
      return {
        ...state,
        hideFacilitiesGroup: addOrRemoveItem(
          state.hideFacilitiesGroup,
          action.payload
        )
      };
    case HIDE_AREAS_GROUP_SUCCESS:
      return {
        ...state,
        hideAreasGroup: addOrRemoveItem(state.hideAreasGroup, action.payload)
      };
    case HIDE_LINEARS_METACLASS_SUCCESS:
      return {
        ...state,
        hideLinearsMetaclass: addOrRemoveItem(
          state.hideLinearsMetaclass,
          action.payload
        )
      };
    case LOCK_FACILITIES_SUCCESS:
      return {
        ...state,
        lockFacilities: addOrRemoveItem(state.lockFacilities, action.payload)
      };
    case HIDE_AREAS_SUCCESS:
      return {
        ...state,
        hideAreas: addOrRemoveItem(state.hideAreas, action.payload)
      };
    case HIDE_LINEARS_SUCCESS:
      return {
        ...state,
        hideLinears: addOrRemoveItem(state.hideLinears, action.payload)
      };
    case HIDE_FACILITIES_SUCCESS:
      return {
        ...state,
        hideFacilities: addOrRemoveItem(state.hideFacilities, action.payload)
      };
    case REMOVE_METACLASS_BY_TYPE_SUCCESS: {
      const { id, condition } = action.payload;
      const obj = {};
      if (state.oppenedListByType[id]) {
        if (state.oppenedListByType[id].includes(condition)) {
          const oppenedListByType = { ...state.oppenedListByType };
          oppenedListByType[id].splice(
            oppenedListByType[id].indexOf(condition),
            1
          );
          if (!oppenedListByType[id].length) {
            delete oppenedListByType[id];
          }
          obj.oppenedListByType = oppenedListByType;
        }
      }
      if (state.hideLinearsByMetaclassAndType[id]) {
        if (state.hideLinearsByMetaclassAndType[id].types.includes(condition)) {
          const hideLinearsByMetaclassAndType = {
            ...state.hideLinearsByMetaclassAndType
          };
          hideLinearsByMetaclassAndType[id].types.splice(
            hideLinearsByMetaclassAndType[id].types.indexOf(condition),
            1
          );
          if (!hideLinearsByMetaclassAndType[id].types.length) {
            delete hideLinearsByMetaclassAndType[id];
          }
          obj.hideLinearsByMetaclassAndType = hideLinearsByMetaclassAndType;
        }
      }
      return {
        ...state,
        ...obj
      };
    }
    case REMOVE_NETWORK_SUCCESS: {
      const id = action.payload;
      const oppenedListByType = { ...state.oppenedListByType };
      const hideLinearsByMetaclassAndNetwork = {
        ...state.hideLinearsByMetaclassAndNetwork
      };
      const obj = {};
      if (Object.keys(oppenedListByType).length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in oppenedListByType) {
          if (oppenedListByType[key] && oppenedListByType[key].includes(id)) {
            oppenedListByType[key].splice(
              oppenedListByType[key].indexOf(id),
              1
            );
            if (!oppenedListByType[key].length) {
              delete oppenedListByType[key];
            }
            obj.oppenedListByType = oppenedListByType;
          }
        }
      }
      if (Object.keys(hideLinearsByMetaclassAndNetwork).length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in hideLinearsByMetaclassAndNetwork) {
          if (
            hideLinearsByMetaclassAndNetwork[key] &&
            hideLinearsByMetaclassAndNetwork[key].networks.includes(id)
          ) {
            hideLinearsByMetaclassAndNetwork[key].networks.splice(
              hideLinearsByMetaclassAndNetwork[key].networks.indexOf(id),
              1
            );
            if (!hideLinearsByMetaclassAndNetwork[key].networks.length) {
              delete hideLinearsByMetaclassAndNetwork[key];
            }
            obj.hideLinearsByMetaclassAndNetwork = hideLinearsByMetaclassAndNetwork;
          }
        }
      }
      return {
        ...state,
        ...obj
      };
    }
    case OPENED_LIST_BY_TYPE_SUCCESS: {
      const { itemKey, lastItemKey } = action.payload;
      const oppenedListByType = { ...state.oppenedListByType };
      if (oppenedListByType[lastItemKey]) {
        oppenedListByType[lastItemKey] = addOrRemoveItem(
          oppenedListByType[lastItemKey],
          itemKey
        );
        if (!oppenedListByType[lastItemKey].length) {
          delete oppenedListByType[lastItemKey];
        }
      } else {
        oppenedListByType[lastItemKey] = [itemKey];
      }
      return {
        ...state,
        oppenedListByType
      };
    }
    case REMOVE_METACLASS_SUCCESS: {
      const { metaClassId, linearsIds, areasIds } = action.payload;
      const obj = {};
      const hideLinears = state.hideLinears.slice();
      const hideAreas = state.hideAreas.slice();
      const hideLinearsByMetaclassAndNetwork = {
        ...state.hideLinearsByMetaclassAndNetwork
      };
      const hideLinearsByMetaclassAndType = {
        ...state.hideLinearsByMetaclassAndType
      };
      if (state.oppenedList.includes(metaClassId)) {
        const oppenedList = state.oppenedList.slice();
        oppenedList.splice(oppenedList.indexOf(metaClassId), 1);
        obj.oppenedList = oppenedList;
      }
      if (state.oppenedListByType[metaClassId]) {
        const oppenedListByType = { ...state.oppenedListByType };
        delete oppenedListByType[metaClassId];
        obj.oppenedListByType = oppenedListByType;
      }
      if (hideAreas.length && areasIds) {
        // eslint-disable-next-line no-restricted-syntax
        for (const areaId of areasIds) {
          if (hideAreas.includes(areaId)) {
            hideAreas.splice(hideAreas.indexOf(areaId), 1);
            obj.hideAreas = hideAreas;
          }
        }
      }
      if (hideLinears.length && linearsIds) {
        // eslint-disable-next-line no-restricted-syntax
        for (const linearId of linearsIds) {
          if (hideLinears.includes(linearId)) {
            hideLinears.splice(hideLinears.indexOf(linearId), 1);
            obj.hideLinears = hideLinears;
          }
        }
      }
      if (state.hideAreasGroup.includes(metaClassId)) {
        const hideAreasGroup = state.hideAreasGroup.slice();
        hideAreasGroup.splice(hideAreasGroup.indexOf(metaClassId), 1);
        obj.hideAreasGroup = hideAreasGroup;
      }
      if (state.hideLinearsMetaclass.includes(metaClassId)) {
        const hideLinearsMetaclass = state.hideLinearsMetaclass.slice();
        hideLinearsMetaclass.splice(
          hideLinearsMetaclass.indexOf(metaClassId),
          1
        );
        obj.hideLinearsMetaclass = hideLinearsMetaclass;
      }
      if (hideLinearsByMetaclassAndNetwork[metaClassId]) {
        delete hideLinearsByMetaclassAndNetwork[metaClassId];
        obj.hideLinearsByMetaclassAndNetwork = hideLinearsByMetaclassAndNetwork;
      }
      if (hideLinearsByMetaclassAndType[metaClassId]) {
        delete hideLinearsByMetaclassAndType[metaClassId];
        obj.hideLinearsByMetaclassAndType = hideLinearsByMetaclassAndType;
      }
      return {
        ...state,
        ...obj
      };
    }
    case REMOVE_TASK_SUCCESS: {
      const { condition = "facility", tasksByType } = action.payload;
      const oppenedList = state.oppenedList.slice();
      const obj = {};
      if (oppenedList.includes(condition)) {
        if (!tasksByType[condition] || !(tasksByType[condition].length - 1)) {
          oppenedList.splice(oppenedList.indexOf(condition), 1);
          obj.oppenedList = oppenedList;
        }
      }
      return {
        ...state,
        ...obj
      };
    }
    case OPENED_LIST_SUCCESS: {
      return {
        ...state,
        oppenedList: addOrRemoveItem(state.oppenedList, action.payload)
      };
    }
    case REMOVE_CLASS_SUCCESS: {
      const { classId } = action.payload;
      const obj = {};
      if (state.hideFacilitiesGroup.includes(classId)) {
        const hideFacilitiesGroup = state.hideFacilitiesGroup.slice();
        hideFacilitiesGroup.splice(hideFacilitiesGroup.indexOf(classId), 1);
        obj.hideFacilitiesGroup = hideFacilitiesGroup;
      }
      if (state.oppenedList.includes(classId)) {
        const oppenedList = state.oppenedList.slice();
        oppenedList.splice(oppenedList.indexOf(classId), 1);
        obj.oppenedList = oppenedList;
      }
      return {
        ...state,
        ...obj
      };
    }
    case SAVE_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SAVE_SETTINGS_REQUEST: {
      const curentSettings = JSON.parse(localStorage.getItem("settings"));
      localStorage.setItem(
        "settings",
        JSON.stringify({
          ...curentSettings,
          [action.payload]: {
            hideAreas: state.hideAreas,
            oppenedList: state.oppenedList,
            hideLinears: state.hideLinears,
            oppenedTasks: state.oppenedTasks,
            linearsToggle: state.linearsToggle,
            hideFacilities: state.hideFacilities,
            lockFacilities: state.lockFacilities,
            hideAreasGroup: state.hideAreasGroup,
            oppenedListByType: state.oppenedListByType,
            hideFacilitiesGroup: state.hideFacilitiesGroup,
            hideLinearsMetaclass: state.hideLinearsMetaclass,
            hideLinearsByMetaclassAndType: state.hideLinearsByMetaclassAndType,
            hideLinearsByMetaclassAndNetwork:
              state.hideLinearsByMetaclassAndNetwork
          }
        })
      );
      return state;
    }
    default:
      return state;
  }
}

import React from "react";

export default ({ remove, openTask, subLayerKey }) => {
  const openTaskHandler = () => openTask(subLayerKey);
  return (
    <span className="common-component-handlers">
      {!!(
        typeof openTask === "function" &&
        subLayerKey &&
        subLayerKey.taskId
      ) && (
        <button
          data-tip="Перейти к задаче"
          data-for="mainTooltip"
          onClick={openTaskHandler}
          className="icon networkInfo"
        />
      )}
      <button onClick={remove} className="field-row__remove remove-metaclass" />
    </span>
  );
};

/* eslint-disable eqeqeq */
/* eslint-disable import/no-cycle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-classes-per-file */
import React from "react";
import classnames from "classnames";
import TableContext from "./TableContext";
import ActiveResultComp from "./ActiveResultComponent";
import "./style.scss";

export const ResultTableInnerCell = props => {
  const { innerCellWidth, setInnerCellWidth } = React.useContext(TableContext);
  return (
    <ResultTableInnerCellContainer
      index={props.index}
      innerCellWidth={innerCellWidth}
      setInnerCellWidth={setInnerCellWidth}
    >
      {props.children}
    </ResultTableInnerCellContainer>
  );
};

export const ResultTableCell = props => {
  const { setCellWidth, cellWidth } = React.useContext(TableContext);
  return (
    <ResultTableCellContainer
      setCellWidth={setCellWidth}
      cellWidth={cellWidth}
      index={props.index}
    >
      {React.Children.map(props.children, arg => {
        const propsObj = {};
        if (!arg) return null;
        if (arg.type === ResultTableInnerCell) {
          propsObj.index = props.index;
        }
        return React.cloneElement(arg, propsObj);
      })}
    </ResultTableCellContainer>
  );
};

export const ResultTableRow = ({ className, ...props }) => {
  return (
    <div className={classnames("task-result__row", className)} {...props}>
      {React.Children.map(props.children, (arg, index) => {
        const props = {};
        if (arg.type == ResultTableCell) {
          props.index = index;
        }
        return React.cloneElement(arg, props);
      })}
    </div>
  );
};
class ResultTableInnerCellContainer extends React.Component {
  ref = React.createRef();

  componentDidMount() {
    const { setInnerCellWidth, index } = this.props;
    const { current } = this.ref;
    const width = current.clientWidth;
    setInnerCellWidth({ index, width });
  }

  render() {
    const { index, innerCellWidth } = this.props;
    return (
      <div
        style={{
          minWidth: innerCellWidth[index] || 0
        }}
        ref={this.ref}
        className="result-table-inner-cell"
      >
        {this.props.children}
      </div>
    );
  }
}

class ResultTableCellContainer extends React.Component {
  ref = React.createRef();

  componentDidMount() {
    const { setCellWidth, index } = this.props;
    const { current } = this.ref;
    const width = current.clientWidth;
    setCellWidth({ index, width });
  }

  render() {
    const { cellWidth, index } = this.props;
    return (
      <div
        style={{
          minWidth: cellWidth[index] || 0
        }}
        ref={this.ref}
        className="result-table-cell"
      >
        {this.props.children}
      </div>
    );
  }
}

export class ResultTable extends React.Component {
  state = { cellWidth: {}, innerCellWidth: {} };

  setCellWidth = obj => this.handleCellWidth({ ...obj, type: "cellWidth" });

  setInnerCellWidth = obj =>
    this.handleCellWidth({ ...obj, type: "innerCellWidth" });

  handleCellWidth = ({ index, width, type }) => {
    this.setState(state => {
      if (!state[type][index] || state[type][index] < width) {
        return {
          ...state,
          [type]: {
            ...state[type],
            [index]: width
          }
        };
      }
      return state;
    });
  };

  render() {
    return (
      <div className="result-table">
        <TableContext.Provider
          value={{
            cellWidth: this.state.cellWidth,
            setCellWidth: this.setCellWidth,
            innerCellWidth: this.state.innerCellWidth,
            setInnerCellWidth: this.setInnerCellWidth
          }}
        >
          {this.props.children}
        </TableContext.Provider>
      </div>
    );
  }
}

export const ActiveResultComponent = ActiveResultComp;

import React from "react";
import { connect, useDispatch } from "react-redux";
import coloringObj from "utils/coloringObj";
import { taskResult, taskId, taskLogs } from "state/selectors/projects";
import Button from "components/Common/Button";
import { sendNetwork } from "state/actions/projects";
import { setHideLinearsByNetwork } from "state/actions/groupsSettings";
import TaskRowTitle from "../ResultTable/TaskRowTitle";
import HideNetwork from "../ResultTable/HideNetwork";
import TaskRowSubTitle from "../ResultTable/TaskRowSubTitle";
import {
  ResultTable,
  ResultTableCell,
  ResultTableRow,
  ActiveResultComponent
} from "../ResultTable";
import ResultProcess from "../ResultProcess";

const Component = ({ el, index, onClickHandler }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    if (typeof onClickHandler === "function") {
      onClickHandler(el, index);
    }
  };
  const acceptResult = () => {
    dispatch(
      sendNetwork("REQUEST", {
        index: index + 1,
        id: el.id,
        name: el.task_name
      })
    );
  };
  const totalLength = Intl.NumberFormat("ru-RU").format(
    el.props.total_length.toFixed(2) / 1000
  );
  const totalCost = Intl.NumberFormat("ru-RU").format(
    el.props.total_cost.toFixed(2) / 1000
  );
  return (
    <div className="result-table-row-container">
      <HideNetwork network_id={el.props.network_id} />
      <Button onClick={acceptResult} className="task-result-button">
        Перенести в проект
      </Button>
      <ResultTableRow onClick={onClick}>
        <ResultTableCell>
          <span
            className="task-results__color-line"
            style={{
              backgroundColor: coloringObj(`tmpColor${index}`).color
            }}
          />
          <TaskRowTitle
            title={`Вариант №${index + 1}`}
            className="with-hide-network"
          />
        </ResultTableCell>
        <ResultTableCell>
          <div className="table-cell">
            <TaskRowSubTitle
              subTitle="Общая протяженность, км:"
              value={totalLength}
            />
          </div>
        </ResultTableCell>
        <ResultTableCell>
          <div className="table-cell">
            <TaskRowSubTitle
              subTitle="Общая стоимость, тыс. руб.:"
              value={totalCost}
            />
          </div>
        </ResultTableCell>
      </ResultTableRow>
    </div>
  );
};

const Container = ({ taskResult, onClickHandler }) => {
  return (
    <ResultTable>
      {taskResult &&
        taskResult.map((el, index) => {
          return (
            <Component
              el={el}
              key={el.id}
              index={index}
              onClickHandler={onClickHandler}
            />
          );
        })}
    </ResultTable>
  );
};

const ResultContainer = ({
  activeResult,
  removeResult,
  onClickHandler,
  taskResult
}) => {
  return (
    <div className="task-result__container">
      {activeResult && (
        <ActiveResultComponent
          goBack={removeResult}
          activeResult={activeResult}
        />
      )}
      {taskResult && !activeResult && (
        <Container onClickHandler={onClickHandler} taskResult={taskResult} />
      )}
    </div>
  );
};

const RoadsResult = props => {
  const [activeResult, setActiveResult] = React.useState();
  const { taskResult, taskId, taskLogs } = props;
  const removeResult = () => {
    setActiveResult("");
    if (typeof props.handleActiveResult === "function") {
      props.handleActiveResult("");
    }
    props.setHideLinearsByNetwork("SUCCESS", []);
  };
  const onClickHandler = (el, index) => {
    setActiveResult({ ...el, index });
    const hiddenNetworks = taskResult
      .filter(item => item.props.network_id !== el.props.network_id)
      .map(({ props }) => props.network_id);
    if (typeof props.handleActiveResult === "function") {
      props.handleActiveResult({ ...el, index });
    }
    props.setHideLinearsByNetwork("SUCCESS", hiddenNetworks);
  };
  if (!taskResult || !taskResult.length) {
    if (!taskLogs[taskId]) return null;
    return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
  }
  return (
    <ResultContainer
      taskResult={taskResult}
      activeResult={activeResult}
      removeResult={removeResult}
      onClickHandler={onClickHandler}
    />
  );
};

const mapStateToProps = state => ({
  taskResult: taskResult(state),
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

const mapDispatchToProps = { setHideLinearsByNetwork };

export default connect(mapStateToProps, mapDispatchToProps)(RoadsResult);

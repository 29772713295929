import React, { Component } from "react";
import ShowRow from "./ShowRow";

class TitleBefore extends Component {
  showList = (item, subLayerKey, subLayerParent) => {
    const { showList } = this.props;
    showList(item, subLayerKey, subLayerParent);
  };

  render() {
    const { type, subLayerParent, bgColor, subLayerKey, itemKey } = this.props;
    return (
      <>
        <ShowRow
          type={type}
          item={itemKey}
          subLayerKey={subLayerKey}
          subLayerParent={subLayerParent}
          showRow={item => this.showList(item, subLayerKey, subLayerParent)}
        />
        <span className="colorLine">
          <span
            className="colorLineColor"
            style={{ backgroundColor: bgColor }}
          />
        </span>
      </>
    );
  }
}

export default TitleBefore;

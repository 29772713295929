import React from "react";
import { ReactComponent as GatheringCenters } from "assets/images/oil_gathering_line.svg";
import { ReactComponent as LinearCosts } from "assets/images/linear_cost.svg";
import { ReactComponent as Roads } from "assets/images/task_calculate_road_network.svg";
import { ReactComponent as Facility } from "assets/images/task_calculate_po.svg";
import { ReactComponent as Quarries } from "assets/images/task_calculate_quarrying.svg";
import { ReactComponent as Pipes } from "assets/images/task_calculate_pipeline_network.svg";
import { ReactComponent as Hvl } from "assets/images/task_calculate_vl.svg";

export default ({ icon }) => {
  switch (icon) {
    case "gathering_centers":
      return <GatheringCenters />;
    case "linear_cost":
      return <LinearCosts />;
    case "roads":
      return <Roads />;
    case "facility":
      return <Facility />;
    case "quarries":
      return <Quarries />;
    case "pipes":
      return <Pipes />;
    case "hvl":
      return <Hvl />;
    default:
      return null;
  }
};

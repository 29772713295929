export default [
  { coef: "corridor_coeff_ad", label: "АД" },
  { coef: "corridor_coeff_vl", label: "ВЛ" },
  { coef: "corridor_coeff_nsk", label: "НСК" },
  { coef: "corridor_coeff_nnp", label: "ННП" },
  { coef: "corridor_coeff_vnd", label: "ВНД" },
  { coef: "corridor_coeff_vvd", label: "ВВД" },
  { coef: "corridor_coeff_gnd", label: "ГНД" },
  { coef: "corridor_coeff_gvd", label: "ГВД" }
];

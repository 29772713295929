import React from "react";
import PropTypes from "prop-types";
import LoginView from "./LoginView";

class LoginContainer extends React.Component {
  state = {
    username: "",
    password: ""
  };

  componentDidMount() {
    window.document.addEventListener("keyup", this.handleEnterClick);
  }

  componentDidUpdate() {
    const { auth, history } = this.props;
    if (auth) {
      history.push("/projects");
    }
  }

  componentWillUnmount() {
    window.document.removeEventListener("keyup", this.handleEnterClick);
  }

  handleEnterClick = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      this.loginHandler();
    }
  };

  loginHandler = () => {
    const { setAuth, login } = this.props;
    const { username, password } = this.state;
    const data = { username, password };
    setAuth("SUCCESS", false);
    login("REQUEST", data);
  };

  changeHandler = (value, type) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: value
    }));
  };

  render() {
    const { username, password } = this.state;
    return (
      <LoginView
        username={username}
        password={password}
        loginHandler={this.loginHandler}
        changeHandler={this.changeHandler}
      />
    );
  }
}

LoginContainer.propTypes = {
  auth: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired
};

export default LoginContainer;

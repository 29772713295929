/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NewWellfieldView from "./NewWellfieldView";

class NewWellfieldContainer extends Component {
  state = { wellfieldName: "", files: [], disabled: false, complete: true };

  onDrop = acceptedFiles => {
    this.props.setNewWellfieldTaskId("SUCCESS", "");
    this.props.removeNewWellfieldLogs("SUCCESS");
    this.props.clearCancelingFlags("SUCCESS");
    const formData = new FormData();
    if (this.state.wellfieldName) {
      formData.append("name", this.state.wellfieldName);
    }
    if (acceptedFiles.length) {
      formData.append("files", acceptedFiles[0]);
      this.props.sendWellfield("REQUEST", formData);
    }
    this.setState({
      disabled: true,
      fileName: acceptedFiles[0].name,
      fileSize: (acceptedFiles[0].size / (1024 * 1024)).toFixed(1),
      files: acceptedFiles,
      complete: false
    });
  };

  onChangeHandler = ({ value }) => {
    this.setState({
      wellfieldName: value
    });
  };

  confirmCancelation = param => {
    if (param === "yes") this.abortCheckingOrUpload();
  };

  closeHandler = () => {
    this.cancelHandler();
  };

  abortCheckingOrUpload = () => {
    if (!this.state.complete) {
      if (this.props.newWellfieldLogs.length) {
        this.abortCheckingHandler();
      } else {
        this.abortUploadHandler();
      }
    }
    this.props.history.push("/wellfields");
    this.props.removeNewWellfieldLogs("SUCCESS");
    this.props.getWellfields();
  };

  cancelHandler = () => {
    if (!this.state.complete) {
      // this.props.showCancelConfirmationModal('SUCCESS',{flag: true, fn: this.modalOption});
      this.props.showModal("SUCCESS", {
        type: "CancelConfirmationModal",
        option: this.confirmCancelation
      });
    } else {
      this.props.history.push("/wellfields");
      this.props.removeNewWellfieldLogs("SUCCESS");
      this.props.getWellfields();
    }
  };

  abortUploadHandler = () => {
    this.props.cancelWellfieldSending("REQUEST");
    this.setState({
      complete: true
    });
  };

  abortCheckingHandler = () => {
    const { newWellfieldTaskId } = this.props;
    this.props.cancelWellfieldChecking("REQUEST", newWellfieldTaskId);
    this.setState({
      complete: true
    });
  };

  createHandler = () => {
    this.props.getWellfields();
    this.props.history.push("/wellfields");
    this.props.removeNewWellfieldLogs("SUCCESS");
  };

  helpHandler = () => {
    this.props.showModal("SUCCESS", {
      type: "NewWellfieldHelpModal",
      option: null
    });
  };

  componentWillReceiveProps(nextProps) {
    const { newWellfieldLogs } = nextProps;
    if (
      newWellfieldLogs.length &&
      newWellfieldLogs[newWellfieldLogs.length - 1].finished
    ) {
      this.setState({
        complete: true
      });
      this.props.updateUploadProgress("SUCCESS", 0);
      const { wellfieldName } = this.state;
      if (newWellfieldLogs[newWellfieldLogs.length - 1].has_errors) {
        this.props.setError(
          "SUCCESS",
          `Месторождение «${wellfieldName}»‎ не создано`
        );
      } else {
        this.props.setSuccessLogs(
          "SUCCESS",
          `Месторождение «${wellfieldName}»‎ создано`
        );
        this.setState({
          disabled: false
        });
      }
    }
  }

  componentWillMount() {
    this.props.clearCancelingFlags("SUCCESS");
  }

  render() {
    const { disabled, fileName, wellfieldName, fileSize } = this.state;
    return (
      <NewWellfieldView
        disabled={disabled}
        fileName={fileName}
        fileSize={fileSize}
        onDrop={this.onDrop}
        wellfieldName={wellfieldName}
        closeHandler={this.closeHandler}
        createHandler={this.createHandler}
        cancelHandler={this.cancelHandler}
        helpHandler={this.helpHandler}
        onChangeHandler={this.onChangeHandler}
        onCancelUpload={this.abortUploadHandler}
        onCancelChecking={this.abortCheckingHandler}
        progress={this.props.uploadProgress}
        canceled={this.props.checkingCanceled || this.props.uploadingCanceled}
      />
    );
  }
}

export default withRouter(NewWellfieldContainer);

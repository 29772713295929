export const projects = ({ common: { projects } }) => projects;
export const wellfields = ({ common: { wellfields } }) => wellfields;
export const classes = ({ common: { classes } }) => classes;
export const info = ({ common: { logs } }) => logs.info;
export const errors = ({ common: { logs } }) => logs.errors;
export const success = ({ common: { logs } }) => logs.success;
export const lastProjects = ({ common: { lastProjects } }) => lastProjects;
export const newWellfieldLogs = ({ common: { newWellfieldLogs } }) =>
  newWellfieldLogs;
export const lastWellfields = ({ common: { lastWellfields } }) =>
  lastWellfields;
export const newWellfieldTaskId = ({ common: { newWellfieldTaskId } }) =>
  newWellfieldTaskId;
export const uploadProgress = ({ common: { uploadProgress } }) =>
  uploadProgress;
export const checkingCanceled = ({ common: { checkingCanceled } }) =>
  checkingCanceled;
export const uploadingCanceled = ({ common: { uploadingCanceled } }) =>
  uploadingCanceled;

import React from "react";
import FacilityTaskResultView from "./FacilityTaskResultView";

export default ({ taskResult, setNewPoPosition }) => {
  const setNewPoPositionHandler = () => {
    if (taskResult) {
      const { id, props } = taskResult[0];
      const {
        optimized_facility_id: optimizedFacilityId,
        facility_id: oldFacilityId
      } = props;
      setNewPoPosition("REQUEST", {
        id,
        oldFacilityId,
        facility_id: optimizedFacilityId
      });
    }
  };
  return (
    <FacilityTaskResultView
      taskResult={taskResult}
      setNewPoPosition={setNewPoPositionHandler}
    />
  );
};

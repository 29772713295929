import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { getOppenedListByType } from "state/selectors/groupsSettings";
import { setOpenedListByType } from "state/actions/groupsSettings";
import ToggleTitle from "./ToggleTitle";
import ToggleList from "./ToggleList";

const ToggleItemView = ({
  item,
  onMouseEnterHandler,
  onMouseLeaveHandler,
  listProps
}) => {
  const {
    active,
    containerClass,
    beforeComp,
    beforeProp,
    chooseRow,
    afterComp
  } = listProps;
  const onMouseEnter = () => onMouseEnterHandler(item);
  const onMouseLeave = () => onMouseLeaveHandler(item);
  const chooseRowHandler = () => chooseRow(item);
  return (
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classnames(
        "row-toggle",
        { active: active(item) },
        containerClass
      )}
    >
      {beforeComp && beforeComp(item, beforeProp)}
      <button className="row-toggle__text" onClick={chooseRowHandler}>
        {item.name}
      </button>
      {afterComp && afterComp(item)}
    </li>
  );
};

class SubLayer extends Component {
  state = { showList: false };

  componentDidMount() {
    const { titleProps, getOppenedListByType } = this.props;
    const { itemKey, lastItemKey } = titleProps;
    const item = getOppenedListByType[lastItemKey];
    if (item) {
      if (item.includes(itemKey)) {
        this.setState({
          showList: true
        });
      }
    }
  }

  showListHandler = () => {
    const { titleProps, setOpenedListByType, updated } = this.props;
    const { itemKey, lastItemKey } = titleProps;
    const { showList } = this.state;
    this.setState({ showList: !showList }, () => {
      setOpenedListByType("SUCCESS", { itemKey, lastItemKey });
      if (typeof updated === "function") {
        updated();
      }
    });
  };

  onMouseEnterHandler = item => {
    const { listProps } = this.props;
    if (typeof listProps.onMouseEnter === "function") {
      return listProps.onMouseEnter(item);
    }
  };

  onMouseLeaveHandler = item => {
    const { listProps } = this.props;
    if (typeof listProps.onMouseLeave === "function") {
      return listProps.onMouseLeave(item);
    }
  };

  render() {
    const { showList } = this.state;
    const { titleProps, listProps } = this.props;
    return (
      <div className="open-projects-main-row toggle-sublayer">
        <ToggleTitle
          {...titleProps}
          showList={showList}
          showListHandler={this.showListHandler}
        />
        {showList && (
          <ul>
            <ToggleList {...listProps} />
            {listProps.withoutGroup &&
              listProps.withoutGroup.map((item, key) => {
                return (
                  <ToggleItemView
                    key={key}
                    item={item}
                    listProps={listProps}
                    onMouseEnterHandler={this.onMouseEnterHandler}
                    onMouseLeaveHandler={this.onMouseLeaveHandler}
                  />
                );
              })}
          </ul>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getOppenedListByType: getOppenedListByType(state)
});

const mapDispatchToProps = { setOpenedListByType };

export default connect(mapStateToProps, mapDispatchToProps)(SubLayer);

import React, { Component } from "react";

import classnames from "classnames";

import LeftSidebar from "components/LeftSidebar";
import setOptionHandler from "./setOptionHandler";
import handleIcon from "./handleIcon";

const setTitleHandler = props => {
  const { type } = props;
  const { taskInfo, taskLogs } = props;
  if (["createFacility", "createLinearObject"].includes(type)) {
    return "Создание объекта";
  }
  if (type === "createArea") {
    return "Создание области";
  }
  if (type === "taskInfo") {
    if (taskLogs && taskInfo) {
      const data = taskLogs[taskInfo.id];
      if (data && data[0]) {
        const { finished, has_errors: error } = data[0];
        const iconType = handleIcon(finished, error);
        const taskIcon = (
          <span className={classnames("icon main-title__icon", [iconType])} />
        );
        return (
          <span>
            {taskInfo?.name} {taskIcon}
          </span>
        );
      }
    }
    return "";
  }
  return "Создание задачи";
};

class MainContainerTopContainer extends Component {
  state = {};

  componentDidMount() {
    this.setTitle(this.props);
  }

  static getDerivedStateFromProps(nextProps) {
    const title = setTitleHandler(nextProps);
    return { title };
  }

  optionHandler = type => {
    const { activeTab } = this.props;
    activeTab("SUCCESS", type);
  };

  cancelHandler = () => {
    const { type, removeClickType, changeTaskSettings } = this.props;
    const option = setOptionHandler(type);
    removeClickType("SUCCESS");
    changeTaskSettings("SUCCESS", {});
    this.optionHandler(option);
  };

  setTitle = props => {
    const title = setTitleHandler(props);
    this.setState({ title });
  };

  render() {
    const { title } = this.state;
    return (
      <LeftSidebar.TopRow>
        <h2 className="main-title">{title}</h2>
        <button onClick={this.cancelHandler} className="new-project__close">
          <div className="new-project__close-line close" />
        </button>
      </LeftSidebar.TopRow>
    );
  }
}

export default MainContainerTopContainer;

import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import { connect } from "react-redux";
import { taskResult, taskLogs, taskId } from "state/selectors/projects";
import config from "./config";
import ResultProcess from "../ResultProcess";

const QuarriesResult = ({ taskResult, taskId, taskLogs }) => {
  if (!taskResult || !taskResult.length) {
    if (!taskLogs[taskId]) return null;
    return <ResultProcess taskId={taskId} taskLogs={taskLogs} />;
  }
  if (!taskResult[0].props) return null;
  return (
    <ScrollBar className="quarries-results">
      <div>
        {config.map((elem, id) => {
          return (
            <div key={id} className="quarries-results__col">
              <div className="quarries-results__sub-col">
                {elem.map((obj, key) => {
                  return (
                    <h2 className="quarries-results__title" key={key}>
                      {obj.title}
                    </h2>
                  );
                })}
              </div>
              <div className="quarries-results__sub-col">
                {elem.map((obj, key) => {
                  let value = "";
                  if (typeof obj.value === "object") {
                    value = obj.value.reduce(
                      (acc, cv) => acc + taskResult[0].props[cv],
                      0
                    );
                  } else {
                    value = taskResult[0].props[obj.value];
                  }
                  value = new Intl.NumberFormat("ru-RU", {
                    minimumFractionDigits: 2
                  }).format(value / obj.divisor);
                  return (
                    <h2 key={key} className="quarries-results__value">
                      {value}
                    </h2>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </ScrollBar>
  );
};

const mapStateToProps = state => ({
  taskResult: taskResult(state),
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(QuarriesResult);

import React from "react";
import { connect } from "react-redux";
import { taskResult } from "state/selectors/projects";
import ApplyResultButton from "./ApplyResultButton";

const ApplyResultButtonContainer = ({ handler, taskResult }) => {
  if (handler !== "results" || !taskResult || !taskResult[0]) return null;
  if (taskResult && !taskResult[0].props.applied) {
    return <ApplyResultButton taskResult={taskResult} />;
  }
  return <h2 className="is-applied-title">Перенесено в проект</h2>;
};

const mapStateToProps = state => ({
  taskResult: taskResult(state)
});

export default connect(mapStateToProps)(ApplyResultButtonContainer);

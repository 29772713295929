import React from "react";
import { connect } from "react-redux";
import { linearId } from "state/selectors/settings";

const BottomRow = ({ getLinearId }) => {
  if (getLinearId) return null;
  return (
    <div className="alert-row">
      <h2 className="alert-row__title">
        Выберите <span className="alert-row__title-bold">«Объект»</span> для
        расчета его стоимости
      </h2>
    </div>
  );
};

const mapStateToProps = state => ({
  getLinearId: linearId(state)
});

export default connect(mapStateToProps)(BottomRow);

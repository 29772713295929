import React from "react";
import { connect } from "react-redux";
import {
  layout,
  getProjectArea,
  getProjectName,
  getWellfieldName
} from "state/selectors/projects";
import { showModal as setShowModal } from "state/actions/settings";
import { showModal } from "state/selectors/settings";

import AboutProjectModalContainer from "./AboutProjectModalContainer";

const AboutProjectModalRedux = props => {
  return <AboutProjectModalContainer {...props} />;
};

const mapStateToProps = state => ({
  layout: layout(state),
  showModal: showModal(state),
  projectArea: getProjectArea(state),
  projectName: getProjectName(state),
  getWellfieldName: getWellfieldName(state)
});

export default connect(mapStateToProps, { setShowModal })(
  AboutProjectModalRedux
);

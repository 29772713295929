/* eslint-disable camelcase */
import React from "react";
import daysjs from "dayjs";
import classnames from "classnames";
import ScrollBar from "components/Common/ScrollBar";
import "../style.scss";

const LogsLogsView = ({ newWellfieldLogs = [], checkingCanceled }) => (
  <div className="new-wellfield__logs">
    <h2 className="input-label">Процесс проверки архива</h2>
    <div className="new-wellfield__logs-container logs-container__padding-right">
      <ScrollBar>
        <ul className="logs-area__padding-right">
          {!checkingCanceled &&
            newWellfieldLogs.map(
              ({ finished, has_errors, message, _updated }, key) => {
                return (
                  <li key={key} className="new-wellfield__logs-row">
                    <span className="new-wellfield__logs-time">
                      [{daysjs(_updated).format("YYYY-MM-DD HH:mm")}]
                    </span>
                    <span
                      className={classnames("new-wellfield__logs-message", {
                        has_errors,
                        finished
                      })}
                    >
                      {message}
                    </span>
                  </li>
                );
              }
            )}
        </ul>
      </ScrollBar>
    </div>
  </div>
);

export default LogsLogsView;

import React from "react";
import _get from "lodash.get";
import { useDispatch } from "react-redux";
import { getFacilitiesFromProjectByTaskType } from "state/actions/projects";
import { clickType } from "state/actions/settings";
import classnames from "classnames";
import BottomRow from "./BottomRow";
import fields from "./fields";
import DemsComponent from "./DemsComponent";
import CommonField from "../../CommonField";

const HandleProps = ({ addedProps, errors, info, defaultValue }) => {
  let Component = null;
  if (addedProps.bigTitle) {
    Component = (
      <h2 className="create-task-big-title">{addedProps.bigTitle}</h2>
    );
  } else if (addedProps === "dem") {
    Component = (
      <DemsComponent
        error={errors.includes("props.dem_id")}
        disabled={info}
        defaultValue={defaultValue}
      />
    );
  } else {
    Component = (
      <CommonField
        error={errors.includes(addedProps.propName)}
        addedProps={{
          ...addedProps,
          disabled: info,
          defaultValue
        }}
      />
    );
  }
  return Component;
};

const Fields = ({ addedProps, taskInfo, errors, info }) => {
  let { defaultValue } = addedProps;
  if (taskInfo) {
    if (addedProps === "dem") {
      defaultValue = taskInfo.props.dem_id;
    } else {
      defaultValue = _get(taskInfo, addedProps.propName);
    }
  }
  return (
    <HandleProps
      defaultValue={defaultValue}
      addedProps={addedProps}
      errors={errors}
      info={info}
    />
  );
};

export default React.memo(({ info, taskInfo, errors = [] }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!info) {
      dispatch(
        getFacilitiesFromProjectByTaskType("REQUEST", "gathering_centers")
      );
      dispatch(clickType("SUCCESS", ["chooseGatheringCenters"]));
    }
  }, [info]);
  return (
    <div className="d-flex flex-column justify-between flex-1">
      <div className="create-task-container">
        {fields.map((addedProps, index) => {
          if (addedProps.fields) {
            return (
              <div
                key={index}
                className={classnames("create-task-row", [
                  addedProps.parentContainerClass
                ])}
              >
                {addedProps.fields.map((addedProps, index) => {
                  return (
                    <div
                      key={index}
                      className={classnames(addedProps.parentContainerClass)}
                    >
                      <Fields
                        info={info}
                        taskInfo={taskInfo}
                        errors={errors}
                        addedProps={addedProps}
                      />
                    </div>
                  );
                })}
              </div>
            );
          }
          return (
            <div key={index} className="create-task-row">
              <Fields
                info={info}
                taskInfo={taskInfo}
                errors={errors}
                addedProps={addedProps}
              />
            </div>
          );
        })}
      </div>
      <BottomRow info={info} />
    </div>
  );
});

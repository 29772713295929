import React, { Component } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { wellfields } from "state/selectors/common";
import {
  showModal as setShowModal,
  setRemoveProps,
  openRemoveAlert
} from "state/actions/settings";

import { removeWellfield, downloadWellfield } from "state/actions/wellfield";

import _values from "lodash.values";
import _isEqual from "lodash.isequal";
import ProjectsCol from "../Projects/ProjectsFirstPage/projectsCol";
import PreviewCol from "../Projects/ProjectsFirstPage/previewCol";

dayjs.extend(utc);

class Wellfields extends Component {
  state = {
    wellfields: []
  };

  componentDidMount() {
    this.setWellfields(this.props);
  }

  componentDidUpdate(nextProps) {
    const { wellfields } = this.props;
    if (!_isEqual(nextProps.wellfields, wellfields)) {
      this.setWellfields(this.props);
    }
  }

  setDate = ({ _created }) => dayjs.utc(_created).format("DD.MM.YYYY H:mm");

  setWellfields = props => {
    const { wellfields } = props;
    if (wellfields) {
      const wellfielsArray = _values(wellfields);
      wellfielsArray.sort((a, b) => {
        const { _created: aCreated } = a;
        const { _created: bCreated } = b;
        const cond = dayjs(aCreated).isBefore(dayjs(bCreated), "second");
        if (cond) return 1;
        return -1;
      });

      this.setState({
        wellfields: wellfielsArray
      });
    }
  };

  createNewWellfield = () => {
    const { history } = this.props;
    history.push("/wellfields/new");
  };

  removeHandler = ({ id, name }) => {
    const { setRemoveProps, openRemoveAlert } = this.props;
    setRemoveProps("SUCCESS", {
      id,
      removeType: "wellfield",
      name: name || "Без названия",
      title: "месторождения",
      questionTitle: "месторождение"
    });
    openRemoveAlert("SUCCESS", true);
  };

  downloadHandler = ({ id, name }) => {
    const { downloadWellfield } = this.props;
    downloadWellfield("REQUEST", { id, name });
  };

  render() {
    const { wellfields } = this.state;
    return (
      <div className="projects">
        <div className="projects__container">
          <PreviewCol wellfield createNew={this.createNewWellfield} />
          <ProjectsCol
            wellfield
            handleClick={() => {}}
            setDate={this.setDate}
            setWellField={() => {}}
            remove={this.removeHandler}
            projects={wellfields}
            download={this.downloadHandler}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  wellfields: wellfields(state)
});

const mapDispathToProps = {
  removeWellfield,
  downloadWellfield,
  setRemoveProps,
  openRemoveAlert,
  setShowModal
};

export default connect(mapStateToProps, mapDispathToProps)(Wellfields);

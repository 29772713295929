import React from "react";
import classnames from "classnames";
import RestrictedPosition from "./RestrictedPosition";
import FacilityResultValues from "./FacilityResultValues";

const FacilityResultCol = ({ left, title, data }) => {
  return (
    <div
      className={classnames("facility-result-col", [left ? "left" : "right"])}
    >
      <h3 className="facility-result-title">{title}</h3>
      {data === "restricted_position" ? (
        <RestrictedPosition />
      ) : (
        <FacilityResultValues data={data} />
      )}
    </div>
  );
};

export default FacilityResultCol;

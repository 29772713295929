import React from "react";
import PropTypes from "prop-types";
import ProjectListContainer from "./ProjectListContainer";

const ProjectList = ({
  download,
  activeText,
  openList,
  remove,
  projects,
  activeRow,
  choseProject,
  tooltipRef
}) => {
  return projects.map((project, id) => {
    return (
      <ProjectListContainer
        key={id}
        project={project}
        choseProject={choseProject}
        activeRow={activeRow}
        activeText={activeText}
        tooltipRef={tooltipRef}
        download={download}
        remove={remove}
        openList={openList}
      />
    );
  });
};

ProjectList.propTypes = {
  activeRow: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired,
  choseProject: PropTypes.func.isRequired
};

export default ProjectList;

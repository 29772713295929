import * as math from "mathjs";

export default (lon, lat, width, length, radians) => {
  const AVG_EARTH_RADIUS = 6371138;
  const cos = math.cos((lat * math.pi) / 180);
  const m_lon = 180 / (cos * AVG_EARTH_RADIUS * math.pi);
  const m_lat = 180 / (AVG_EARTH_RADIUS * math.pi);
  const min_lon = lon - (length * m_lon) / 2;
  const min_lat = lat - (width * m_lat) / 2;
  const max_lon = lon + (length * m_lon) / 2;
  const max_lat = lat + (width * m_lat) / 2;
  let values = [
    [min_lat, min_lon],
    [max_lat, min_lon],
    [max_lat, max_lon],
    [min_lat, max_lon]
  ];
  if (radians) {
    let centroid = math.mean(values, 0);
    const avg_lat = centroid[0];
    const coeff = math.cos((avg_lat * math.pi) / 180);
    const scale_vec = [
      (math.pi * AVG_EARTH_RADIUS) / 180,
      (coeff * math.pi * AVG_EARTH_RADIUS) / 180
    ];
    centroid = math.dotMultiply(centroid, scale_vec);
    values.forEach((item, index) => {
      const value = math.dotMultiply(item, scale_vec);
      values[index] = math.subtract(value, centroid);
    });
    const cos = math.cos(radians);
    const sin = math.sin(radians);
    const rotation_matrix = [
      [cos, sin],
      [-sin, cos]
    ];
    values = math.multiply(values, rotation_matrix);
    values.forEach((item, index) => {
      const value = math.add(item, centroid);
      values[index] = math.dotDivide(value, scale_vec);
    });
  }
  return values;
};

import React from "react";
import classnames from "classnames";

import ToggleContainer from "components/Common/ToggleContainer";
import TaskStatus from "./TaskStatus";

const PriorityIcon = ({ priority }) => {
  const dataTip = {
    0: "Низкий приоритет",
    4: "Средний приоритет",
    9: "Высокий приоритет"
  };
  const dataClass = {
    0: "lowPriority",
    4: "mediumPriority",
    9: "highPriority"
  };
  return (
    <span
      data-tip={dataTip[priority] || ""}
      data-for="mainTooltip"
      className={classnames("task-priority", dataClass[priority] || "")}
    />
  );
};

export default ({
  downloadTask,
  repeatTask,
  removeHandler,
  data,
  updated,
  active,
  chooseRow
}) => {
  const handleIcon = item => (
    <TaskStatus
      item={item}
      repeatTask={repeatTask}
      downloadTask={downloadTask}
      removeHandler={removeHandler}
    />
  );

  return (
    <ToggleContainer
      updated={updated}
      titleProps={{
        itemKey: data.itemKey,
        title: data.title,
        ...data.titleProps
      }}
      listProps={{
        active,
        chooseRow,
        data: data.values,
        rowClass: "task-toggle-text",
        afterComp: item => handleIcon(item),
        beforeComp: item => <PriorityIcon priority={item.priority} />,
        ...data.listProps
      }}
    />
  );
};

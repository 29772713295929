import React from "react";

export default function ListElem({
  handleClick,
  clickType,
  name,
  elem,
  backgroundColor
}) {
  const onClickHandler = () => handleClick(clickType, elem);
  return (
    <li onClick={onClickHandler} className="topMenuListItem">
      <span style={{ backgroundColor }} className="topMenuListItemColor" />
      {name}
    </li>
  );
}

export const HIDE_LINEARS_BY_METACLASS_AND_TYPE =
  "HIDE_LINEARS_BY_METACLASS_AND_TYPE";
export const HIDE_LINEARS_BY_METACLASS_AND_TYPE_SUCCESS =
  "HIDE_LINEARS_BY_METACLASS_AND_TYPE_SUCCESS";

export const HIDE_LINEARS_BY_METACLASS_AND_NETWORK =
  "HIDE_LINEARS_BY_METACLASS_AND_NETWORK";
export const HIDE_LINEARS_BY_METACLASS_AND_NETWORK_SUCCESS =
  "HIDE_LINEARS_BY_METACLASS_AND_NETWORK_SUCCESS";
export const HIDE_LINEARS_BY_NETWORK = "HIDE_LINEARS_BY_NETWORK";
export const HIDE_LINEARS_BY_NETWORK_SUCCESS =
  "HIDE_LINEARS_BY_NETWORK_SUCCESS";

export const SET_HIDE_LINEARS_BY_NETWORK = "SET_HIDE_LINEARS_BY_NETWORK";
export const SET_HIDE_LINEARS_BY_NETWORK_SUCCESS =
  "SET_HIDE_LINEARS_BY_NETWORK_SUCCESS";

export const OPENED_LIST_BY_TYPE = "OPENED_LIST_BY_TYPE";
export const OPENED_LIST_BY_TYPE_SUCCESS = "OPENED_LIST_BY_TYPE_SUCCESS";

export const OPENED_LIST = "OPENED_LIST";
export const OPENED_LIST_SUCCESS = "OPENED_LIST_SUCCESS";

export const SAVE_SETTINGS = "SAVE_SETTINGS";
export const SAVE_SETTINGS_REQUEST = "SAVE_SETTINGS_REQUEST";
export const SAVE_SETTINGS_SUCCESS = "SAVE_SETTINGS_SUCCESS";

export const HIDE_AREAS = "HIDE_AREAS";
export const HIDE_AREAS_GROUP = "HIDE_AREAS_GROUP";

export const HIDE_AREAS_GROUP_SUCCESS = "HIDE_AREAS_GROUP_SUCCESS";
export const HIDE_AREAS_SUCCESS = "HIDE_AREAS_SUCCESS";

export const HIDE_LINEARS = "HIDE_LINEARS";
export const HIDE_LINEARS_METACLASS = "HIDE_LINEARS_METACLASS";
export const HIDE_LINEARS_METACLASS_SUCCESS = "HIDE_LINEARS_METACLASS_SUCCESS";
export const HIDE_LINEARS_SUCCESS = "HIDE_LINEARS_SUCCESS";

export const HIDE_FACILITIES = "HIDE_FACILITIES";
export const HIDE_FACILITIES_GROUP = "HIDE_FACILITIES_GROUP";
export const HIDE_FACILITIES_SUCCESS = "HIDE_FACILITIES_SUCCESS";
export const HIDE_FACILITIES_GROUP_SUCCESS = "HIDE_FACILITIES_GROUP_SUCCESS";

export const LOCK_FACILITIES = "LOCK_FACILITIES";
export const LOCK_FACILITIES_SUCCESS = "LOCK_FACILITIES_SUCCESS";

export const SET_OPENED_TASKS = "SET_OPENED_TASKS";
export const SET_OPENED_TASKS_SUCCESS = "SET_OPENED_TASKS_SUCCESS";

export const REMOVE_OPENED_TASKS = "REMOVE_OPENED_TASKS";
export const REMOVE_OPENED_TASKS_SUCCESS = "REMOVE_OPENED_TASKS_SUCCESS";

export const REMOVE_HIDDEN_METACLASS = "REMOVE_HIDDEN_METACLASS";
export const REMOVE_HIDDEN_METACLASS_REQUEST =
  "REMOVE_HIDDEN_METACLASS_REQUEST";

export const SET_LINEARS_TOGGLE = "SET_LINEARS_TOGGLE";
export const SET_LINEARS_TOGGLE_SUCCESS = "SET_LINEARS_TOGGLE_SUCCESS";

import {
  MOVE_TO_MAP_CENTER_REQUEST,
  SET_MAP_TYPE_SUCCESS,
  SET_CENTER_SUCCESS,
  SET_ZOOM_SUCCESS,
  SET_VIEW_SUCCESS
} from "../constants/map";

import { CLEAR_STATE_SUCCESS } from "../constants/projects";

const initialState = {
  mapType: "Google",
  zoom: 12
};

const removeKey = (state, key) => {
  const stateObj = { ...state };
  delete stateObj[key];
  return state;
};

const setZoom = payload => {
  if (payload >= 18) return 18;
  return payload <= 1 ? 1 : payload;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MOVE_TO_MAP_CENTER_REQUEST: {
      return {
        ...state,
        zoom: 12,
        setView: {
          ...state.mapCenter
        }
      };
    }
    case CLEAR_STATE_SUCCESS:
      return removeKey(state, "mapCenter");
    case SET_VIEW_SUCCESS:
      return {
        ...state,
        setView: action.payload
      };
    case SET_CENTER_SUCCESS:
      return {
        ...state,
        mapCenter: action.payload
      };
    case SET_MAP_TYPE_SUCCESS:
      return {
        ...state,
        mapType: action.payload
      };
    case SET_ZOOM_SUCCESS:
      return {
        ...state,
        zoom: setZoom(action.payload)
      };
    default:
      return state;
  }
}

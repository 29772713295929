import React from "react";
import WithIcons from "components/HOC/WithIcons";
import _get from "lodash.get";
import FacilityView from "./FacilityView";

class FacilitiesContainer extends React.Component {
  onClickHandler = e => {
    let taskPoints;
    const {
      singleObj,
      chooseSinks,
      chooseSources,
      addTaskPoints,
      chooseQuarries,
      chooseFacility,
      chooseConsumers,
      changeTaskSettings,
      chooseSingleFacility,
      chooseGatheringCenters,
      chooseWaterConsumersIds,
      choosePowerConsumersIds
    } = this.props;
    if (chooseSinks) {
      const id = _get(e, "target.options.data.properties.id");
      if (singleObj) {
        changeTaskSettings("SUCCESS", {
          "props.sinks_ids": [id]
        });
      } else {
        taskPoints = { sinks_ids: id };
      }
    }
    if (chooseSources) {
      const id = _get(e, "target.options.data.properties.id");
      if (singleObj) {
        changeTaskSettings("SUCCESS", {
          "props.sources_ids": [id]
        });
      } else {
        taskPoints = { sources_ids: id };
      }
    }
    if (chooseConsumers) {
      const id = _get(e, "target.options.data.properties.id");
      if (singleObj) {
        changeTaskSettings("SUCCESS", {
          "props.consumers_ids": [id]
        });
      } else {
        taskPoints = { consumers_ids: id };
      }
    }
    if (chooseFacility) {
      const id = _get(e, "target.options.data.properties.id");
      taskPoints = { facilities_ids: id };
    }
    if (chooseGatheringCenters) {
      const id = _get(e, "target.options.data.properties.id");
      taskPoints = { wells_ids: id };
    }
    if (chooseWaterConsumersIds) {
      const id = _get(e, "target.options.data.properties.id");
      taskPoints = { water_consumers_ids: id };
    }
    if (choosePowerConsumersIds) {
      const id = _get(e, "target.options.data.properties.id");
      taskPoints = { power_consumers_ids: id };
    }
    if (chooseSingleFacility) {
      const facility_id = _get(e, "target.options.data.properties.id");
      changeTaskSettings("SUCCESS", {
        "props.facility_id": facility_id
      });
    }
    if (chooseQuarries) {
      const { id, palette } = _get(e, "target.options.data.properties");
      if (palette === "quarry") {
        taskPoints = { quarries_ids: id };
      }
    }
    if (taskPoints) {
      addTaskPoints("SUCCESS", taskPoints);
    }
  };

  render() {
    const { facilitiesOnMap } = this.props;
    if (!facilitiesOnMap.length) return null;
    return (
      <>
        {facilitiesOnMap.map(({ id, ...props }, key) => {
          return (
            <FacilityView
              {...props}
              id={id}
              key={id + key}
              onClickHandler={this.onClickHandler}
            />
          );
        })}
      </>
    );
  }
}

export default WithIcons(FacilitiesContainer);

const toObject = (xs, key) => {
  if (!xs) return {};
  return xs.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    rv[x[key]] = x;
    return rv;
  }, {});
};

export default toObject;

import React, { Component } from "react";
import ValidationSchema from "./ValidationSchema";

export const ErrorsContext = React.createContext({
  errors: [],
  clearErrors: () => {}
});

class Validation extends Component {
  state = { errors: [] };

  handleSubmit = e => {
    const { type } = this.props;
    const errors = [];
    const form = e.target;
    e.preventDefault();
    const validationFields = ValidationSchema(type);
    if (form.elements["props.calculate"]?.checked) {
      const taskRequiredProps = [
        "props.dem_id",
        "priority",
        "props.graph_edge_length_m"
      ];
      taskRequiredProps.forEach(name => {
        if (form[name] && !form[name].value) {
          errors.push(name);
        }
      });
    }

    Array.from(form.elements).forEach(elem => {
      const { name } = elem;
      if (elem.className.includes("required")) {
        if (
          form[name] &&
          !form[name].value &&
          !validationFields.includes(name)
        ) {
          errors.push(name);
        }
      }
    });

    validationFields.forEach(name => {
      if (!form[name]?.value) {
        errors.push(name);
      }
    });

    this.setState({ errors }, () => {
      console.log(errors);
      if (!errors.length) {
        const { handleSubmit } = this.props;
        handleSubmit(form);
      }
    });
  };

  clearErrors = () => {
    const { errors } = this.state;
    if (errors.length) {
      this.setState({ errors: [] });
    }
  };

  render() {
    const { errors } = this.state;
    const { children } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <ErrorsContext.Provider
          value={{
            errors,
            clearErrors: this.clearErrors
          }}
        >
          {children}
        </ErrorsContext.Provider>
      </form>
    );
  }
}

export default Validation;

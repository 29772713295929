import React from "react";

const RestrictedPosition = () => {
  return (
    <div className="facility-result-values">
      <h3 className="restricted-position-title">
        Расчет стоимости начальной позиции невозможен, <br />
        так как объект находится в запретной области
      </h3>
    </div>
  );
};
export default RestrictedPosition;

export const LOGIN = "LOGIN";
export const SET_AUTH = "SET_AUTH";

// REQUEST
export const LOGIN_REQUEST = "LOGIN_REQUEST";
// SUCCESS
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_AUTH_SUCCESS = "SET_AUTH_SUCCESS";

// FAILURE
export const SET_AUTH_FAILURE = "SET_AUTH_FAILURE";

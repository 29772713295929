import React from "react";
import ActiveTextComponent from "components/Common/ToggleContainer/ActiveTextComponent";
import ReactTooltip from "react-tooltip";
import ListItem from "./ListItem";
import ProjectListTitle from "./ProjectListTitle";

const ProjectListComponent = ({
  showList,
  project,
  choseProject,
  activeRow,
  activeText,
  remove,
  download,
  tooltipRef
}) => {
  if (!showList) return null;
  return (
    <ul>
      {project.data.map((item, key) => {
        ReactTooltip.rebuild();
        let toggleText = item.name;
        const chooseRowHandler = () => choseProject(item);
        const isActive = activeRow(item);
        if (activeText) {
          const re = new RegExp(activeText, "ig");
          const arr = item.name.split(re);
          toggleText = arr.map((text, key) => {
            return (
              <ActiveTextComponent
                key={key}
                index={key}
                text={text}
                arrLength={arr.length}
                coloredActiveText={activeText}
              />
            );
          });
        }
        const onRemove = () => remove(item);

        const onDownload = () => download(item);

        return (
          <ListItem
            key={key}
            isActive={isActive}
            chooseRowHandler={chooseRowHandler}
            toggleText={toggleText}
            tooltipRef={tooltipRef}
            download={onDownload}
            remove={onRemove}
          />
        );
      })}
    </ul>
  );
};

class ProjectListContainer extends React.Component {
  state = {
    showList: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.showList) {
      const { openList } = this.props;
      if (prevProps.openList !== openList) {
        this.setState({ showList: openList });
      }
    }
  }

  onClickHandler = () => {
    const { showList } = this.state;
    this.setState({ showList: !showList });
  };

  render() {
    const { showList } = this.state;
    const {
      project,
      choseProject,
      activeRow,
      activeText,
      tooltipRef,
      download,
      remove
    } = this.props;
    return (
      <div className="open-projects-main-row">
        <ProjectListTitle
          showList={showList}
          title={project.title}
          onClickHandler={this.onClickHandler}
        />
        <ProjectListComponent
          showList={showList}
          project={project}
          choseProject={choseProject}
          activeRow={activeRow}
          activeText={activeText}
          remove={remove}
          download={download}
          tooltipRef={tooltipRef}
        />
      </div>
    );
  }
}

export default ProjectListContainer;

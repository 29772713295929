import React from "react";
import { connect } from "react-redux";
import {
  downloadProject,
  getPreviewProjectProps
} from "state/actions/projects";
import { setRemoveProps, openRemoveAlert } from "state/actions/settings";
import { projects, wellfields } from "state/selectors/common";

import SearchInput from "components/Common/SearchInput";
import LeftSidebar from "components/LeftSidebar";
import WithProjectsProps from "components/HOC/WithProjectsProps";
import Button from "components/Common/Button";
import _values from "lodash.values";
import groupBy from "utils/groupBy";
import _transform from "lodash.transform";
import _isEqual from "lodash.isequal";
import OpenProjectsComponent from "./OpenProjectsComponent";
import { setMapType } from "../../../state/actions/map";
import ProjectInfo from "./ProjectInfo";
import { transformProjects } from "./utils";

class OpenProjects extends React.Component {
  tooltipRef = React.createRef();

  state = {
    searchValue: "",
    projectId: null,
    projects: []
  };

  componentDidMount() {
    this.handleProjects();
  }

  componentDidUpdate(prevProps) {
    const { projects, wellfields } = this.props;
    if (
      !_isEqual(projects, prevProps.projects) ||
      !_isEqual(wellfields, prevProps.wellfields)
    ) {
      this.handleProjects();
    }
  }

  handleProjects = (searchValue = "") => {
    const { projects, wellfields } = this.props;
    if (projects && wellfields) {
      const dataByWellfield = groupBy(
        _values(projects).filter(({ name }) =>
          name.toLowerCase().includes(searchValue)
        ),
        "wellfield_id"
      );
      const data = _transform(
        dataByWellfield,
        transformProjects(wellfields),
        []
      );
      this.setState({
        projects: data
      });
    }
  };

  choseProject = ({ id }) => {
    const { getPreviewProjectProps } = this.props;
    this.setState({ projectId: id });
    getPreviewProjectProps("REQUEST", id);
  };

  handleClick = () => {
    const { projectId } = this.state;
    const { changeLocation, setMapType } = this.props;
    if (projectId !== null) {
      changeLocation(`/app/${projectId}`);
    }
    setMapType("SUCCESS", "withoutMap");
  };

  activeRow = ({ id }) => {
    const { projectId } = this.state;
    return id === projectId ? "active" : "";
  };

  cancelHandler = () => {
    const { setMapType, changeLocation } = this.props;
    setMapType("SUCCESS", "Google");
    changeLocation("/projects");
  };

  removeHandler = ({ id, wellfieldName: wellfield, name = "Без названия" }) => {
    const { setRemoveProps, openRemoveAlert } = this.props;
    setRemoveProps("SUCCESS", {
      id,
      name,
      wellfield,
      removeType: "project",
      title: "проекта",
      questionTitle: "проект"
    });
    openRemoveAlert("SUCCESS", true);
  };

  download = ({ id, name }) => {
    const { downloadProject } = this.props;
    downloadProject("REQUEST", { id, name });
  };

  onSearchHandler = ({ target: { value } }) => {
    this.setState({ searchValue: value }, () => this.handleProjects(value));
  };

  render() {
    const { searchValue, projectId, projects } = this.state;
    return (
      <LeftSidebar>
        <div className="left-sidebar__main-container">
          <div className="left-sidebar__top-fields">
            <LeftSidebar.TopRow>
              <h2 className="main-title"> Открытие проекта </h2>
              <button
                onClick={this.cancelHandler}
                className="new-project__close"
              >
                <div className="new-project__close-line close" />
              </button>
            </LeftSidebar.TopRow>
            <div className="left-sidebar__row">
              <SearchInput
                placeholder="Поиск проекта…"
                value={searchValue}
                onChange={this.onSearchHandler}
              />
            </div>
            <div className="left-sidebar__projects">
              <OpenProjectsComponent
                projects={projects}
                openList={!!searchValue}
                projectId={projectId}
                download={this.download}
                activeRow={this.activeRow}
                remove={this.removeHandler}
                handleClick={this.handleClick}
                choseProject={this.choseProject}
                activeText={searchValue}
                cancelHandler={this.cancelHandler}
                tooltipRef={this.tooltipRef}
              />
            </div>
          </div>
          <LeftSidebar.BottomRow>
            <Button onClick={this.handleClick}> Открыть </Button>
            <Button secondary onClick={this.cancelHandler}>
              Отмена
            </Button>
          </LeftSidebar.BottomRow>
        </div>
        <ProjectInfo innerRef={this.tooltipRef} />
      </LeftSidebar>
    );
  }
}

const mapStateToProps = state => ({
  projects: projects(state),
  wellfields: wellfields(state)
});

const mapDispatchToProps = {
  setRemoveProps,
  openRemoveAlert,
  downloadProject,
  getPreviewProjectProps,
  setMapType
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithProjectsProps(OpenProjects));

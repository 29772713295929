const colorignFunc = type => {
  switch (type) {
    case "bog_3":
    case "bog_1_2":
      return {
        color: "#027D7E"
      };
    case "forest":
      return {
        color: "#7ED321"
      };
    case "lake":
      return {
        color: "#256AFF"
      };
    case "permafrost":
      return {
        color: "#CFD8DD"
      };
    case "dry_land":
      return {
        color: "#73AB93"
      };
    case "other":
      return {
        color: " rgb(171, 177, 182)",
        calculated: {
          weight: 0,
          fillOpacity: 0.64,
          opacity: 0.64,
          color: " rgb(171, 177, 182)"
        },
        existing: {
          weight: 0,
          fillOpacity: 0.64,
          opacity: 0.64,
          color: " rgb(171, 177, 182)"
        }
      };
    case "optimized_result":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#6b4eff",
        color: "#5740cd"
      };
    case "tmpColor0":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#F5A623",
        color: "#F5A623"
      };
    case "tmpColor1":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#6B4EFF",
        color: "#6B4EFF"
      };
    case "tmpColor2":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#FF3CDD",
        color: "#FF3CDD"
      };
    case "tmpColor3":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#A000FF",
        color: "#A000FF"
      };
    case "tmpColor4":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#35DA67",
        color: "#35DA67"
      };
    case "tmpColor5":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "rgb(255, 105, 180)",
        color: "rgb(255, 105, 180)"
      };
    case "high_voltage": {
      return {
        color: "#49B4FF",
        existing: {
          weight: 4,
          fillOpacity: 0.5,
          opacity: 0.5,
          color: "#49B4FF"
        },
        calculated: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#49B4FF"
        }
      };
    }
    case "high_pressure_gas_pipe":
      return {
        color: "#F7C614",
        existing: {
          weight: 4,
          fillOpacity: 0.5,
          opacity: 0.5,
          color: "#F7C614"
        },
        calculated: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#F7C614"
        },
        calculated_tmp: {
          weight: 1,
          fillOpacity: 1,
          opacity: 1,
          color: "#000"
        }
      };
    case "low_pressure_gas_pipe":
      return {
        color: "#F7C614",
        existing: {
          weight: 4,
          fillOpacity: 0.5,
          opacity: 0.5,
          color: "#F7C614"
        },
        calculated: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#F7C614"
        },
        calculated_tmp: {
          weight: 1,
          fillOpacity: 1,
          opacity: 1,
          color: "#000"
        }
      };
    case "high_pressure_water_pipe":
      return {
        color: "#49B4FF",
        existing: {
          weight: 4,
          fillOpacity: 0.5,
          opacity: 0.5,
          color: "#49B4FF"
        },
        calculated: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#49B4FF"
        },
        calculated_tmp: {
          weight: 1,
          fillOpacity: 1,
          opacity: 1,
          color: "#49B4FF"
        }
      };
    case "oil_pipe_line":
      return {
        color: "#FF5730",
        existing: {
          weight: 4,
          fillOpacity: 0.5,
          opacity: 0.5,
          color: "#FF5730"
        },
        calculated: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#FF5730"
        },
        calculated_tmp: {
          weight: 1,
          fillOpacity: 1,
          opacity: 1,
          color: "#FF5730"
        }
      };
    case "low_pressure_water_pipe":
      return {
        color: "#49B4FF",
        existing: {
          weight: 4,
          fillOpacity: 0.5,
          opacity: 0.5,
          color: "#49B4FF"
        },
        calculated: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#49B4FF"
        },
        calculated_tmp: {
          weight: 1,
          fillOpacity: 1,
          opacity: 1,
          color: "#49B4FF"
        }
      };
    case "gas_pipe":
      return {
        color: "#F7C614",
        existing: {
          weight: 4,
          fillOpacity: 1,
          opacity: 1,
          color: "#F7C614"
        },
        calculated_tmp: {
          fillOpacity: 1,
          weight: 1,
          fillColor: "#F7C614",
          color: "#F7C614"
        }
      };
    case "river":
      return {
        weight: 1,
        fillOpacity: 0.6,
        opacity: 0.6,
        color: "rgb(37, 106, 255)"
      };
    case "infrastructure":
      return {
        weight: 0,
        fillOpacity: 0.64,
        opacity: 0.64,
        color: "rgb(192, 1, 39)"
      };
    case "karst":
      return {
        weight: 0,
        fillOpacity: 0.64,
        opacity: 0.64,
        color: "rgb(255, 70, 70)"
      };
    case "roads":
      return {
        color: "rgba(255, 186, 86, 1)",
        calculatedactive: {
          color: "#FFDCAA"
        },
        existingactive: {
          color: "rgba(255, 220, 170, 0.7)"
        },
        calculated_tmpactive: {
          color: "#FFDCAA"
        },
        calculated: {
          fillOpacity: 1,
          weight: 3,
          strokeColor: "rgba(255, 186, 86, 1)",
          color: "rgba(255, 186, 86, 1)"
        },
        existing: {
          weight: 2,
          fillOpacity: 1,
          color: "rgba(255, 186, 86, 0.5)"
        },
        calculated_tmp: {
          weight: 2,
          fillOpacity: 1,
          color: "rgb(255, 0, 0)"
        }
      };
    case "floodland":
      return {
        fillOpacity: 1,
        weight: 0,
        color: "rgba(79, 211, 186, 0.64)"
      };
    case "forest_corridor":
      return {
        color: "rgba(167, 175, 157, 1)",
        existing: {
          fillOpacity: 1,
          weight: 2,
          color: "rgba(167, 175, 157, 1)",
          dashArray: "4 8 4 4"
        }
      };
    case "oil_gathering_line":
      return {
        color: "#FF5730",
        existing: {
          fillOpacity: 0.5,
          opacity: 0.5,
          weight: 4,
          color: "#FF5730"
        },
        projected: {
          fillOpacity: 1,
          weight: 4,
          color: "#FF5730"
        },
        calculated: {
          fillOpacity: 1,
          weight: 4,
          color: "#FF5730"
        },
        calculated_tmp: {
          fillOpacity: 1,
          weight: 1,
          fillColor: "#FF5730",
          color: "#FF5730"
        }
      };
    case "pipe":
      return {
        projected: {
          fillOpacity: 1,
          weight: 4,
          color: "#a7a4e0"
        },
        calculated: {
          fillOpacity: 1,
          weight: 4,
          color: "#fd603c"
        },
        calculated_tmp: {
          fillOpacity: 1,
          weight: 1,
          fillColor: "#FF5730",
          color: "#FF5730"
        }
      };
    case "hv":
      return {
        existing: {
          fillOpacity: 1,
          weight: 2,
          color: "#49b4ff"
        }
      };
    case "well":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#939dab",
        color: "#666d77",
        calculated: {
          fillOpacity: 1,
          weight: 2,
          fillColor: "#939dab",
          color: "#666d77"
        },
        existing: {
          fillOpacity: 1,
          weight: 2,
          fillColor: "#939dab",
          color: "#666d77"
        },
        calculated_tmp: {
          fillOpacity: 1,
          weight: 2,
          fillColor: "#939dab",
          color: "#666d77"
        }
      };
    case "oil_delivery_point":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#B4B5B6",
        color: "#7D7E7F"
      };
    case "oil_treatment_plant":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#B4B5B6",
        color: "#7D7E7F"
      };
    case "booster_pump_station":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#b4b5b6",
        color: "#7d7e7f"
      };
    case "quarry":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#FFBA56",
        color: "#B2813C"
      };
    case "gas_delivery_point":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#FFBA56",
        color: "#B23C21"
      };
    case "gas_disposal_well":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#FF5730",
        color: "#B23C21"
      };
    case "compressor_station":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#FF5730",
        color: "#B23C21"
      };
    case "raw_water_intake_facility":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#49B4FF",
        color: "#327DB2"
      };
    case "water_disposal_well":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#49B4FF",
        color: "#327DB2"
      };
    case "multiphase_pump_station":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#b4b5b6",
        color: "#7d7e7f"
      };
    case "central_gathering_facility":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#B4B5B6",
        color: "#7D7E7F"
      };
    case "commissioning_station":
      return {
        weight: 2,
        fillOpacity: 1,
        fillColor: "#B4B5B6",
        color: "#7D7E7F"
      };
    case "stop_valve_station":
      return {
        weight: 2,
        fillOpacity: 1,
        fillColor: "#B4B5B6",
        color: "#7D7E7F"
      };
    case "preliminary_water_removal_unit":
      return {
        weight: 2,
        fillOpacity: 1,
        fillColor: "#49B4FF",
        color: "#327DB2"
      };
    case "modular_cluster_pump_station":
      return {
        weight: 2,
        fillOpacity: 1,
        fillColor: "#49B4FF",
        color: "#327DB2"
      };
    case "oil_transfer_pumping_station":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#B4B5B6",
        color: "#7D7E7F"
      };
    case "transformer_station":
      return {
        weight: 2,
        fillOpacity: 1,
        color: "#B29220",
        fillColor: "#FFD22F"
      };
    case "plant_power_station":
      return {
        weight: 2,
        fillOpacity: 1,
        color: "#B29220",
        fillColor: "#FFD22F"
      };
    case "outer_source_of_power_supply":
      return {
        fillOpacity: 1,
        weight: 2,
        fillColor: "#FFD22F",
        color: "#B29220"
      };
    case "facility":
      return {
        weight: 2,
        fillOpacity: 1,
        color: "#6A8CAD",
        fillColor: "#99C9F9"
      };
    default:
      return {};
  }
};
export default colorignFunc;

import { action } from "utils";

import {
  MOVE_TO_MAP_CENTER,
  SET_MAP_TYPE,
  SET_CENTER,
  SET_ZOOM,
  SET_VIEW
} from "../constants/map";

export const setView = action(SET_VIEW);
export const setZoom = action(SET_ZOOM);
export const setCenter = action(SET_CENTER);
export const setMapType = action(SET_MAP_TYPE);
export const moveToMapCenter = action(MOVE_TO_MAP_CENTER);

import React from "react";
import classnames from "classnames";

const TaskRowTitle = ({ title, className }) => {
  return (
    <h3 className={classnames("task-results__title", className)}>{title}</h3>
  );
};

export default TaskRowTitle;

import React, { Component } from "react";
import { connect } from "react-redux";

import { getTaskSettings } from "state/selectors/settings";
import { changeTaskSettings, setDefaultProps } from "state/actions/settings";
import { getFacilitiesFromProjectByTaskType } from "state/actions/projects";

import CheckBox from "../CheckBox";
import CommonFields from "../CommonFields";
import CommonField from "../../CommonField";
import hvlCoefs from "./hvlCoefs";

const defaultValues = {
  corridor_coeff_ad: 0,
  corridor_coeff_vl: 0,
  corridor_coeff_nsk: 0,
  corridor_coeff_nnp: 0,
  corridor_coeff_vnd: 0,
  corridor_coeff_vvd: 0,
  corridor_coeff_gnd: 0,
  corridor_coeff_gvd: 0
};

class Hvl extends Component {
  state = {
    defaultValues,
    taskInfo: { props: {} }
  };

  componentDidMount() {
    this.setPropsToState(this.props);
    const { info, getFacilitiesFromProjectByTaskType } = this.props;
    if (!info) {
      getFacilitiesFromProjectByTaskType("REQUEST", "high_voltage");
    }
  }

  setPropsToState = props => {
    const { info, taskInfo } = props;
    if (info) {
      this.setState(() => ({
        taskInfo,
        defaultValues: {
          ...taskInfo.props
        }
      }));
    }
  };

  render() {
    const { info, taskSettings, errors = [] } = this.props;
    const { taskInfo, defaultValues } = this.state;
    return (
      <div>
        <CommonFields info={info} errors={errors} taskInfo={taskInfo} />
        <div className="create-task-main-container">
          <div className="vl-coefs-row">
            <h2 className="coefs-row__title">
              Коэффициенты прокладки в коридоре
            </h2>
            <div>
              {hvlCoefs.map(({ coef, label }, key) => (
                <CommonField
                  key={key}
                  addedProps={{
                    propName: `props.${coef}`,
                    placeholder: "",
                    type: "number",
                    classContainer: "quarter",
                    defaultValue: defaultValues[coef],
                    label,
                    disabled: info
                  }}
                />
              ))}
            </div>
          </div>
          <div className="create-task-toggle-row">
            <div className="create-task-toggle-row__title">
              <CheckBox
                id="2jxg2"
                disabled={info}
                defaultValue={defaultValues.use_existing_vl}
                propName="props.use_existing_vl"
                label="Использовать существующие ВЛ"
              />
            </div>
          </div>
        </div>
        {!taskSettings && !info && (
          <div className="alert-row">
            <h2 className="alert-row__title">
              Выберите <span className="alert-row__title-bold">«Сток»</span> и{" "}
              <span className="alert-row__title-bold">«Истоки»</span> на карте
            </h2>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskSettings: getTaskSettings(state)
});

const mapDispatchToProps = {
  setDefaultProps,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType
};

export default connect(mapStateToProps, mapDispatchToProps)(Hvl);

import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withLeaflet } from "react-leaflet";
import { setCenter } from "state/actions/map";
import { getZoom, settedView } from "state/selectors/map";
import { getProjectArea } from "state/selectors/projects";

import center from "@turf/center";

const ReactLeafletAdjustContainer = ({
  zoom = 12,
  setCenter,
  settedView,
  projectArea,
  leaflet: { map }
}) => {
  const lastZoom = useRef(0);
  useEffect(() => {
    if (projectArea) {
      const [lng, lat] = center(projectArea).geometry.coordinates;
      map.setView([lat, lng], zoom || 14, { animation: true });
      setCenter("SUCCESS", { lat, lng });
    }
  }, [projectArea]);
  useEffect(() => {
    if (settedView) {
      const { lng, lat } = settedView;
      map.setView([lat, lng], zoom || 14, { animation: true });
    }
  }, [settedView]);
  useEffect(() => {
    if (lastZoom.current && lastZoom.current !== zoom) {
      map.setZoom(zoom);
    }
    lastZoom.current = zoom;
  }, [zoom]);
  return null;
};

const mapDispatchToProps = {
  setCenter
};

const mapStateToProps = state => ({
  zoom: getZoom(state),
  settedView: settedView(state),
  projectArea: getProjectArea(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLeaflet(ReactLeafletAdjustContainer));

import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import classnames from "classnames";
import ProjectItem from "./ProjectItem";

const ProjectsCol = ({
  remove,
  download,
  wellfield,
  projects,
  handleClick,
  setWellField,
  setDate
}) => {
  let projectsList = <h2 className="preload-text">Идёт загрузка...</h2>;
  if (projects) {
    if (!Object.keys(projects).length) {
      projectsList = (
        <h2 className="preload-text">
          Список {wellfield ? "месторождений" : "проектов"} пуст
        </h2>
      );
    } else {
      projectsList = Object.keys(projects).map((key, index) => (
        <ProjectItem
          item={projects[key]}
          key={index}
          index={projects[key]}
          remove={remove}
          download={download}
          wellfieldCond={wellfield}
          date={setDate(projects[key])}
          handleClick={item => handleClick(item)}
          wellfield={setWellField(projects[key].wellfield_id)}
        />
      ));
    }
  }
  return (
    <div className="projects__col pull-right">
      <div className="projects__col-row title">
        <h2 className="main-title">
          {wellfield ? "Список месторождений" : "Последние проекты"}
        </h2>
      </div>
      <ul className={classnames("projects-list-container", { wellfield })}>
        <ScrollBar>
          <ul>{projectsList}</ul>
        </ScrollBar>
      </ul>
    </div>
  );
};

export default ProjectsCol;

import React, { Component } from "react";
import { connect } from "react-redux";
import { additionalTaskPropsPipes } from "state/selectors/wellfield";
import { changeTaskSettings, activeTab } from "state/actions/settings";
import { getActiveTab } from "state/selectors/settings";
import { getFacilitiesFromProjectByTaskType } from "state/actions/projects";
import _isEqual from "lodash.isequal";
import CheckBox from "../CheckBox";
import CommonFields from "../CommonFields";
import PipeFields from "./PipeFields";
import CommonField from "../../CommonField";
import BottomRow from "./BottomRow";
import CoefsRow from "./CoefsRow";
import config from "./config";
import units from "./units";
import propsNames from "./propsNames";
import coefs from "./coefs";

const initialDefaultValues = {
  corridor_coeff_ad: 0,
  corridor_coeff_vl: 0,
  corridor_coeff_nsk: 0,
  corridor_coeff_nnp: 0,
  corridor_coeff_vnd: 0,
  corridor_coeff_vvd: 0,
  corridor_coeff_gnd: 0,
  corridor_coeff_gvd: 0
};

class Pipes extends Component {
  state = {
    options: [],
    defaultValues: initialDefaultValues,
    taskInfo: { props: {} }
  };

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  componentDidUpdate(prevProps) {
    this.setPropsToState(this.props, prevProps);
  }

  componentWillUnmount() {
    const { changeTaskSettings } = this.props;
    changeTaskSettings("SUCCESS", { props: {} });
  }

  setPropsToState = (props, oldProps = {}) => {
    const {
      info,
      taskInfo,
      getActiveTab,
      additionalTaskPropsPipes,
      getFacilitiesFromProjectByTaskType
    } = props;
    const { defaultValues } = this.state;
    if (defaultValues.type !== getActiveTab && getActiveTab !== "taskInfo") {
      if (!info) {
        getFacilitiesFromProjectByTaskType("REQUEST", getActiveTab);
      }
      let defaultValues = { ...initialDefaultValues };
      if (config[getActiveTab]) {
        defaultValues = {
          ...defaultValues,
          ...config[getActiveTab]
        };
      }
      this.setState(() => ({
        defaultValues: {
          ...defaultValues,
          type: getActiveTab
        }
      }));
    }
    if (
      !_isEqual(additionalTaskPropsPipes, oldProps.additionalTaskPropsPipes)
    ) {
      this.setState(() => ({
        options: additionalTaskPropsPipes.map(({ id: value, name: label }) => ({
          value,
          label
        }))
      }));
    }
    if (info && !_isEqual(taskInfo, oldProps.taskInfo)) {
      this.setState(() => ({
        taskInfo,
        defaultValues: {
          type: taskInfo.type,
          ...taskInfo.props
        }
      }));
    }
  };

  pipesTypeHandler = value => {
    const {
      getActiveTab,
      activeTab,
      getFacilitiesFromProjectByTaskType
    } = this.props;
    if (value !== getActiveTab) {
      activeTab("SUCCESS", value);
      getFacilitiesFromProjectByTaskType("REQUEST", value);
    }
  };

  render() {
    const { info, errors = [] } = this.props;
    const { defaultValues, taskInfo, options } = this.state;
    return (
      <div>
        <CommonFields
          info={info}
          errors={errors}
          taskInfo={taskInfo}
          showAmountOfSolutions
        />
        <div className="create-task-main-container">
          <div className="create-task-row middle-border-bottom">
            <CommonField
              addedProps={{
                disabled: info,
                type: "option",
                placeholder: "",
                propName: "type",
                classContainer: "wide",
                label: "Тип трубопровода",
                options,
                defaultValue: defaultValues.type,
                onChangeHandler: this.pipesTypeHandler
              }}
            />
          </div>
          <PipeFields
            coefs={coefs}
            units={units}
            disabled={info}
            propsNames={propsNames}
            defaultValues={defaultValues}
          />
          <CoefsRow info={info} defaultValues={defaultValues} />
          <div className="create-task-toggle-row">
            <div className="create-task-toggle-row__title">
              <CheckBox
                id="2jxaxf2"
                disabled={info}
                defaultValue={defaultValues.use_existing_pipes}
                propName="props.use_existing_pipes"
                label="Использовать существующие трубы"
              />
            </div>
          </div>
          {!info && <BottomRow />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  getActiveTab: getActiveTab(state),
  additionalTaskPropsPipes: additionalTaskPropsPipes(state)
});

const mapDispatchToProps = {
  activeTab,
  changeTaskSettings,
  getFacilitiesFromProjectByTaskType
};

export default connect(mapStateToProps, mapDispatchToProps)(Pipes);

import React from "react";
import classnames from "classnames";

const handleError = (error, finished) => {
  if (error) return "error";
  if (finished) return "done";
  return "process";
};

const TopRow = ({ name, logs, closeHandler }) => {
  let icon = "";
  if (logs) {
    const { finished, has_errors: error } = logs[0];
    icon = handleError(error, finished);
  }
  return (
    <div className="main-container__top-row task-info-top-row">
      <h2 className="main-title">{name}</h2>
      <span className={classnames("icon", icon)} />
      <button className="new-project__close" onClick={closeHandler}>
        <div className="new-project__close-line close" />
      </button>
    </div>
  );
};

export default TopRow;

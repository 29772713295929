import React from "react";
import { useSelector } from "react-redux";
import { showRuler } from "state/selectors/settings";

import RulerContainer from "./RulerContainer";

export default props => {
  const show = useSelector(showRuler);
  if (!show) return null;
  return <RulerContainer {...props} />;
};

/* eslint-disable camelcase */
import React from "react";
import { useDispatch } from "react-redux";
import { activeTab, changeTaskSettings } from "state/actions/settings";
import { taskConstants } from "utils/constants";

import Button from "components/Common/Button";

const {
  GATHERING_CENTERS,
  POWER_SUPPLY_CENTERS,
  WATER_PUMPING_STATIONS
} = taskConstants;

export default ({ data, index, type }) => {
  const [title, setTitle] = React.useState("");
  React.useEffect(() => {
    if (type === GATHERING_CENTERS) {
      setTitle("Рассчитать трубопровод НСК");
    }
    if (type === WATER_PUMPING_STATIONS) {
      setTitle("Рассчитать трубопровод ВВД");
    }
    if (type === POWER_SUPPLY_CENTERS) {
      setTitle("Рассчитать сеть ВЛ");
    }
  }, [type]);
  const dispatch = useDispatch();
  const onClickHandler = () => {
    if (type === GATHERING_CENTERS) {
      const { gathering_centers_ids, facilities_ids } = data;
      const sinks = gathering_centers_ids[index];
      const sources = facilities_ids[index];
      dispatch(
        changeTaskSettings("SUCCESS", {
          "props.sinks_ids": [sinks],
          "props.sources_ids": sources
        })
      );
      dispatch(activeTab("SUCCESS", "oil_gathering_line"));
    }
    if (type === WATER_PUMPING_STATIONS) {
      const { water_gathering_centers_ids, facilities_ids } = data;
      const sources = water_gathering_centers_ids[index];
      const sinks = facilities_ids[index];
      dispatch(
        changeTaskSettings("SUCCESS", {
          "props.sinks_ids": sinks,
          "props.sources_ids": [sources]
        })
      );
      dispatch(activeTab("SUCCESS", "high_pressure_water_pipe"));
    }
    if (type === POWER_SUPPLY_CENTERS) {
      const { electricity_centers_ids, facilities_ids } = data;
      dispatch(activeTab("SUCCESS", "high_voltage"));
      dispatch(
        changeTaskSettings("SUCCESS", {
          type: "high_voltage",
          "props.sources_ids": electricity_centers_ids,
          "props.consumers_ids": facilities_ids.flatMap(x => x)
        })
      );
    }
  };
  return (
    <div className="gathering-centers__row-button">
      <Button onClick={onClickHandler}>{title}</Button>
    </div>
  );
};

import React from "react";
import _get from "lodash.get";
import classnames from "classnames";
import TaskToggle from "../TaskToggle";
import IconHander from "../IconHander";

export default ({
  configFields,
  disabledTasks,
  pipesType,
  poTypes,
  avaliableTaskTypes,
  taskClickHandler
}) => {
  return (
    <ul className="topMenuTasksContainer">
      {configFields.map((item, key) => {
        let disabled = disabledTasks && disabledTasks[item.type];
        let dataProps = {};
        if (disabled) {
          if (disabled.length > 1) {
            disabled = [disabled.join(";")];
          }
          dataProps = {
            "data-tip": disabled[0],
            "data-place": "left",
            "data-for": "mainTooltip"
          };
        }
        if (item.type === "facility") {
          if (!poTypes.length) return null;
          return (
            <TaskToggle
              key={key}
              {...dataProps}
              icon={item.icon}
              type={item.type}
              title={item.title}
              pipesType={poTypes}
              avaliableTaskTypes={[
                ..._get(avaliableTaskTypes, [0, "enabled_tasks"])
              ]}
              taskClickHandler={taskClickHandler}
            />
          );
        }
        if (item.type === "linearPipes") {
          if (!pipesType.length) return null;
          return (
            <TaskToggle
              key={key}
              {...dataProps}
              icon={item.icon}
              type={item.type}
              title={item.title}
              pipesType={pipesType}
              avaliableTaskTypes={_get(avaliableTaskTypes, [
                0,
                "enabled_tasks"
              ])}
              taskClickHandler={taskClickHandler}
            />
          );
        } else {
          let disabled = disabledTasks && disabledTasks[item.type];
          let dataProps = {};
          if (disabled) {
            if (disabled.length > 1) {
              disabled = [disabled.join(";")];
            }
            dataProps = {
              "data-tip": disabled[0],
              "data-place": "left",
              "data-for": "mainTooltip"
            };
          }
          return (
            <li
              key={key}
              className={classnames(
                "topMenuTaskRow topMenuTaskItem",
                [item.type],
                {
                  disabled
                }
              )}
              onClick={disabled ? () => {} : () => taskClickHandler(item.type)}
              {...dataProps}
            >
              <span className={`topMenuTaskIcon ${item.icon}`}>
                <IconHander icon={item.icon} />
              </span>
              <h2 className="topMenuTaskText">{item.title}</h2>
            </li>
          );
        }
      })}
    </ul>
  );
};

import React from "react";
import { facilityIds, wellsIds } from "state/selectors/settings";
import { useSelector } from "react-redux";

export default ({ info }) => {
  const getFacilities = useSelector(facilityIds);
  const getWellsIds = useSelector(wellsIds);
  if (info || getFacilities || getWellsIds) return null;
  return (
    <div className="alert-row">
      <h2 className="alert-row__title">
        Выберите <span className="bold">«Кустовые площадки»</span> с
        заполненными профилями добычи
      </h2>
    </div>
  );
};

import React from "react";
import { connect } from "react-redux";
import {
  projectPalette,
  taskQuarries,
  taskObjects
} from "state/selectors/projects";
import { facilitiesOnMap } from "state/selectors/objects";
import {
  singleObj,
  chooseSinks,
  chooseSources,
  chooseQuarries,
  chooseFacility,
  chooseConsumers,
  chooseSingleFacility,
  chooseGatheringCenters,
  chooseWaterConsumersIds,
  choosePowerConsumersIds
} from "state/selectors/settings";
import { setQuarriesIds } from "state/actions/projects";
import {
  addTaskPoints,
  changeTaskSettings,
  setActiveFacilities
} from "state/actions/settings";

import FacilitiesContainer from "./FacilitiesContainer";

const FacilitiesRedux = ({ projectPalette, ...props }) => {
  if (!Object.keys(projectPalette).length) return null;
  return <FacilitiesContainer {...props} projectPalette={projectPalette} />;
};

const mapDispatchToProps = {
  addTaskPoints,
  setQuarriesIds,
  changeTaskSettings,
  setActiveFacilities
};

const mapStateToProps = state => ({
  singleObj: singleObj(state),
  taskObjects: taskObjects(state),
  chooseSinks: chooseSinks(state),
  taskQuarries: taskQuarries(state),
  chooseSources: chooseSources(state),
  chooseFacility: chooseFacility(state),
  chooseQuarries: chooseQuarries(state),
  projectPalette: projectPalette(state),
  chooseConsumers: chooseConsumers(state),
  facilitiesOnMap: facilitiesOnMap(state),
  choosePowerConsumersIds: choosePowerConsumersIds(state),
  chooseSingleFacility: chooseSingleFacility(state),
  chooseGatheringCenters: chooseGatheringCenters(state),
  chooseWaterConsumersIds: chooseWaterConsumersIds(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitiesRedux);

import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import SubLayer from "./SubLayer";
import ActiveTextComponent from "./ActiveTextComponent";
import ToggleListView from "./ToggleListView";

class List extends Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }

  onMouseEnterHandler = item => {
    const { onMouseEnter } = this.props;
    if (typeof onMouseEnter === "function") {
      return onMouseEnter(item);
    }
  };

  onMouseLeaveHandler = item => {
    const { onMouseLeave } = this.props;
    if (typeof onMouseLeave === "function") {
      return onMouseLeave(item);
    }
  };

  render() {
    const {
      data,
      active,
      rowClass,
      subLayer,
      afterComp,
      chooseRow,
      beforeComp,
      titleProps,
      beforeProp,
      activeText = "",
      onMouseEnter,
      onMouseLeave,
      containerClass,
      subLayerParent
    } = this.props;
    if (subLayer) {
      return Object.keys(subLayer).map((key, id) => {
        const listProps = {
          active,
          chooseRow,
          afterComp,
          beforeComp,
          onMouseEnter,
          onMouseLeave,
          beforeProp: subLayer[key].beforeProp,
          containerClass: `${containerClass} ${subLayer[key].containerClass ||
            "sublayer"}`
        };
        const titlePropsConfig = {
          onMouseEnter,
          onMouseLeave,
          itemKey: key,
          title: subLayer[key].title,
          afterComp: titleProps.afterComp,
          lastItemKey: titleProps.itemKey,
          titleClass: titleProps.titleClass,
          beforeProp: subLayer[key].beforeProp,
          containerClass: [
            titleProps.containerClass,
            subLayer[key].containerClass
          ],
          afterCompFunc: [
            () => titleProps.subAfterCompFunc(key, subLayerParent),
            subLayer[key]
          ],
          beforeComp: bgColor =>
            titleProps.beforeComp(bgColor, key, subLayerParent)
        };
        if (subLayer[key].subLayer) {
          listProps.titleProps = titleProps;
          listProps.subLayerParent = key;
          listProps.subLayer = subLayer[key].subLayer;
          listProps.withoutGroup = subLayer[key].values;
        } else {
          listProps.data = subLayer[key].values;
        }
        return (
          <SubLayer
            key={id}
            listProps={listProps}
            titleProps={titlePropsConfig}
          />
        );
      });
    }
    if (!data) return null;
    return data.map((item, key) => {
      let toggleText = item.name;
      if (activeText) {
        const re = new RegExp(activeText, "ig");
        const arr = item.name.split(re);
        toggleText = arr.map((text, key) => {
          return (
            <ActiveTextComponent
              key={key}
              index={key}
              text={text}
              item={item}
              arrLength={arr.length}
              coloredActiveText={activeText}
            />
          );
        });
      }
      return (
        <ToggleListView
          key={key}
          onMouseEnterHandler={this.onMouseEnterHandler}
          onMouseLeaveHandler={this.onMouseLeaveHandler}
          item={item}
          active={active}
          containerClass={containerClass}
          beforeComp={beforeComp}
          beforeProp={beforeProp}
          rowClass={rowClass}
          chooseRow={chooseRow}
          toggleText={toggleText}
          afterComp={afterComp}
        />
      );
    });
  }
}

List.propTypes = {
  data: PropTypes.array,
  active: PropTypes.func,
  subLayer: PropTypes.object,
  afterComp: PropTypes.func,
  chooseRow: PropTypes.func,
  beforeComp: PropTypes.func,
  titleProps: PropTypes.object,
  beforeProp: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  containerClass: PropTypes.string,
  subLayerParent: PropTypes.string
};

List.defaultProps = {
  data: null,
  active: null
};

export default List;

import { connect } from "react-redux";
import { downloadProject, clearState } from "state/actions/projects";
import { setRemoveProps, openRemoveAlert } from "state/actions/settings";
import { lastProjects, lastWellfields } from "state/selectors/common";

import ProjectsFirstPageContainer from "./ProjectsFirstPageContainer";

const mapStateToProps = state => ({
  projects: lastProjects(state),
  wellfields: lastWellfields(state)
});

const mapDispatchToProps = {
  clearState,
  setRemoveProps,
  openRemoveAlert,
  downloadProject
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsFirstPageContainer);

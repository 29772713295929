import React from "react";
import { connect } from "react-redux";
import { getTaskSettings } from "state/selectors/settings";

const BottomRow = ({ taskFacilities }) => {
  if (taskFacilities) return null;
  return (
    <div className="alert-row">
      <h2 className="alert-row__title">
        Выберите <span className="alert-row__title-bold">«Сток»</span> и{" "}
        <span className="alert-row__title-bold">«Истоки»</span> на карте
      </h2>
    </div>
  );
};

const mapStateToProps = state => ({
  taskFacilities: getTaskSettings(state).props
});

export default connect(mapStateToProps)(BottomRow);

import React from "react";
import { ReactComponent as WaitingIcon } from "../../../../assets/images/waiting.svg";

const ProjectInfoView = ({
  inaccessibleFields,
  tasksTitles,
  doneTasks,
  errorTasks
}) => {
  return (
    <div className="project-list__tooltip-sub-container">
      <div className="project-list__tooltip">
        <h3 className="project-list__tooltip-text without-data">
          Для просмотра подробной информации — выберите этот проект
        </h3>
        <h3 className="project-list__tooltip-title">Доступность задач</h3>
        <h4 className="project-list__tooltip-text">Задачи:</h4>
        {inaccessibleFields.length &&
          (inaccessibleFields.length === 12 ? (
            <h4 className="project-list__tooltip-text">
              Ни один из типов задач не доступен для расчета на выбранной схеме
              обустройства.
            </h4>
          ) : (
            <>
              <ul className="project-list__tooltip-list">
                {inaccessibleFields.map((el, key) => {
                  return (
                    <li key={key} className="project-list__tooltip-list-item">
                      {el}
                    </li>
                  );
                })}
              </ul>
              <h4 className="project-list__tooltip-text">
                не доступны на выбранной схеме обустройства.
              </h4>
            </>
          ))}
        {!inaccessibleFields.length && (
          <h4 className="project-list__tooltip-text">
            Все типы задач доступны для расчета.
          </h4>
        )}
      </div>
      {!!tasksTitles.length && (
        <div className="project-list__tooltip-bottom">
          <div className="project-list__tooltip-bottom-col">
            <h3 className="project-list__tooltip-title">Рассчитанные задачи</h3>
            <div>
              {tasksTitles.map((title, key) => {
                return (
                  <h4 key={key} className="project-list__tooltip-text">
                    {title}
                  </h4>
                );
              })}
            </div>
          </div>
          <div className="project-list__tooltip-bottom-col">
            <h3 className="project-list__tooltip-title">Успешно</h3>
            <div>
              {doneTasks.map((count, key) => {
                if (count === "waiting") {
                  return (
                    <div
                      key={key}
                      className="project-list__tooltip-text waiting"
                    >
                      <WaitingIcon />
                    </div>
                  );
                }
                return (
                  <h4 key={key} className="project-list__tooltip-text">
                    {count}
                  </h4>
                );
              })}
            </div>
          </div>
          <div className="project-list__tooltip-bottom-col">
            <h3 className="project-list__tooltip-title">С ошибкой</h3>
            <div>
              {errorTasks.map((count, key) => {
                if (count === "waiting") {
                  return (
                    <div
                      key={key}
                      className="project-list__tooltip-text waiting"
                    >
                      <WaitingIcon />
                    </div>
                  );
                }
                return (
                  <h4 key={key} className="project-list__tooltip-text">
                    {count}
                  </h4>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectInfoView;

import React from "react";
import MenuItem from "./MenuItem";

export default ({ showMenuHandler }) => (
  <>
    <div className="topMenu__opts topMenu__col topMenu__opts_sep-line">
      {["ruler", "timeline", "heatMap"].map((type, key) => (
        <MenuItem key={key} type={type} showMenuHandler={showMenuHandler} />
      ))}
    </div>
    <div className="topMenu__opts topMenu__col">
      <h2 className="top-menu-opt__title">Создать:</h2>
      {["objects", "linear", "areas", "tasks"].map((type, key) => (
        <MenuItem key={key} type={type} showMenuHandler={showMenuHandler} />
      ))}
    </div>
  </>
);

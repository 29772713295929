import React from "react";
import { connect } from "react-redux";
import { createArea, draggable, draw, repaint } from "state/selectors/settings";
import { setTransformSettings } from "state/actions/settings";
import AreaHandlersContainer from "./AreaHandlersContainer";

const AreaHandlersRedux = ({ createArea, ...props }) => {
  if (!createArea) return null;
  return <AreaHandlersContainer {...props} />;
};

const mapStateToProps = state => ({
  draw: draw(state),
  repaint: repaint(state),
  draggable: draggable(state),
  createArea: createArea(state)
});

const mapDispatchToProps = {
  setTransformSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaHandlersRedux);

import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import Spinner from "components/Spinner";
import RightMenu from "components/RightMenu";
import RemoveAlert from "components/Common/RemoveAlert";
import ReactTooltipContainer from "components/ReactTooltipContainer";
import Modal from "components/Common/Modal";
import TopMenu from "./TopMenu";
import ErrorsContainer from "./Errors";
import TasksSocket from "./TasksSocket";
import NewWellfieldSocket from "./NewWellfieldSocket";
import HandlersContainer from "../Handlers/HandlersContainer";
import ReactLeafletMap from "./ReactLeafletMap";

const CommonComponent = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    const changeLocation = () => {
      if (history.location.pathname.length === 1) {
        if (localStorage.jwtToken) {
          history.push("/projects");
        } else {
          history.push("/login");
        }
      }
    };
    changeLocation();
  }, [history.location.pathname]);
  return (
    <>
      <Modal />
      <TopMenu />
      <Spinner />
      <RightMenu />
      <TasksSocket />
      <RemoveAlert />
      <ReactLeafletMap />
      <ErrorsContainer />
      <HandlersContainer />
      <NewWellfieldSocket />
      <ReactTooltipContainer />
      {children}
    </>
  );
};

export default CommonComponent;

import React from "react";
import { connect } from "react-redux";
import { getVisibleAreas } from "state/selectors/objects";
import _isEqual from "lodash.isequal";
import AreasContainer from "./AreasContainer";

class AreasRedux extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { areas } = this.props;
    if (!_isEqual(nextProps.areas, areas)) {
      return true;
    }
    return false;
  }

  render() {
    const { areas } = this.props;
    if (!areas.length) return null;
    return <AreasContainer areas={areas} />;
  }
}

const mapStateToProps = state => ({
  areas: getVisibleAreas(state)
});

export default connect(mapStateToProps)(AreasRedux);

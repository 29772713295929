import React from "react";
import { DivIcon } from "leaflet";
import MarkerComponent from "./MarkerComponent";

export default ({
  points,
  iconSize,
  onMoveHandler,
  dashedLine,
  removePoint,
  dragendHandler,
  onClosePopup
}) => {
  const onDragstartHandler = () => {
    if (dashedLine) {
      dashedLine.current.leafletElement.setStyle({ opacity: 0 });
    }
  };
  const icon = new DivIcon({
    className: "ruler-marker",
    iconSize
  });
  return (
    <>
      {points.map((position, key) => (
        <MarkerComponent
          key={key}
          icon={icon}
          index={key}
          points={points}
          position={position}
          removePoint={removePoint}
          moveHandler={onMoveHandler}
          onClosePopup={onClosePopup}
          dragendHandler={dragendHandler}
          onDragstartHandler={onDragstartHandler}
        />
      ))}
    </>
  );
};

import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import TaskLogs from "../TaskLogs";
import CommonButtons from "../TaskResultsTopRow/CommonButtons";
import GatheringCentersResults from "./GatheringCentersResults";
import ApplyResultButtonContainer from "./ApplyResultButtonContainer";

export default ({ type }) => {
  const [handler, setHandler] = React.useState("logs");
  return (
    <div className="task-complete">
      <div className="task-complete__toggle-row">
        <CommonButtons currentHandler={handler} toggleHandler={setHandler} />
        <ApplyResultButtonContainer handler={handler} />
      </div>
      <div className="task-complete__main-container">
        <ScrollBar>
          {handler === "logs" ? (
            <TaskLogs />
          ) : (
            <GatheringCentersResults type={type} />
          )}
        </ScrollBar>
      </div>
    </div>
  );
};

import { call, put, fork, takeLatest, all } from "redux-saga/effects";
import { getRequest, deleteRequest } from "api";
import groupBy from "utils/groupBy";
import toObject from "utils/toObject";
import _mapValues from "lodash.mapvalues";
import _get from "lodash.get";
import FileSaver from "file-saver";

import {
  GET_LAYOUTS_REQUEST,
  REMOVE_WELLFIELD_REQUEST,
  DOWNLOAD_WELLFIELD_REQUEST,
  GET_WELLFIELD_INFO_REQUEST,
  PUT_DEMS_BY_WELLFIELD_REQUEST,
  GET_CLASSES_BY_METACLASS_REQUEST,
  GET_FACILITIES_BY_LAYOUTS_REQUEST
} from "state/constants/wellfield";

import {
  getLayouts,
  getClassesProps,
  putDemsByWellfield,
  putConnectionTypes,
  additionalTaskProps,
  getMetaclassesProps,
  getClassesByWellfield,
  getClassesByMetaclass,
  sortClassesByMetaclasses,
  sortMetaclassesByClasses,
  getMetaclassesByWellfield,
  setPrimaryFacilities,
  getFacilitiesByLayout,
  getWellfieldInfo
} from "state/actions/wellfield";

import {
  getWellfields,
  setInfoLogs,
  setSuccessLogs
} from "state/actions/common";

import { showLoader } from "state/actions/settings";
import service from "../service/wellfield";

const createWatch = (type, saga) =>
  function*() {
    yield takeLatest(type, saga);
  };

const getWellfieldInfoSaga = function*({ payload }) {
  const { wellfielId } = payload;
  try {
    const [
      {
        data: { data: metaclassesByWellfield }
      },
      {
        data: { data: metaclassProps }
      },
      {
        data: { data: classProps }
      },
      {
        data: { data: classesByWellfield }
      },
      {
        data: { data: demsByWellfield }
      },
      {
        data: { data: additionalTaskPropsData }
      },
      {
        data: { data: connectionTypesData }
      }
    ] = yield all([
      call(getRequest, service.metaclassesByWellfieldUrl(wellfielId)),
      call(getRequest, service.metaclassPropsUrl(wellfielId)),
      call(getRequest, service.classPropsUrl(wellfielId)),
      call(getRequest, service.classesByWellfieldUrl(wellfielId)),
      call(getRequest, service.demsByWellfield(wellfielId)),
      call(getRequest, service.addtitionalTaskProps(wellfielId)),
      call(getRequest, service.connectionTypes(wellfielId))
    ]);
    const connectionTypes = toObject(connectionTypesData, "id");
    const classPropsByClass = groupBy(classProps, "class_id");
    const metaclassesByKeys = toObject(metaclassesByWellfield, "id");
    const metaclassPropsByMetaclass = groupBy(metaclassProps, "metaclass_id");
    const classesSortedByMetaclass = groupBy(
      classesByWellfield,
      "metaclass_id"
    );
    const classesByMetaclasses = _mapValues(
      classesSortedByMetaclass,
      (item, key) => ({
        id: key,
        title:
          _get(metaclassesByKeys, [_get(item, [0, "metaclass_id"]), "name"]) ||
          "Без названия",
        valuesArray: item.map(({ id }) => id)
      })
    );
    const classesById = toObject(classesByWellfield, "id");
    const metaclassesByClasses = classesByWellfield.reduce(
      (acc = {}, { id, metaclass_id: metaclassId }) => {
        acc[id] = metaclassId;
        return acc;
      },
      {}
    );
    yield all([
      put(putDemsByWellfield("SUCCESS", demsByWellfield)),
      put(putConnectionTypes("SUCCESS", connectionTypes)),
      put(getClassesProps("SUCCESS", classPropsByClass)),
      put(
        getClassesByWellfield("SUCCESS", {
          classesByMetaclasses: classesSortedByMetaclass,
          classesByWellfield: classesById
        })
      ),
      put(additionalTaskProps("SUCCESS", additionalTaskPropsData[0])),
      put(getMetaclassesProps("SUCCESS", metaclassPropsByMetaclass)),
      put(sortClassesByMetaclasses("SUCCESS", classesByMetaclasses)),
      put(sortMetaclassesByClasses("SUCCESS", metaclassesByClasses)),
      put(getMetaclassesByWellfield("SUCCESS", metaclassesByWellfield))
    ]);
    yield put(getWellfieldInfo("SUCCESS"));
  } catch (error) {
    console.log(error);
    yield put(showLoader("SUCCESS", false));
  }
};

const getClassesByMetaclassSaga = function*({ payload }) {
  const url = service.classesByMetaclassUrl(payload);
  try {
    const { data } = yield call(getRequest, url);
    yield put(getClassesByMetaclass("SUCCESS", data.data));
  } catch (error) {
    yield put(getClassesByMetaclass("FAILURE", error));
  }
};

const putDemsByWellfieldSaga = function*({ payload }) {
  const url = service.demsByWellfield(payload);
  try {
    const { data } = yield call(getRequest, url);
    yield put(putDemsByWellfield("SUCCESS", data));
  } catch (error) {
    yield put(putDemsByWellfield("FAILURE", error));
  }
};

const getLayoutsSaga = function*({ payload }) {
  const url = service.layoutsByWellfield(payload);
  try {
    const { data } = yield call(getRequest, url);
    const layoutsByType = groupBy(data.data, "type");
    const layoutsByName = groupBy(data.data, "name");
    const { primary } = layoutsByType;
    yield put(getLayouts("SUCCESS", layoutsByName));
    if (primary) {
      const { id } = layoutsByType.primary[0];
      const { data } = yield call(getRequest, service.facilitiesByLayout(id));
      yield put(setPrimaryFacilities("SUCCESS", data));
    }
  } catch (error) {
    console.log(error);
  }
};

const getFacilitiesByLayoutSaga = function*({ payload }) {
  const url = service.facilitiesByLayout(payload);
  try {
    const { data } = yield call(getRequest, url);
    yield put(getFacilitiesByLayout("SUCCESS", data));
  } catch (err) {
    console.log(err);
  }
};

const removeWellfieldSaga = function*({ payload }) {
  const { id, name } = payload;
  const url = service.removeWellfield(id);
  try {
    yield put(
      setInfoLogs("SUCCESS", `Удаление месторождения «${name}» началось.`)
    );
    yield call(deleteRequest, url);
    yield put(
      setSuccessLogs("SUCCESS", `Удаление месторождения «${name}» закончилось.`)
    );
    yield put(getWellfields());
  } catch (err) {
    console.log(err);
  }
};

const downloadWellfieldSaga = function*({ payload }) {
  const { id, name } = payload;
  try {
    yield put(
      setInfoLogs("SUCCESS", `Загрузка месторождения «${name}» началась.`)
    );
    yield FileSaver.saveAs(service.downloadWellfield(id));
  } catch (err) {
    console.log(err);
  }
};

export default [
  createWatch(GET_LAYOUTS_REQUEST, getLayoutsSaga),
  createWatch(REMOVE_WELLFIELD_REQUEST, removeWellfieldSaga),
  createWatch(GET_WELLFIELD_INFO_REQUEST, getWellfieldInfoSaga),
  createWatch(DOWNLOAD_WELLFIELD_REQUEST, downloadWellfieldSaga),
  createWatch(PUT_DEMS_BY_WELLFIELD_REQUEST, putDemsByWellfieldSaga),
  createWatch(GET_CLASSES_BY_METACLASS_REQUEST, getClassesByMetaclassSaga),
  createWatch(GET_FACILITIES_BY_LAYOUTS_REQUEST, getFacilitiesByLayoutSaga)
].map(watcher => fork(watcher));

import React from "react";
import { Marker } from "react-leaflet";
import { useSelector } from "react-redux";
import { showRuler } from "state/selectors/settings";
import customIcon from "./customIcon";

const MarkerComponent = props => {
  const { id, icon, className } = props;
  const bindFunction = useSelector(showRuler);
  return (
    <Marker
      data={props.data}
      icon={customIcon({ icon, className })}
      zIndexOffset={9999}
      position={props.position}
      onMouseOut={props.onMouseOut}
      onMouseOver={() => props.onMouseOver({ id })}
      onClick={bindFunction ? null : props.onClickHandler}
    />
  );
};

export default MarkerComponent;

import React from "react";
import Button from "components/Common/Button";
import "../modal.scss";

const CancelConfirmationView = ({ closeHandler, onCancelConfirmedClick }) => (
  <>
    <div className="splash-screen" />
    <div className="remove-alert">
      <div className="modal-container new-wellfield__confirm">
        <div className="main-container__top-row modal-container__top-row">
          <h2 className="main-title">Прервать создание месторождения</h2>
          <div onClick={closeHandler} className="new-project__close">
            <div
              onClick={closeHandler}
              className="new-project__close-line close"
            />
          </div>
        </div>
        <div className="modal-content">
          <p className="new-wellfield__p new-wellfield__confirm__p new-wellfield__confirm__p__bottom">
            Вы действительно хотите прервать загрузку / проверку <br />
            файлов и выйти из режима создания месторождения?
          </p>
          <p className="new-wellfield__p new-wellfield__confirm__p">
            Все несохраненные изменения будут потеряны.
          </p>
        </div>
        <div className="card-bottom">
          <Button
            secondary
            onClick={onCancelConfirmedClick}
            className="remove-alert__button"
          >
            Да
          </Button>
          <Button secondary onClick={closeHandler}>
            Нет
          </Button>
        </div>
      </div>
    </div>
  </>
);
export default CancelConfirmationView;

import groupBy from "utils/groupBy";
import {
  ADD_TASK_SUCCESS,
  GET_TASK_SUCCESS,
  GET_LAYOUT_SUCCESS,
  SET_TASK_ID_SUCCESS,
  CLEAR_STATE_SUCCESS,
  CREATE_TASK_SUCCESS,
  GET_NETWORKS_SUCCESS,
  ACTIVE_NETWORK_SUCCESS,
  GET_TASKS_LOGS_SUCCESS,
  RENAME_PROJECT_SUCCESS,
  REMOVE_TASK_LOGS_SUCCESS,
  GET_TASK_RESULTS_SUCCESS,
  SET_QUARRIES_IDS_SUCCESS,
  SET_PROJECT_INFO_SUCCESS,
  GET_PROJECT_PALETTE_SUCCESS,
  SET_NEW_PROJECT_AREA_SUCCESS,
  SET_AVALIABLE_TASK_TYPES_SUCCESS,
  GET_METACLASSES_BY_WELLFIELD_AND_TYPE_SUCCESS
} from "../constants/projects";

const initialState = {
  projectName: "",
  tasks: [],
  taskLogs: {},
  tasksById: null,
  taskResult: [],
  tasksResults: {},
  avaliableTaskTypes: [],
  projectPalette: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LAYOUT_SUCCESS: {
      return {
        ...state,
        layout: action.payload
      };
    }
    case SET_AVALIABLE_TASK_TYPES_SUCCESS: {
      return {
        ...state,
        avaliableTaskTypes: action.payload
      };
    }
    case RENAME_PROJECT_SUCCESS: {
      return {
        ...state,
        projectName: action.payload
      };
    }
    case SET_QUARRIES_IDS_SUCCESS: {
      return {
        ...state,
        taskQuarries: action.payload
      };
    }
    case ACTIVE_NETWORK_SUCCESS: {
      return {
        ...state,
        activeNetwork: action.payload
      };
    }
    case GET_NETWORKS_SUCCESS: {
      return {
        ...state,
        networks: groupBy(action.payload.data, "id")
      };
    }
    case SET_TASK_ID_SUCCESS: {
      return {
        ...state,
        taskId: action.payload
      };
    }
    case REMOVE_TASK_LOGS_SUCCESS: {
      const taskLogs = { ...state.taskLogs };
      delete taskLogs[action.payload];
      return {
        ...state,
        taskLogs
      };
    }
    case GET_TASKS_LOGS_SUCCESS: {
      const taskLogs = { ...state.taskLogs };
      const grouppedByTaskId = groupBy(action.payload, "task_id");
      for (const taskIdKey in grouppedByTaskId) {
        if (state.taskLogs[taskIdKey]) {
          taskLogs[taskIdKey] = [
            ...taskLogs[taskIdKey],
            ...grouppedByTaskId[taskIdKey]
          ];
        } else {
          taskLogs[taskIdKey] = [...grouppedByTaskId[taskIdKey]];
        }
        taskLogs[taskIdKey] = [...taskLogs[taskIdKey]];
        taskLogs[taskIdKey].sort((a, b) => b.percentage - a.percentage);
      }
      return {
        ...state,
        taskLogs: {
          ...state.taskLogs,
          ...taskLogs
        }
      };
    }
    case GET_TASK_RESULTS_SUCCESS: {
      const data = action.payload?.data;
      const type = action.payload?.type;
      const taskResult = data;
      const taskObjects = {};
      if (taskResult) {
        taskResult.forEach(({ props }, index) => {
          if (props.network_id) {
            taskObjects.type = "linear";
            if (!taskObjects.data) {
              taskObjects.data = [props.network_id];
            } else {
              taskObjects.data.push(props.network_id);
            }
          }
          if (props.optimized_facility_id) {
            taskObjects.type = "optimizedFacility";
            taskObjects.data = [props.optimized_facility_id];
          }
          if (props.gathering_centers_ids) {
            taskObjects.type = "gatheringCenters";
            taskObjects.data = props.gathering_centers_ids;
          }
          if (props.water_gathering_centers_ids) {
            taskObjects.type = "waterGatheringCenters";
            taskObjects.data = props.water_gathering_centers_ids;
          }
          if (props.electricity_centers_ids) {
            taskObjects.type = "electricityCenters";
            taskObjects.data = props.electricity_centers_ids;
          }
          if (type === "high_voltage") {
            taskObjects.type = type;
          }
          const { task_id } = taskResult[index];
          if (state.tasksById[task_id]) {
            const { name } = state.tasksById[task_id][0];
            taskResult[index].task_name = name;
          }
        });
        return {
          ...state,
          taskObjects,
          taskResult
        };
      }
      return state;
    }
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.payload.data.id]: [action.payload.data]
        },
        tasks: [...state.tasks, action.payload.data]
      };
    case GET_TASK_SUCCESS:
      return {
        ...state,
        tasksById: groupBy(action.payload, "id"),
        tasks: action.payload
      };
    case SET_PROJECT_INFO_SUCCESS: {
      return {
        ...state,
        clear: false,
        ...action.payload
      };
    }
    case CLEAR_STATE_SUCCESS:
      return {
        ...initialState,
        clear: true
      };
    case GET_PROJECT_PALETTE_SUCCESS:
      return {
        ...state,
        projectPalette: action.payload
      };
    case SET_NEW_PROJECT_AREA_SUCCESS:
      return {
        ...state,
        setNewProjectArea: action.payload
      };
    case GET_METACLASSES_BY_WELLFIELD_AND_TYPE_SUCCESS:
      return {
        ...state,
        metaclassesByWellfieldAndType: action.payload
      };
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        createTask: action.payload
      };
    default:
      return state;
  }
}

import React from "react";

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M8.5 16C8.07841 16 7.67409 15.8301 7.37599 15.5276C7.07788 15.2251 6.9104 14.8149 6.9104 14.3871C6.9104 13.9593 7.07788 13.5491 7.37599 13.2466C7.67409 12.9441 8.07841 12.7742 8.5 12.7742C8.92159 12.7742 9.32591 12.9441 9.62401 13.2466C9.92212 13.5491 10.0896 13.9593 10.0896 14.3871C10.0896 14.8149 9.92212 15.2251 9.62401 15.5276C9.32591 15.8301 8.92159 16 8.5 16ZM9.2948 11.1613H7.7052V3.09677L4.12861 6.72581L3 5.58065L8.5 0L14 5.58065L12.8714 6.72581L9.2948 3.09677V11.1613Z"
      fill="#8495AC"
    />
  </svg>
);

import { taskConstants } from "utils/constants";

const firstButtonTitleHandler = type => {
  const {
    ROADS,
    HVL,
    QUARRIES,
    LINEAR_COST,
    FACILITY,
    PIPES,
    GATHERING_CENTERS,
    WATER_PUMPING_STATIONS,
    POWER_SUPPLY_CENTERS
  } = taskConstants;
  if (PIPES.includes(type)) {
    return "Запустить";
  }
  switch (type) {
    case HVL:
    case ROADS:
    case FACILITY:
    case QUARRIES:
    case LINEAR_COST:
    case GATHERING_CENTERS:
    case WATER_PUMPING_STATIONS:
    case POWER_SUPPLY_CENTERS:
      return "Запустить";
    case "createArea":
    case "createFacility":
    case "createLinearObject":
      return "Создать";
    default:
      return "Сохранить";
  }
};

export default firstButtonTitleHandler;

import React from "react";
import { AreaChart, Area } from "recharts";
import CustomizedActiveDot from "../CustomizedActiveDot";

export default ({ width, height, plotData, chartStyles, children }) => {
  return (
    <AreaChart
      height={height}
      width={width}
      data={plotData}
      margin={chartStyles}
    >
      {children}
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#FFAFB3" stopOpacity={1} />
          <stop offset="18.23%" stopColor="#FFCBAF" stopOpacity={1} />
          <stop offset="39.23%" stopColor="#FFE3AF" stopOpacity={1} />
          <stop offset="59.67%" stopColor="#F7FFAF" stopOpacity={1} />
          <stop offset="80.66%" stopColor="#CBFEAF" stopOpacity={1} />
          <stop offset="100%" stopColor="#BDFFAF" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Area
        dataKey="y"
        type="monotone"
        strokeWidth="3"
        fillOpacity={1}
        stroke="#FFBA56"
        fill="url(#colorUv)"
        activeDot={<CustomizedActiveDot strokeWidth={1.8} r={4.5} />}
      />
    </AreaChart>
  );
};

import React from "react";

export default function LinearIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 4.66665C14.6667 5.39998 14.0667 5.99998 13.3333 5.99998C13.2133 5.99998 13.1 5.98665 12.9933 5.95331L11.2867 8.98665C11.32 9.09331 11.3333 9.21331 11.3333 9.33331C11.3333 10.0666 10.7333 10.6666 10 10.6666C9.26668 10.6666 8.66668 10.0666 8.66668 9.33331C8.66668 9.21331 8.68001 9.09331 8.71334 8.98665L7.01334 7.28665C6.90668 7.31998 6.78668 7.33331 6.66668 7.33331C6.54668 7.33331 6.42668 7.31998 6.32001 7.28665L3.95334 10.9933C3.98668 11.1 4.00001 11.2133 4.00001 11.3333C4.00001 12.0666 3.40001 12.6666 2.66668 12.6666C1.93334 12.6666 1.33334 12.0666 1.33334 11.3333C1.33334 10.6 1.93334 9.99998 2.66668 9.99998C2.78668 9.99998 2.90001 10.0133 3.00668 10.0466L5.38001 6.34665C5.34668 6.23998 5.33334 6.11998 5.33334 5.99998C5.33334 5.26665 5.93334 4.66665 6.66668 4.66665C7.40001 4.66665 8.00001 5.26665 8.00001 5.99998C8.00001 6.11998 7.98668 6.23998 7.95334 6.34665L9.65334 8.04665C9.76001 8.01331 9.88001 7.99998 10 7.99998C10.12 7.99998 10.24 8.01331 10.3467 8.04665L12.0467 5.00665C12.0133 4.89998 12 4.78665 12 4.66665C12 3.93331 12.6 3.33331 13.3333 3.33331C14.0667 3.33331 14.6667 3.93331 14.6667 4.66665Z"
        fill="#8495AC"
      />
    </svg>
  );
}

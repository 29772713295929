import React from "react";

const TaskRowSubTitle = ({ subTitle, value }) => {
  return (
    <>
      <h3 className="task-results__sub-title task-result__linear-title">
        {subTitle}
        <span className="task-result__linear-value task-results__props-value">
          {value}
        </span>
      </h3>
    </>
  );
};

export default TaskRowSubTitle;

import { action } from "utils";

import {
  SET_ERROR,
  GET_PROJECTS,
  SET_INFO_LOGS,
  GET_WELLFIELD,
  SEND_WELLFIELD,
  GET_COMMON_INFO,
  SET_SUCCESS_LOGS,
  SET_NEW_WELLFIELD_LOGS,
  CANCEL_WELLFIELD_SENDING,
  CANCEL_WELLFIELD_CHECKING,
  SET_NEW_WELLFIELD_TASK_ID,
  UPDATE_UPLOAD_PROGRESS,
  REMOVE_NEW_WELLFIELD_LOGS,
  GET_LAST_PROJECTS_AND_WELLFIELDS,
  CLEAR_CANCELING_FLAGS
} from "../constants/common";

export const setError = action(SET_ERROR);
export const getProjects = action(GET_PROJECTS);
export const setInfoLogs = action(SET_INFO_LOGS);
export const getWellfields = action(GET_WELLFIELD);
export const sendWellfield = action(SEND_WELLFIELD);
export const getCommonInfo = action(GET_COMMON_INFO);
export const setSuccessLogs = action(SET_SUCCESS_LOGS);
export const setNewWellfieldLogs = action(SET_NEW_WELLFIELD_LOGS);
export const setNewWellfieldTaskId = action(SET_NEW_WELLFIELD_TASK_ID);
export const updateUploadProgress = action(UPDATE_UPLOAD_PROGRESS);
export const cancelWellfieldSending = action(CANCEL_WELLFIELD_SENDING);
export const cancelWellfieldChecking = action(CANCEL_WELLFIELD_CHECKING);
export const clearCancelingFlags = action(CLEAR_CANCELING_FLAGS);
export const removeNewWellfieldLogs = action(REMOVE_NEW_WELLFIELD_LOGS);
export const getLastProjectsAndWellfields = action(
  GET_LAST_PROJECTS_AND_WELLFIELDS
);

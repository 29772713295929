import React, { Component } from "react";
import { connect } from "react-redux";
import { setOpenedList } from "state/actions/groupsSettings";
import { getOppenedList } from "state/selectors/groupsSettings";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import ToggleList from "./ToggleList";
import ToggleTitle from "./ToggleTitle";
import "./style.scss";

class ToggleContainer extends Component {
  state = { showList: false };

  static getDerivedStateFromProps(nextProps) {
    const { titleProps } = nextProps;
    const { openList } = titleProps;
    if (openList) {
      return {
        showList: true
      };
    }
    return null;
  }

  componentDidMount() {
    const { getOppenedList, titleProps } = this.props;
    const { itemKey, openList } = titleProps;
    if (getOppenedList.includes(itemKey) || openList) {
      this.setState({ showList: true });
    }
    ReactTooltip.rebuild();
  }

  showListHandler = () => {
    const { updated, setOpenedList } = this.props;
    const { showList } = this.state;
    this.setState({ showList: !showList }, () => {
      const { titleProps } = this.props;
      if (titleProps?.itemKey) {
        setOpenedList("SUCCESS", titleProps?.itemKey);
      }
      if (typeof updated === "function") {
        updated();
      }
    });
  };

  render() {
    const { showList } = this.state;
    const { titleProps, listProps } = this.props;
    return (
      <div className="open-projects-main-row">
        <ToggleTitle
          {...titleProps}
          showList={showList}
          showListHandler={this.showListHandler}
        />
        {showList && (
          <ul>
            <ToggleList {...listProps} titleProps={titleProps} />
          </ul>
        )}
      </div>
    );
  }
}

ToggleContainer.propTypes = {
  /**
   * @param  {string} title
   * @param  {function} itemKey
   * @param  {boolean} showList
   * @param  {function} afterComp
   * @param  {function} beforeComp
   * @param  {function} onMouseEnter
   * @param  {function} onMouseLeave
   * @param  {function} afterCompFunc
   * @param  {string} containerClass
   */
  titleProps: PropTypes.object.isRequired,
  /**
   * @param  {array} title
   * @param  {function} active
   * @param  {object} subLayer
   * @param  {function} afterComp
   * @param  {function} chooseRow
   * @param  {function} beforeComp
   * @param  {object} titleProps
   * @param  {string} beforeProp
   * @param  {function} onMouseEnter
   * @param  {function} onMouseLeave
   * @param  {string} containerClass
   * @param  {string} subLayerParent
   */
  listProps: PropTypes.object.isRequired
};

/**
 * { key: {
 *   beforeProp: string,
 *   title: string,
 *   values: [],
 *   subLayer: {
 *    key: {
 *      title: string,
 *      beforeProp: string,
 *      values: [],
 *      subLayer: {
 *        key: {
 *          beforeProp: string,
 *          containerClass: string,
 *          title: string,
 *          networkId: {},
 *          taskId,
 *          values: []
 *          }
 *        }
 *      }
 *    }
 *  }
 * }
 */

const mapStateToProps = state => ({ getOppenedList: getOppenedList(state) });

const mapDispatchToProps = {
  setOpenedList
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleContainer);

import React from "react";
import { connect } from "react-redux";
import { chooseLinearObject } from "state/selectors/settings";
import { getVisibleLinears } from "state/selectors/objects";
import {
  changeTaskSettings,
  setCardInfo,
  activeTab
} from "state/actions/settings";
import _isEqual from "lodash.isequal";
import LinearContainer from "./LinearContainer";

class LinearRedux extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { chooseLinearObject, getVisibleLinears } = this.props;
    if (
      chooseLinearObject !== nextProps.chooseLinearObject ||
      !_isEqual(getVisibleLinears, nextProps.getVisibleLinears)
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      activeTab,
      setCardInfo,
      changeTaskSettings,
      getVisibleLinears,
      chooseLinearObject
    } = this.props;
    if (!Object.keys(getVisibleLinears).length) return null;
    return (
      <LinearContainer
        activeTab={activeTab}
        setCardInfo={setCardInfo}
        getVisibleLinears={getVisibleLinears}
        changeTaskSettings={changeTaskSettings}
        chooseLinearObject={chooseLinearObject}
      />
    );
  }
}

const mapStateToProps = state => ({
  getVisibleLinears: getVisibleLinears(state),
  chooseLinearObject: chooseLinearObject(state)
});

const mapDispatchToProps = {
  changeTaskSettings,
  setCardInfo,
  activeTab
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearRedux);

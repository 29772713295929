export const SET_PROJECT_INFO = "SET_PROJECT_INFO";
export const SEND_FACILITY = "SEND_FACILITY";
export const GET_METACLASSES_BY_WELLFIELD_AND_TYPE =
  "GET_METACLASSES_BY_WELLFIELD_AND_TYPE";
export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";
export const CLEAR_STATE = "CLEAR_STATE";
export const ADD_TASK = "ADD_TASK";

export const SEND_PROJECT = "SEND_PROJECT";
export const SET_NEW_PROJECT_AREA = "SET_NEW_PROJECT_AREA";
export const GET_PROJECT_PALETTE = "GET_PROJECT_PALETTE";
export const REMOVE_METACLASS = "REMOVE_METACLASS";
export const SET_NEW_PO_POSITION = "SET_NEW_PO_POSITION";
export const GET_TASK_RESULTS = "GET_TASK_RESULTS";
export const SEND_NETWORK = "SEND_NETWORK";
export const REMOVE_METACLASS_BY_TYPE = "REMOVE_METACLASS_BY_TYPE";
export const REMOVE_TASK = "REMOVE_TASK";
export const GET_TASKS_LOGS = "GET_TASKS_LOGS";
export const SET_TASK_ID = "SET_TASK_ID";
export const GET_NETWORKS = "GET_NETWORKS";
export const SET_QUARRIES_IDS = "SET_QUARRIES_IDS";
export const REMOVE_OBJECT = "REMOVE_OBJECT";
export const REMOVE_CLASS = "REMOVE_CLASS";
export const REMOVE_NETWORK = "REMOVE_NETWORK";
export const GET_CALCULATED_OBJECTS = "GET_CALCULATED_OBJECTS";
export const DOWNLOAD_PROJECT = "DOWNLOAD_PROJECT";
export const GET_TASK = "GET_TASK";
export const REPEAT_TASK = "REPEAT_TASK";
export const GET_PREVIEW_PROJECT_PROPS = "GET_PREVIEW_PROJECT_PROPS";
export const GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE =
  "GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE";
export const DOWNLOAD_TASK = "DOWNLOAD_TASK";
export const RENAME_PROJECT = "RENAME_PROJECT";
export const SET_AVALIABLE_TASK_TYPES = "SET_AVALIABLE_TASK_TYPES";
export const REMOVE_TASK_LOGS = "REMOVE_TASK_LOGS";
export const GET_LAYOUT = "GET_LAYOUT";
// REQUEST
export const RENAME_PROJECT_REQUEST = "RENAME_PROJECT_REQUEST";
export const DOWNLOAD_TASK_REQUEST = "DOWNLOAD_TASK_REQUEST";
export const GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_REQUEST =
  "GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_REQUEST";
export const GET_PREVIEW_PROJECT_PROPS_REQUEST =
  "GET_PREVIEW_PROJECT_PROPS_REQUEST";
export const REPEAT_TASK_REQUEST = "REPEAT_TASK_REQUEST";
export const DOWNLOAD_PROJECT_REQUEST = "DOWNLOAD_PROJECT_REQUEST";
export const REMOVE_OBJECT_REQUEST = "REMOVE_OBJECT_REQUEST";
export const GET_CALCULATED_OBJECTS_REQUEST = "GET_CALCULATED_OBJECTS_REQUEST";
export const GET_PROJECT_BY_ID_REQUEST = "GET_PROJECT_BY_ID_REQUEST";
export const REMOVE_TASK_REQUEST = "REMOVE_TASK_REQUEST";
export const SEND_NETWORK_REQUEST = "SEND_NETWORK_REQUEST";
export const GET_TASK_RESULTS_REQUEST = "GET_TASK_RESULTS_REQUEST";
export const CREATE_OBJECT_REQUEST = "CREATE_OBJECT_REQUEST";
export const SET_NEW_PO_POSITION_REQUEST = "SET_NEW_PO_POSITION_REQUEST";
export const GET_PROJECT_PALETTE_REQUEST = "GET_PROJECT_PALETTE_REQUEST";
export const SET_PROJECT_VISITING_REQUEST = "SET_PROJECT_VISITING_REQUEST";
export const CREATE_TASK_REQUEST = "CREATE_TASK_REQUEST";
export const REMOVE_PROJECT_REQUEST = "REMOVE_PROJECT_REQUEST";
export const GET_TASKS_REQUEST = "GET_TASKS_REQUEST";
export const SEND_PROJECT_REQUEST = "SEND_PROJECT_REQUEST";
export const SEND_FACILITY_REQUEST = "SEND_FACILITY_REQUEST";
// SUCCESS
export const GET_LAYOUT_SUCCESS = "GET_LAYOUT_SUCCESS";
export const REMOVE_TASK_LOGS_SUCCESS = "REMOVE_TASK_LOGS_SUCCESS";
export const SET_AVALIABLE_TASK_TYPES_SUCCESS =
  "SET_AVALIABLE_TASK_TYPES_SUCCESS";
export const RENAME_PROJECT_SUCCESS = "RENAME_PROJECT_SUCCESS";
export const GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_SUCCESS =
  "GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_SUCCESS";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const REMOVE_NETWORK_SUCCESS = "REMOVE_NETWORK_SUCCESS";
export const REMOVE_CLASS_SUCCESS = "REMOVE_CLASS_SUCCESS";
export const SET_PROJECT_INFO_SUCCESS = "SET_PROJECT_INFO_SUCCESS";
export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
export const SET_QUARRIES_IDS_SUCCESS = "SET_QUARRIES_IDS_SUCCESS";
export const GET_NETWORKS_SUCCESS = "GET_NETWORKS_SUCCESS";
export const SET_TASK_ID_SUCCESS = "SET_TASK_ID_SUCCESS";
export const GET_TASKS_LOGS_SUCCESS = "GET_TASKS_LOGS_SUCCESS";
export const REMOVE_TASK_SUCCESS = "REMOVE_TASK_SUCCESS";
export const REMOVE_METACLASS_BY_TYPE_SUCCESS =
  "REMOVE_METACLASS_BY_TYPE_SUCCESS";
export const GET_TASK_RESULTS_SUCCESS = "GET_TASK_RESULTS_SUCCESS";
export const REMOVE_METACLASS_SUCCESS = "REMOVE_METACLASS_SUCCESS";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const GET_METACLASSES_BY_WELLFIELD_AND_TYPE_SUCCESS =
  "GET_METACLASSES_BY_WELLFIELD_AND_TYPE_SUCCESS";
export const GET_PROJECT_PALETTE_SUCCESS = "GET_PROJECT_PALETTE_SUCCESS";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const SET_NEW_PROJECT_AREA_SUCCESS = "SET_NEW_PROJECT_AREA_SUCCESS";

export const CLEAR_STATE_SUCCESS = "CLEAR_STATE_SUCCESS";
export const ACTIVE_NETWORK_SUCCESS = "ACTIVE_NETWORK_SUCCESS";

/* eslint-disable no-param-reassign */
import React from "react";
import classnames from "classnames";
import { ReactComponent as InfoAlertIcon } from "assets/images/infoAlert.svg";
import ReactTooltip from "react-tooltip";
import DownloadButton from "./DownloadButton";
import RemoveButton from "./RemoveButton";

const ListItemComponent = ({
  download,
  remove,
  isActive,
  toggleText,
  chooseRowHandler,
  iconProps
}) => {
  setTimeout(() => {
    ReactTooltip.rebuild();
  }, 100);

  return (
    <li
      className={classnames("row-toggle row-toggle-project", {
        active: isActive
      })}
    >
      <button onClick={chooseRowHandler} className="row-toggle__text">
        {toggleText}
      </button>
      <span className="common-component-handlers">
        <span {...iconProps}>
          <InfoAlertIcon />
        </span>
        <DownloadButton download={download} />
        <RemoveButton remove={remove} />
      </span>
    </li>
  );
};

class ListItem extends React.Component {
  onMouseEnter = e => {
    const { tooltipRef, isActive } = this.props;
    const { x, y, height } = e.target.getBoundingClientRect();
    tooltipRef.current.style.display = "block";
    const { clientHeight } = tooltipRef.current;
    tooltipRef.current.style.left = `${x}px`;
    if (!isActive) {
      if (!tooltipRef.current.classList.contains("without-data")) {
        tooltipRef.current.classList.add("without-data");
      }
    } else {
      tooltipRef.current.classList.remove("without-data");
    }
    if (y > clientHeight) {
      tooltipRef.current.style.top = `${y - clientHeight - 8}px`;
      tooltipRef.current.classList.add("mode-bottom");
      tooltipRef.current.classList.remove("mode-top");
    } else {
      tooltipRef.current.style.top = `${y + height + 7}px`;
      tooltipRef.current.classList.remove("mode-bottom");
      tooltipRef.current.classList.add("mode-top");
    }
  };

  onMouseLeave = () => {
    const { tooltipRef } = this.props;
    tooltipRef.current.style.display = "none";
  };

  render() {
    const {
      isActive,
      chooseRowHandler,
      toggleText,
      download,
      remove
    } = this.props;
    let iconProps = {
      className: "info-alert-icon project-info"
    };
    if (isActive) {
      iconProps = {
        onMouseLeave: this.onMouseLeave,
        onMouseEnter: this.onMouseEnter,
        "data-class": "hide",
        ...iconProps
      };
    } else {
      iconProps = {
        "data-for": "mainTooltip",
        "data-tip": "Для просмотра подробной информации — выберите этот проект",
        ...iconProps
      };
    }
    return (
      <ListItemComponent
        download={download}
        remove={remove}
        isActive={isActive}
        toggleText={toggleText}
        chooseRowHandler={chooseRowHandler}
        iconProps={iconProps}
      />
    );
  }
}

export default ListItem;

import React from "react";
import { connect } from "react-redux";
import { getTaskSettings } from "state/selectors/settings";

const BottomRow = ({ taskFacilities = [] }) => {
  if (taskFacilities.length) return null;
  return (
    <div className="alert-row">
      <h2 className="alert-row__title">
        Выберите <span className="alert-row__title-bold">«Объекты»</span> для
        дорожной сети на карте
      </h2>
    </div>
  );
};

const mapStateToProps = state => ({
  taskFacilities: getTaskSettings(state).props.facilities_ids
});

export default connect(mapStateToProps)(BottomRow);

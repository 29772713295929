import React from "react";
import { connect } from "react-redux";
import { cardInfo } from "state/selectors/settings";
import { taskId, getTasksById } from "state/selectors/projects";

import { taskConstants } from "utils/constants";

import Hvl from "./Hvl";
import Linear from "./Linear";
import Roads from "./Roads";
import Pipes from "./Pipes";
import GatheringCenters from "./GatheringCenters";
import FacilityTaskResult from "./FacilityTaskResult";
import Quarries from "./Quarries";
import LinearCost from "./LinearCost";

const {
  HVL,
  ROADS,
  PIPES,
  LINEAR,
  FACILITY,
  QUARRIES,
  LINEAR_COST,
  GATHERING_CENTERS,
  POWER_SUPPLY_CENTERS,
  WATER_PUMPING_STATIONS
} = taskConstants;

const TaskResultsComponent = ({ type }) => {
  if (
    [GATHERING_CENTERS, WATER_PUMPING_STATIONS, POWER_SUPPLY_CENTERS].includes(
      type
    )
  ) {
    return <GatheringCenters type={type} />;
  }
  if (PIPES.includes(type)) {
    return <Pipes />;
  }
  if (type === FACILITY) {
    return <FacilityTaskResult />;
  }
  if (type === ROADS) {
    return <Roads />;
  }
  if (type === LINEAR) {
    return <Linear />;
  }
  if (type === HVL) {
    return <Hvl />;
  }
  if (type === LINEAR_COST) {
    return <LinearCost />;
  }
  if (type === QUARRIES) {
    return <Quarries />;
  }
  return null;
};

const MainContainer = props => {
  const [type, setType] = React.useState("");
  React.useEffect(() => {
    const { getTasksById, cardInfo, taskId } = props;
    if (taskId) {
      if (taskId) {
        const { type } = getTasksById[taskId][0];
        setType(type);
      } else if (cardInfo) {
        const { type } = cardInfo;
        setType(type);
      }
    } else {
      const { type } = cardInfo;
      setType(type);
    }
  }, [props]);
  if (!type) return null;
  return <TaskResultsComponent type={type} />;
};

const mapStateToProps = state => ({
  taskId: taskId(state),
  cardInfo: cardInfo(state),
  getTasksById: getTasksById(state)
});

export default connect(mapStateToProps)(MainContainer);

import React, { Component } from "react";
import classnames from "classnames";

import ReactTooltip from "react-tooltip";
import PipeRows from "./PipeRows";
import IconHandler from "./IconHander";

export default class extends Component {
  state = { show: false };

  showHandler = () => {
    this.setState({
      show: !this.state.show
    });
  };

  componentDidMount() {
    ReactTooltip.rebuild();
  }

  render() {
    const { show } = this.state;
    const { pipesType, taskClickHandler, avaliableTaskTypes } = this.props;
    if (!pipesType) return null;
    const pipesTypes = pipesType.map(({ id }) => id);
    const allDisabled = !pipesTypes.some(el => avaliableTaskTypes.includes(el));
    let dataProps = {};
    const initalDataProps = {
      "data-tip": "Не хватает данных для расчета",
      "data-place": "left",
      "data-for": "mainTooltip"
    };
    if (allDisabled) {
      dataProps = initalDataProps;
    }
    return (
      <li
        {...dataProps}
        className={classnames("relative topMenuTaskItem", [this.props.icon], {
          disabled: allDisabled
        })}
      >
        <div
          className={classnames("topMenuTaskRow", { active: show })}
          onClick={allDisabled ? () => {} : this.showHandler}
        >
          <span className={`topMenuTaskIcon ${this.props.icon}`}>
            <IconHandler icon={this.props.icon} />
          </span>
          <h2 className="topMenuTaskText">{this.props.title}</h2>
        </div>
        {show && (
          <div className="task-toggle-container">
            <h2 className="task-toggle-container__title">
              Выберите тип трубопровода:
            </h2>
            <ul>
              {pipesType.map(({ id, name }, index) => (
                <PipeRows
                  id={id}
                  key={index}
                  name={name}
                  initalDataProps={initalDataProps}
                  taskClickHandler={taskClickHandler}
                  avaliableTaskTypes={avaliableTaskTypes}
                />
              ))}
            </ul>
          </div>
        )}
      </li>
    );
  }
}

import React from "react";

export default ({ onClickHandler, type, title }) => {
  const onClick = () => onClickHandler(type);
  return (
    <button onClick={onClick} className="start-page__button">
      {title}
    </button>
  );
};

import React from "react";
import MenuButton from "./MenuButton";

export default ({
  createProject,
  openProject,
  saveProject,
  downloadProjectHandler,
  removeProjectHandler,
  openFirstPage,
  showModal
}) => {
  return (
    <div className="menu__container">
      <div className="menu__triangle" />
      <ul>
        <li className="menu__row">
          <MenuButton onClick={createProject}>Создать...</MenuButton>
          <MenuButton onClick={openProject}>Открыть...</MenuButton>
        </li>
        <li className="menu__row">
          <MenuButton onClick={saveProject}>Сохранить</MenuButton>
          <MenuButton>Дублировать...</MenuButton>
          <MenuButton onClick={downloadProjectHandler}>Скачать</MenuButton>
          <MenuButton onClick={removeProjectHandler}>Удалить</MenuButton>
          <MenuButton onClick={openFirstPage}>Закрыть проект</MenuButton>
        </li>
        <li className="menu__row">
          <MenuButton onClick={showModal}>О проекте</MenuButton>
        </li>
      </ul>
    </div>
  );
};

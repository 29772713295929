import _get from "lodash.get";
import handleTitle from "../../LeftMenu/List/utils/handleTitle";

export const handleResult = finished => (finished ? "done" : "process");

export const poTypes = [
  {
    id: "facility_placement",
    name: "Оптимизация расположения ПО по стоимости инж. подготовки"
  },
  { id: "gathering_centers", name: "Расчет расположения центров сбора" },
  { id: "water_pumping_stations", name: "Расчет расположения БКНС" },
  { id: "power_supply_centers", name: "Расчет расположения ЦЭ" }
];

export const reduceArr = (acc = {}, { id: type, name: title }) => {
  acc[type] = title;
  return acc;
};

export const reduceTaskConfig = (acc = {}, { title, type }) => {
  acc[type] = title;
  return acc;
};

export const transformTasks = taskLogs => (acc, curr, id) => {
  const { type } = curr[0];
  let result;
  if (taskLogs && taskLogs[id] && taskLogs[id][0]) {
    const { finished, has_errors: hasErrors } = taskLogs[id][0];
    result = hasErrors ? "error" : handleResult(finished);
  }
  if (!acc[type]) {
    acc[type] = {
      title: handleTitle(type),
      [result]: {
        count: 1
      }
    };
  } else if (acc[type] && acc[type][result]) {
    acc[type][result].count += 1;
  } else {
    acc[type][result] = { count: 1 };
  }
};

export const handleInaccessible = fields => el => fields[el];

export const filterPipesType = inaccessibleTasks => ({ id }) =>
  !Object.keys(inaccessibleTasks).includes(id);

export const filterInaccessible = el =>
  !(el.includes("pipe") || el.includes("oil_gathering_line"));

export const transformProjects = wellfields => (acc, curr, key) => {
  acc.push({
    title: _get(wellfields, [key, "name"]),
    data: curr
  });
};

import React from "react";

export default ({ openCardHandler, id }) => {
  const clickHandler = () => openCardHandler(id);
  return (
    <button onClick={clickHandler} className="to-card-button">
      Посмотреть свойства
    </button>
  );
};

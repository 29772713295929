import React from "react";

import ScrollBar from "components/Common/ScrollBar";
import Roads from "../TaskData/Roads";
import Quarries from "../TaskData/Quarries";
import LinearCost from "../TaskData/LinearCost";
import Facility from "../TaskData/Facility";
import Pipes from "../TaskData/Pipes";
import Hvl from "../TaskData/Hvl";
import GatheringCenters from "../TaskData/GatheringCenters";
import WaterPumpingStations from "../TaskData/WaterPumpingStations";
import PowerSupplyCenters from "../TaskData/PowerSupplyCenters";

import FacilityObj from "../ObjectsCard/Facility";
import AreaObj from "../ObjectsCard/Area";
import LinearObj from "../ObjectsCard/Linear";

export default class CardsFactory {
  static withProps(Component, taskInfo) {
    return (
      <ScrollBar>
        <Component info taskInfo={taskInfo} />
      </ScrollBar>
    );
  }

  static build(type, taskInfo) {
    switch (type) {
      case "area":
        return CardsFactory.withProps(AreaObj, taskInfo);
      case "linear":
        return CardsFactory.withProps(LinearObj, taskInfo);
      case "facility":
        return CardsFactory.withProps(FacilityObj, taskInfo);
      case "linear_cost":
        return CardsFactory.withProps(LinearCost, taskInfo);
      case "facility_placement":
        return CardsFactory.withProps(Facility, taskInfo);
      case "roads":
        return CardsFactory.withProps(Roads, taskInfo);
      case "high_voltage":
        return CardsFactory.withProps(Hvl, taskInfo);
      case "oil_pipe":
      case "oil_gathering_line":
      case "low_pressure_gas_pipe":
      case "high_pressure_gas_pipe":
      case "low_pressure_water_pipe":
      case "high_pressure_water_pipe":
        return CardsFactory.withProps(Pipes, taskInfo);
      case "quarries":
        return CardsFactory.withProps(Quarries, taskInfo);
      case "power_supply_centers":
        return CardsFactory.withProps(PowerSupplyCenters, taskInfo);
      case "water_pumping_stations":
        return CardsFactory.withProps(WaterPumpingStations, taskInfo);
      case "gathering_centers":
        return CardsFactory.withProps(GatheringCenters, taskInfo);
      default:
        return null;
    }
  }
}

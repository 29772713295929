import React, { Component } from "react";
import { connect } from "react-redux";
import { getDemsByWellfield } from "state/selectors/wellfield";
import { setTaskSettings } from "state/actions/settings";
import _isEqual from "lodash.isequal";
import CommonField from "../CommonField";

export const commonFieldsNames = [
  "name",
  "dem_id",
  "priority",
  "amount_of_solutions",
  "graph_edge_length_m"
];

const handleData = ({
  name,
  info,
  priority,
  options,
  amount_of_solutions,
  graph_edge_length_m,
  dem_id
}) => [
  {
    propName: "name",
    placeholder: "",
    required: true,
    defaultValue: name,
    classContainer: "wide",
    label: "Название задачи",
    disabled: info
  },
  [
    {
      propName: "priority",
      type: "option",
      placeholder: "",
      required: true,
      defaultValue: priority,
      classContainer: "half",
      label: "Приоритет",
      options: options.priority,
      disabled: info
    },
    {
      propName: "props.amount_of_solutions",
      type: "option",
      placeholder: "",
      defaultValue: amount_of_solutions,
      classContainer: "half",
      label: "Количество решений",
      options: options.amount_of_solutions,
      disabled: info
    }
  ],
  {
    dem: "м",
    required: true,
    type: "number",
    defaultValue: graph_edge_length_m,
    propName: "props.graph_edge_length_m",
    placeholder: "",
    classContainer: "graph-edge-length half",
    label: "Шаг сетки",
    disabled: info
  },
  {
    required: true,
    propName: "props.dem_id",
    type: "option",
    placeholder: "",
    classContainer: "wide",
    defaultValue: dem_id,
    label: "Цифровая модель рельефа",
    options: options.dem_id,
    disabled: info
  }
];

class CommonFields extends Component {
  state = {
    name: "",
    dem_id: "",
    priority: 4,
    amount_of_solutions: 1,
    graph_edge_length_m: 100,
    options: {
      priority: [
        { value: 0, label: "Низкий" },
        { value: 4, label: "Средний" },
        { value: 9, label: "Высокий" }
      ],
      amount_of_solutions: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" }
      ],
      dem_id: []
    }
  };

  componentDidMount() {
    this.setProps(this.props);
  }

  componentDidUpdate(prevProps) {
    const { taskInfo, dems, info } = this.props;
    if (
      info !== prevProps.info ||
      !_isEqual(prevProps.taskInfo, taskInfo) ||
      !_isEqual(prevProps.dems, dems)
    ) {
      this.setProps(this.props);
    }
  }

  setProps = props => {
    const { dems, info, taskInfo } = props;

    if (dems && dems instanceof Array) {
      const { options } = this.state;
      const dem_id = dems.reduce((acc, currentValue) => {
        const { id, type } = currentValue;
        acc.push({ value: id, label: type }, []);
        return acc;
      }, []);

      this.setState(() => ({
        options: {
          ...options,
          dem_id
        }
      }));
    }
    if (info) {
      this.setState(() => ({
        name: taskInfo.name,
        dem_id: taskInfo.props.dem_id,
        priority: taskInfo.props.priority,
        amount_of_solutions: taskInfo.props.amount_of_solutions,
        graph_edge_length_m: taskInfo.props.graph_edge_length_m
      }));
    }
  };

  onChangeHandler = (value, type) => {
    this.setState({
      [type]: value
    });
  };

  render() {
    const { showAmountOfSolutions, errors = [], info } = this.props;
    const {
      name,
      dem_id,
      priority,
      options,
      amount_of_solutions,
      graph_edge_length_m
    } = this.state;

    const data = handleData({
      name,
      info,
      priority,
      options,
      amount_of_solutions,
      graph_edge_length_m,
      dem_id
    });

    return (
      <div className="create-task-container create-task-container_common-fields">
        {data.map((addedProps, key) => {
          if (addedProps instanceof Array) {
            return (
              <div key={key} className="create-task-row">
                {addedProps.map((props, id) => {
                  if (props.propName.includes("amount_of_solutions")) {
                    if (showAmountOfSolutions) {
                      return (
                        <CommonField
                          key={key + id}
                          addedProps={props}
                          error={errors.includes(props.propName)}
                        />
                      );
                    }
                    return null;
                  }
                  return (
                    <CommonField
                      key={key}
                      addedProps={props}
                      error={errors.includes(props.propName)}
                    />
                  );
                })}
              </div>
            );
          }
          if (!(info && addedProps.propName === "name")) {
            return (
              <div key={key} className="create-task-row">
                <CommonField
                  addedProps={addedProps}
                  error={errors.includes(addedProps.propName)}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dems: getDemsByWellfield(state)
});

const mapDispatchToProps = {
  setTaskSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonFields);

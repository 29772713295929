import _mapValues from "lodash.mapvalues";
import _get from "lodash.get";
import {
  GET_LAST_PROJECTS_AND_WELLFIELDS_SUCCESS,
  SET_NEW_WELLFIELD_TASK_ID_SUCCESS,
  REMOVE_NEW_WELLFIELD_LOGS_SUCCESS,
  SET_NEW_WELLFIELD_LOGS_SUCCESS,
  SET_SUCCESS_LOGS_SUCCESS,
  GET_WELLFIELD_SUCCESS,
  SET_INFO_LOGS_SUCCESS,
  GET_PROJECTS_SUCCESS,
  SET_ERROR_SUCCESS,
  UPDATE_UPLOAD_PROGRESS_SUCCESS,
  CANCEL_WELLFIELD_SENDING_SUCCESS,
  CANCEL_WELLFIELD_CHECKING_SUCCESS,
  CLEAR_CANCELING_FLAGS_SUCCESS
} from "../constants/common";

import { GET_PROJECT_BY_ID_SUCCESS } from "../constants/projects";

const initialState = {
  projects: null,
  wellfields: null,
  lastProjects: {},
  lastWellfields: {},
  newWellfieldLogs: [],
  logs: {
    success: [],
    errors: [],
    info: []
  },
  checkingCanceled: false,
  uploadingCanceled: false,
  uploadProgress: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case REMOVE_NEW_WELLFIELD_LOGS_SUCCESS: {
      return {
        ...state,
        newWellfieldLogs: []
      };
    }
    case SET_NEW_WELLFIELD_LOGS_SUCCESS: {
      return {
        ...state,
        newWellfieldLogs: [...state.newWellfieldLogs, ...action.payload]
      };
    }
    case GET_LAST_PROJECTS_AND_WELLFIELDS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_NEW_WELLFIELD_TASK_ID_SUCCESS: {
      return {
        ...state,
        newWellfieldTaskId: action.payload
      };
    }
    case UPDATE_UPLOAD_PROGRESS_SUCCESS: {
      return {
        ...state,
        uploadProgress: action.payload
      };
    }
    case SET_INFO_LOGS_SUCCESS: {
      return {
        ...state,
        logs: {
          ...initialState.logs,
          info: action.payload
        }
      };
    }
    case SET_SUCCESS_LOGS_SUCCESS: {
      return {
        ...state,
        logs: {
          ...initialState.logs,
          success: action.payload
        }
      };
    }
    case SET_ERROR_SUCCESS:
      return {
        ...state,
        logs: {
          ...initialState.logs,
          errors: action.payload
        }
      };
    case GET_PROJECT_BY_ID_SUCCESS:
      if (!state.projects) return state;
      if (state.projects[action.payload.id]) return state;

      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.id]: action.payload
        }
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: _mapValues(action.payload, item => {
          return {
            ...item,
            wellfieldName: _get(state.wellfields, [item.wellfield_id, "name"])
          };
        })
      };
    case GET_WELLFIELD_SUCCESS:
      return {
        ...state,
        wellfields: action.payload
      };
    case CANCEL_WELLFIELD_CHECKING_SUCCESS:
      return {
        ...state,
        checkingCanceled: true,
        newWellfieldTaskId: "",
        uploadProgress: 0
      };
    case CANCEL_WELLFIELD_SENDING_SUCCESS:
      return {
        ...state,
        uploadingCanceled: true,
        newWellfieldTaskId: "",
        uploadProgress: 0
      };
    case CLEAR_CANCELING_FLAGS_SUCCESS:
      return {
        ...state,
        uploadingCanceled: false,
        checkingCanceled: false
      };
    default:
      return state;
  }
}

import React from "react";
import OilPipes from "./OilPipes";
import WaterPipes from "./WaterPipes";
import GasPipes from "./GasPipes";

export default function PipeFields({ disabled, defaultValues }) {
  const { type } = defaultValues;
  if (["oil_pipe", "oil_gathering_line"].includes(type)) {
    return <OilPipes defaultValues={defaultValues} disabled={disabled} />;
  }
  if (["high_pressure_water_pipe", "low_pressure_water_pipe"].includes(type)) {
    return <WaterPipes defaultValues={defaultValues} disabled={disabled} />;
  }
  if (["high_pressure_gas_pipe", "low_pressure_gas_pipe"].includes(type)) {
    return <GasPipes defaultValues={defaultValues} disabled={disabled} />;
  }
  return null;
}

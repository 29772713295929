const priority = [
  { value: 0, label: "Низкий" },
  { value: 4, label: "Средний" },
  { value: 9, label: "Высокий" }
];

const numberOfOilTreatmentUnits = [
  { value: "booster_pump_station", label: "ДНС" },
  { value: "multiphase_pump_station", label: "МФНС" }
];

const data = [
  {
    propName: "name",
    placeholder: "",
    required: true,
    classContainer: "wide",
    label: "Название задачи"
  },
  {
    propName: "priority",
    type: "option",
    placeholder: "",
    required: true,
    classContainer: "half",
    label: "Приоритет",
    defaultValue: 4,
    options: priority
  },
  "dem",
  {
    parentContainerClass: "border-bottom border-top",
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.number_of_centers",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 1,
        content: "≤ кол-ва кустовых площадок",
        classContainer: "content-bottom",
        label: "Количество центров сбора"
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.number_of_oil_treatment_units",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 1,
        content: "≤ кол-ва центров сбора",
        classContainer: "content-bottom",
        label: "Количество УПН"
      }
    ]
  },
  {
    parentContainerClass: "border-top",
    bigTitle: "При кол-ве УПН < кол-ва ЦС"
  },
  {
    parentContainerClass: "border-bottom",
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.additional_type_of_center",
        type: "option",
        placeholder: "",
        required: true,
        classContainer: "wide",
        label: "Тип вспомогательного ЦС",
        defaultValue: "booster_pump_station",
        options: numberOfOilTreatmentUnits
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.max_watercut",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 5,
        classContainer: "wide",
        label: "Макс. обводненность, %"
      }
    ]
  },
  {
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.oil_density",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 0.822,
        classContainer: "wide",
        label: "Плотность нефти"
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.gas_rel_density",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 0.7,
        classContainer: "wide",
        label: "Относит. плотность газа"
      }
    ]
  },
  {
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.mu_oil_20",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 4.0,
        classContainer: "wide",
        label: "Вязкость нефти при 20 \u00BA"
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.mu_oil_50",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 2.0,
        classContainer: "wide",
        label: "Вязкость нефти при 50 \u00BA"
      }
    ]
  },
  {
    propName: "props.flow_temperature",
    placeholder: "",
    type: "number",
    required: true,
    defaultValue: 10,
    classContainer: "half",
    label: "Температура потока"
  },
  {
    fields: [
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.pressure_min",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 6.0,
        classContainer: "wide",
        label: "Минимальное давление"
      },
      {
        parentContainerClass: "DataFieldsRow half",
        propName: "props.pressure_max",
        placeholder: "",
        type: "number",
        required: true,
        defaultValue: 40.0,
        classContainer: "wide",
        label: "Максимальное давление"
      }
    ]
  }
];

export default data;

import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "components/Common/Button";
import { getActiveTab } from "state/selectors/settings";
import firstButtonTitleHandler from "./utils/firstButtonTitleHandler";
import secondButtonTitleHandler from "./utils/secondButtonTitleHandler";

const BottomRow = ({ firstButtonHandler = () => {}, secondButtonHandler }) => {
  const type = useSelector(getActiveTab);

  const firstButtonTitle = firstButtonTitleHandler(type);
  const secondButtonTitle = secondButtonTitleHandler(type);

  return (
    <>
      <Button onClick={firstButtonHandler} type="submit">
        {firstButtonTitle}
      </Button>
      <Button secondary onClick={secondButtonHandler}>
        {secondButtonTitle}
      </Button>
    </>
  );
};

BottomRow.propTypes = {
  firstButtonHandler: PropTypes.func,
  secondButtonHandler: PropTypes.func.isRequired
};

BottomRow.defaultProps = {
  firstButtonHandler: () => {}
};

export default BottomRow;

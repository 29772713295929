import React from "react";
import GeometryUtil from "leaflet-geometryutil";
import { withLeaflet, Polyline } from "react-leaflet";
import Points from "../Points";

class DrawingPolyline extends React.PureComponent {
  state = { positions: [] };

  handleProps = props => {};

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.handleProps(nextProps);
  }

  getClosestPoint = latlng => {
    const { magnet, layerGroupRef } = this.props;
    if (magnet && layerGroupRef.current) {
      return GeometryUtil.closestLayerSnap(
        this.props.leaflet.map,
        layerGroupRef.current.leafletElement.getLayers(),
        latlng,
        16,
        true
      );
    }
    return null;
  };

  clickHandler = mapEvent => {
    let { latlng } = mapEvent;
    const closest = this.getClosestPoint(latlng);
    if (closest) {
      latlng = closest.latlng;
    }
    this.setState(
      prevState => ({
        positions: [...prevState.positions, latlng]
      }),
      () => {
        if (typeof this.props.linePositionHandler === "function") {
          this.props.linePositionHandler(this.state.positions);
        }
      }
    );
  };

  moveHandler = mapMoveEvent => {
    if (mapMoveEvent && this.state.positions.length && this.drawingLineRef) {
      const closest = this.getClosestPoint(mapMoveEvent.latlng);
      if (closest) {
        const { latlng } = closest;
        const drawingLinePoints = [
          this.state.positions[this.state.positions.length - 1],
          latlng
        ];
        this.drawingLineRef.leafletElement.setLatLngs(drawingLinePoints);
      } else {
        const drawingLinePoints = [
          this.state.positions[this.state.positions.length - 1],
          mapMoveEvent.latlng
        ];
        this.drawingLineRef.leafletElement.setLatLngs(drawingLinePoints);
      }
    }
  };

  componentWillUnmount() {
    if (typeof this.props.savePolyline === "function") {
      this.props.savePolyline(this.state.positions);
    }
    this.props.leaflet.map.off("click", this.clickHandler);
    this.props.leaflet.map.off("mousemove", this.moveHandler);
  }

  componentDidMount() {
    this.props.leaflet.map.on("click", this.clickHandler);
    this.props.leaflet.map.on("mousemove", this.moveHandler);
  }

  onDragHandler = (e, index) => {
    const newLatLngs = this.state.positions.slice();
    newLatLngs[index] = e.latlng;
    this.setState({
      positions: newLatLngs
    });
  };

  onDragEnd = (e, index) => {
    this.setState(
      prevState => {
        const newArr = prevState.positions.slice();
        newArr[index] = e.target._latlng;
        return {
          positions: newArr
        };
      },
      () => {
        if (typeof this.props.linePositionHandler === "function") {
          this.props.linePositionHandler(this.state.positions);
        }
      }
    );
  };

  onDblclick = (e, index) => {
    this.setState(prevState => {
      const newArr = prevState.positions.slice();
      newArr.splice(index, 1);
      return {
        positions: newArr
      };
    });
  };

  render() {
    const { polyStyle, pointIcon } = this.props;
    const pointsStyle = {};
    if (pointIcon) {
      pointsStyle.icon = pointIcon;
    }
    if (!this.state.positions.length || !polyStyle || !pointIcon) return null;
    return (
      <>
        <Polyline
          positions={[]}
          className="drawingLine"
          {...polyStyle}
          ref={node => (this.drawingLineRef = node)}
        />
        <Polyline {...polyStyle} positions={this.state.positions} />
        <Points
          positions={this.state.positions}
          onDragEnd={this.onDragEnd}
          onDblclick={this.onDblclick}
          onDragHandler={this.onDragHandler}
          {...pointsStyle}
        />
      </>
    );
  }
}

export default withLeaflet(DrawingPolyline);

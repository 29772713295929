import React from "react";

import ScrollBar from "components/Common/ScrollBar";
import TopRow from "./TopRow";
import Factory from "./Factory";

const List = props => {
  const Component = Factory(props);
  if (!Component) return null;
  const { type } = props;
  return (
    <>
      <TopRow type={type} />
      <ScrollBar>
        <Component />
      </ScrollBar>
    </>
  );
};

export default List;

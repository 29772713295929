import React from "react";

import { taskConstants } from "utils/constants";
import Hvl from "./Hvl";
import Pipes from "./Pipes";
import Roads from "./Roads";
import Quarries from "./Quarries";
import Facility from "./Facility";
import LinearCost from "./LinearCost";
import WaterPumpingStations from "./WaterPumpingStations";
import PowerSupplyCenters from "./PowerSupplyCenters";
import GatheringCenters from "./GatheringCenters";
import { ErrorsContext } from "../Validation";
import "./style.scss";

const {
  ROADS,
  HVL,
  QUARRIES,
  LINEAR_COST,
  FACILITY,
  PIPES,
  POWER_SUPPLY_CENTERS,
  GATHERING_CENTERS,
  WATER_PUMPING_STATIONS
} = taskConstants;

const TaskDataComponent = ({ children }) => {
  const { errors, clearErrors } = React.useContext(ErrorsContext);
  React.useEffect(() => {
    clearErrors();
  }, [children]);
  return (
    <>
      {React.Children.map(children, child =>
        React.cloneElement(child, { errors })
      )}
    </>
  );
};

const Component = ({ type }) => {
  if (PIPES.includes(type)) {
    return <Pipes />;
  }
  if (type === LINEAR_COST) {
    return <LinearCost />;
  }
  if (type === ROADS) {
    return <Roads />;
  }
  if (type === FACILITY) {
    return <Facility />;
  }
  if (type === HVL) {
    return <Hvl />;
  }
  if (type === QUARRIES) {
    return <Quarries />;
  }
  if (type === GATHERING_CENTERS) {
    return <GatheringCenters />;
  }
  if (type === WATER_PUMPING_STATIONS) {
    return <WaterPumpingStations />;
  }
  if (type === POWER_SUPPLY_CENTERS) {
    return <PowerSupplyCenters />;
  }
  return null;
};

export default React.memo(
  ({ type }) => {
    return (
      <TaskDataComponent>
        <Component type={type} />
      </TaskDataComponent>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.type === nextProps.type;
  }
);

import React, { useEffect, useState, useRef } from "react";
import { withLeaflet } from "react-leaflet";
import { GeoJSONFillable } from "react-leaflet-geojson-patterns";
import { divIcon } from "leaflet";
import Points from "../Points";

const DrawingPolygon = props => {
  const [positions, setPositions] = useState([]);
  const [polyStyle, setPolyStyle] = useState({});
  const [mapEvent, setMapEvent] = useState(null);
  const node = useRef(null);
  useEffect(() => {
    if (node.current) {
      Object.keys(node.current.leafletElement._layers).forEach(key => {
        node.current.contextValue.layerContainer.removeLayer(
          node.current.leafletElement._layers[key]
        );
      });
      const data = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [positions]
        }
      };
      node.current.leafletElement.addData(data);
    }
    if (typeof props.updatePolygonPositions === "function") {
      props.updatePolygonPositions(positions);
    }
  }, [positions]);

  const onDragEnd = (e, index) => {
    const newArr = positions.slice();
    const { lat, lng } = e.target._latlng;
    newArr[index] = [lng, lat];
    setPositions(newArr);
  };

  const onDragHandler = (e, index) => {
    const newLatLngs = positions.slice();
    const { lat, lng } = e.latlng;
    newLatLngs[index] = [lng, lat];
    const { splashRef, newProjectArea } = props;
    if (splashRef.current && newProjectArea) {
      const arr = splashRef.current.leafletElement.getLatLngs().slice();
      arr[1][index] = { lat, lng };
      splashRef.current.leafletElement.setLatLngs(arr);
    }
  };

  const onDblclick = (e, index) => {
    const { splashRef, newProjectArea } = props;
    if (splashRef.current && newProjectArea) {
      const arr = splashRef.current.leafletElement.getLatLngs().slice();
      arr[1].splice(index, 1);
      splashRef.current.leafletElement.setLatLngs(arr);
    }
    const arr = positions.slice();
    arr.splice(index, 1);
    setPositions(arr);
  };
  useEffect(() => {
    if (mapEvent) {
      const { lat, lng } = mapEvent.latlng;
      const { splashRef, newProjectArea } = props;
      if (splashRef.current && newProjectArea) {
        const arr = splashRef.current.leafletElement.getLatLngs().slice();
        arr[1].push({ lat, lng });
        splashRef.current.leafletElement.setLatLngs(arr);
      }
      setPositions(positions.concat([[lng, lat]]));
    }
  }, [mapEvent]);
  useEffect(() => {
    props.leaflet.map.on("click", setMapEvent);
    return () => {
      props.leaflet.map.off("click", setMapEvent);
    };
  }, []);
  useEffect(() => {
    const obj = { ...props.polyStyle };
    if (props.newProjectArea) {
      obj.fillColor = "transparent";
      obj.color = "#fff";
      obj.dashArray = "4, 4";
      setPolyStyle(obj);
    }
    setPolyStyle(obj);
  }, [props.polyStyle, props.newProjectArea]);
  const pointsProps = {
    positions: positions.map(el => [el[1], el[0]]),
    onDragEnd,
    onDblclick,
    onDragHandler
  };
  const { pointIcon, newProjectArea } = props;
  if (pointIcon) {
    pointsProps.icon = pointIcon;
  }
  if (newProjectArea) {
    pointsProps.icon = divIcon({
      className: `draw-project-area`,
      iconSize: [12, 12],
      iconAnchor: [6, 6]
    });
  }
  if (!positions.length) return null;
  const data = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [positions]
    }
  };
  return (
    <>
      <GeoJSONFillable
        ref={node}
        data={data}
        style={() => {
          return polyStyle;
        }}
      />
      <Points {...pointsProps} />
    </>
  );
};

export default withLeaflet(DrawingPolygon);

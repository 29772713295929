import React from "react";
import classnames from "classnames";

export default ({ toggleHandler, children, handler, currentHandler }) => {
  const onClickHandler = () => {
    if (typeof toggleHandler === "function") {
      toggleHandler(handler);
    }
  };
  return (
    <button
      onClick={onClickHandler}
      className={classnames("task-complete__toggle-button", {
        active: currentHandler === handler
      })}
    >
      {children}
    </button>
  );
};

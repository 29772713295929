/* eslint-disable no-underscore-dangle */
import React from "react";
import classnames from "classnames";

const ErrorItem = props => {
  const { err, success, errors, info } = props;
  return (
    <div className={classnames("logs_alert", { success, errors, info })}>
      <span className={classnames("logs__icon", { success, errors, info })} />
      <p className="logs__desc">{err._issues ? err._issues.id : err}</p>
    </div>
  );
};

export default ErrorItem;

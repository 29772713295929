import { all } from "redux-saga/effects";
import authSaga from "./auth";
import commonSaga from "./common";
import objectsSaga from "./objects";
import projectSaga from "./projects";
import wellfieldSaga from "./wellfield";

export default function* root() {
  yield all([
    ...authSaga,
    ...commonSaga,
    ...projectSaga,
    ...objectsSaga,
    ...wellfieldSaga
  ]);
}

import React from "react";
import { connect } from "react-redux";

import { taskFacilities } from "state/selectors/objects";
import { projectPalette } from "state/selectors/projects";
import center from "@turf/center";
import WithIcons from "components/HOC/WithIcons";
import _get from "lodash.get";
import coloringObj from "../../../../../utils/coloringObj";
import FacilityView from "./FacilityView";

const TaskFacilities = ({ taskFacilities, projectPalette, setIcon }) => {
  if (!taskFacilities || !taskFacilities.data) return null;
  return (
    <>
      {taskFacilities.data.map(
        ({ facility_area, id, class_id, name }, index) => {
          let style = {
            color: "#6B4EFF",
            fillColor: "#6B4EFF",
            fillOpacity: 1,
            weight: 2
          };
          let className = "optimized-facility";
          if (
            [
              "gatheringCenters",
              "waterGatheringCenters",
              "electricityCenters"
            ].includes(taskFacilities.type)
          ) {
            style = coloringObj(_get(projectPalette, [class_id]));
            className = `facility-with-tmp-color tmpColor${index}`;
          }
          const icon = projectPalette ? setIcon(projectPalette[class_id]) : "";
          const [lng, lat] = center(facility_area).geometry.coordinates;
          const position = { lat, lng };
          const data = {
            ...facility_area,
            properties: { id }
          };
          console.log(data);
          return (
            <FacilityView
              id={id}
              key={id}
              icon={icon}
              data={data}
              name={name}
              style={style}
              position={position}
              className={className}
              facility_area={facility_area}
            />
          );
        }
      )}
    </>
  );
};

const mapStateToProps = state => ({
  taskFacilities: taskFacilities(state),
  projectPalette: projectPalette(state)
});

export default connect(mapStateToProps)(WithIcons(TaskFacilities));

export default {
  sand: "Отсыпки песком, руб",
  small_rock: "Отсыпки щебнем, руб.",
  heat_insulating_slab: "Теплоизоляционных плит, руб.",
  earthwork: "Земляных работ, руб.",
  cover_making: "Лежневого настила, руб.",
  geotextile_layer_making: "Прослойки из геотекстиля, руб.",
  thermal_layer_making: "Укладки теплоизоляционных плит, руб.",
  roll_lawn_layer_making: "Прослойки из биоматов, руб.",
  geogrid_making: "Устройства биорешетки, руб.",
  grass_strengthening: "Укрепления откосов посевом травы, руб.",
  transportation: "Транспортировки, руб."
};

import React from "react";
import _get from "lodash.get";
import _isEqual from "lodash.isequal";

import WithCommonObjectsFunc from "components/HOC/WithCommonObjectsFunc";
import ListWrapper from "../../MainContainer/common/ListWrapper";

class ObjectsListContainer extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { sortedFacilities } = this.props;
    if (!_isEqual(nextProps.sortedFacilities, sortedFacilities)) {
      return true;
    }
    return false;
  }

  showFacilities = ({ id }) => {
    const { hideFacilities } = this.props;
    hideFacilities("SUCCESS", id);
  };

  lockRow = ({ id }) => console.log(id, "lock!");

  showList = id => {
    const { hideFacilitiesGroup } = this.props;
    hideFacilitiesGroup("SUCCESS", id);
  };

  removeItem = ({ name, id, class_id }) => {
    const { setRemoveProps, openRemoveAlert, classesByWellfield } = this.props;
    const { name: objectType } = _get(classesByWellfield, class_id);
    setRemoveProps("SUCCESS", {
      id,
      name: name || "Без названия",
      objectType,
      title: "объекта",
      typeTitle: "объекта",
      removeType: "object",
      questionTitle: "объект"
    });
    openRemoveAlert("SUCCESS", true);
  };

  removeMetaclass = id => {
    const { setRemoveProps, openRemoveAlert, classesByWellfield } = this.props;
    const { name: objectType } = _get(classesByWellfield, id);
    setRemoveProps("SUCCESS", {
      id,
      objectType,
      removeType: "class",
      typeTitle: "объекта",
      title: "группы объектов",
      questionTitle: "группу объектов"
    });
    openRemoveAlert("SUCCESS", true);
  };

  chooseRow = item => {
    const { setCardInfo, activeTab } = this.props;
    setCardInfo("SUCCESS", {
      type: "facility",
      data: item
    });
    activeTab("SUCCESS", "objectInfoCard");
  };

  render() {
    const { sortedFacilities, type, updated } = this.props;
    if (!sortedFacilities)
      return <h2 className="preload-text">Идёт загрузка...</h2>;
    if (!Object.keys(sortedFacilities).length) {
      return <h2 className="preload-text">Список объектов пуст</h2>;
    }
    return (
      <ListWrapper
        type={type}
        lockRow={this.lockRow}
        showList={this.showList}
        chooseRow={this.chooseRow}
        removeItem={this.removeItem}
        updated={updated}
        showRow={this.showFacilities}
        objects={sortedFacilities}
        removeMetaclass={this.removeMetaclass}
      />
    );
  }
}

export default WithCommonObjectsFunc(ObjectsListContainer);

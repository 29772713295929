import React, { Component } from "react";

import classNames from "classnames";

import Menu from "./Menu";
import ChooseObjectsButton from "./ChooseObjectsButton";
import config from "./utils/config";

class ChooseObjectsHandler extends Component {
  state = {
    activeId: 0,
    showMenu: false,
    taskType: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { taskType } = this.props;
    if (taskType !== prevState.taskType) {
      this.setState(
        {
          taskType
        },
        () => {
          if (taskType && config(taskType)) {
            this.props.clickType(
              "SUCCESS",
              config(taskType)[0].chooseObjectsParams
            );
          }
        }
      );
    }
  }

  handleActiveButton = id => {
    if (this.state.activeId !== id) {
      this.setState(() => ({
        showMenu: false
      }));
    }
    this.setState(() => ({
      activeId: id
    }));
  };

  showMenuHandler = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    const { taskType, showMenu, activeId } = this.state;
    const {
      getClickType: { distanceToQuarry }
    } = this.props;
    if (!taskType || !config(taskType)) return null;
    const singleButton = config(taskType, distanceToQuarry).length === 1;
    return (
      <div
        className={classNames("choose-objects-container", taskType, {
          distanceToQuarry,
          "single-button": singleButton
        })}
      >
        <div className="choose-objects-component">
          {showMenu && (
            <Menu
              taskType={taskType}
              closeMenu={this.closeMenu}
              singleButton={singleButton}
              activeId={activeId}
            />
          )}
          <div
            className={classNames("choose-objects__bg", `active-${activeId}`)}
          />
          <div className="choose-objects-buttons-container">
            {config(taskType, distanceToQuarry).map((params, key) => (
              <ChooseObjectsButton
                id={key}
                key={key}
                {...params}
                taskType={taskType}
                showMenuHandler={this.showMenuHandler}
                handleActiveButton={this.handleActiveButton}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ChooseObjectsHandler;

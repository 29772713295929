import React from "react";

const MenuButton = props => {
  const { children, onClick } = props;
  return (
    <button onClick={onClick} className="menu__title">
      {children}
    </button>
  );
};

export default MenuButton;

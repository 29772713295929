import React from "react";

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.2948 16H7.7052V7.93546L4.12861 11.5645L3 10.4193L8.5 4.83868L14 10.4193L12.8714 11.5645L9.2948 7.93546V16Z"
      fill="#8495AC"
    />
    <path
      d="M8.5 2.38419e-05C8.07841 2.38419e-05 7.67409 0.169954 7.37598 0.472432C7.07788 0.77491 6.9104 1.18516 6.9104 1.61293C6.9104 2.0407 7.07788 2.45094 7.37598 2.75342C7.67409 3.0559 8.07841 3.22583 8.5 3.22583C8.92158 3.22583 9.3259 3.0559 9.62401 2.75342C9.92212 2.45094 10.0896 2.0407 10.0896 1.61293C10.0896 1.18516 9.92212 0.77491 9.62401 0.472432C9.3259 0.169954 8.92158 2.38419e-05 8.5 2.38419e-05Z"
      fill="#8495AC"
    />
  </svg>
);

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCommonInfo } from "state/actions/common";
import { getProjectName } from "state/selectors/projects";
import { saveSettings } from "state/actions/groupsSettings";
import { activeTab, showLoader } from "state/actions/settings";
import { getProjectById, clearState } from "state/actions/projects";
import MainPageView from "./MainPageView";

class MainPageContainer extends Component {
  componentDidMount() {
    const { saveSettings, match } = this.props;
    this.getProject(this.props);
    window.addEventListener("beforeunload", () => {
      saveSettings("REQUEST", match.params.id);
    });
  }

  componentDidUpdate(prevProps) {
    const { projectName } = this.props;
    if (prevProps.projectName !== projectName) {
      this.getProject(this.props);
    }
  }

  componentWillUnmount() {
    const {
      activeTab,
      clearState,
      getCommonInfo,
      saveSettings,
      showLoader,
      match
    } = this.props;
    saveSettings("REQUEST", match.params.id);
    activeTab("SUCCESS", "objects");
    clearState("SUCCESS", false);
    showLoader("SUCCESS", false);
    getCommonInfo();
  }

  getProject = props => {
    if (!props.projectName) {
      const { match, getProjectById } = props;
      const { id } = match.params;
      getProjectById("REQUEST", id);
      if (localStorage.getItem("settings")) {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const { id } = props.match.params;
        if (settings[id]) {
          props.saveSettings("SUCCESS", settings[id]);
        }
      }
    }
  };

  render() {
    return <MainPageView />;
  }
}

MainPageContainer.propTypes = {
  clearState: PropTypes.func.isRequired,
  getCommonInfo: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  activeTab,
  showLoader,
  clearState,
  saveSettings,
  getCommonInfo,
  getProjectById
};

const mapStateToProps = state => ({
  projectName: getProjectName(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPageContainer);

import React from "react";
import LockRow from "./LockRow";

const ListAfterComponent = ({
  tooltipType,
  item,
  setView,
  removeItem,
  lockRow
}) => {
  const removeItemHandler = () => removeItem(item);
  const setViewHandler = () => setView(item);
  return (
    <span className="common-component-handlers">
      <button
        data-tip={`Показать ${tooltipType} на карте`}
        data-for="mainTooltip"
        className="set-view"
        onClick={setViewHandler}
      />
      {item.itemType !== "network" && (
        <button className="field-row__remove" onClick={removeItemHandler} />
      )}
      {typeof lockRow === "function" && <LockRow item={item} />}
    </span>
  );
};

export default ListAfterComponent;

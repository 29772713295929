export default {
  oil_pipe: {
    oil_density: 0.822,
    gas_rel_density: 0.7,
    mu_oil_20: 4,
    mu_oil_50: 2,
    flow_temperature: 10,
    pressure_min: 6,
    pressure_max: 40
  },
  oil_gathering_line: {
    oil_density: 0.822,
    gas_rel_density: 0.7,
    mu_oil_20: 4,
    mu_oil_50: 2,
    flow_temperature: 10,
    pressure_min: 6,
    pressure_max: 40
  },
  high_pressure_water_pipe: {
    water_density: 1000,
    flow_temperature: 10,
    pressure_min: 6,
    pressure_max: 40
  },
  low_pressure_water_pipe: {
    water_density: 1000,
    flow_temperature: 10,
    pressure_min: 6,
    pressure_max: 40
  },
  high_pressure_gas_pipe: {
    gas_rel_density: 0.7,
    flow_temperature: 10,
    pressure_min: 6,
    pressure_max: 40
  },
  low_pressure_gas_pipe: {
    gas_rel_density: 0.7,
    flow_temperature: 10,
    pressure_min: 6,
    pressure_max: 40
  }
};

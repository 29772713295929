import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { sortClassesByMetaclasses } from "state/selectors/wellfield";
import {
  getHideAreas,
  getHideLinears,
  hideAreasGroup,
  getHidedFacilities,
  hideFacilitiesGroup,
  hideLinearsMetaclass,
  hideLinearsByMetaclassAndType,
  hideLinearsByMetaclassAndNetwork
} from "state/selectors/groupsSettings";

import ReactTooltip from "react-tooltip";

class ShowRow extends Component {
  elem = React.createRef();

  state = { active: true };

  componentDidMount() {
    const {
      type,
      subLayerParent,
      subLayerKey,
      hideAreasGroup,
      getHideAreas
    } = this.props;
    if (type === "areas") {
      const { item } = this.props;
      if (!subLayerParent && !subLayerKey) {
        if (typeof item === "string") {
          if (hideAreasGroup.includes(item)) {
            this.setState({
              active: false
            });
            this.elem.current.parentNode.parentNode.classList.toggle(
              "hide-row"
            );
          }
        } else {
          const { id } = item;
          if (getHideAreas.includes(id)) {
            this.setState({
              active: false
            });
          }
        }
      }
    }
    if (type === "objects") {
      const { item, hideFacilitiesGroup, getHidedFacilities } = this.props;
      if (!subLayerParent && !subLayerKey) {
        if (typeof item === "string") {
          if (hideFacilitiesGroup.includes(item)) {
            this.setState({
              active: false
            });
            this.elem.current.parentNode.parentNode.classList.toggle(
              "hide-row"
            );
          }
        } else {
          const { id } = item;
          if (getHidedFacilities.includes(id)) {
            this.setState({
              active: false
            });
          }
        }
      }
    }
    if (type === "linear") {
      const { item } = this.props;
      if (!subLayerParent && !subLayerParent && subLayerKey) {
        const { hideLinearsByMetaclassAndType } = this.props;
        if (hideLinearsByMetaclassAndType[item]) {
          if (hideLinearsByMetaclassAndType[item].types.includes(subLayerKey)) {
            this.setState({
              active: false
            });
            this.elem.current.parentNode.parentNode.classList.toggle(
              "hide-row"
            );
          }
        }
      } else if (!subLayerParent && !subLayerKey) {
        if (typeof item === "string") {
          const { hideLinearsMetaclass } = this.props;
          if (hideLinearsMetaclass.includes(item)) {
            this.setState({
              active: false
            });
            this.elem.current.parentNode.parentNode.classList.toggle(
              "hide-row"
            );
          }
        } else {
          const { item, getHideLinears } = this.props;
          const { id } = item;
          if (getHideLinears.includes(id)) {
            this.setState({
              active: false
            });
          }
        }
      } else {
        const { hideLinearsByMetaclassAndNetwork } = this.props;
        if (hideLinearsByMetaclassAndNetwork[item]) {
          if (
            hideLinearsByMetaclassAndNetwork[item].networks.includes(
              subLayerKey
            )
          ) {
            this.setState({
              active: false
            });
            this.elem.current.parentNode.parentNode.classList.toggle(
              "hide-row"
            );
          }
        }
      }
    }
  }

  static getDerivedStateFromProps() {
    ReactTooltip.rebuild();
    return null;
  }

  onClickHandler = () => {
    const { active } = this.state;
    const { item, showRow } = this.props;
    this.elem.current.parentNode.parentNode.classList.toggle("hide-row");
    this.setState({ active: !active }, () => showRow(item));
  };

  render() {
    const { active } = this.state;
    return (
      <button
        data-tip={active ? "Скрыть" : "Показать"}
        data-for="mainTooltip"
        onClick={this.onClickHandler}
        ref={this.elem}
        className={classnames("showRow", { hide: !active })}
      >
        <div className="field-row__showRow-icon" />
      </button>
    );
  }
}

const mapStateToProps = state => ({
  getHideAreas: getHideAreas(state),
  getHideLinears: getHideLinears(state),
  hideAreasGroup: hideAreasGroup(state),
  getHidedFacilities: getHidedFacilities(state),
  hideFacilitiesGroup: hideFacilitiesGroup(state),
  hideLinearsMetaclass: hideLinearsMetaclass(state),
  hideLinearsByMetaclassAndType: hideLinearsByMetaclassAndType(state),
  hideLinearsByMetaclassAndNetwork: hideLinearsByMetaclassAndNetwork(state),
  sortClassesByMetaclasses: sortClassesByMetaclasses(state)
});

export default connect(mapStateToProps)(ShowRow);

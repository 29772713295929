import React from "react";
import { TileLayer } from "react-leaflet";

const tileLayerGoogle = {
  url: "http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
  subdomains: ["mt0", "mt1", "mt2", "mt3"]
};
export default ({ mapType }) => {
  if (mapType === "Google") return <TileLayer {...tileLayerGoogle} />;
  return null;
};

import {
  call,
  put,
  fork,
  take,
  takeLatest,
  all,
  select,
  cancelled,
  cancel,
  delay
} from "redux-saga/effects";
import { getRequest, postRequest, deleteRequest, putRequest } from "api";
import FileSaver from "file-saver";

import {
  getTask,
  addTask,
  getLayout,
  clearState,
  removeTask,
  getNetworks,
  sendProject,
  sendNetwork,
  removeClass,
  downloadTask,
  getTaskResult,
  renameProject,
  removeNetwork,
  getProjectById,
  removeTaskLogs,
  setProjectInfo,
  removeMetaclass,
  downloadProject,
  setNewPoPosition,
  setNewProjectArea,
  getProjectPalette,
  setAvaliableTaskTypes,
  removeMetaclassByType,
  getMetaclassesByWellfieldAndType,
  getFacilitiesFromProjectByTaskType
} from "state/actions/projects";

import {
  getAreas,
  getlinears,
  removeAreas,
  getAreaById,
  getLinearById,
  removeLinears,
  getFacilities,
  removeFacility,
  setFacilityById,
  getFacilitiesById
} from "state/actions/objects";

import {
  getLayouts,
  getWellfieldInfo,
  removeWellfield
} from "state/actions/wellfield";

import { setCenter } from "state/actions/map";

import {
  setSuccessLogs,
  getCommonInfo,
  setInfoLogs,
  setError
} from "state/actions/common";

import {
  setTaskSettings,
  taskStarted,
  activeTab,
  showLoader,
  removeClickType
} from "state/actions/settings";

import {
  CLEAR_STATE_SUCCESS,
  GET_TASKS_REQUEST,
  REPEAT_TASK_REQUEST,
  REMOVE_TASK_REQUEST,
  CREATE_TASK_REQUEST,
  SEND_NETWORK_REQUEST,
  SEND_PROJECT_REQUEST,
  CREATE_OBJECT_REQUEST,
  SEND_FACILITY_REQUEST,
  REMOVE_OBJECT_REQUEST,
  REMOVE_PROJECT_REQUEST,
  GET_TASK_RESULTS_REQUEST,
  DOWNLOAD_TASK_REQUEST,
  RENAME_PROJECT_REQUEST,
  DOWNLOAD_PROJECT_REQUEST,
  GET_PROJECT_BY_ID_REQUEST,
  SET_NEW_PO_POSITION_REQUEST,
  GET_PROJECT_PALETTE_REQUEST,
  SET_PROJECT_VISITING_REQUEST,
  GET_CALCULATED_OBJECTS_REQUEST,
  GET_PREVIEW_PROJECT_PROPS_REQUEST,
  GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_REQUEST
} from "state/constants/projects";

import { projects } from "state/selectors/common";

import { sortMetaclassesByClasses } from "state/selectors/wellfield";

import { getProjectId, getTasksByType } from "state/selectors/projects";

import {
  getFacilityById,
  getFacilityByClassId,
  getAreasByMetaclassId,
  getLinearsByMetaclassId
} from "state/selectors/objects";

import { GET_WELLFIELD_INFO_SUCCESS } from "state/constants/wellfield";
import service from "../service/projects";

const createSaga = (action, url) =>
  function*() {
    try {
      const {
        data: { data }
      } = yield call(getRequest, url);
      yield put(action("SUCCESS", data));
    } catch (error) {
      yield put(action("FAILURE", error));
    }
  };

const createWatch = (type, saga) =>
  function*() {
    yield takeLatest(type, saga);
  };

function* commonSaga(func) {
  try {
    yield func;
  } catch (error) {
    const {
      response: { data }
    } = error;
    yield put(setError("SUCCESS", data.user_message));
  } finally {
    if (yield cancelled()) {
      console.log("cancelled");
    }
  }
}
// Create Saga

function* removeProject({ payload }) {
  const { id, name } = payload;
  const url = service.projectById(id);
  yield call(deleteRequest, url);
  yield put(setSuccessLogs("SUCCESS", `Проект «${name}» удалён.`));
  yield put(clearState("SUCCESS"));
  yield put(getCommonInfo());
}

function* removeProjectSaga(data) {
  yield call(commonSaga, removeProject(data));
}

function* fetchLinearById(id) {
  const metaclassesByClasses = yield select(sortMetaclassesByClasses);
  const url = service.linearById(id);
  const {
    data: { data }
  } = yield call(getRequest, url);
  yield put(
    getLinearById("SUCCESS", {
      ...data[0],
      metaclass_id: metaclassesByClasses[data[0].class_id],
      first_network: data[0].networks_ids.length
        ? data[0].networks_ids[0]
        : "without_network"
    })
  );
}

function* fetchLinearByIdSaga(data) {
  yield call(commonSaga, fetchLinearById(data));
}

function* fetchAreaById(id) {
  const url = service.areasById(id);
  const metaclassesByClasses = yield select(sortMetaclassesByClasses);
  const {
    data: { data }
  } = yield call(getRequest, url);
  yield put(
    getAreaById("SUCCESS", {
      data: data.map(item => {
        return {
          ...item,
          metaclass_id: metaclassesByClasses[item.class_id]
        };
      })
    })
  );
}

function* fetchAreaByIdSaga(data) {
  yield call(commonSaga, fetchAreaById(data));
}

function* getLayoutFunc(layoutId) {
  const {
    data: { data }
  } = yield call(getRequest, service.layout(layoutId));
  yield put(getLayout("SUCCESS", data[0].name));
}

function* getLayoutSaga(layoutId) {
  yield call(commonSaga, getLayoutFunc(layoutId));
}

function* getAllTasksSaga(id) {
  const projectId = yield select(getProjectId);
  try {
    yield call(createSaga(getTask, service.getTask(projectId || id)));
  } catch (error) {
    const { response } = error;
    yield put(showLoader("SUCCESS", false));
    yield put(setError("SUCCESS", response.data));
  }
}

function* getAvaliableTaskTypes(id) {
  try {
    const url = service.getAvaliableTaskTypes(id);
    const { data } = yield call(getRequest, url);
    yield put(setAvaliableTaskTypes("SUCCESS", data.data));
  } catch (err) {
    console.log(err);
  }
}

function* getMetaclassesByWellfieldAndTypeSaga(wellfield) {
  const url = service.metaclassesByWellfieldAndTypeUrl(wellfield, "facility");
  try {
    const { data } = yield call(getRequest, url);
    yield put(getMetaclassesByWellfieldAndType("SUCCESS", data));
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* getObjects(id) {
  let metaclassesByClasses = yield select(sortMetaclassesByClasses);
  if (!metaclassesByClasses) {
    yield take(GET_WELLFIELD_INFO_SUCCESS);
    metaclassesByClasses = yield select(sortMetaclassesByClasses);
  }
  yield put(getAreas("REQUEST", { id, metaclassesByClasses }));
  yield put(getFacilities("REQUEST", { id }));
  yield put(getlinears("REQUEST", { id, metaclassesByClasses }));
}

function* getProjectPaletteSaga({ payload }) {
  try {
    const url = service.classesPalette(payload);
    const { data } = yield call(getRequest, url);
    yield put(getProjectPalette("SUCCESS", data.data[0]));
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* setNetworksSaga(id) {
  try {
    const { data } = yield call(getRequest, service.getNetworks(id));
    yield put(getNetworks("SUCCESS", data));
  } catch (err) {
    console.log(err);
  }
}

function* getProjectInfoSaga({ payload }) {
  try {
    const {
      id,
      layout_id,
      wellfield_id,
      name: projectName,
      project_area: projectArea
    } = payload;
    yield put(
      setProjectInfo("SUCCESS", {
        id,
        projectName,
        projectArea,
        wellfield_id
      })
    );
    if (layout_id) {
      yield fork(getLayoutSaga, layout_id);
    }
    yield put(getWellfieldInfo("REQUEST", { wellfielId: wellfield_id }));
    yield fork(setNetworksSaga, id);
    yield call(getProjectPaletteSaga, { payload: wellfield_id });
    yield all([
      fork(getObjects, id),
      call(getMetaclassesByWellfieldAndTypeSaga, wellfield_id),
      call(getAvaliableTaskTypes, id)
    ]);
    yield call(getAllTasksSaga, id);
    yield put(showLoader("SUCCESS", false));
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
    yield put(showLoader("SUCCESS", false));
  }
}

function* fetchProjectById({ payload: id }) {
  const url = service.projectById(id);
  yield put(showLoader("SUCCESS", true));
  const {
    data: { data }
  } = yield call(getRequest, url);
  yield put(getProjectById("SUCCESS", data[0]));
  const req = yield fork(getProjectInfoSaga, { payload: data[0] });
  yield take(CLEAR_STATE_SUCCESS);
  yield cancel(req);
}

const fetchProjectByIdSaga = function*(data) {
  yield fork(commonSaga, fetchProjectById(data));
};

function* getPreviewProjectPropsSaga({ payload: id }) {
  try {
    yield put(clearState("SUCCESS"));
    const url = service.projectById(id);
    yield put(showLoader("SUCCESS", true));
    const { data } = yield call(getRequest, url);
    const req = yield fork(getProjectInfoSaga, { payload: data.data[0] });
    yield take(CLEAR_STATE_SUCCESS);
    yield cancel(req);
  } catch (err) {
    yield put(showLoader("SUCCESS", false));
  }
}

export function* getAvaliableTaskTypesSaga() {
  const projectId = yield select(getProjectId);
  yield call(getAvaliableTaskTypes, projectId);
}

function* sendFacility(postData) {
  const { data } = yield call(
    postRequest,
    service.sendFacility,
    postData.payload
  );
  const { id } = data.data[0];
  yield put(activeTab("SUCCESS", "objects"));
  yield put(getFacilitiesById("REQUEST", { id }));
  yield getAvaliableTaskTypesSaga();
}

function* sendFacilitySaga(postData) {
  yield call(commonSaga, sendFacility(postData));
}

function* sendProjectSaga(postData) {
  try {
    yield put(showLoader("SUCCESS", true));
    const { data } = yield call(
      postRequest,
      service.sendProjectUrl,
      postData.payload
    );
    const { id } = data.data[0];
    yield put(removeClickType("SUCCESS"));
    yield put(sendProject("SUCCESS"));
    yield put(getLayouts("REQUEST"));
    yield put(getLayouts("SUCCESS", {}));
    yield put(setCenter("SUCCESS", null));
    yield fetchProjectByIdSaga({ payload: id });
    yield put(showLoader("SUCCESS", false));
    yield put(setNewProjectArea("SUCCESS", false));
  } catch (error) {
    const { response } = error;
    yield put(showLoader("SUCCESS", false));
    yield put(setError("SUCCESS", response.data));
  }
}

function* calculateTask({ id }) {
  try {
    yield fork(getRequest, service.calculateTaskUrl(id));
  } catch (err) {
    console.log(err);
  } finally {
    if (cancelled()) {
      console.log("cancelled");
    }
  }
}

function* createTaskSaga({ payload }) {
  try {
    const { type, data } = payload;
    const url = service.sendNewTaskUrl;
    const { data: reqData } = yield call(postRequest, url, data);
    const { id } = reqData.data[0];
    yield fork(calculateTask, { type, id });
    const currentTask = { id, ...payload.data };
    if (!currentTask.hasOwnProperty("priority")) {
      currentTask.priority = 0;
    }
    yield put(
      addTask("SUCCESS", {
        data: currentTask
      })
    );
    yield put(taskStarted("SUCCESS", id));
    yield put(setTaskSettings("SUCCESS", { props: {} }));
    yield delay(1500);
  } catch (error) {
    console.log(error, "error");
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* setProjectVisitingSaga({ payload }) {
  try {
    const url = service.projectById(payload);
    yield call(getRequest, url);
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* removeMetaclassSaga({ payload }) {
  const { id } = payload;
  const projectId = yield select(getProjectId);
  const url = service.removeMetaclass(id, projectId);
  try {
    yield call(deleteRequest, url);
    yield call(getObjects, projectId);
    const linearsByMetaclassId = yield select(getLinearsByMetaclassId);
    const areasByMetaclassId = yield select(getAreasByMetaclassId);
    yield put(
      removeMetaclass("SUCCESS", {
        metaClassId: id,
        linearsIds:
          linearsByMetaclassId[id] &&
          linearsByMetaclassId[id].map(({ id }) => id),
        areasIds:
          areasByMetaclassId[id] && areasByMetaclassId[id].map(({ id }) => id)
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* removeClassSaga({ payload }) {
  const { id } = payload;
  const projectId = yield select(getProjectId);
  const url = service.removeСlass(id, projectId);
  try {
    yield call(deleteRequest, url);
    yield call(getObjects, projectId);
    const facilityByClassId = yield select(getFacilityByClassId);
    yield put(
      removeClass("SUCCESS", {
        classId: id,
        facilityIds:
          facilityByClassId[id] && facilityByClassId[id].map(({ id }) => id)
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* removeMetaclassByTypeSaga({ payload }) {
  const { id, condition } = payload;
  const projectId = yield select(getProjectId);
  const url = service.removeByTypeMetaclass(id, projectId, condition);
  try {
    yield call(deleteRequest, url);
    yield call(getObjects, projectId);
    yield call(setNetworksSaga, projectId);
    yield put(
      removeMetaclassByType("SUCCESS", {
        id,
        condition
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* removeNetworkSaga({ payload }) {
  const { id } = payload;
  const projectId = yield select(getProjectId);
  const url = service.removeNetwork(id);
  try {
    yield call(deleteRequest, url);
    yield call(getObjects, projectId);
    yield call(setNetworksSaga, projectId);
    yield put(removeNetwork("SUCCESS", id));
  } catch (error) {
    console.log(error);
  }
}

function* setNewPoPositionSaga({ payload }) {
  const { id, facility_id, oldFacilityId } = payload;
  try {
    let facilityById = yield select(getFacilityById);
    facilityById = facilityById[facility_id][0];
    const {
      _updated,
      props,
      connection_points,
      facility_area,
      center_point
    } = facilityById;
    yield call(getRequest, service.sendTaskResult(id));
    yield put(setNewPoPosition("SUCCESS"));
    yield put(
      setSuccessLogs(
        "SUCCESS",
        `Расположение объекта «${facilityById.name}» оптимизировано.`
      )
    );
    yield put(
      setFacilityById("SUCCESS", {
        id: oldFacilityId,
        data: {
          props,
          _updated,
          center_point,
          facility_area,
          connection_points
        }
      })
    );
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* createObjectSaga({ payload }) {
  const { type, data } = payload;
  try {
    if (type === "createLinearObject") {
      const url = service.sendLinear;
      let dem_id;
      let calculate;
      let graph_edge_length_m;
      let priority;
      if (data.props) {
        dem_id = data.props.dem_id;
        calculate = data.props.calculate;
        graph_edge_length_m = data.props.graph_edge_length_m;
        priority = data.priority;
        delete data.priority;
        delete data.props.calculate;
        delete data.props.dem_id;
        delete data.props.graph_edge_length_m;
      }
      const linearReq = yield call(postRequest, url, data);
      if (calculate) {
        let calcData = {
          name: `Расчет стоимости «${data.name}»`,
          type: "linear_cost",
          priority,
          props: {
            dem_id,
            project_id: data.project_id,
            linear_id: linearReq.data.data[0].id,
            graph_edge_length_m
          }
        };
        const { data: reqData } = yield call(
          postRequest,
          service.sendNewTaskUrl,
          calcData
        );
        const { id } = reqData.data[0];
        calcData = {
          ...calcData,
          ...reqData.data[0]
        };
        yield call(getRequest, service.calculateTaskUrl(id));
        yield put(addTask("SUCCESS", { data: calcData }));
      }
      const { id: linearId } = linearReq.data.data[0];
      yield call(fetchLinearByIdSaga, linearId);
      const projectId = yield select(getProjectId);
      yield call(setNetworksSaga, projectId);
    }
    if (type === "createArea") {
      const url = service.sendArea;
      const req = yield call(postRequest, url, data);
      const { id: areaId } = req.data.data[0];
      yield call(fetchAreaByIdSaga, areaId);
    }
    yield getAvaliableTaskTypesSaga();
  } catch (error) {
    console.log(error, "error");
  }
}

function* getTaskResultSaga({ payload }) {
  const { id, type } = payload;
  try {
    yield put(getTaskResult("SUCCESS", []));
    const {
      data: { data }
    } = yield call(getRequest, service.taskResult(id));
    yield put(getTaskResult("SUCCESS", { data, type }));
  } catch (err) {
    console.log(err, "err");
  }
}

function* sendNetworkFunc(payload) {
  const { id, index, name } = payload;
  const { data } = yield call(getRequest, service.sendTaskResult(id));
  yield put(sendNetwork("SUCCESS", data));
  let log = "";
  if (index !== undefined) {
    log = `Вариант ${index} задачи «${name}» перенесен в проект`;
  } else {
    log = `Результат задачи «${name}» перенесен в проект`;
  }
  yield put(setSuccessLogs("SUCCESS", log));
  const projectId = yield select(getProjectId);
  yield fork(getObjects, projectId);
  yield fork(setNetworksSaga, projectId);
}

function* sendNetworkSaga({ payload }) {
  yield call(commonSaga, sendNetworkFunc(payload));
}

function* removeTaskFunc(payload) {
  const { id, condition, activeTab: activeTabValue } = payload;
  const url = service.removeTask(id);
  yield call(deleteRequest, url);
  if (activeTabValue) {
    yield put(activeTab("SUCCESS", "tasks"));
  }
  const projectId = yield select(getProjectId);
  const metaclassesByClasses = yield select(sortMetaclassesByClasses);
  yield getAllTasksSaga();
  if (condition === "facility_placement") {
    yield put(getFacilities("REQUEST", { id: projectId }));
  } else {
    yield put(getlinears("REQUEST", { id: projectId, metaclassesByClasses }));
  }
  const tasksByType = yield select(getTasksByType);
  yield put(removeTask("SUCCESS", { condition, tasksByType }));
}

const removeTaskSaga = function*({ payload }) {
  yield call(commonSaga, removeTaskFunc(payload));
};

function* getCalculatedObjectsSaga() {
  try {
    const id = yield select(getProjectId);
    const metaclassesByClasses = yield select(sortMetaclassesByClasses);
    yield call(setNetworksSaga, id);
    yield put(getlinears("REQUEST", { id, metaclassesByClasses }));
    yield put(getFacilities("REQUEST", { id }));
  } catch (error) {
    console.log(error, "error");
  }
}

function* downloadProjectSaga({ payload }) {
  const { id, name } = payload;
  try {
    yield put(setInfoLogs("SUCCESS", `Загрузка проекта «${name}» началась.`));
    const url = service.downloadProject(id);
    yield FileSaver.saveAs(url);
    yield put(downloadProject("SUCCESS"));
  } catch (err) {
    console.log(err);
  }
}

function* downloadTaskSaga({ payload }) {
  const { id, name } = payload;
  try {
    yield put(
      setInfoLogs(
        "SUCCESS",
        `Загрузка информации по задаче «${name}» началась.`
      )
    );
    const url = service.downloadTask(id);
    yield FileSaver.saveAs(url);
    yield put(downloadTask("SUCCESS"));
  } catch (err) {
    console.log(err);
  }
}

function* removeObjectSaga({ payload }) {
  const { type } = payload;
  try {
    if (type === "object") {
      yield put(removeFacility("REQUEST", payload));
    } else if (type === "linear") {
      yield put(removeLinears("REQUEST", payload));
    } else if (type === "area") {
      yield put(removeAreas("REQUEST", payload));
    } else if (type === "class") {
      yield removeClassSaga({ payload });
    } else if (type === "metaclass") {
      yield removeMetaclassSaga({ payload });
    } else if (type === "metaclassByType") {
      yield removeMetaclassByTypeSaga({ payload });
    } else if (type === "network") {
      yield removeNetworkSaga({ payload });
    } else if (type === "task") {
      yield removeTaskSaga({ payload });
    } else if (type === "project") {
      yield removeProjectSaga({ payload });
    } else if (type === "wellfield") {
      yield put(removeWellfield("REQUEST", payload));
    }
    yield getAvaliableTaskTypesSaga();
  } catch (err) {
    console.log(err, "err");
  }
}

function* repeatTaskSaga({ payload }) {
  const { id, name } = payload;
  try {
    yield call(getRequest, service.calculateTaskUrl(id));
    yield put(removeTaskLogs("SUCCESS", id));
    yield put(setInfoLogs("SUCCESS", `Задача «${name}» перезапущена.`));
  } catch (err) {
    yield put(setError("SUCCESS", `Задача «${name}» успешно не перезапущена.`));
  }
}

function* getFacilitiesFromProjectByTaskTypeSaga({ payload: taskType }) {
  try {
    const id = yield select(getProjectId);
    const url = service.getFacilitiesFromProjectByTaskType(id, taskType);
    const {
      data: { data }
    } = yield call(getRequest, url);
    yield put(getFacilitiesFromProjectByTaskType("SUCCESS", data[0]));
  } catch (err) {
    console.log(err);
  }
}

function* renameProjectSaga({ payload }) {
  try {
    const projectId = yield select(getProjectId);
    const getProjectsById = yield select(projects);
    const data = { ...getProjectsById[projectId] };
    data.name = payload;
    delete data.wellfieldName;
    delete data._created;
    delete data._updated;
    delete data.id;
    delete data.last_visited;
    yield call(putRequest, service.projectById(projectId), data);
    yield put(renameProject("SUCCESS", payload));
  } catch (error) {
    console.log(error);
  }
}

export default [
  createWatch(GET_TASKS_REQUEST, getAllTasksSaga),
  createWatch(CREATE_TASK_REQUEST, createTaskSaga),
  createWatch(REMOVE_TASK_REQUEST, removeTaskSaga),
  createWatch(REPEAT_TASK_REQUEST, repeatTaskSaga),
  createWatch(SEND_PROJECT_REQUEST, sendProjectSaga),
  createWatch(SEND_NETWORK_REQUEST, sendNetworkSaga),
  createWatch(REMOVE_OBJECT_REQUEST, removeObjectSaga),
  createWatch(CREATE_OBJECT_REQUEST, createObjectSaga),
  createWatch(SEND_FACILITY_REQUEST, sendFacilitySaga),
  createWatch(DOWNLOAD_TASK_REQUEST, downloadTaskSaga),
  createWatch(RENAME_PROJECT_REQUEST, renameProjectSaga),
  createWatch(REMOVE_PROJECT_REQUEST, removeProjectSaga),
  createWatch(GET_TASK_RESULTS_REQUEST, getTaskResultSaga),
  createWatch(DOWNLOAD_PROJECT_REQUEST, downloadProjectSaga),
  createWatch(GET_PROJECT_BY_ID_REQUEST, fetchProjectByIdSaga),
  createWatch(SET_NEW_PO_POSITION_REQUEST, setNewPoPositionSaga),
  createWatch(GET_PROJECT_PALETTE_REQUEST, getProjectPaletteSaga),
  createWatch(SET_PROJECT_VISITING_REQUEST, setProjectVisitingSaga),
  createWatch(GET_CALCULATED_OBJECTS_REQUEST, getCalculatedObjectsSaga),
  createWatch(GET_PREVIEW_PROJECT_PROPS_REQUEST, getPreviewProjectPropsSaga),
  createWatch(
    GET_FACILITIES_FROM_PROJECT_BY_TASK_TYPE_REQUEST,
    getFacilitiesFromProjectByTaskTypeSaga
  )
].map(watcher => fork(watcher));

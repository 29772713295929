import React, { useState, useEffect } from "react";
import { Popup } from "react-leaflet";

export default ({ points, onClosePopup, index }) => {
  const ref = React.useRef(null);
  const [state, setState] = useState({ distance: 0, point: [] });
  useEffect(() => {
    let point;
    let distance = 0;
    points.forEach(function(latLng) {
      if (point) {
        distance += point.distanceTo(latLng);
      }
      point = latLng;
    });
    setState({ distance, point });
  }, [points]);
  const onClick = e => {
    e.preventDefault();
    if (ref && ref.current) {
      ref.current.leafletElement._close();
      if (typeof onClosePopup === "function") {
        onClosePopup(index);
      }
    }
  };
  if (!state.distance) return null;
  return (
    <Popup
      ref={ref}
      className="ruler"
      position={state.point}
      closeButton={false}
    >
      <p>{(state.distance / 1000).toFixed(2) * 1} км</p>
      <a href="#close" onClick={onClick} className="leaflet-popup-close-button">
        ×
      </a>
    </Popup>
  );
};

export default {
  classesByWellfieldUrl: id => `/api/wellfields/${id}/classes`,
  classPropsUrl: id => `/api/wellfields/${id}/class_properties`,
  removeWellfield: wellfieldId => `/api/wellfields/${wellfieldId}`,
  getLayouts: wellfieldId => `api/wellfields/${wellfieldId}/layouts`,
  getLayoutFacility: layoutId => `api/layouts/${layoutId}/facilities`,
  metaclassPropsUrl: id => `/api/wellfields/${id}/metaclass_properties`,
  facilitiesByLayout: layoutId => `api/layouts/${layoutId}/facilities`,
  downloadWellfield: wellfieldId => `/api/wellfields/${wellfieldId}/dump`,
  layoutsByWellfield: wellfieldId => `api/wellfields/${wellfieldId}/layouts`,
  connectionTypes: wellfieldId =>
    `/api/wellfields/${wellfieldId}/connection_types`,
  demsByWellfield: id =>
    `/api/dems?where={"wellfield_id":"${id}"}&max_results=100500`,
  classesByMetaclassUrl: id =>
    `/api/classes?where={"metaclass": "${id}"}&sort=name&max_results=100500`,
  metaclassesByWellfieldUrl: id =>
    `/api/metaclasses?where={"wellfield_id":"${id}"}&max_results=100500`,
  task_properties: wellfieldId =>
    `/api/wellfields/${wellfieldId}/task_properties?max_results=100500`,
  addtitionalTaskProps: wellfieldId =>
    `api/wellfields/${wellfieldId}/calculated_linear_metaclasses?max_results=100500`
};

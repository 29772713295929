import React from "react";

export default ({ type }) => {
  switch (type) {
    case "objects":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 9.6C18 6.504 15.3086 4 12 4C8.68629 4 6 6.50721 6 9.6C6 13.8 12 20 12 20C12 20 18 13.8 18 9.6ZM13.5153 11.0142C13.1134 11.3893 12.5684 11.6 12.0001 11.6C10.8166 11.6 9.85722 10.7045 9.85722 9.59998C9.85722 8.49541 10.8166 7.59998 12.0001 7.59998C13.1835 7.59998 14.1429 8.49541 14.1429 9.59998C14.1429 10.1304 13.9172 10.6391 13.5153 11.0142Z"
            fill="white"
          />
        </svg>
      );
    case "areas":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 6C4 6.74028 4.4022 7.38663 5 7.73244V16.2676C4.4022 16.6134 4 17.2597 4 18C4 19.1046 4.89543 20 6 20C6.74028 20 7.38663 19.5978 7.73244 19H16.2676C16.6134 19.5978 17.2597 20 18 20C19.1046 20 20 19.1046 20 18C20 17.2597 19.5978 16.6134 19 16.2676V7.73244C19.5978 7.38663 20 6.74028 20 6C20 4.89543 19.1046 4 18 4C17.2597 4 16.6134 4.4022 16.2676 5H7.73244C7.38663 4.4022 6.74028 4 6 4C4.89543 4 4 4.89543 4 6ZM7.73244 17H16.2676C16.4432 16.6964 16.6964 16.4432 17 16.2676V7.73244C16.6964 7.5568 16.4432 7.30363 16.2676 7H7.73244C7.5568 7.30363 7.30363 7.5568 7 7.73244V16.2676C7.30363 16.4432 7.5568 16.6964 7.73244 17Z"
            fill="white"
          />
        </svg>
      );
    case "linear":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 7C22 8.1 21.1 9 20 9C19.82 9 19.65 8.98 19.49 8.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L5.93 16.49C5.98 16.65 6 16.82 6 17C6 18.1 5.1 19 4 19C2.9 19 2 18.1 2 17C2 15.9 2.9 15 4 15C4.18 15 4.35 15.02 4.51 15.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L18.07 7.51C18.02 7.35 18 7.18 18 7C18 5.9 18.9 5 20 5C21.1 5 22 5.9 22 7Z"
            fill="white"
          />
        </svg>
      );
    case "tasks":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2222 3.8H14.5067C14.1333 2.756 13.1556 2 12 2C10.8444 2 9.86667 2.756 9.49333 3.8H5.77778C4.79594 3.8 4 4.60589 4 5.6V18.2C4 19.1941 4.79594 20 5.77778 20H18.2222C19.2041 20 20 19.1941 20 18.2V5.6C20 4.60589 19.2041 3.8 18.2222 3.8ZM12 3.8C12.4909 3.8 12.8889 4.20294 12.8889 4.7C12.8889 5.19706 12.4909 5.6 12 5.6C11.5091 5.6 11.1111 5.19706 11.1111 4.7C11.1111 4.20294 11.5091 3.8 12 3.8ZM16 6V7H8V6H6V18H18V6H16ZM16 10V11.6H8V10H16ZM12 13H8V14.6H12V13Z"
            fill="white"
          />
        </svg>
      );
    case "ruler":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 16.331L4.69811 14.6588L7.65187 17.5793L8.91948 16.331L5.96572 13.4105L7.65187 11.75L9.34999 13.4105L10.6176 12.1621L8.91948 10.5017L10.6176 8.82938L13.5714 11.75L14.839 10.5017L11.8852 7.58107L13.5714 5.92058L15.2575 7.58107L16.5371 6.33276L14.839 4.67227L16.5371 3L21 7.58107L7.65187 21L3 16.331Z"
            fill="white"
          />
        </svg>
      );
    case "timeline":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7273 4.5C18.7439 4.5 22 7.85786 22 12C22 16.1421 18.7439 19.5 14.7273 19.5C10.7107 19.5 7.45455 16.1421 7.45455 12C7.45455 7.85786 10.7107 4.5 14.7273 4.5ZM14.7273 6.375C11.7148 6.375 9.27272 8.8934 9.27272 12C9.27272 15.1066 11.7148 17.625 14.7273 17.625C17.7397 17.625 20.1818 15.1066 20.1818 12C20.1818 8.8934 17.7397 6.375 14.7273 6.375ZM13.8182 8.25H15.1818V11.7937L17.3 13.9781L16.3364 14.9719L13.8182 12.375V8.25ZM2.90909 17.625C2.40701 17.625 2 17.2053 2 16.6875C2 16.1697 2.40701 15.75 2.90909 15.75H6.39091C6.67273 16.4156 7.03636 17.0437 7.45455 17.625H2.90909ZM3.81819 12.9375C3.31611 12.9375 2.9091 12.5178 2.9091 12C2.9091 11.4822 3.31611 11.0625 3.81819 11.0625H5.68182L5.63637 12L5.68182 12.9375H3.81819ZM4.72726 8.25C4.22519 8.25 3.81817 7.83027 3.81817 7.3125C3.81817 6.79473 4.22519 6.375 4.72726 6.375H7.45454C7.03635 6.95625 6.67272 7.58437 6.3909 8.25H4.72726Z"
            fill="white"
          />
        </svg>
      );
    case "heatMap":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.4444 4.9H20V6.7H16.4444V4.9ZM16.4444 8.5H20V10.3H16.4444V8.5ZM16.4444 12.1H20V13.9H17.1111L16.4444 13.09V12.1ZM20 15.7V17.5H18.2222C18.2222 16.879 18.1333 16.267 17.9644 15.7H20ZM16.4444 17.5C16.4444 18.6935 15.9762 19.8381 15.1427 20.682C14.3092 21.5259 13.1787 22 12 22C10.8213 22 9.6908 21.5259 8.8573 20.682C8.02381 19.8381 7.55556 18.6935 7.55556 17.5C7.55556 16.024 8.25778 14.719 9.33333 13.9V6.7C9.33333 5.98392 9.61428 5.29716 10.1144 4.79081C10.6145 4.28446 11.2928 4 12 4C12.7072 4 13.3855 4.28446 13.8856 4.79081C14.3857 5.29716 14.6667 5.98392 14.6667 6.7V13.9C15.7422 14.719 16.4444 16.024 16.4444 17.5ZM11.1111 9.4V14.953C10.0711 15.322 9.33333 16.321 9.33333 17.5C9.33333 18.2161 9.61428 18.9028 10.1144 19.4092C10.6145 19.9155 11.2928 20.2 12 20.2C12.7072 20.2 13.3855 19.9155 13.8856 19.4092C14.3857 18.9028 14.6667 18.2161 14.6667 17.5C14.6667 16.321 13.9289 15.322 12.8889 14.953V9.4H11.1111ZM7.55556 4.9V6.7H4V4.9H7.55556ZM7.55556 8.5V10.3H4V8.5H7.55556ZM7.55556 12.1V13.09L6.88889 13.9H4V12.1H7.55556ZM4 15.7H6.03556C5.86667 16.267 5.77778 16.879 5.77778 17.5H4V15.7Z"
            fill="white"
          />
        </svg>
      );
    default:
      return null;
  }
};

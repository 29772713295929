import React, { useRef } from "react";

import ScrollArea from "react-scrollbar/dist/no-css";

const ScrollBar = ({ children, className, errors, style = {} }) => {
  const _nodeRef = useRef(null);
  const updated = () => {
    if (_nodeRef.current) {
      _nodeRef.current.scrollArea.refresh();
    }
  };
  if (!children) return null;
  const childrenProps = {
    updated
  };
  if (errors) {
    childrenProps.errors = errors;
  }
  return (
    <ScrollArea
      speed={1}
      className={className}
      smoothScrolling
      ref={_nodeRef}
      style={{ ...style }}
    >
      {React.cloneElement(children, childrenProps)}
    </ScrollArea>
  );
};

export default ScrollBar;

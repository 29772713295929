/* eslint-disable react/no-deprecated */
import React, { Component } from "react";
import { connect } from "react-redux";

import dayjs from "dayjs";

import { taskLogs, taskId } from "state/selectors/projects";

import Log from "./Log";

class TaskLogs extends Component {
  state = { taskLogsValues: [] };

  componentDidMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleProps(nextProps);
  }

  handleProps = props => {
    const { taskLogs, taskId } = props;
    let taskLogsValues = [];
    if (taskLogs && taskId && taskLogs[taskId]) {
      taskLogsValues = taskLogs[taskId].map(({ _updated, ...props }) => ({
        ...props,
        date: dayjs(_updated).format("YYYY-MM-DD H:mm:ss")
      }));
      this.setState({
        taskLogsValues
      });
    }
  };

  render() {
    const { taskLogsValues } = this.state;
    if (!taskLogsValues.length) return null;
    return (
      <div className="task-complete__logs">
        {taskLogsValues.map(({ id, ...props }) => (
          <Log key={id} {...props} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskId: taskId(state),
  taskLogs: taskLogs(state)
});

export default connect(mapStateToProps)(TaskLogs);

export default {
  areasByIdUrl: id => `/api/areas/${id}`,
  linearsByIdUrl: id => `/api/linears/${id}`,
  facilityByIdUrl: id =>
    `/api/facilities/${id}?embedded={"connection_points":1}`,
  facilitiesUrl: projectId => `/api/projects/${projectId}/facilities`, // Потому что без перекрестков
  areasUrl: projectId =>
    `/api/areas?where={"project_id":"${projectId}"}&max_results=100500`,
  linearsUrl: projectId =>
    `/api/linears?where={"project_id":"${projectId}"}&max_results=100500`
};

import React from "react";

import _isEqual from "lodash.isequal";
import _get from "lodash.get";

import WithCommonObjectsFunc from "components/HOC/WithCommonObjectsFunc";
import setCondition from "./utils/setCondition";
import ListWrapper from "../../MainContainer/common/ListWrapper";

const LinearsList = React.memo(
  props => {
    const showRow = ({ id }) => props.hideLinears("SUCCESS", id);
    const showList = (item, subLayerKey, subLayerParent) => {
      if (!subLayerKey && !subLayerParent) {
        props.hideLinearsMetaclass("SUCCESS", item);
      } else if (subLayerKey && !subLayerParent) {
        props.hideLinearsByMetaclassAndType("SUCCESS", {
          metaclass: item,
          types: subLayerKey
        });
      } else {
        props.hideLinearsByMetaclassAndNetwork("SUCCESS", {
          metaclass: item,
          networks: subLayerKey,
          types: subLayerParent
        });
      }
    };
    const removeItem = ({ name, id, class_id: classId, type }) => {
      const {
        setRemoveProps,
        openRemoveAlert,
        sortClassesByMetaclasses,
        sortMetaclassesByClasses
      } = props;
      const { title: objectType } = _get(
        sortClassesByMetaclasses,
        sortMetaclassesByClasses[classId]
      );
      const condition =
        objectType.toLowerCase() === "просеки" ? "" : setCondition(type);
      setRemoveProps("SUCCESS", {
        id,
        name: name || "Без названия",
        objectType,
        typeTitle: "объекта",
        removeType: "linear",
        title: "линейного объекта",
        questionTitle: "линейный объект",
        condition
      });
      openRemoveAlert("SUCCESS", true);
    };
    const removeMetaclass = id => {
      const {
        setRemoveProps,
        openRemoveAlert,
        sortClassesByMetaclasses
      } = props;
      const { title: objectType } = _get(sortClassesByMetaclasses, id);
      setRemoveProps("SUCCESS", {
        id,
        objectType,
        typeTitle: "объекта",
        removeType: "metaclass",
        title: "группы линейных объектов",
        questionTitle: "группу линейных объектов"
      });
      openRemoveAlert("SUCCESS", true);
    };
    const removeMetaclassByType = (metaclassId, type, subLayerParent) => {
      const {
        networks,
        setRemoveProps,
        openRemoveAlert,
        sortClassesByMetaclasses
      } = props;
      const removeType = ["calculated", "existing"].includes(type)
        ? "metaclassByType"
        : "network";
      const { title: objectType } = _get(sortClassesByMetaclasses, metaclassId);
      let id;
      let title;
      let questionTitle;
      let name;
      let condition;
      if (removeType === "network") {
        id = type;
        name = networks[id][0].name;
        title = "сети линейных объектов";
        questionTitle = "сеть линейных объектов";
        condition = setCondition(subLayerParent);
      } else {
        id = metaclassId;
        title = "группы линейных объектов";
        questionTitle = "группу линейных объектов";
        condition = setCondition(type);
      }
      setRemoveProps("SUCCESS", {
        id,
        type,
        name,
        title,
        objectType,
        removeType,
        condition,
        questionTitle,
        typeTitle: "объекта"
      });
      openRemoveAlert("SUCCESS", true);
    };
    const handleTask = (id, type, task) => {
      const { activeTab, setTaskId, getTaskResult, setTaskFacilities } = props;
      if (task.props) {
        setTaskFacilities("SUCCESS", task.props.facilities_ids || []);
      }
      getTaskResult("REQUEST", { id, type });
      setTaskId("SUCCESS", task.id);
      activeTab("SUCCESS", "taskInfo");
    };
    const openTask = ({ taskId }) => {
      const item = props.getTasksById[taskId];
      if (item) {
        const { id, type } = item[0];
        props.setOpenedTasks("SUCCESS", {
          taskId: id,
          currentHandler: "results"
        });
        handleTask(id, type, item[0]);
      } else {
        props.setInfoLogs("SUCCESS", "Задачи не существует");
      }
    };
    const chooseRow = item => {
      props.setCardInfo("SUCCESS", {
        type: "linear",
        data: item
      });
      props.activeTab("SUCCESS", "linearInfoCard");
    };
    if (!props.getSortedLinears)
      return <h2 className="preload-text">Идёт загрузка...</h2>;
    if (!Object.keys(props.getSortedLinears).length) {
      return <h2 className="preload-text">Список линейных объектов пуст</h2>;
    }
    return (
      <ListWrapper
        objects={props.getSortedLinears}
        showRow={showRow}
        type={props.type}
        showList={showList}
        openTask={openTask}
        chooseRow={chooseRow}
        removeItem={removeItem}
        updated={props.updated}
        removeMetaclass={removeMetaclass}
        removeMetaclassByType={removeMetaclassByType}
      />
    );
  },
  (prevProps, nextProps) => {
    if (!_isEqual(prevProps.getSortedLinears, nextProps.getSortedLinears)) {
      return false;
    }
    return false;
  }
);

export default WithCommonObjectsFunc(LinearsList);

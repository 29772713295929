import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { activeTab } from "state/actions/settings";
import { getActiveTab } from "state/selectors/settings";
import ButtonList from "./ButtonList";

const Handlers = () => {
  const dispatch = useDispatch();
  const type = useSelector(getActiveTab);
  const optionHandler = currentType => {
    dispatch(activeTab("SUCCESS", currentType));
  };
  return (
    <div className="left-sideBar__options">
      <ButtonList optionHandler={optionHandler} />
      <button
        onClick={() => optionHandler("hide")}
        className={classnames("openLeftSideBar", { open: type })}
      />
    </div>
  );
};

export default Handlers;

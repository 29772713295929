import React, { Component } from "react";
import { connect } from "react-redux";
import { getHideLinearsByNetwork } from "state/selectors/groupsSettings";
import { hideLinearsByNetwork } from "state/actions/groupsSettings";
import _isEqual from "lodash.isequal";
import classnames from "classnames";

class HideNetwork extends Component {
  state = { hide: false };

  handleHide = props => {
    if (
      props.getHideLinearsByNetwork &&
      props.getHideLinearsByNetwork.includes(props.network_id)
    ) {
      this.setState({ hide: true });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.getHideLinearsByNetwork.includes(props.network_id) !== state.hide
    ) {
      return {
        hide: props.getHideLinearsByNetwork.includes(props.network_id)
      };
    }
    return null;
  }

  componentDidMount() {
    this.handleHide(this.props);
  }

  handleNetwork = () => {
    this.props.hideLinearsByNetwork("SUCCESS", this.props.network_id);
  };

  render() {
    const { hide } = this.state;
    return (
      <div
        onClick={this.handleNetwork}
        className={classnames("field-row__showRow-icon result-table-hide", {
          hide
        })}
      />
    );
  }
}

const mapStateToProps = state => ({
  getHideLinearsByNetwork: getHideLinearsByNetwork(state)
});

const mapDispatchToProps = {
  hideLinearsByNetwork
};

export default connect(mapStateToProps, mapDispatchToProps)(HideNetwork);

import React from "react";
import { Pane } from "react-leaflet";
import FacilityCreationTools from "./FacilityCreationTools";
import LinearCreationTools from "./LinearCreationTools";
import AreaCreationTools from "./AreaCreationTools";
import "./style.scss";

const style = { zIndex: "999" };

export default ({ layerRef, splashRef }) => {
  return (
    <Pane name="drawing-pane" style={style}>
      <FacilityCreationTools />
      <LinearCreationTools layerRef={layerRef} />
      <AreaCreationTools splashRef={splashRef} />
    </Pane>
  );
};

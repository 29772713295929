import React, { Component } from "react";

import classnames from "classnames";
import TopMenu from "./TopMenu";
import OptionButton from "../Handlers/OptionButton";
import EditProjectName from "./EditProjectName";

import "./style.scss";

export default class ExpandedTopMenuView extends Component {
  state = { showTopMenu: false };

  topMenuRef = React.createRef();

  handleClickOutside = event => {
    const { current } = this.topMenuRef;
    if (current && !current.contains(event.target)) {
      document.removeEventListener("mousedown", this.handleClickOutside);
      this.optionHandler();
    }
  };

  optionHandler = () => {
    this.setState(state => ({
      showTopMenu: !state.showTopMenu
    }));
  };

  render() {
    const { showTopMenu } = this.state;
    const { getActiveTab, projectName, removeProjectHandler } = this.props;
    return (
      <div className="left-sidebar__top">
        <div
          className={classnames("left-sideBar__options menu", {
            full: getActiveTab
          })}
        >
          <div ref={this.topMenuRef}>
            <OptionButton type="menu" onClick={this.optionHandler} />
            {showTopMenu && (
              <TopMenu
                optionHandler={this.optionHandler}
                removeProject={removeProjectHandler}
                handleClickOutside={this.handleClickOutside}
              />
            )}
          </div>
        </div>
        <h2
          className={classnames("leftSideBar__title_text", {
            shadow: !getActiveTab
          })}
        >
          {projectName}
        </h2>
        {getActiveTab && <EditProjectName projectName={projectName} />}
      </div>
    );
  }
}

import React from "react";
import { connect } from "react-redux";
import { additionalTaskProps } from "state/selectors/wellfield";
import {
  avaliableTaskTypes,
  getTasksById,
  taskLogs
} from "state/selectors/projects";
import _get from "lodash.get";
import _tranform from "lodash.transform";
import _isEqual from "lodash.isequal";
import taskConfig from "../../../CommonContainer/TopMenu/utils/tasksConfig";
import {
  poTypes,
  reduceArr,
  reduceTaskConfig,
  transformTasks,
  handleInaccessible,
  filterPipesType,
  filterInaccessible
} from "../utils";
import ProjectInfoView from "./ProjectInfoView";

class ProjectInfo extends React.Component {
  state = { inaccessibleFields: [], tasks: {} };

  componentDidMount() {
    this.handleProps();
    this.handleTasksLogs();
  }

  componentDidUpdate(prevProps) {
    const {
      getTasksById,
      taskLogs,
      additionalTaskProps,
      avaliableTaskTypes
    } = this.props;
    if (
      !_isEqual(prevProps.getTasksById, getTasksById) ||
      !_isEqual(prevProps.taskLogs, taskLogs)
    )
      this.handleTasksLogs();

    if (
      !_isEqual(prevProps.additionalTaskProps, additionalTaskProps) ||
      !_isEqual(prevProps.avaliableTaskTypes, avaliableTaskTypes)
    )
      this.handleProps();
  }

  handleTasksLogs = () => {
    const { getTasksById, taskLogs } = this.props;
    if (getTasksById) {
      const d = _tranform(getTasksById, transformTasks(taskLogs), {});
      this.setState(() => ({ tasks: d }));
    }
  };

  handleProps = () => {
    const { additionalTaskProps, avaliableTaskTypes } = this.props;
    const pipesTypeArr = additionalTaskProps?.pipes ?? [];
    let pipesType = pipesTypeArr.slice();
    const fields = {
      pipes: "Расчет сети трубопроводов",
      ...taskConfig.reduce(reduceTaskConfig, {}),
      ...pipesTypeArr.reduce(reduceArr, {}),
      ...poTypes.reduce(reduceArr, {})
    };
    const inaccessibleTasks =
      _get(avaliableTaskTypes, [0, "inaccessible_tasks"]) || {};
    let inaccessibleTasksArr = Object.keys(inaccessibleTasks);
    if (inaccessibleTasks) {
      pipesType = pipesType.filter(filterPipesType(inaccessibleTasks));
      if (!pipesType.length) {
        inaccessibleTasksArr = inaccessibleTasksArr.filter(filterInaccessible);
        inaccessibleTasksArr.push("pipes");
      }
    }
    const inaccessible = inaccessibleTasksArr.map(handleInaccessible(fields));
    this.setState({ inaccessibleFields: inaccessible });
  };

  render() {
    const { innerRef } = this.props;
    const { tasks, inaccessibleFields } = this.state;
    const tasksTitles = [];
    let doneTasks = [];
    let errorTasks = [];
    Object.keys(tasks).forEach(key => {
      const curr = tasks[key];
      const done = curr?.done;
      const error = curr?.error;
      const title = curr?.title;
      tasksTitles.push(title);

      if (done || error) {
        if (done) {
          doneTasks.push(done.count);
        }
        if (error) {
          errorTasks.push(error.count);
        }
        if (doneTasks.length !== errorTasks.length) {
          const len = Math.abs(errorTasks.length - doneTasks.length);
          const arr = [...Array(len)].map(() => 0);
          if (errorTasks.length < doneTasks.length) {
            errorTasks = [...errorTasks, ...arr];
          } else {
            doneTasks = [...doneTasks, ...arr];
          }
        }
      } else {
        doneTasks.push("waiting");
        errorTasks.push("waiting");
      }
    });
    return (
      <div ref={innerRef} className="project-list__tooltip-container">
        <ProjectInfoView
          inaccessibleFields={inaccessibleFields}
          tasksTitles={tasksTitles}
          doneTasks={doneTasks}
          errorTasks={errorTasks}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskLogs: taskLogs(state),
  getTasksById: getTasksById(state),
  avaliableTaskTypes: avaliableTaskTypes(state),
  additionalTaskProps: additionalTaskProps(state)
});

export default connect(mapStateToProps)(ProjectInfo);

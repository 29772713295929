import React, { Component } from "react";
import { connect } from "react-redux";
import {
  classesByWellfield,
  classesByMetaclasses,
  getMetaclassesByWellfield
} from "state/selectors/wellfield";
import { projectPalette } from "state/selectors/projects";
import {
  getSettingsClassId,
  getSettingsMetaclassId
} from "state/selectors/settings";
import { changeTaskSettings, setTaskSettings } from "state/actions/settings";

import groupBy from "utils/groupBy";
import _get from "lodash.get";
import CommonField from "../../CommonField";

class CommonFields extends Component {
  state = {
    name: "",
    options: {
      classes: [],
      metaclasses: []
    }
  };

  componentDidMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.handleProps(nextProps);
  }

  onChangeHandler = (value, type) => {
    if (type === "metaclass_id") {
      const { metaclass_id } = this.state;
      const { changeTaskSettings } = this.props;
      if (metaclass_id !== value) {
        this.setState({
          metaclass_id: value
        });
        changeTaskSettings("SUCCESS", { metaclass_id: value });
      }
    }
  };

  handleProps = props => {
    const {
      data,
      projectPalette,
      getSettingsClassId,
      getSettingsMetaclassId,
      classesByWellfield,
      classesByMetaclasses,
      getMetaclassesByWellfield
    } = props;
    let class_id = getSettingsClassId;
    if (!class_id && data) {
      class_id = data.class_id;
    }
    const metaclass_id = getSettingsMetaclassId;
    let classes = [];
    const metaclasses = [];
    if (classesByMetaclasses[metaclass_id]) {
      for (const item of classesByMetaclasses[metaclass_id]) {
        classes.push({
          value: item.id,
          label: item.name
        });
      }
    } else if (class_id) {
      classes = [
        {
          value: _get(classesByWellfield, [class_id, "id"]),
          label: _get(classesByWellfield, [class_id, "name"])
        }
      ];
    }
    if (getMetaclassesByWellfield) {
      const { area } = groupBy(getMetaclassesByWellfield, "type");
      for (const item of area) {
        metaclasses.push({
          value: item.id,
          label: item.name
        });
      }
    }
    let object = {
      class_id,
      metaclass_id,
      options: {
        classes,
        metaclasses
      }
    };
    if (data) {
      object = {
        ...object,
        ...data
      };
    }
    if (classes && projectPalette) {
      const palette = projectPalette[_get(classes, [0, "value"])];
      if (palette) {
        object.palette = palette;
        if (this.state.palette !== palette) {
          this.props.changeTaskSettings("SUCCESS", { palette });
        }
      }
    }
    this.setState(object);
  };

  render() {
    const { errors = [] } = this.props;
    const data = [
      {
        propName: "name",
        placeholder: "",
        required: true,
        defaultValue: this.state.name,
        classContainer: "wide",
        label: "Название объекта",
        disabled: this.props.info
      },
      {
        propName: "metaclass_id",
        type: "option",
        placeholder: "",
        required: true,
        defaultValue: this.state.metaclass_id,
        classContainer: "wide",
        label: "Тип области",
        onChangeHandler: value => this.onChangeHandler(value, "metaclass_id"),
        options: this.state.options.metaclasses,
        disabled: this.props.info
      },
      {
        propName: "class_id",
        type: "option",
        placeholder: "",
        required: true,
        defaultValue: this.state.class_id,
        classContainer: "wide",
        label: "Класс",
        onChangeHandler: this.onChangeHandler,
        options: this.state.options.classes,
        disabled: this.props.info
      }
    ];
    return (
      <div className="create-task-container">
        {data.map((addedProps, key) => {
          return (
            <div key={key} className="create-task-row">
              <CommonField
                addedProps={addedProps}
                error={errors.includes(addedProps.propName)}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  projectPalette: projectPalette(state),
  getSettingsClassId: getSettingsClassId(state),
  getSettingsMetaclassId: getSettingsMetaclassId(state),
  classesByWellfield: classesByWellfield(state),
  classesByMetaclasses: classesByMetaclasses(state),
  getMetaclassesByWellfield: getMetaclassesByWellfield(state)
});

const mapDispatchToProps = {
  setTaskSettings,
  changeTaskSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonFields);

export default dem => {
  switch (dem) {
    case "deg":
      return "\u00BA";
    case "rubPerMetr":
      return "\u20BD/м\u00B2";
    default:
      return dem;
  }
};

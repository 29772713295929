import React, { Component } from "react";
import { connect } from "react-redux";
import { lockFacilities } from "state/actions/groupsSettings";
import { getLockedFacilities } from "state/selectors/groupsSettings";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";

class LockRow extends Component {
  state = { hide: false };

  componentDidMount() {
    this.checkProps(this.props);
  }

  static getDerivedStateFromProps(nextProps) {
    const { getLockedFacilities, item } = nextProps;
    const hide = getLockedFacilities.includes(item.id);
    return {
      hide
    };
  }

  checkProps = props => {
    const { getLockedFacilities, item } = props;
    if (getLockedFacilities.includes(item.id)) {
      this.setState({
        hide: true
      });
    }
  };

  lockRow = () => {
    const { hide } = this.state;
    const { item, lockFacilities } = this.props;
    ReactTooltip.rebuild();
    this.setState({ hide: !hide }, () => lockFacilities("SUCCESS", item.id));
  };

  render() {
    const { hide } = this.state;
    return (
      <span
        data-tip={hide ? "Запретить перемещение" : "Разрешить перемещение"}
        className="field-row__lock"
        data-for="mainTooltip"
      >
        <button
          className={classnames("field-row__lock-icon", { hide })}
          onClick={this.lockRow}
        />
      </span>
    );
  }
}

const mapStateToProps = state => ({
  getLockedFacilities: getLockedFacilities(state)
});

const mapDispatchToProps = { lockFacilities };

export default connect(mapStateToProps, mapDispatchToProps)(LockRow);

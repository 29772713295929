export const CREATE_TASK = "CREATE_TASK";
export const CHANGE_TASK_SETTINGS = "CHANGE_TASK_SETTINGS";
export const SET_TASK_SETTINGS = "SET_TASK_SETTINGS";
export const CLICK_TYPE = "CLICK_TYPE";
export const SET_TRANSFORM_SETTINGS = "SET_TRANSFORM_SETTINGS";
export const CREATE_OBJECT = "CREATE_OBJECT";
export const REMOVE_CLICK_TYPE = "REMOVE_CLICK_TYPE";
export const SET_TASK_FACILITIES = "SET_TASK_FACILITIES";
export const SHOW_TASK_RESULTS = "SHOW_TASK_RESULTS";
export const TASK_STARTED = "TASK_STARTED";
export const ACTIVE_TAB = "ACTIVE_TAB";
export const SET_REMOVE_PROPS = "SET_REMOVE_PROPS";
export const OPEN_REMOVE_ALERT = "OPEN_REMOVE_ALERT";
export const SHOW_LOADER = "SHOW_LOADER";
export const SET_DEFAULT_PROPS = "SET_DEFAULT_PROPS";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_HELP_MODAL = "SHOW_HELP_MODAL";
export const SHOW_CANCEL_CONFIRMATION_MODAL = "SHOW_CANCEL_CONFIRMATION_MODAL";
export const SET_CARD_INFO = "SET_CARD_INFO";
export const SET_ACTIVE_FACILITIES = "SET_ACTIVE_FACILITIES";
export const ADD_TASK_POINTS = "ADD_TASK_POINTS";
// SUCCESS
export const SET_CARD_INFO_SUCCESS = "SET_CARD_INFO_SUCCESS";
export const SHOW_MODAL_SUCCESS = "SHOW_MODAL_SUCCESS";
export const SET_DEFAULT_PROPS_SUCCESS = "SET_DEFAULT_PROPS_SUCCESS";
export const ADD_TASK_POINTS_SUCCESS = "ADD_TASK_POINTS_SUCCESS";
export const SHOW_LOADER_SUCCESS = "SHOW_LOADER_SUCCESS";
export const SET_REMOVE_PROPS_SUCCESS = "SET_REMOVE_PROPS_SUCCESS";
export const OPEN_REMOVE_ALERT_SUCCESS = "OPEN_REMOVE_ALERT_SUCCESS";
export const ACTIVE_TAB_SUCCESS = "ACTIVE_TAB_SUCCESS";
export const TASK_STARTED_SUCCESS = "TASK_STARTED_SUCCESS";
export const SET_TASK_FACILITIES_SUCCESS = "SET_TASK_FACILITIES_SUCCESS";
export const SHOW_TASK_RESULTS_SUCCESS = "SHOW_TASK_RESULTS_SUCCESS";
export const REMOVE_CLICK_TYPE_SUCCESS = "REMOVE_CLICK_TYPE_SUCCESS";
export const SET_TRANSFORM_SETTINGS_SUCCESS = "SET_TRANSFORM_SETTINGS_SUCCESS";
export const SET_TASK_SETTINGS_SUCCESS = "SET_TASK_SETTINGS_SUCCESS";
export const CHANGE_TASK_SETTINGS_SUCCESS = "CHANGE_TASK_SETTINGS_SUCCESS";
export const CLICK_TYPE_SUCCESS = "CLICK_TYPE_SUCCESS";
export const SET_ACTIVE_FACILITIES_SUCCESS = "SET_ACTIVE_FACILITIES_SUCCESS";

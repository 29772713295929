import { connect } from "react-redux";
import {
  activeTab,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities
} from "state/actions/settings";
import {
  setTaskId,
  repeatTask,
  getTaskResult,
  setQuarriesIds,
  removeTaskLogs
} from "state/actions/projects";
import { taskInfo, taskLogs } from "state/selectors/projects";
import { cardInfo } from "state/selectors/settings";
import { classesByWellfield } from "state/selectors/wellfield";

import InfoCardContainer from "./InfoCardContainer";

const mapDispatchToProps = {
  activeTab,
  setTaskId,
  repeatTask,
  getTaskResult,
  removeTaskLogs,
  setQuarriesIds,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities
};

const mapStateToProps = state => ({
  cardInfo: cardInfo(state),
  taskInfo: taskInfo(state),
  taskLogs: taskLogs(state),
  classesByWellfield: classesByWellfield(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoCardContainer);

import React from "react";
import classnames from "classnames";
import CommonField from "../../CommonField";

const RadioComponent = ({ onChange, info, defaultValue, active }) => {
  return (
    <div>
      <button
        className={classnames("radio-component-row", {
          active: active === "quarries"
        })}
        onClick={() => onChange("quarries")}
      >
        <div className="radio-circle" />
        <h3 className="radio-component-text">
          Использовать при расчете карьеры
        </h3>
      </button>
      <button
        className={classnames("radio-component-row", {
          active: active === "distanceToQuarry"
        })}
        onClick={() => onChange("distanceToQuarry")}
      >
        <div className="radio-circle" />
        <h3 className="radio-component-text">
          Использовать при расчете дальность возки грунта
        </h3>
        {active === "distanceToQuarry" && (
          <div className="radio-range">
            <CommonField
              addedProps={{
                propName: "props.distance_to_quarry",
                label: "Дальность возки грунта для отсыпки",
                dem: "м",
                required: true,
                type: "number",
                disabled: info,
                defaultValue
              }}
            />
          </div>
        )}
      </button>
    </div>
  );
};

export default RadioComponent;

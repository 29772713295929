import React, { Component } from "react";
import { connect } from "react-redux";
import { changeTaskSettings } from "state/actions/settings";

class CheckBox extends Component {
  state = { checked: false };

  componentDidMount() {
    this.setPropsToState(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultValue } = this.props;
    if (defaultValue !== undefined && defaultValue !== prevState.checked) {
      this.setState({
        checked: prevProps.defaultValue
      });
    }
  }

  setPropsToState = props => {
    const { defaultValue } = props;
    if (defaultValue !== undefined) {
      this.setState({
        checked: defaultValue
      });
    }
  };

  onChangeHandler = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked }, () => {
      const { checked } = this.state;
      const { onChange, propName, changeTaskSettings } = this.props;
      if (typeof onChange === "function") {
        onChange(checked);
      }
      if (propName) {
        changeTaskSettings("SUCCESS", {
          [propName]: checked
        });
      }
    });
  };

  render() {
    const { checked } = this.state;
    const { id, label, disabled, propName: name } = this.props;
    return (
      <div className="checkBoxContainer">
        <input
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          className="checkBoxInput"
          onChange={this.onChangeHandler}
        />
        <label htmlFor={id} className="checkBoxLabel">
          <span className="checkBoxSpan">{label}</span>
        </label>
      </div>
    );
  }
}

export default connect(null, { changeTaskSettings })(CheckBox);

import React, { Component } from "react";

import ToggleContainer from "components/Common/ToggleContainer";
import TitleBefore from "./TitleBefore";
import TitleAfter from "./TitleAfter";

import ListBeforeComponent from "./ListBeforeComponent";
import ListAfterComponent from "./ListAfterComponent";

class ListWrapperItem extends Component {
  active = () => "";

  chooseRow = item => {
    const { chooseRow } = this.props;
    if (typeof chooseRow === "function") {
      chooseRow(item);
    }
  };

  showList = (...args) => {
    const { showList } = this.props;
    showList(...args);
  };

  showRow = item => {
    const { showRow } = this.props;
    showRow(item);
  };

  render() {
    const {
      type,
      objects,
      itemKey,
      lockRow,
      setView,
      openTask,
      updated,
      removeItem,
      onMouseEnter,
      onMouseLeave,
      removeMetaclass,
      removeMetaclassByType
    } = this.props;
    const listBefore = (item, bgColor) => (
      <ListBeforeComponent
        item={item}
        type={type}
        bgColor={bgColor}
        showRow={this.showRow}
      />
    );
    const listAfter = item => {
      let tooltipType = "";
      if (item.facility_area) {
        tooltipType = "объект";
      } else if (item.geom && item.geom.type === "LineString") {
        tooltipType = "объект";
      } else {
        tooltipType = "область";
      }
      return (
        <ListAfterComponent
          setView={setView}
          item={item}
          tooltipType={tooltipType}
          removeItem={removeItem}
          lockRow={lockRow}
        />
      );
    };
    let titleProps = { ...objects[itemKey] };
    delete titleProps.subLayer;
    delete titleProps.data;
    titleProps = {
      ...titleProps,
      onMouseEnter,
      onMouseLeave,
      itemKey,
      afterComp: ([func, subLayerKey]) => (
        <TitleAfter
          remove={func}
          openTask={openTask}
          subLayerKey={subLayerKey}
        />
      ),
      beforeComp: (bgColor, subLayerKey, subLayerParent) => (
        <TitleBefore
          type={type}
          bgColor={bgColor}
          itemKey={itemKey}
          showList={this.showList}
          subLayerKey={subLayerKey}
          object={objects[itemKey]}
          subLayerParent={subLayerParent}
        />
      ),
      titleClass: "d-inline align-middle",
      afterCompFunc: [() => removeMetaclass(itemKey)],
      subAfterCompFunc: (type, subLayerParent) =>
        removeMetaclassByType(itemKey, type, subLayerParent)
    };
    return (
      <ToggleContainer
        updated={updated}
        titleProps={titleProps}
        listProps={{
          onMouseEnter,
          onMouseLeave,
          active: this.active,
          afterComp: listAfter,
          beforeComp: listBefore,
          chooseRow: this.chooseRow,
          data: objects[itemKey].values,
          subLayer: objects[itemKey].subLayer,
          rowClass: objects[itemKey].rowClass,
          beforeProp: objects[itemKey].beforeProp,
          containerClass: "relative field-text-row"
        }}
      />
    );
  }
}

export default ListWrapperItem;

import React, { Component } from "react";
import ScrollBar from "components/Common/ScrollBar";
import { taskConstants } from "utils/constants";
import _set from "lodash.set";
import TaskData from "../TaskData";
import MainContainerTop from "./MainContainerTop";
import MainContainerBottom from "./MainContainerBottom";
import Validation from "../Validation";
import ObjectsValidation from "../Validation/ObjectsValidation";
import ObjectsCard from "../ObjectsCard";

const { PIPES, ...taskTypes } = taskConstants;

const checkSelectedObjects = (objects, type) => {
  for (const key of ObjectsValidation(type)) {
    if (!objects[key]) return false;
    if (objects[key] instanceof Array) {
      if (!objects[key].length) return false;
    }
  }
  return true;
};

class CreateCardContainer extends Component {
  componentWillUnmount() {
    const { getFacilitiesFromProjectByTaskType, removeClickType } = this.props;
    getFacilitiesFromProjectByTaskType("SUCCESS", false);
    removeClickType("SUCCESS");
  }

  sendNewFacilities = object => {
    const {
      getTaskSettings,
      sendFacility,
      project_id,
      removeClickType
    } = this.props;
    const { center_point, facility_area } = getTaskSettings;
    const currentObject = { ...object };
    delete currentObject.metaClassId;
    delete currentObject.lng;
    delete currentObject.lat;
    currentObject.props = {
      ...currentObject.props,
      start_date: null,
      finish_date: null
    };
    center_point.coordinates = center_point.coordinates.reverse();
    sendFacility("REQUEST", {
      ...currentObject,
      project_id,
      center_point,
      facility_area
    });
    removeClickType("SUCCESS");
  };

  createArea = taskProps => {
    const {
      type,
      activeTab,
      project_id,
      createObject,
      getTaskSettings,
      removeClickType
    } = this.props;
    const { geom } = getTaskSettings;
    const { class_id, name, metaclass_id } = taskProps;
    const data = { name, geom, class_id, project_id, metaclass_id };
    createObject("REQUEST", { type, data });
    activeTab("SUCCESS", "areas");
    removeClickType("SUCCESS");
  };

  createLinearObject = taskProps => {
    const {
      activeTab,
      removeClickType,
      getTaskSettings,
      createObject,
      project_id,
      createTask
    } = this.props;
    const { geom } = getTaskSettings;
    if (geom) {
      const { type, ...data } = taskProps;
      createObject("REQUEST", {
        type,
        data: {
          ...data,
          geom,
          type: "existing",
          project_id,
          props: {
            ...data.props,
            finish_date: null,
            start_date: null
          }
        }
      });
      activeTab("SUCCESS", "linear");
      createTask("SUCCESS", false);
      removeClickType("SUCCESS");
    }
  };

  createTask = taskProps => {
    const {
      type,
      selectedObjects,
      createTask,
      activeTab,
      removeClickType
    } = this.props;
    taskProps.props = { ...taskProps.props, ...selectedObjects };
    const checkObjects = checkSelectedObjects(selectedObjects, type);
    if (checkObjects) {
      const props = { ...taskProps };
      if (type === "facility_placement") {
        if (taskProps.props.distance_to_quarry !== undefined) {
          delete props.props.quarries_ids;
        }
      }
      createTask("SUCCESS", false);
      createTask("REQUEST", {
        type: props.type,
        data: props
      });
      activeTab("SUCCESS", "tasks");
      removeClickType("SUCCESS");
    }
  };

  handleSubmit = ({ elements }) => {
    const { type, project_id } = this.props;
    const taskProps = {
      type,
      props: { project_id }
    };
    Array.from(elements).forEach(elem => {
      if (elem.name && elem.nodeName === "INPUT") {
        const { name, value, type } = elem;
        let currentValue = "";
        if (
          ["priority", "props.amount_of_solutions"].includes(name) ||
          type === "number"
        ) {
          currentValue = parseFloat(value || 0);
        } else if (elem.type === "checkbox") {
          currentValue = elem.checked;
        } else {
          currentValue = value;
        }
        _set(taskProps, name, currentValue);
      }
    });
    if ([...Object.values(taskTypes), ...PIPES].includes(type)) {
      if (taskProps.type === "roads") {
        if (!taskProps.props.use_roads_params) {
          taskProps.props.height_angle = 0;
          taskProps.props.minimal_radius = 0;
        }
      }
      this.createTask(taskProps);
    }
    if (type === "createFacility") {
      this.sendNewFacilities(taskProps);
    }
    if (type === "createArea") {
      this.createArea(taskProps);
    }
    if (["createLinearObject"].includes(type)) {
      this.createLinearObject(taskProps);
    }
  };

  render() {
    const { type } = this.props;
    return (
      <Validation type={type} handleSubmit={this.handleSubmit}>
        <MainContainerTop />
        <ScrollBar>
          {(() => {
            if ([...PIPES, ...Object.values(taskTypes)].includes(type)) {
              return <TaskData type={type} />;
            }
            return <ObjectsCard type={type} />;
          })()}
        </ScrollBar>
        <MainContainerBottom />
      </Validation>
    );
  }
}

export default CreateCardContainer;

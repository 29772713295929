import {
  call,
  put,
  fork,
  takeLatest,
  cancelled,
  take,
  cancel,
  select
} from "redux-saga/effects";
import { getRequest, deleteRequest } from "api";
import service from "service/objects";

import {
  getAreas,
  getlinears,
  removeAreas,
  removeLinears,
  getFacilities,
  removeFacility,
  getFacilitiesById
} from "state/actions/objects";

import { setError } from "state/actions/common";

import { changeTaskSettings } from "state/actions/settings";
import { CLEAR_STATE_SUCCESS } from "state/constants/projects";
import {
  GET_AREAS_REQUEST,
  SELECT_ALL_REQUEST,
  GET_LINEARS_REQUEST,
  GET_FACILITIES_REQUEST,
  REMOVE_FACILITY_REQUEST,
  GET_FACILITIES_BY_ID_REQUEST,
  REMOVE_LINEARS_REQUEST,
  REMOVE_AREAS_REQUEST
} from "state/constants/objects";

import { getFacilitiesByType } from "state/selectors/objects";

import { facilitiesFromProjectByTaskType } from "state/selectors/settings";

import _get from "lodash.get";
import { getAvaliableTaskTypesSaga } from "./projects";

const createWatch = (type, saga) =>
  function*() {
    yield takeLatest(type, saga);
  };

function* commonSaga(func) {
  try {
    yield func;
  } catch (error) {
    const {
      response: { data }
    } = error;
    yield put(setError("SUCCESS", data.user_message));
  } finally {
    if (yield cancelled()) {
      console.log("cancelled");
    }
  }
}

function* fetchAreas({ payload: data }) {
  const { id, metaclassesByClasses } = data;
  const url = service.areasUrl(id);
  try {
    const {
      data: { data }
    } = yield call(getRequest, url);
    yield put(
      getAreas(
        "SUCCESS",
        data.map(item => ({
          ...item,
          metaclass_id: _get(metaclassesByClasses, item.class_id)
        }))
      )
    );
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* fetchLinears({ payload: data }) {
  const { id, metaclassesByClasses } = data;
  const url = service.linearsUrl(id);
  try {
    const {
      data: { data }
    } = yield call(getRequest, url);
    yield put(
      getlinears(
        "SUCCESS",
        data.map(item => {
          return {
            ...item,
            first_network: item.networks_ids.length
              ? item.networks_ids[0]
              : "without_network",
            metaclass_id: _get(metaclassesByClasses, item.class_id)
          };
        })
      )
    );
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* fetchFacilities({ payload: data }) {
  const { id } = data;
  const url = service.facilitiesUrl(id);
  try {
    const {
      data: { data }
    } = yield call(getRequest, url);
    yield put(getFacilities("SUCCESS", data));
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* getObjectsSaga(func) {
  const req = yield fork(commonSaga, func);
  yield take(CLEAR_STATE_SUCCESS);
  yield cancel(req);
}

function* fetchAreasSaga(data) {
  yield call(getObjectsSaga, fetchAreas(data));
}

function* fetchLinearsSaga(data) {
  yield call(getObjectsSaga, fetchLinears(data));
}

function* fetchFacilitiesSaga(data) {
  yield call(getObjectsSaga, fetchFacilities(data));
}

function* selectAllSaga({ payload }) {
  if (payload === "facilities_ids") {
    const facilities = yield select(getFacilitiesByType);
    delete facilities.calculated_tmp;
    let arr = [];
    yield Object.keys(facilities).forEach(key => {
      arr = arr.concat(facilities[key]);
    });
    yield put(
      changeTaskSettings("SUCCESS", {
        [`props.${payload}`]: arr.map(({ id }) => id)
      })
    );
  } else {
    const objects = yield select(facilitiesFromProjectByTaskType);
    yield put(
      changeTaskSettings("SUCCESS", {
        [`props.${payload}`]: objects[payload]
      })
    );
  }
}

function* getFacilitiesByIdSaga({ payload }) {
  try {
    const { id } = payload;
    const url = service.facilityByIdUrl(id);
    const { data } = yield call(getRequest, url);
    yield put(getFacilitiesById("SUCCESS", data));
  } catch (error) {
    console.log(error);
  }
}

function* removeFacilitySaga({ payload }) {
  const { id } = payload;
  try {
    const url = service.facilityByIdUrl(id);
    yield call(deleteRequest, url);
    yield put(removeFacility("SUCCESS", id));
    yield getAvaliableTaskTypesSaga();
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* removeLirearsSaga({ payload }) {
  const { id } = payload;
  try {
    const url = service.linearsByIdUrl(id);
    yield call(deleteRequest, url);
    yield put(removeLinears("SUCCESS", id));
    yield getAvaliableTaskTypesSaga();
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

function* removeAreasSaga({ payload }) {
  const { id } = payload;
  try {
    const url = service.areasByIdUrl(id);
    yield call(deleteRequest, url);
    yield put(removeAreas("SUCCESS", id));
    yield getAvaliableTaskTypesSaga();
  } catch (error) {
    const { response } = error;
    yield put(setError("SUCCESS", response.data));
  }
}

export default [
  createWatch(SELECT_ALL_REQUEST, selectAllSaga),
  createWatch(GET_AREAS_REQUEST, fetchAreasSaga),
  createWatch(GET_LINEARS_REQUEST, fetchLinearsSaga),
  createWatch(REMOVE_AREAS_REQUEST, removeAreasSaga),
  createWatch(REMOVE_LINEARS_REQUEST, removeLirearsSaga),
  createWatch(GET_FACILITIES_REQUEST, fetchFacilitiesSaga),
  createWatch(REMOVE_FACILITY_REQUEST, removeFacilitySaga),
  createWatch(GET_FACILITIES_BY_ID_REQUEST, getFacilitiesByIdSaga)
].map(watcher => fork(watcher));

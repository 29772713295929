import React from "react";
import HelpModalView from "./HelpModalView";

const HelpModalContainer = ({ setShowModal }) => {
  const closeHandler = () => {
    setShowModal("SUCCESS", { type: "", option: null });
  };
  return <HelpModalView closeHandler={closeHandler} />;
};

export default HelpModalContainer;

import { connect } from "react-redux";
import {
  createLinearObject,
  draggable,
  draw,
  magnet,
  repaint
} from "state/selectors/settings";
import { setTransformSettings } from "state/actions/settings";
import LinearHandlersContainer from "./LinearHandlersContainer";

const mapDispatchToProps = {
  setTransformSettings
};

const mapStateToProps = state => ({
  draw: draw(state),
  magnet: magnet(state),
  repaint: repaint(state),
  draggable: draggable(state),
  createLinearObject: createLinearObject(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearHandlersContainer);

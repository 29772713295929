import React from "react";
import ShowRows from "./ShowRow";

const ListBeforeComponent = ({ type, item, showRow, bgColor }) => (
  <>
    <ShowRows type={type} item={item} showRow={showRow} />
    <span className="colorLine">
      <span className="colorLineColor" style={{ backgroundColor: bgColor }} />
    </span>
  </>
);

export default ListBeforeComponent;

const priority = [
  { value: 0, label: "Низкий" },
  { value: 4, label: "Средний" },
  { value: 9, label: "Высокий" }
];

const data = [
  {
    propName: "name",
    placeholder: "",
    required: true,
    classContainer: "wide",
    label: "Название задачи"
  },
  {
    propName: "priority",
    type: "option",
    placeholder: "",
    required: true,
    classContainer: "half",
    label: "Приоритет",
    defaultValue: 4,
    options: priority
  },
  "dem",
  {
    parentContainerClass: "DataFieldsRow half",
    propName: "props.number_of_power_supply_centers",
    placeholder: "",
    type: "number",
    required: true,
    defaultValue: 1,
    classContainer: "DataFieldsRow half",
    content: "≤ кол-ва кустовых площадок",
    label: "Количество ЭСН"
  }
];

export default data;

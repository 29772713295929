import React from "react";
import { area as areaSquare } from "@turf/turf";
import { divIcon } from "leaflet";
import { connect } from "react-redux";
import { changeTaskSettings } from "state/actions/settings";
import { getTaskSettings } from "state/selectors/settings";
import coloringObj from "utils/coloringObj";
import { Patterns } from "react-leaflet-geojson-patterns";
import PatternsObj from "../../utils/Patterns";
import { DrawingPolygon } from "../DrawingTools";

const AreaCreationTools = ({
  getTaskSettings,
  changeTaskSettings,
  splashRef,
  newProjectArea
}) => {
  const updatePolygonPositions = positions => {
    const geom = {
      type: "Polygon",
      coordinates: [positions]
    };
    geom.area = (areaSquare(geom) / 1000000).toFixed(3) * 1;
    changeTaskSettings("SUCCESS", { geom });
  };
  let myIcon = divIcon({
    className: `draw-icon ${getTaskSettings.palette}`,
    iconSize: [16, 16],
    iconAnchor: [8, 8]
  });
  const patterns = PatternsObj();
  let polyStyle = {};
  if (getTaskSettings.palette) {
    myIcon = divIcon({
      className: `draw-icon ${getTaskSettings.palette}`,
      iconSize: [16, 16],
      iconAnchor: [8, 8]
    });
    polyStyle = {
      ...coloringObj(getTaskSettings.palette),
      weight: 0,
      fillOpacity: 1
    };
    if (patterns[getTaskSettings.palette]) {
      polyStyle.fillPattern = Patterns.PathPattern(
        patterns[getTaskSettings.palette]
      );
    } else {
      polyStyle.weight = 1;
    }
  }
  return (
    <DrawingPolygon
      pointIcon={myIcon}
      splashRef={splashRef}
      polyStyle={polyStyle}
      newProjectArea={newProjectArea}
      updatePolygonPositions={updatePolygonPositions}
    />
  );
};

const mapStateToProps = state => ({
  getTaskSettings: getTaskSettings(state)
});

const mapDispatchToProps = {
  changeTaskSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaCreationTools);

import React from "react";
import HandlersButton from "../HandlersButton";

const LinearHandlersComponent = ({
  draw,
  draggable,
  magnet,
  repaint,
  handleTransform
}) => {
  return (
    <div className="handlers">
      <HandlersButton
        type="contour"
        active={draw}
        clickType="draw"
        title="Рисование контура"
        onClick={handleTransform}
      />
      <HandlersButton
        type="drag"
        active={draggable}
        title="Перемещение"
        clickType="draggable"
        onClick={handleTransform}
      />
      <HandlersButton
        type="magnet"
        active={magnet}
        clickType="magnet"
        title="Прилипание"
        onClick={handleTransform}
      />
      <HandlersButton
        type="repaint"
        active={repaint}
        clickType="repaint"
        title="Перерисовать"
        onClick={handleTransform}
      />
    </div>
  );
};

export default LinearHandlersComponent;

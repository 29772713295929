import { connect } from "react-redux";
import { wellfields } from "state/selectors/common";
import {
  getProjectName,
  getProjectId,
  wellfieldId
} from "state/selectors/projects";
import { clearState, downloadProject } from "state/actions/projects";
import { setSuccessLogs } from "state/actions/common";
import {
  setRemoveProps,
  openRemoveAlert,
  showModal
} from "state/actions/settings";
import { setMapType } from "state/actions/map";
import TopMenuContainer from "./TopMenuContainer";

const mapStateToProps = state => ({
  wellfields: wellfields(state),
  projectId: getProjectId(state),
  wellfieldId: wellfieldId(state),
  projectName: getProjectName(state)
});

const mapDispatchToProps = {
  showModal,
  clearState,
  setSuccessLogs,
  setRemoveProps,
  openRemoveAlert,
  downloadProject,
  setMapType
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuContainer);

import { connect } from "react-redux";
import { showModal as setShowModal } from "state/actions/settings";
import { setAuth } from "state/actions/auth";
import { modalOption } from "state/selectors/settings";
import LogoutModalContainer from "./LogoutModalContainer";

const mapDipatchToProps = {
  setAuth,
  setShowModal
};

const mapStateToProps = state => ({
  modalOption: modalOption(state)
});

export default connect(
  mapStateToProps,
  mapDipatchToProps
)(LogoutModalContainer);

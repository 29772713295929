import React from "react";

export default function QuarryIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9832 6.74732L13.8717 7.09573H2.12835L2.01683 6.74206C1.9814 6.6281 2.00259 6.50944 2.07396 6.41348C2.14528 6.31741 2.25827 6.25019 2.3784 6.25019H2.9599C2.93204 6.10927 2.91705 5.97229 2.91705 5.8223C2.91705 4.5641 3.94407 3.55462 5.21089 3.55462C5.2999 3.55462 5.38758 3.56011 5.47394 3.56993C5.5201 2.69588 6.24827 2.00103 7.13984 2.00103C7.47294 2.00103 7.78301 2.09813 8.04346 2.26522C8.30392 2.09813 8.61399 2 8.94714 2C9.83871 2 10.5669 2.69339 10.6129 3.56744C10.6993 3.55762 10.787 3.54936 10.876 3.54936C12.1428 3.54936 13.1698 4.56414 13.1698 5.82235C13.1698 5.97234 13.1548 6.10927 13.127 6.25019H13.6216C13.7417 6.25019 13.8547 6.31211 13.926 6.40817C13.9974 6.50414 14.0186 6.63336 13.9832 6.74732ZM13.6383 7.84737L12.3782 11.9039C12.3293 12.0614 12.1827 12.169 12.0168 12.169H11.9182C11.6277 11.7462 11.1213 11.4233 10.546 11.4233C9.97062 11.4233 9.46427 11.7462 9.17373 12.169H6.86126C6.57073 11.7462 6.06438 11.4233 5.48903 11.4233C4.91368 11.4233 4.40733 11.7462 4.1168 12.169H3.98342C3.81742 12.169 3.6708 12.0615 3.62185 11.9039L2.3618 7.84737H13.6383ZM5.53183 14C6.04902 14 6.46828 13.5836 6.46828 13.0699C6.46828 12.5562 6.04902 12.1398 5.53183 12.1398C5.01464 12.1398 4.59538 12.5562 4.59538 13.0699C4.59538 13.5836 5.01464 14 5.53183 14ZM11.5083 13.0699C11.5083 13.5836 11.0891 14 10.5719 14C10.0547 14 9.63543 13.5836 9.63543 13.0699C9.63543 12.5562 10.0547 12.1398 10.5719 12.1398C11.0891 12.1398 11.5083 12.5562 11.5083 13.0699Z"
        fill="#8495AC"
      />
    </svg>
  );
}

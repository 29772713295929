import React from "react";
import ScrollBar from "components/Common/ScrollBar";

import List from "./List";

export default (
  metaclassesByType,
  handleClick,
  title,
  type,
  clickType,
  colorType = "color"
) => {
  return (
    <div>
      <h2 className="topMenuContainerTitle">{title}</h2>
      <div className="topMenuListContainer">
        <ScrollBar>
          <ul>
            <List
              type={type}
              clickType={clickType}
              colorType={colorType}
              handleClick={handleClick}
              metaclassesByType={metaclassesByType}
            />
          </ul>
        </ScrollBar>
      </div>
    </div>
  );
};

import React, { useState } from "react";

import Logout from "./Logout";

export default ({ clickHandler }) => {
  const [show, setShow] = useState(false);
  const showHandler = () => {
    setShow(lastValue => !lastValue);
  };
  const onClickHandler = () => {
    clickHandler();
    setShow(lastValue => !lastValue);
  };
  return (
    <div className="topMenu__col">
      <button className="user" onClick={showHandler} />
      {show && (
        <Logout showHandler={showHandler} onClickHandler={onClickHandler} />
      )}
    </div>
  );
};

import React from "react";
import DataFields from "components/Common/DataFields";
import Button from "components/Common/Button";
import PropTypes from "prop-types";
import "./auth.scss";

const LoginView = ({ username, password, changeHandler, loginHandler }) => (
  <div className="login">
    <div className="login__logo" />
    <DataFields
      type="text"
      value={username}
      placeholder="Логин"
      classContainer="login-input_container"
      onChangeHandler={({ value }) => changeHandler(value, "username")}
    />
    <DataFields
      type="password"
      value={password}
      placeholder="Пароль"
      classContainer="login-input_container"
      onChangeHandler={({ value }) => changeHandler(value, "password")}
    />
    <Button onClick={loginHandler} className="login__button">
      Войти
    </Button>
  </div>
);

LoginView.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  loginHandler: PropTypes.func.isRequired
};

export default LoginView;

import { connect } from "react-redux";
import {
  setTaskId,
  repeatTask,
  downloadTask,
  getTaskResult
} from "state/actions/projects";

import {
  activeTab,
  setRemoveProps,
  openRemoveAlert,
  setTaskFacilities
} from "state/actions/settings";

import { getTasksById } from "state/selectors/projects";

import TasksContainer from "./TasksContainer";

const mapStateToProps = state => ({
  getTasksById: getTasksById(state)
});

const mapDispatchToProps = {
  setTaskId,
  repeatTask,
  downloadTask,
  getTaskResult,
  activeTab,
  setTaskFacilities,
  setRemoveProps,
  openRemoveAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksContainer);

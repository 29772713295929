export default {
  demsUrl: `/api/dems`,
  sendWellfield: "/api/wellfields/load_from_zip",
  projectsUrl: "/api/projects?max_results=100500",
  lastProjects: '/api/projects?sort=[("last_visited", -1)]&max_results=15',
  wellfieldsUrl:
    /* '/api/wellfields?max_results=100500', */ '/api/wellfields?where={"status":"existing"}&max_results=100500',
  lastWellfields: ids =>
    `/api/wellfields?where={"id":${ids}}&max_results=100500`
};

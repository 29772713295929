import React from "react";
import classnames from "classnames";
import _get from "lodash.get";
import { GeoJSON } from "react-leaflet";
import _isEqual from "lodash.isequal";
import Popup from "../../Popup";
import MarkerComponent from "./MarkerComponent";
import "./style.scss";

const onMouseOut = () => {
  const elem = document.getElementsByClassName(`active for-hover`)[0];
  if (elem) {
    elem.classList.remove("active");
  }
};

const onMouseOver = ({ id }) => {
  const elem = document.getElementsByClassName(`${id} for-hover`)[0];
  if (elem && !elem.classList.contains("active")) {
    elem.classList.add("active");
  }
};

export default class FacilityView extends React.Component {
  ref = React.createRef();

  refHoverEl = React.createRef();

  shouldComponentUpdate(nextProps) {
    const {
      data,
      id,
      class_id,
      showRuler,
      type,
      name,
      icon,
      className
    } = this.props;
    if (!_isEqual(data, nextProps.data)) {
      if (this.ref.current) {
        this.refHoverEl.current.leafletElement.clearLayers();
        this.refHoverEl.current.leafletElement.addData(nextProps.data);
        this.ref.current.leafletElement.clearLayers();
        this.ref.current.leafletElement.addData(nextProps.data);
      }
    }
    if (
      !_isEqual(data, nextProps.data) ||
      className !== nextProps.className ||
      icon !== nextProps.icon ||
      name !== nextProps.name ||
      type !== nextProps.type ||
      showRuler !== nextProps.showRuler ||
      id !== nextProps.id ||
      class_id !== nextProps.class_id
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      position,
      class_id,
      id,
      type,
      name,
      style,
      icon,
      className,
      palette,
      data,
      onClickHandler
    } = this.props;
    return (
      <React.Fragment key={id}>
        <GeoJSON
          data={data}
          ref={this.refHoverEl}
          key={`${id + className}for-hover`}
          className={classnames(id, "for-hover")}
        />
        <GeoJSON
          style={style}
          key={id + className}
          ref={this.ref}
          data={data}
          onMouseOut={onMouseOut}
          onClick={onClickHandler}
          onMouseOver={() => onMouseOver({ id })}
          className={classnames(id, type, "facility", className, palette)}
        >
          <MarkerComponent
            id={id}
            icon={icon}
            position={position}
            className={className}
            data={data}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            onClickHandler={onClickHandler}
          />
          <Popup properties={{ name, style, class_id }} />
        </GeoJSON>
      </React.Fragment>
    );
  }
}

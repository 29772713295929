export default {
  gas_rel_density: "",
  flow_temperature: "°C",
  pressure_min: "атм",
  pressure_max: "атм",
  water_density: "кг/м³",
  oil_density: "т/м³",
  mu_oil_20: "мПа·c",
  mu_oil_50: "мПа·c"
};

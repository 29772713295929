import React from "react";
import { GeoJSON } from "react-leaflet";
import classnames from "classnames";
import Popup from "../../Popup";

export default ({ el, index, onClickHandler, openCardHandler }) => {
  const key = el.properties.classNames.reduce((acc = "", val) => {
    return `${acc} ${val}`;
  });
  return (
    <GeoJSON
      data={el}
      setZIndex="99999"
      onClick={onClickHandler}
      style={el.properties.style}
      key={key + el.properties.styleId + index}
      className={classnames(
        [el.properties.styleId, el.properties.objectType],
        el.properties.classNames
      )}
    >
      <Popup
        objectType="linear"
        properties={el.properties}
        openCardHandler={openCardHandler}
      />
    </GeoJSON>
  );
};

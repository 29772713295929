import React, { Component } from "react";
import { connect } from "react-redux";
import { clickType } from "state/actions/settings";
import { getTaskSettings } from "state/selectors/settings";
import pluralizeRus from "utils/pluralizeRus";
import Icon from "./Icon";

class ChooseObjectsButton extends Component {
  chooseObjects = () => {
    this.props.handleActiveButton(this.props.id);
    this.props.clickType("SUCCESS", this.props.chooseObjectsParams);
  };

  render() {
    const { label, name, taskProps, taskType } = this.props;
    let pointsLen = 0;
    if (taskProps.props && taskProps.props[name]) {
      if (typeof taskProps.props[name] === "string") {
        pointsLen = taskProps.props[name].length ? 1 : 0;
      } else {
        pointsLen = taskProps.props[name].length;
      }
    }
    return (
      <div className="choose-objects__button">
        <span
          onClick={this.chooseObjects}
          className="choose-objects__button-container"
        >
          <Icon taskType={taskType} name={name} />
          <span className="choose-objects__button-text">
            {label}:{" "}
            <span className="choose-objects__button__white">
              {!pointsLen ? (
                "не выбран"
              ) : (
                <>
                  <span className="choose-objects__semibold">{pointsLen}</span>{" "}
                  объект{pluralizeRus(pointsLen, ["", "а", "ов"])}
                </>
              )}
            </span>
          </span>
        </span>
        <span
          onClick={this.props.showMenuHandler}
          className="choose-objects__menu-icon"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskProps: getTaskSettings(state)
});

const mapDispatchToProps = {
  clickType
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseObjectsButton);

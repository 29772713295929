import React from "react";
import { useSelector } from "react-redux";
import { additionalTaskPropsRoads } from "state/selectors/wellfield";
import CommonField from "../../CommonField";

const RoadTypes = React.memo(({ error, class_id_target, disabled }) => {
  const additionalTaskRoads = useSelector(additionalTaskPropsRoads);
  let roadTypes = [];
  if (additionalTaskRoads) {
    roadTypes = additionalTaskRoads.map(({ id: value, name: label }) => ({
      value,
      label
    }));
  }
  return (
    <div className="create-task-row">
      <CommonField
        error={error}
        addedProps={{
          required: true,
          propName: "props.class_id_target",
          type: "option",
          placeholder: "",
          classContainer: "wide",
          label: "Класс",
          defaultValue: class_id_target,
          options: roadTypes,
          disabled
        }}
      />
    </div>
  );
});

export default RoadTypes;

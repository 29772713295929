import React from "react";
import ScrollBar from "components/Common/ScrollBar";
import TaskLogs from "../TaskLogs";
import CommonButtons from "../TaskResultsTopRow/CommonButtons";
import LinearCostResult from "./LinearCostResult";

const LinearCost = ({ taskResult }) => {
  const [handler, setHandler] = React.useState("logs");

  return (
    <div className="task-complete">
      <div className="task-complete__toggle-row">
        <CommonButtons currentHandler={handler} toggleHandler={setHandler} />
      </div>
      <div className="task-complete__main-container">
        <ScrollBar>
          {handler === "logs" ? (
            <TaskLogs />
          ) : (
            <LinearCostResult taskResult={taskResult} />
          )}
        </ScrollBar>
      </div>
    </div>
  );
};

export default LinearCost;

import React from "react";
import { useSelector } from "react-redux";
import { getActiveTab } from "state/selectors/settings";
import { getProjectName } from "state/selectors/projects";

import ExpandedTopMenuView from "./ExpandedTopMenuView";

const ExpandedTopMenuContainer = () => {
  const projectName = useSelector(getProjectName);
  const activeTab = useSelector(getActiveTab);
  return (
    <ExpandedTopMenuView projectName={projectName} getActiveTab={activeTab} />
  );
};

export default ExpandedTopMenuContainer;

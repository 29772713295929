import React from "react";
import WithCommonObjectsFunc from "components/HOC/WithCommonObjectsFunc";
import _get from "lodash.get";
import ListWrapper from "../../MainContainer/common/ListWrapper";

const AreasListContainer = props => {
  const hideAreas = ({ id }) => props.hideAreas("SUCCESS", id);

  const showList = id => props.hideAreasGroup("SUCCESS", id);

  const removeItem = ({ name, id, class_id: classId }) => {
    const {
      setRemoveProps,
      openRemoveAlert,
      sortClassesByMetaclasses,
      sortMetaclassesByClasses
    } = props;
    const { title: objectType } = _get(
      sortClassesByMetaclasses,
      sortMetaclassesByClasses[classId]
    );
    setRemoveProps("SUCCESS", {
      id,
      name: name || "Без названия",
      objectType,
      title: "области",
      removeType: "area",
      typeTitle: "области",
      questionTitle: "область"
    });
    openRemoveAlert("SUCCESS", true);
  };
  const removeMetaclass = id => {
    const { setRemoveProps, openRemoveAlert, sortClassesByMetaclasses } = props;
    const { title: objectType } = _get(sortClassesByMetaclasses, id);
    setRemoveProps("SUCCESS", {
      id,
      objectType,
      typeTitle: "области",
      removeType: "metaclass",
      title: "группы областей",
      questionTitle: "группу областей"
    });
    openRemoveAlert("SUCCESS", true);
  };
  const chooseRow = item => {
    props.setCardInfo("SUCCESS", {
      type: "area",
      data: item
    });
    props.activeTab("SUCCESS", "areaInfoCard");
  };
  const { sortedAreas, type, updated } = props;
  if (!sortedAreas) return <h2 className="preload-text">Идёт загрузка...</h2>;
  if (!Object.keys(sortedAreas).length) {
    return <h2 className="preload-text">Список областей пуст</h2>;
  }
  return (
    <ListWrapper
      type={type}
      showRow={hideAreas}
      showList={showList}
      chooseRow={chooseRow}
      updated={updated}
      removeItem={removeItem}
      objects={sortedAreas}
      removeMetaclass={removeMetaclass}
    />
  );
};

export default WithCommonObjectsFunc(AreasListContainer);

import React from "react";
import titleConfig from "./utils/titleConfig";

const TopRow = React.memo(
  ({ type }) => {
    return (
      <div className="main-container__top-row">
        <h2 className="main-title">{titleConfig[type]}</h2>
      </div>
    );
  },
  (nextProps, prevProps) => {
    if (nextProps.type !== prevProps.type) {
      return false;
    }
    return true;
  }
);

export default TopRow;

import groupBy from "utils/groupBy";
import { createSelector } from "reselect";
import {
  classesByWellfield,
  metaclassesByType,
  sortClassesByMetaclasses
} from "state/selectors/wellfield";

import _get from "lodash.get";
import _set from "lodash.set";

export const taskId = ({ project: { taskId } }) => taskId;

export const getProjectId = ({ project: { id } }) => id;

export const networks = ({ project: { networks } }) => networks;

export const taskLogs = ({ project: { taskLogs } }) => taskLogs;

export const taskResult = ({ project: { taskResult } }) => taskResult;

export const getProjectName = ({ project: { projectName } }) => projectName;

export const avaliableTaskTypes = ({ project: { avaliableTaskTypes } }) =>
  avaliableTaskTypes;

export const getProjectArea = ({ project: { projectArea } }) => projectArea;

export const projectPalette = ({ project: { projectPalette } }) =>
  projectPalette;

const filterByName = ({ name }) =>
  name.toLowerCase() !== "вспомогательные объекты";

const filterByClasses = classesByWellfield => key => !!classesByWellfield[key];

const composeFacilityMetaclasses = (
  classesByWellfield,
  projectPalette
) => item => {
  const { id, name } = classesByWellfield[item];
  return {
    id,
    palette: projectPalette[id],
    name: name.charAt(0).toUpperCase() + name.slice(1)
  };
};

export const metaclassesByTypeWithPalette = createSelector(
  [
    metaclassesByType,
    projectPalette,
    sortClassesByMetaclasses,
    classesByWellfield
  ],
  (
    metaclassesByType,
    projectPalette,
    sortClassesByMetaclasses,
    classesByWellfield
  ) => {
    const metaclassesByTypeWithPalette = { ...metaclassesByType };
    const { facility } = metaclassesByType;
    ["area", "linear"].forEach(type => {
      const elem = metaclassesByType[type];
      if (elem && projectPalette) {
        elem.forEach((el, index) => {
          const { id } = el;
          const key = _get(classesByWellfield, [
            _get(sortClassesByMetaclasses, [id, "valuesArray", 0]),
            "id"
          ]);
          _set(
            metaclassesByTypeWithPalette,
            [type, index, "palette"],
            projectPalette[key]
          );
        });
      }
    });
    if (facility && projectPalette) {
      metaclassesByTypeWithPalette.facility = facility.filter(filterByName);
      const { id } = metaclassesByTypeWithPalette.facility[0];
      const valuesArray = _get(sortClassesByMetaclasses, [id, "valuesArray"]);
      if (valuesArray) {
        metaclassesByTypeWithPalette.facility = valuesArray
          .filter(filterByClasses(classesByWellfield))
          .map(composeFacilityMetaclasses(classesByWellfield, projectPalette));
      }
    }
    return metaclassesByTypeWithPalette;
  }
);

export const getTasksById = ({ project: { tasksById } }) => tasksById;

export const getActiveTask = createSelector(
  [taskId, getTasksById],
  (taskId, tasksById) => {
    if (!tasksById || !taskId) return false;
    return tasksById[taskId];
  }
);

export const getTasksResult = ({ project: { tasksResults } }) => tasksResults;

export const taskObjects = ({ project: { taskObjects } }) => taskObjects;

export const linearTaskObjects = ({ project: { taskObjects } }) => {
  if (taskObjects && ["linear", "high_voltage"].includes(taskObjects.type)) {
    return taskObjects.data;
  }
  return [];
};

export const taskQuarries = ({ project: { taskQuarries } }) => taskQuarries;

export const wellfieldId = ({ project: { wellfield_id } }) => wellfield_id;

export const newProjectArea = ({ project: { setNewProjectArea } }) =>
  setNewProjectArea;

export const layout = ({ project: { layout } }) => layout;

export const getTasksByType = ({ project: { tasks } }) =>
  groupBy(tasks, "type");

export const getWellfieldName = state => {
  return _get(state.common.wellfields, [wellfieldId(state), "name"]);
};

export const taskInfo = ({ project }) => {
  if (project.taskId) {
    return _get(project.tasksById, [project.taskId, 0]);
  }
  return {};
};

const filterQuarries = taskQuarries => el => !taskQuarries.includes(el);

export const quarriesTask = createSelector(
  [taskResult, getTasksById, taskQuarries],
  (taskResult, getTasksById, taskQuarries) => {
    const result = taskResult[0];
    if (
      result &&
      taskQuarries &&
      getTasksById[result?.task_id][0]?.type === "quarries"
    ) {
      const { facilities_ids } = result.props;
      return facilities_ids.filter(filterQuarries(taskQuarries));
    }
    return false;
  }
);

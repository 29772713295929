import React from "react";
import LeftMenu from "../LeftMenu";
import TaskResultsContainer from "../TaskResultsContainer";

export default function MainPageView() {
  return (
    <div className="main-page">
      <LeftMenu />
      <TaskResultsContainer />
    </div>
  );
}

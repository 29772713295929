import React from "react";
import { connect } from "react-redux";
import ScrollBar from "components/Common/ScrollBar";
import { cardInfo } from "state/selectors/settings";
import LinearCostResult from "../LinearCost/LinearCostResult";
import Profile from "./Profile";
import Costs from "./Costs";
import GraphContainer from "./GraphContainer";
import CommonButtons from "../TaskResultsTopRow/CommonButtons";

const LinearContainer = ({ currentHandler, taskResult }) => {
  if (currentHandler === "cost") {
    return <LinearCostResult taskResult={taskResult.cost} />;
  }
  if (currentHandler === "profile") {
    return GraphContainer({ data: taskResult, type: "relief", unit: "м" })(
      Profile
    );
  }
  if (currentHandler === "distribution") {
    return GraphContainer({ data: taskResult, type: "costs" })(Costs);
  }
  return null;
};

const Linear = ({ cardInfo }) => {
  const [handler, setHandler] = React.useState("cost");
  const { data } = cardInfo;
  return (
    <div className="task-complete">
      <div className="task-complete__toggle-row">
        <CommonButtons
          type="linear"
          currentHandler={handler}
          toggleHandler={setHandler}
        />
      </div>
      <div className="task-complete__main-container">
        <ScrollBar>
          <LinearContainer taskResult={data} currentHandler={handler} />
        </ScrollBar>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  cardInfo: cardInfo(state)
});

export default connect(mapStateToProps)(Linear);

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const ProjectItem = ({
  date,
  item,
  index,
  remove,
  download,
  wellfield,
  handleClick,
  wellfieldCond
}) => {
  const onClickHandler = () => handleClick(index);

  const onDownloadHandler = () => download(item);

  const onRemoveHander = () => remove(item);

  return (
    <li className="projects__item">
      <div className="projects__item-bg" />
      <div className="projects__col-row" onClick={onClickHandler}>
        {!wellfieldCond && <div className="projects__col-img" />}
        <div
          className={classnames("projects__col-desc", {
            wellfield: wellfieldCond
          })}
        >
          <h2 className="projects__col-desc-title">
            <span className="projects__col-desc-title-text">{item.name}</span>
          </h2>
          {!wellfieldCond && (
            <h3 className="projects__col-desc-subtitle">{wellfield}</h3>
          )}
          <h3 className="projects__col-date">
            <span>
              {wellfieldCond ? "Дата создания:" : "был открыт:"} {date}
            </span>
            {wellfieldCond && (
              <span className="projects-count">
                Проектов: {item.projects_ids.length}
              </span>
            )}
          </h3>
        </div>
      </div>
      <span className="wellfield-icons">
        <span onClick={onDownloadHandler} className="icon download-icon" />
        <span
          onClick={onRemoveHander}
          className="icon remove-icon remove-icon_wellfield"
        />
      </span>
    </li>
  );
};

ProjectItem.propTypes = {
  item: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  index: PropTypes.object.isRequired
};

export default ProjectItem;

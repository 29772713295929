const config = [
  [
    {
      title: "Длина дорог, км",
      value: "total_length",
      divisor: 1000
    },
    {
      title: "Плечо возки, т*км",
      value: "total_arm",
      divisor: 523.286237572
    },
    {
      title: "Объем добычи, м3",
      value: "total_volume",
      divisor: 1
    }
  ],
  [
    {
      title: "Стоимость добычи, млн руб.",
      value: "total_extraction_cost",
      divisor: 1000000
    },
    {
      title: "Стоимость перевозки, млн руб.",
      value: "total_transportation_cost",
      divisor: 1000000
    },
    {
      title: "Стоимость разгрузки, млн руб.",
      value: "total_unloading_cost",
      divisor: 1000000
    },
    {
      title: "Общая стоимость, млн руб.",
      value: [
        "total_extraction_cost",
        "total_transportation_cost",
        "total_unloading_cost"
      ],
      divisor: 1000000
    }
  ]
];

export default config;

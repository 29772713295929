/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";

import LogsResulsComponent from "./LogsResulsComponent";
import DropzoneComponent from "./DropzoneComponent";
import DownloadComponent from "./DownloadComponent";

export default ({
  onCancelChecking,
  onCancelUpload,
  canceled,
  onDrop,
  progress,
  fileName,
  fileSize,
  newWellfieldLogs = []
}) => {
  const [done, setDone] = useState(false);
  const [checkingpercentage, setCheckingpercentage] = useState(0);

  useEffect(() => {
    if (newWellfieldLogs && newWellfieldLogs.length) {
      setDone(newWellfieldLogs.some(({ finished }) => finished));
      setCheckingpercentage(
        newWellfieldLogs[newWellfieldLogs.length - 1].percentage
      );
    }
  }, [newWellfieldLogs]);

  return (
    <div className="new-wellfield__dropzone">
      <h2 className="input-label">Файл</h2>
      {done || canceled ? (
        <LogsResulsComponent
          onDrop={onDrop}
          canceled={canceled}
          fileName={fileName}
          newWellfieldLogs={newWellfieldLogs}
        />
      ) : newWellfieldLogs.length ? (
        <DownloadComponent
          leftLabelText="Проверка "
          rightLabelText="1 из 15 файлов"
          fileName={fileName}
          progress={checkingpercentage}
          onCancel={onCancelChecking}
        />
      ) : progress > 0 ? (
        <DownloadComponent
          leftLabelText="Загрузка "
          rightLabelText={`${(fileSize * (progress / 100)).toFixed(
            1
          )} из ${fileSize} Mb`}
          fileName={fileName}
          progress={progress}
          onCancel={onCancelUpload}
        />
      ) : (
        <DropzoneComponent onDrop={onDrop} fileName={fileName} />
      )}
      <h2 className="new-wellfield__tip">
        Для создания месторождения загрузите{" "}
        <span className="new-wellfield__tip_semibold">zip-архив</span>, имеющий
        определенную структуру.
      </h2>
    </div>
  );
};

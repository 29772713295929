import { combineReducers } from "redux";
import mapReducer from "state/reducers/map";
import authReducer from "state/reducers/auth";
import commonReducer from "state/reducers/common";
import objectsReducer from "state/reducers/objects";
import projectReducer from "state/reducers/project";
import settingsReducer from "state/reducers/settings";
import wellfieldReducer from "state/reducers/wellfield";
import groupsSettingsReducer from "state/reducers/groupsSettings";

export default combineReducers({
  map: mapReducer,
  auth: authReducer,
  common: commonReducer,
  project: projectReducer,
  objects: objectsReducer,
  wellfield: wellfieldReducer,
  groupsSettings: groupsSettingsReducer,
  settings: settingsReducer
});
